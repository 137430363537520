import React from 'react';
import { Tooltip, Drawer } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridValueFormatterParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Company, EmissionFactor, Group } from '@esg/esg-global-types';
import DeleteConfirmModal from '../../shared/modal/DeleteConfirmModal';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import ConfigAddWidget, { CreateInput } from '../ConfigAddWidget';
import ConfigEditWidget, { EditInput } from '../ConfigEditWidget';
import moment, { Moment } from 'moment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ConfiguredMetricSelect from '../../shared/input/select/ConfiguredMetricSelect';
import {
  allowDeleteEmissionFactor,
  createEmissionFactor,
  deleteEmissionFactor,
  getEmissionFactors,
  updateEmissionFactor
} from '../../../lib/app/emission_factor';
import { DocumentReference } from 'firebase/firestore';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { PanelEmissionFactorsToolbar } from './PanelEmissionFactorsToolbar';
import { PanelEmissionFactorsNoRows } from './PanelEmissionFactorsNoRows';
import { PanelEmissionFactorsLoading } from './PanelEmissionFactorsLoading';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';
import { MetricExtended } from '../../../lib/metric_capture/metric';

interface TabProps {
  master_list?: boolean;
}

const PanelEmissionFactors = (props: TabProps) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [displayWidgetPanelRight, setDisplayWidgetPanelRight] = React.useState(false);
  const [isCreateWidget, setIsCreateWidget] = React.useState(true);
  const [gridLoading, setGridLoading] = React.useState(true);
  const [emissionFactorRows, setEmissionFactorRows] = React.useState<Array<EmissionFactor>>([]);
  const [actionRow, setActionRow] = React.useState<EmissionFactor | null>(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [allowEmissionFactorDelete, setAllowEmissionFactorDelete] = React.useState<boolean | null>(
    null
  );

  // Handler functions
  const handleCreateClick = (): void => {
    setActionRow(null);
    setDisplayWidgetPanelRight(true);
    setIsCreateWidget(true);
  };

  const handleEditClick = (row: EmissionFactor): void => {
    setActionRow(row);
    setIsCreateWidget(false);
    setDisplayWidgetPanelRight(true);
  };

  const handleDeleteClick = async (row: EmissionFactor): Promise<void> => {
    try {
      setActionRow(row);
      !props.master_list && group && company && row.ref
        ? setAllowEmissionFactorDelete(
            await allowDeleteEmissionFactor(group.id, company.id, row.ref)
          )
        : setAllowEmissionFactorDelete(true);
      setShowDeleteModal(true);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelEmissionFactors failed on an unknown error while calling handleDeleteClick.',
          {
            group: group,
            company: company,
            emission_factor_ref: row.ref
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to delete Emission Factor. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const handleCloseDeleteModal = (): void => {
    setShowDeleteModal(false);
  };

  // Row functions
  const fetchRows = async (): Promise<void> => {
    try {
      setGridLoading(true);
      // Load rows from database.
      const emission_factors: Array<EmissionFactor> =
        !props.master_list && group && company
          ? await getEmissionFactors(group.id, company.id)
          : await getEmissionFactors();
      emission_factors.map((emission_factor: EmissionFactor) => {
        emission_factor.target_year = moment(emission_factor.start_date).format('yyyy');
        emission_factor.category = emission_factor.metric_group.name;
        emission_factor.start_date = moment(emission_factor.start_date).format('DD MMM yyyy');
        emission_factor.end_date = moment(emission_factor.end_date).format('DD MMM yyyy');
        return {
          ...emission_factor
        };
      });
      // Load rows to memory.
      setEmissionFactorRows(emission_factors);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelEmissionFactors failed on an unknown error while calling fetchRows.',
          !props.master_list
            ? {
                group: group,
                company: company
              }
            : {},
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch emission factors. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setGridLoading(false);
    }
  };

  const deleteRow = async (row: EmissionFactor): Promise<void> => {
    try {
      // Soft delete emission factor database record.
      if (!props.master_list && group && company) {
        await deleteEmissionFactor(
          row.metric_group.id,
          row.metric.id,
          row.id,
          group.id,
          company.id
        );
      } else {
        await deleteEmissionFactor(row.metric_group.id, row.metric.id, row.id);
      }
      // Remove from memory.
      setEmissionFactorRows(
        emissionFactorRows.filter((obj) => {
          return obj.id != row.id;
        })
      );
      // Close modal.
      setShowDeleteModal(false);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelEmissionFactors failed on an unknown error while calling deleteRow.',
          !props.master_list
            ? {
                group: group,
                company: company,
                row: row
              }
            : {},
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to remove emission factor. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const createRow = async (
    metric: MetricExtended,
    start_date: Moment,
    end_date: Moment,
    factor: number,
    source: string
  ): Promise<void> => {
    try {
      // Define firestore document reference for row to be created.
      const emission_factor_doc: DocumentReference =
        !props.master_list && group && company
          ? await createEmissionFactor(
              metric.metric_group.id,
              metric.id,
              {
                metric: metric,
                start_date: start_date,
                end_date: end_date,
                factor: factor,
                source: source
              },
              group.id,
              company.id
            )
          : await createEmissionFactor(metric.metric_group.id, metric.id, {
              metric: metric,
              start_date: start_date,
              end_date: end_date,
              factor: factor,
              source: source
            });
      if (emission_factor_doc) {
        // Add row to memory.
        setEmissionFactorRows([
          {
            id: emission_factor_doc.id,
            metric: metric,
            metric_group: {
              id: metric.metric_group.id
            },
            target_year: moment(start_date).format('yyyy'),
            category: metric.metric_group.name,
            start_date: moment(start_date).format('DD MMM yyyy'),
            end_date: moment(end_date).format('DD MMM yyyy'),
            factor: factor,
            source: source,
            deleted: null,
            ref: emission_factor_doc
          },
          ...emissionFactorRows
        ]);
      } else {
        throw new MetadataError('Could not create Emission Factor in database', {
          emission_factor_doc: emission_factor_doc
        });
      }
      // Close side menu.
      setDisplayWidgetPanelRight(false);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelEmissionFactors failed on an unknown error while calling createRow.',
          !props.master_list
            ? {
                group: group,
                company: company,
                metric: metric,
                start_date: start_date,
                end_date: end_date,
                factor: factor,
                source: source
              }
            : {
                metric: metric,
                start_date: start_date,
                end_date: end_date,
                factor: factor,
                source: source
              },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to create emission factor. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const updateRow = async (
    new_row_data: EmissionFactor,
    old_row_data: EmissionFactor
  ): Promise<void> => {
    try {
      // Update emission factor database record.
      if (!props.master_list && group && company) {
        await updateEmissionFactor(
          new_row_data.metric_group.id,
          new_row_data.metric.id,
          new_row_data,
          old_row_data,
          group.id,
          company.id
        );
      } else {
        await updateEmissionFactor(
          new_row_data.metric_group.id,
          new_row_data.metric.id,
          new_row_data,
          old_row_data
        );
      }
      // Update row in memory.
      setEmissionFactorRows(
        emissionFactorRows.map(
          (emission_factor) => {
            if (emission_factor.id === new_row_data.id) {
              new_row_data.target_year = moment(new_row_data.start_date).format('yyyy');
              new_row_data.category = new_row_data.metric_group.name;
              new_row_data.start_date = moment(new_row_data.start_date).format('DD MMM yyyy');
              new_row_data.end_date = moment(new_row_data.end_date).format('DD MMM yyyy');
              return {
                ...new_row_data
              };
            } else {
              return emission_factor;
            }
          }
          // emission_factor.id === row.id ? row : emission_factor
        )
      );
      // Close side menu.
      setDisplayWidgetPanelRight(false);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelEmissionFactors failed on an unknown error while calling updateRow.',
          !props.master_list
            ? {
                group: group,
                company: company,
                new_row_data: new_row_data,
                old_row_data: old_row_data
              }
            : {
                new_row_data: new_row_data,
                old_row_data: old_row_data
              },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update emission factor. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  React.useEffect(
    () => {
      try {
        setEmissionFactorRows([]);
        (async () => {
          fetchRows();
        })().catch((error) => {
          throw new Error(error);
        });
      } catch (err: unknown) {
        const tracking_id: string = uuidv4();
        log(
          'error',
          new MetadataError(
            err instanceof Error
              ? err.message
              : 'Error: PanelEmissionFactors failed on an unknown error while calling fetchRows.',
            null,
            tracking_id
          )
        );
        setFeedbackData({
          message: `An error occurred. Tracking ID: ${tracking_id}`,
          state: true,
          type: 'error'
        });
      }
      return;
    },
    props.master_list ? [] : [group, company]
  );

  const columns: Array<GridColDef> = [
    {
      field: 'category',
      headerName: 'Category',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'start_date',
      headerName: 'Start',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'end_date',
      headerName: 'End',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'target_year',
      headerName: 'Target Year',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'metric',
      headerName: 'Metric',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.metric.name;
      }
    },
    {
      field: 'factor',
      headerName: 'Factor',
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) return 0;
        return Number(
          params.value.toLocaleString(undefined, {
            maximumFractionDigits: 10,
            useGrouping: false
          })
        );
      },
      flex: 1
    },
    {
      field: 'metric_unit',
      headerName: 'Unit',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.metric.unit;
      }
    },
    {
      field: 'source',
      headerName: 'Source',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      headerAlign: 'right',
      align: 'right',
      hideable: false,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <>
            <GridActionsCellItem
              key={1}
              icon={
                <Tooltip title="Edit emission factor">
                  <EditIcon color="primary" />
                </Tooltip>
              }
              size="large"
              label="Edit"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleEditClick(row)}
            />
            <GridActionsCellItem
              key={2}
              icon={
                <Tooltip title="Delete emission factor">
                  <DeleteIcon color="primary" />
                </Tooltip>
              }
              size="large"
              label="Delete"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleDeleteClick(row)}
            />
          </>
        ];
      }
    }
  ];

  const metricSelectInputFactory = (
    handleInputChange: (input_id: string, value: unknown) => void
  ) => {
    return !props.master_list && group && company ? (
      <ConfiguredMetricSelect
        handleInputChange={handleInputChange}
        value={actionRow}
        group_id={group.id}
        company_id={company.id}
      />
    ) : (
      <ConfiguredMetricSelect handleInputChange={handleInputChange} value={actionRow} />
    );
  };
  const create_widget_inputs: readonly CreateInput[] = [
    {
      id: 'metric',
      type: 'node',
      label: 'Metrics',
      inputNodeFactory: metricSelectInputFactory
    },
    {
      id: 'start_date',
      type: 'date',
      label: 'Start',
      defaultValue: moment().startOf('year')
    },
    {
      id: 'end_date',
      type: 'date',
      label: 'End',
      defaultValue: moment().endOf('year')
    },
    {
      id: 'factor',
      type: 'number',
      label: 'Factor'
    },
    {
      id: 'source',
      type: 'text',
      label: 'Source'
    }
  ];

  const edit_widget_inputs: Array<EditInput> = [
    {
      id: 'start_date',
      type: 'date',
      label: 'Start',
      defaultValue: moment(actionRow?.start_date)
    },
    {
      id: 'end_date',
      type: 'date',
      label: 'End',
      defaultValue: moment(actionRow?.end_date)
    },
    {
      id: 'factor',
      type: 'number',
      label: 'Factor'
    },
    {
      id: 'source',
      type: 'text',
      label: 'Source'
    }
  ];

  return (
    <>
      {/* Delete Confirmation Modal */}
      <DeleteConfirmModal
        open={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        delete_label={actionRow && actionRow.metric.name}
        handleDelete={actionRow && (() => deleteRow(actionRow))}
        allow_archive={false}
        allow_delete={allowEmissionFactorDelete}
        item_type={'Emission Factor'}
        delete_warning={'You will be required to create this Emission Factor again if deleted.'}
      />

      {/* Side Widget Panel */}
      <React.Fragment key={'right_add'}>
        <Drawer
          anchor={'right'}
          open={displayWidgetPanelRight}
          onClose={() => {
            setDisplayWidgetPanelRight(false);
          }}
          PaperProps={{ style: { width: '40%', padding: '1.5rem' } }}
        >
          {/* Create / Edit Widget */}
          {isCreateWidget ? (
            <ConfigAddWidget
              create_label="Emission Factor"
              create_icon={<AddCircleOutlineIcon sx={{ marginRight: '1rem' }} fontSize="medium" />}
              handleClose={() => setDisplayWidgetPanelRight(false)}
              create_function_inputs={create_widget_inputs}
              createFunction={(
                metric: MetricExtended,
                start_date: Moment,
                end_date: Moment,
                factor: number,
                source: string
              ) => createRow(metric, start_date, end_date, factor, source)}
            />
          ) : (
            <ConfigEditWidget
              edit_label={actionRow ? actionRow.metric.name : 'Emission Factor'}
              edit_icon={<EditIcon sx={{ marginRight: '1rem' }} fontSize="medium" />}
              handleClose={() => setDisplayWidgetPanelRight(false)}
              edit_entity={actionRow}
              edit_function_inputs={edit_widget_inputs}
              handleEditInput={(input_id: string, value: unknown) =>
                actionRow && setActionRow({ ...actionRow, [input_id]: value })
              }
              confirmEditFunction={(edit_entity: EmissionFactor, original_data: EmissionFactor) =>
                updateRow(edit_entity, original_data)
              }
            />
          )}
        </Drawer>
      </React.Fragment>

      {/* Interactive Data Table */}
      <DataGrid
        autoHeight
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          },
          sorting: {
            sortModel: [{ field: 'target_year', sort: 'desc' }]
          },
          columns: {
            columnVisibilityModel: {
              start_date: false,
              end_date: false
            }
          }
        }}
        hideFooter={emissionFactorRows.length > 1 ? false : true}
        columnHeaderHeight={emissionFactorRows.length < 1 ? 0 : 56}
        pageSizeOptions={[10, 25, 50, 100]}
        loading={gridLoading}
        rows={emissionFactorRows}
        disableRowSelectionOnClick
        slots={{
          toolbar: PanelEmissionFactorsToolbar,
          noRowsOverlay: PanelEmissionFactorsNoRows,
          loadingOverlay: PanelEmissionFactorsLoading
        }}
        slotProps={{ toolbar: { handleCreate: handleCreateClick } }}
        sx={{ '&, [class^=MuiDataGrid]': { border: 'none' } }}
      />
    </>
  );
};

export default PanelEmissionFactors;
