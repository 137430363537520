import React from 'react';
import './App.css';

import AppBase from './components/app/AppBase';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import 'typeface-montserrat';

const theme = createTheme({
  palette: {
    primary: {
      main: '#053d5d'
    },
    secondary: {
      main: '#000000'
    }
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(',')
  }
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AppBase data-testid="app_base-id" />
      </ThemeProvider>
    </div>
  );
}

export default App;
