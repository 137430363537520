import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { Country } from '@esg/esg-global-types';
import {
  country_label,
  generateFlagURI,
  getCountries,
  getCountryMasterList
} from '../../../../lib/metric_capture/country';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Checkbox } from '@mui/material';

interface CountrySelectProps {
  handleChangeCountries?: (countries: Array<Country> | Country | null) => void;
  defaultCountryToParent?: (countries: Array<Country>) => void;
  options?: Array<Country>;
  selected_options?: Array<Country> | Country | null;
  disabled?: boolean;
  input_label?: string;
  multi_select?: boolean;
  tag_limit?: number;
  group_id?: string;
  company_id?: string;
  master_list?: boolean;
  loading?: boolean;
  variant?: 'standard' | 'outlined';
}

// Checkbox icons
const check_icon_blank = <CheckBoxOutlineBlankIcon fontSize="small" />;
const check_icon_filled = <CheckBoxIcon fontSize="small" />;

/**
 * Country selection auto-complete component that handles a variety of built-in scenarios.
 * @param {Array<Country> | Country | null} props.handleChangeCountries Callback function to notify the parent component when the selected options have changed.
 * @param {Array<Country>} props.defaultCountryToParent Callback function to set the default country value in a parent component from the list of country options
 * @param {Array<Country>} props.options Custom list of options provided by the parent component. Defaults to none.
 * @param {Array<Country> | Country | null} props.selected_options Custom list of selected options provided by the parent component. Changes component from an uncontrolled to controlled.
 * @param {boolean} props.disabled Renders the component in a disabled state along with any other provided components. Defaults to enabled.
 * @param {string} props.input_label Sets a custom component input label. Defaults to the singular version of the main entity name.
 * @param {string} props.group_by Group the options by any one of a variety of grouping options. Defaults to ungrouped.
 * @param {boolean} props.multi_select Allow multi option selection and keeps the select open while selecting. Defaults to a single select and closes after selecting an option.
 * @param {number} props.tag_limit Sets a limit on the amount of tags rendered in the input. Defaults to unlimited.
 * @param {string} props.company_id Query configured countries for the provided company. Works in conjunction with props.group_id. Optionally works in conjunction with unconfigured_only or standard_ids. Defaults to empty list.
 * @param {boolean} props.master_list Forcefully query countries from the master list. Does not require group_id and company_id. Defaults to false.
 * @param {boolean} props.loading A flag to set the autocomplete into a loading state for things such as fetching entries from database.
 * @param {string} props.variant An optional styling parameter for the textbox input of the autocomplete
 */
const CountrySelect = (props: CountrySelectProps) => {
  const [optionList, setOptions] = React.useState<Array<Country>>([]);
  const [selectedOptions, setSelectedOptions] = React.useState<Array<Country> | Country | null>(
    props.multi_select ? [] : null
  );

  // Define autocomplete options
  React.useEffect(() => {
    (async () => {
      const options: Array<Country> = props.options
        ? // Countries supplied by the parent component.
          props.options
        : // All master list countries.
          props.master_list
          ? await getCountryMasterList()
          : props.group_id && props.company_id
            ? // Configured countries per company.
              await getCountries(props.group_id, props.company_id)
            : [];
      if (options) setOptions(options);
    })();
  }, [props.group_id, props.company_id, props.options]);

  // Define selected options
  React.useEffect(() => {
    setSelectedOptions(
      props.multi_select
        ? Array.isArray(props.selected_options)
          ? props.selected_options
          : []
        : props.selected_options
          ? props.selected_options
          : null
    );
  }, [props.options, props.selected_options]);

  React.useEffect(() => {
    (async () => {
      if (props.defaultCountryToParent) {
        const countries: Array<Country> = props.options
          ? props.options
          : await getCountryMasterList();
        if (countries) {
          props.defaultCountryToParent(countries);
        }
      }
    })();
  }, []);

  return (
    <Autocomplete
      id="country-select"
      fullWidth
      disabled={props.disabled}
      loading={props.loading}
      limitTags={props.tag_limit}
      options={optionList}
      value={selectedOptions}
      getOptionLabel={(option: Country) => {
        return option.name;
      }}
      disableCloseOnSelect={props.multi_select}
      isOptionEqualToValue={(option: Country, value: Country) => option.id === value.id}
      multiple={props.multi_select}
      noOptionsText={`No ${country_label.many.toLowerCase()}`}
      renderOption={(render_props, option: Country, { selected }) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...render_props}
          key={option.id}
        >
          {props.multi_select && (
            <Checkbox
              icon={check_icon_blank}
              checkedIcon={check_icon_filled}
              style={{ marginRight: 8 }}
              checked={selected}
            />
          )}
          <img
            loading="lazy"
            width="20"
            srcSet={`${generateFlagURI(option.code)} 2x`}
            src={generateFlagURI(option.code)}
            alt={option.name}
          />
          {option.name}
        </Box>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label={props.input_label ?? country_label.one}
          InputProps={{
            ...params.InputProps,
            ...(!props.multi_select &&
              props.selected_options &&
              !Array.isArray(props.selected_options) &&
              props.selected_options.code && {
                startAdornment: (
                  <img
                    loading="lazy"
                    width="20"
                    srcSet={`${generateFlagURI(props.selected_options.code)} 2x`}
                    src={generateFlagURI(props.selected_options.code)}
                    alt={props.selected_options.name}
                  />
                )
              })
          }}
          variant={props.variant ?? 'outlined'}
        />
      )}
      onChange={(
        event: React.SyntheticEvent<Element, Event>,
        value: Array<Country> | Country | null
      ) => {
        setSelectedOptions(value);
        props.handleChangeCountries && props.handleChangeCountries(value);
      }}
    />
  );
};

export default CountrySelect;
