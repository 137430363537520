import { Country, User } from '@esg/esg-global-types';
import { Box, Grid, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { InputErrors } from '../../../@types/shared';
import {
  validateEmail,
  validatePasswordInput,
  validatePhoneNumber
} from '../../../lib/validation/text_validation';
import CountrySelect from '../../shared/input/select/CountrySelect';
import { getCountryFromListByPhone } from '../../../lib/metric_capture/country';
import RandExp from 'randexp';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';

const AccountDetails = ({
  user,
  password,
  confirm_password,
  input_errors,
  handleUserChange,
  handleCountryChange,
  handleChangePassword,
  handleChangeConfirmPassword,
  handleInputErrors
}: {
  user: User;
  password?: string;
  confirm_password?: string;
  input_errors: InputErrors;
  handleUserChange: (user_data: User) => void;
  handleCountryChange: (country_data: Country | null) => void;
  handleChangePassword?: (password: string) => void;
  handleChangeConfirmPassword?: (confirm_password: string) => void;
  handleInputErrors: (input_id: string, error: string) => void;
}) => {
  const [selectedCountry, setSelectedCountry] = React.useState<Country | null>(null);

  const valid_phone_example = new RandExp(selectedCountry?.phone_regex || '').gen();

  const handleChangeFirstName = (first_name: string) => {
    if (first_name) handleInputErrors('first_name', '');
    handleUserChange({ ...user, first_name: first_name });
  };

  const handleChangeLastName = (last_name: string) => {
    if (last_name) handleInputErrors('last_name', '');
    handleUserChange({ ...user, last_name: last_name });
  };

  const handleChangeEmail = (email: string) => {
    if (email && validateEmail(email)) handleInputErrors('email', '');
    handleUserChange({ ...user, email: email });
  };

  const handleChangeCountry = (new_country: Array<Country> | Country | null) => {
    if (!Array.isArray(new_country)) {
      setSelectedCountry(new_country);
      handleCountryChange(new_country);
      if (user?.phone && new_country?.phone_regex && new_country?.phone_code && new_country?.name) {
        handleInputErrors(
          'phone',
          validatePhoneNumber(user.phone, new_country.phone_regex)
            ? ''
            : `Number should start with +${new_country.phone_code}, have the correct amount of characters for ${new_country.name} and contain no spaces.`
        );
        handleUserChange({ ...user, phone: user?.phone });
      }
    }
  };

  const getCountryMasterListFromSelect = (countries: Array<Country>): void => {
    const user_country: Country | null = getCountryFromListByPhone(user.phone, countries);
    if (user_country) {
      setSelectedCountry(user_country);
      handleCountryChange(user_country);
    }
  };

  const handleChangePhone = (phone: string) => {
    if (
      phone &&
      selectedCountry?.phone_regex &&
      selectedCountry?.phone_code &&
      selectedCountry?.name
    ) {
      handleInputErrors(
        'phone',
        validatePhoneNumber(phone, selectedCountry.phone_regex)
          ? ''
          : `Number should start with +${selectedCountry.phone_code}, have the correct amount of characters for ${selectedCountry.name} and contain no spaces.`
      );
    }
    handleUserChange({ ...user, phone: phone });
  };

  return (
    <Box>
      <Typography sx={{ fontSize: '1.4rem', letterSpacing: 2.5, mt: 6, mx: 4 }}>
        Account Details
      </Typography>
      <Box sx={{ m: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              type="text"
              label="First Name"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeFirstName(event.target.value)
              }
              onBlur={() => handleInputErrors('first_name', user.first_name ? '' : 'Required')}
              value={user.first_name}
              error={!!input_errors.first_name}
              helperText={input_errors.first_name}
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              type="text"
              label="Last Name"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeLastName(event.target.value)
              }
              onBlur={() => handleInputErrors('last_name', user.last_name ? '' : 'Required')}
              value={user.last_name}
              error={!!input_errors.last_name}
              helperText={input_errors.last_name}
            ></TextField>
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              required
              type="email"
              label="Email Address"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeEmail(event.target.value)
              }
              onBlur={() =>
                handleInputErrors('email', validateEmail(user.email) ? '' : 'Invalid Email')
              }
              value={user.email}
              error={!!input_errors.email}
              helperText={input_errors.email}
            ></TextField>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={4.5}>
                <CountrySelect
                  master_list
                  handleChangeCountries={handleChangeCountry}
                  defaultCountryToParent={getCountryMasterListFromSelect}
                  selected_options={selectedCountry}
                />
              </Grid>
              <Grid item xs={7.5}>
                <TextField
                  fullWidth
                  required
                  disabled={!selectedCountry}
                  type="text"
                  label="Contact Number"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangePhone(event.target.value);
                  }}
                  onBlur={() => {
                    handleInputErrors(
                      'phone',
                      validatePhoneNumber(user.phone, selectedCountry?.phone_regex)
                        ? ''
                        : `Number should start with +${selectedCountry?.phone_code}, have the correct amount of characters for ${selectedCountry?.name} and contain no spaces.`
                    );
                  }}
                  value={user.phone}
                  error={!!input_errors.phone}
                  helperText={
                    <>
                      {!input_errors.phone && (
                        <>
                          <Stack alignItems="center" direction="row" gap={1}>
                            <InfoIcon fontSize="small" />
                            <Typography variant="caption">
                              Phone number used for Multifactor Authentication.
                            </Typography>
                          </Stack>
                        </>
                      )}
                      {input_errors.phone && (
                        <>
                          <Stack alignItems="center" direction="row" gap={1}>
                            <ErrorIcon fontSize="small" />
                            <Typography variant="caption">
                              {input_errors.phone} Example: {valid_phone_example}
                            </Typography>
                          </Stack>
                        </>
                      )}
                    </>
                  }
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
          {password !== undefined &&
            handleChangePassword &&
            confirm_password !== undefined &&
            handleChangeConfirmPassword && (
              <>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required
                    type="password"
                    label="Password"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (validatePasswordInput(event.target.value)) {
                        handleInputErrors('password', '');
                      }
                      handleChangePassword(event.target.value);
                    }}
                    onBlur={() =>
                      handleInputErrors(
                        'password',
                        validatePasswordInput(password) ? '' : 'Invalid Password'
                      )
                    }
                    value={password}
                    error={!!input_errors.password}
                    helperText={
                      <>
                        Password must be at least 6 characters
                        <br></br>
                        {input_errors.password && input_errors.password}
                      </>
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required
                    type="password"
                    label="Confirm Password"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.value === password) {
                        handleInputErrors('confirm_password', '');
                      }
                      handleChangeConfirmPassword(event.target.value);
                    }}
                    onBlur={() =>
                      handleInputErrors(
                        'confirm_password',
                        password === confirm_password ? '' : 'Passwords must match'
                      )
                    }
                    value={confirm_password}
                    error={!!input_errors.confirm_password}
                    helperText={input_errors.confirm_password}
                  ></TextField>
                </Grid>
              </>
            )}
        </Grid>
      </Box>
    </Box>
  );
};

export default AccountDetails;
