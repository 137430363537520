import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import ContactFormTextInput from './ContactFormTextInput';
import ContactFormSelect from './ContactFormSelect';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINT } from '../../env';
import { SelectChangeEvent } from '@mui/material';
import { createTheme, ThemeProvider, Theme, useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { FeedbackSnackbarContext } from '../../context/FeedbackSnackbarContext';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../util/log';

interface ContactFormStyles {
  colors: {
    primary?: string;
    secondary?: string;
    error?: string;
  };
}

const ContactForm = ({ styles }: { styles: ContactFormStyles }) => {
  const [input_first_name, setInputFirstName] = React.useState('');
  const [input_last_name, setInputLastName] = React.useState('');
  const [input_company, setInputCompany] = React.useState('');
  const [input_interest, setInputInterest] = React.useState('');
  const [input_message, setInputMessage] = React.useState('');
  const [input_email, setInputEmail] = React.useState('');
  const [input_phone, setInputPhone] = React.useState('');
  const [sendLoading, setSendLoading] = React.useState<boolean>(false);
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);

  const navigate = useNavigate();

  const outerTheme = useTheme();
  const textInputTheme = (outerTheme: Theme) =>
    createTheme({
      palette: {
        mode: outerTheme.palette.mode
      },
      components: {
        MuiTextField: {
          styleOverrides: {
            root: {
              '--TextField-brandBorderColor': styles.colors.primary,
              '--TextField-brandBorderHoverColor': styles.colors.primary,
              '--TextField-brandBorderFocusedColor': styles.colors.primary,
              '& label.Mui-focused': {
                color: styles.colors.primary
              }
            }
          }
        },
        MuiInput: {
          styleOverrides: {
            root: {
              '&:before': {
                borderBottom: '1px solid var(--TextField-brandBorderColor)'
              },
              '&:hover:not(.Mui-disabled, .Mui-error):before': {
                borderBottom: '1px solid var(--TextField-brandBorderHoverColor)'
              },
              '&.Mui-focused:after': {
                borderBottom: '1px solid var(--TextField-brandBorderFocusedColor)'
              }
            }
          }
        }
      }
    });

  const input_validity = {
    null_fields:
      input_first_name &&
      input_last_name &&
      input_company &&
      input_interest &&
      input_message &&
      input_email &&
      input_phone
        ? ''
        : 'Please fill out all fields',
    invalid_email:
      input_email.length === 0 || input_email.indexOf('@') !== -1 ? '' : 'Invalid Email'
  };

  const form_invalid = Object.values(input_validity).some((value) => value !== '') ? true : false;

  const interest_choices = [
    {
      value: 'Readiness Gauge'
    },
    {
      value: 'Metric Collection'
    },
    {
      value: 'Risks and Opportunities'
    },
    {
      value: 'Report Compilation'
    },
    {
      value: 'Resource Center'
    }
  ];

  const handleSend = async () => {
    const request_body: object = {
      first_name: input_first_name,
      last_name: input_last_name,
      company: input_company,
      interest: input_interest,
      message: input_message,
      email: input_email,
      phone: input_phone
    };
    try {
      setSendLoading(true);
      const response = await fetch(`${API_ENDPOINT}/send_contact_request`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request_body)
      });
      if (response.ok) {
        navigate('/thank-you');
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: ContactForm failed on an unknown error while calling handleSend.',
          {
            request_body: request_body
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to send contact request. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setSendLoading(false);
    }
  };

  const handleFirstNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputFirstName(event.target.value);
  };

  const handleLastNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputLastName(event.target.value);
  };

  const handleCompanyInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputCompany(event.target.value);
  };

  const handleInterestInput = (event: SelectChangeEvent<string>) => {
    setInputInterest(event.target.value);
  };

  const handleMessageInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputMessage(event.target.value);
  };

  const handleEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputEmail(event.target.value);
  };

  const handlePhoneInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputPhone(event.target.value);
  };

  return (
    <ThemeProvider theme={textInputTheme(outerTheme)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingY: 5,
          paddingX: 20,
          border: `1px solid ${styles.colors.primary}`
        }}
      >
        <Grid container spacing={12}>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <ContactFormTextInput
                  id="text-first-name"
                  input_label="First Name"
                  handleInputChange={handleFirstNameInput}
                  primary_color={styles.colors.primary}
                />
              </Grid>
              <Grid item xs={12}>
                <ContactFormTextInput
                  id="text-last-name"
                  input_label="Last Name"
                  handleInputChange={handleLastNameInput}
                  primary_color={styles.colors.primary}
                />
              </Grid>
              <Grid item xs={12}>
                <ContactFormTextInput
                  id="text-company"
                  input_label="Company Name"
                  handleInputChange={handleCompanyInput}
                  primary_color={styles.colors.primary}
                />
              </Grid>
              <Grid item xs={12}>
                <ContactFormTextInput
                  id="text-email"
                  input_label="Email"
                  type="email"
                  handleInputChange={handleEmailInput}
                  primary_color={styles.colors.primary}
                  options={
                    input_validity.invalid_email
                      ? {
                          error: true,
                          helperText: 'Invalid Email'
                        }
                      : {}
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <ContactFormTextInput
                  id="text-phone"
                  input_label="Contact Number"
                  handleInputChange={handlePhoneInput}
                  primary_color={styles.colors.primary}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <ContactFormSelect
                  id="interest-select-label"
                  label="I'm Interested In..."
                  choices={interest_choices}
                  handleInputChange={handleInterestInput}
                  primary_color={styles.colors.primary}
                />
              </Grid>
              <Grid item xs={12}>
                <ContactFormTextInput
                  id="text-message"
                  input_label="Message"
                  handleInputChange={handleMessageInput}
                  primary_color={styles.colors.primary}
                  options={{
                    multiline: true,
                    rows: 3
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <LoadingButton
                  variant="contained"
                  loading={sendLoading}
                  sx={{
                    letterSpacing: 2,
                    width: '60%',
                    marginTop: 5
                  }}
                  onClick={async () => await handleSend()}
                  disabled={form_invalid}
                >
                  SEND
                </LoadingButton>
                {form_invalid && (
                  <Typography variant="caption" color="#d32f2f" paddingTop={0.5}>
                    {input_validity.null_fields}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default ContactForm;
