import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import ei_logo from '../../assets/ei-logo.webp';
import { Link, useLocation } from 'react-router-dom';
import LoginButton from '../app/authentication/LoginButton';
import LoginModal from '../app/authentication/LoginModal';

const pages = [
  { title: 'ESG', route: '/' },
  { title: 'Readiness', active: false, route: '/' }, // /web/readiness
  { title: 'Resources', route: '/' }, // /web/resources
  { title: 'Assessment', route: '/' }, // /web/assessment
  { title: 'Reporting', route: '/' }, // /web/reporting
  { title: 'Data', route: '/' }, // /web/data
  { title: 'Contact', route: '/contact' }
];

interface PropTypes {
  setAuthenticated(state: boolean): void;
}

function NavBarWeb(props: PropTypes) {
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const location = useLocation();
  const current_route = location.pathname;

  const handleNavItemClick = () => {
    //alert(event);
  };

  const handleShowLogin = (state: boolean) => {
    setShowLoginModal(state);
  };

  return (
    <>
      {showLoginModal && (
        <LoginModal
          showLoginModal={showLoginModal}
          setShowLoginModal={setShowLoginModal}
          setAuthenticated={props.setAuthenticated}
        />
      )}
      <AppBar position="fixed" className="appbar-base" sx={{ backgroundColor: '#053d5d' }}>
        <Container maxWidth={false} sx={{ marginX: 0 }} className="appbar-container">
          <Toolbar disableGutters>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 0.3, ml: 8 }}>
              <img src={ei_logo} alt="Logo" height={35} />
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
                gap: 3
              }}
            >
              {pages.map((page) => (
                <Link
                  key={page.title}
                  to={page.route}
                  style={{ textDecoration: 'none', fontSize: '12px' }}
                >
                  <Button
                    onClick={handleNavItemClick}
                    sx={
                      current_route === page.route
                        ? {
                            fontWeight: 'bold',
                            my: 2,
                            color: 'white',
                            display: 'block',
                            fontSize: '12px'
                          }
                        : { my: 2, color: 'white', display: 'block', fontSize: '12px' }
                    }
                  >
                    {page.title}
                  </Button>
                </Link>
              ))}
            </Box>
            <Box
              sx={{
                flexGrow: 0.4,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'flex-end',
                mr: 8
              }}
            >
              <LoginButton handleLogin={handleShowLogin} />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
export default NavBarWeb;
