import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FeedbackSnackbarContext } from '../../../../context/FeedbackSnackbarContext';
import { QualitativeMetricRecordGridRow } from './MetricRecordGridQualitative';
import { Box, Button, DialogActions, Skeleton, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReportingPeriodGroupSelect from '../../../shared/input/select/ReportingPeriodGroupSelect';
import { CaptureContext, MetricRecordEntities } from '../../../../@types/shared';
import { Company, Group, MetricRecord, ReportingPeriodGroup } from '@esg/esg-global-types';
import { getQualitativeMetricRecord } from '../../../../lib/metric_capture/metric_record';
import { GroupContext } from '../../../../context/GroupContext';
import { CompanyContext } from '../../../../context/CompanyContext';
import AlertBanner from '../../../shared/banner/AlertBanner';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../../util/log';
import { MetadataError } from '@ep/error-handling';

/**
 * A modal allowing the user to copy a qualitative Metric Record's status and value from another Reporting Period Group.
 * @param {boolean} open The display state of the modal.
 * @param {QualitativeMetricRecordGridRow | null} metric_record The metric record to copy to.
 * @param {MetricRecordEntities} capture_entities Options for the selected Reporting Period Group.
 * @param {CaptureContext} capture_context Data for the updating of the copied Metric Record.
 * @param {Promise<QualitativeMetricRecordGridRow | undefined>} handleUpdateRow Function to update the chosen Metric Record with the copied data.
 * @param {void} handleCloseCopyModal Handler function to close the modal.
 * @returns {JSX.Element}
 */
const CopyQualitativeMetricRecordModal = ({
  open,
  metric_record,
  capture_entities,
  capture_context,
  handleUpdateRow,
  handleCloseCopyModal
}: {
  open: boolean;
  metric_record: QualitativeMetricRecordGridRow | null;
  capture_entities: MetricRecordEntities;
  capture_context: CaptureContext;
  handleUpdateRow: (
    old_metric_record: QualitativeMetricRecordGridRow,
    new_metric_record: QualitativeMetricRecordGridRow
  ) => Promise<QualitativeMetricRecordGridRow | undefined>;
  handleCloseCopyModal: () => void;
}) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [copyMetricRecord, setCopyMetricRecord] = React.useState<MetricRecord | null | undefined>(
    undefined
  );
  const [copyLoading, setCopyLoading] = React.useState<boolean>(false);
  const [copyMetricRecordLoading, setCopyMetricRecordLoading] = React.useState<boolean>(false);

  const available_reporting_period_groups: Array<ReportingPeriodGroup> =
    capture_entities.reporting_period_groups.filter(
      (reporting_period_group: ReportingPeriodGroup) => {
        return reporting_period_group.id !== capture_context.reporting_period_group?.id;
      }
    );

  const copyMetricRecordFromReportingPeriodGroup = async (): Promise<void> => {
    setCopyLoading(true);
    try {
      if (metric_record && copyMetricRecord && copyMetricRecord.status !== undefined) {
        const new_metric_record: QualitativeMetricRecordGridRow = {
          ...metric_record,
          status: copyMetricRecord.status,
          value: copyMetricRecord.value.toString()
        };
        await handleUpdateRow(metric_record, new_metric_record);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: CopyQualitativeMetricRecordModal failed on an unknown error while calling copyMetricRecordFromReportingPeriodGropu.',
          {
            group: group,
            company: company,
            old_metric_record: metric_record,
            new_metric_record: copyMetricRecord
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update metric record. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setCopyLoading(false);
    }
  };

  const findMetricRecordToCopy = async (
    reporting_period_group: ReportingPeriodGroup
  ): Promise<void> => {
    setCopyMetricRecordLoading(true);
    try {
      if (group && company && metric_record) {
        const copy_metric_record: MetricRecord | null = await getQualitativeMetricRecord(
          group.id,
          company.id,
          metric_record.metric,
          reporting_period_group
        );
        setCopyMetricRecord(copy_metric_record);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: CopyQualitativeMetricRecordModal failed on an unknown error while calling findMetricRecordToCopy.',
          {
            group: group,
            company: company,
            reporting_period_group: reporting_period_group
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update metric record. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setCopyMetricRecordLoading(false);
    }
  };

  const handleCloseModal = (): void => {
    setCopyMetricRecord(undefined);
    handleCloseCopyModal();
  };

  return (
    <Dialog open={open} onClose={handleCloseModal}>
      <DialogTitle>Copy Metric Record from Reporting Period Group</DialogTitle>
      <DialogContent>
        <>
          <DialogContentText sx={{ mb: 4 }}>{metric_record?.metric.name}</DialogContentText>
          <ReportingPeriodGroupSelect
            reporting_period_group_options={available_reporting_period_groups}
            handleChangeReportingPeriodGroups={async (
              reporting_period_group: Array<ReportingPeriodGroup> | ReportingPeriodGroup | null
            ) => {
              if (reporting_period_group) {
                await findMetricRecordToCopy(reporting_period_group as ReportingPeriodGroup);
              }
            }}
          />
          {copyMetricRecordLoading ? (
            <Skeleton sx={{ my: 4, height: 75 }} />
          ) : (
            <>
              {copyMetricRecord === null && (
                <Box sx={{ my: 4 }}>
                  <AlertBanner
                    severity="info"
                    open
                    message={`No Existing Metric Record found for selected Reporting Period Group`}
                  />
                </Box>
              )}
              {copyMetricRecord && (
                <Box sx={{ my: 4 }}>
                  <DialogContentText>Found Metric Record: </DialogContentText>
                  <Box
                    sx={{
                      my: 2,
                      border: '1px solid rgba(0, 0, 0, 0.3)',
                      borderRadius: '3px',
                      p: 2
                    }}
                  >
                    <DialogContentText sx={{ mb: 1 }}>Status:</DialogContentText>
                    <Typography sx={{ mb: 3 }}>
                      {copyMetricRecord.status ? 'True' : 'False'}
                    </Typography>
                    <DialogContentText sx={{ mb: 1 }}>Details:</DialogContentText>
                    <Typography>{copyMetricRecord.value}</Typography>
                  </Box>
                </Box>
              )}
            </>
          )}
        </>
        <DialogActions sx={{ mt: 4 }}>
          <Button variant="text" size="medium" onClick={handleCloseModal}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            size="medium"
            startIcon={<ContentCopyIcon />}
            loading={copyLoading}
            onClick={async () => {
              await copyMetricRecordFromReportingPeriodGroup();
              handleCloseModal();
            }}
            color="primary"
            disabled={!copyMetricRecord}
          >
            Copy Metric Record
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default CopyQualitativeMetricRecordModal;
