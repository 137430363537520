import { MetadataError } from '@ep/error-handling';

/**
 * Check that the required group_id and company_id is provided when not querying a master list. Throws error if invalid.
 * @param {boolean} master_list Whether or not a master list should be queried.
 * @param {Group} group_id ID of configured group.
 * @param {string} company_id ID of configured company.
 * @returns {void}
 */
export const validateMasterListParams = (
  master_list: boolean,
  group_id?: string,
  company_id?: string
): void => {
  if (!master_list && (!group_id || !company_id)) {
    throw new MetadataError('A group_id and company_id is required for non-master list queries.', {
      group_id: group_id,
      company_id: company_id,
      master_list: master_list
    });
  }
};

/**
 * Check that both configured and master list contains entities to be compared. Throws error if invalid.
 * @param {Array<unknown>} master_list Master list of Metrics.
 * @param {Array<unknown>} configured_list Configured list of Metrics.
 * @returns {void}
 */
export const validateMasterListComparison = (
  master_list: Array<unknown>,
  configured_list: Array<unknown>
): void => {
  if (!master_list || master_list.length <= 0 || !configured_list || configured_list.length < 0) {
    throw new MetadataError('Unable to compare entity lists.', {
      master_list: master_list,
      configured_list: configured_list
    });
  }
};

/**
 * Creates a reference in memory for a nested object for the provided path.
 * @param {string} path Path to the nested value.
 * @param {any} obj Object for which the reference should be created.
 * @param {string} separator Determines which keys to reference. Defaults to a full-stop.
 */
export const resolveObjectFromString = (
  path: string,
  obj: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  separator = '.'
) => (Array.isArray(path) ? path : path.split(separator).reduce((prev, curr) => prev[curr], obj));
