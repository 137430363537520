import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
// import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Facebook, LinkedIn, Twitter, YouTube } from '@mui/icons-material';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: White;
`;

export default function Footer() {
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: '#053d5d',
          padding: '2rem'
        }}
      >
        <Grid container rowGap={5} justifyContent={'center'} alignContent={'center'}>
          <Grid container item justifyContent={'space-evenly'} flexWrap={'wrap'}>
            <Grid item textAlign={'left'}>
              <Typography color="white" variant="h6" gutterBottom>
                Corporate
              </Typography>
              <Grid container item direction={'column'} rowGap={1}>
                <StyledLink to="https://energypartners.co.za/about/" target="_blank">
                  About
                </StyledLink>
                <StyledLink to="https://energypartners.co.za/privacy-policy/" target="_blank">
                  Privacy Policy
                </StyledLink>
                <StyledLink to="https://energypartners.co.za/terms-conditions/" target="_blank">
                  Terms and Conditions
                </StyledLink>
              </Grid>
            </Grid>
            <Grid item textAlign={'left'}>
              <Typography color="white" variant="h6" gutterBottom>
                Services Offered
              </Typography>
              <Grid container item direction={'column'} rowGap={1}>
                <StyledLink to={'/web/readiness'}>Readiness Gauge</StyledLink>
                <StyledLink to="/web/resources">Resource Center</StyledLink>
                <StyledLink to="/web/assessment">Company Assessment</StyledLink>
                <StyledLink to="/web/reporting">Report Generator</StyledLink>
                <StyledLink to="/web/data">Data Collector</StyledLink>
              </Grid>
            </Grid>
            <Grid textAlign={'center'}>
              <Typography color="white" variant="h6" gutterBottom>
                Follow Energy Partners
              </Typography>
              <Box>
                <StyledLink to="https://www.facebook.com/energypartners" target="_blank">
                  <Facebook />
                </StyledLink>
                <StyledLink to="https://za.linkedin.com/company/energy-partners-" target="_blank">
                  <LinkedIn />
                </StyledLink>
                <StyledLink to="https://twitter.com/EnergyPartners_" target="_blank">
                  <Twitter />
                </StyledLink>
                <StyledLink
                  to="https://www.youtube.com/channel/UCH-UiZevfEtbN4nuvegfIFA"
                  target="_blank"
                >
                  <YouTube />
                </StyledLink>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            display={'flex'}
            flexDirection={'column'}
            alignContent={'center'}
            spacing={2}
          >
            <Grid item>
              <Typography color="white" variant="body2">
                Copyright Energy Partners 2023 - We are a BEE level 4 accredited provider.
              </Typography>
            </Grid>
            <Grid item>
              <StyledLink to={'https://www.flaticon.com/free-icons/speed'}>
                <Typography color="white" variant="body2">
                  Speed icons created by Freepik - Flaticon
                </Typography>
              </StyledLink>
              <StyledLink to={'http://www.freepik.com'}>
                <Typography color="white" variant="body2">
                  Vectors Designed by vectorjuice / Freepik
                </Typography>
              </StyledLink>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
