import { Box, Grid, Typography } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { API_ENDPOINT, GCP_FILE_UPLOAD_BUCKET } from '../../env';
import { FeedbackSnackbarContext } from '../../context/FeedbackSnackbarContext';
import { CompanyContext } from '../../context/CompanyContext';
import moment from 'moment';
import { MetricRecordEntities } from '../../@types/shared';
import { getMetricRecordEntities } from '../../util/metric_record_entities';
import { DocumentReference } from 'firebase/firestore';
import { Company, Group, Site, Subdivision, User } from '@esg/esg-global-types';
import { generateFileUploadRequest } from '../../lib/file_upload/uploadRequestPrep';
import { generateUuidV4 } from '../../util/uuid';
import { GroupContext } from '../../context/GroupContext';
import { UserContext } from '../../context/UserContext';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../util/log';
import RawExportContext from './RawExportContext';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AlertBanner from '../shared/banner/AlertBanner';
import { getReportingPeriodDays } from '../../lib/metric_capture/reporting_period';
import FullMetricRecordFilters from './filters/FullMetricRecordFilters';

export interface ExportFilterProps {
  export_entities: MetricRecordEntities;
  export_filters: Array<ExportFilter>;
  export_type: ExportType | null;
  input_errors: FilterInputErrors;
  handleUpdateFilters: (
    input_field:
      | 'country'
      | 'region'
      | 'division'
      | 'subdivision'
      | 'site'
      | 'metric'
      | 'reporting_period',
    input_ids: Array<string>,
    select_all: boolean,
    reset_child_filters?: Array<'region' | 'subdivision' | 'site'>
  ) => void;
  handleClearFilters: () => void;
  handleInputValidity: (field: string, error: string) => void;
}

export interface ExportType {
  id: string;
  label: string;
  description: string;
  filters: (props: ExportFilterProps) => React.ReactNode;
}

export interface ExportFilter {
  field: 'country' | 'region' | 'division' | 'subdivision' | 'site' | 'metric' | 'reporting_period';
  ids: Array<string>;
}

export interface FilterInputErrors {
  [key: string]: string;
}

/**
 * Wrapper component of exporting functionality of various types
 * @returns {JSX.Element}
 */
const ExportHub = () => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const user_info: User | null = React.useContext(UserContext);
  const [exportType, setExportType] = React.useState<ExportType | null>(null);
  const [exportEntities, setExportEntities] = React.useState<MetricRecordEntities>({
    countries: [],
    regions: [],
    divisions: [],
    subdivisions: [],
    sites: [],
    reporting_period_groups: [],
    reporting_periods: [],
    metrics: [],
    user_types: [],
    external_companies: [],
    emission_factors: []
  });
  const [exportFilters, setExportFilters] = React.useState<Array<ExportFilter>>([]);
  const [loadingEntities, setLoadingEntities] = React.useState<boolean>(true);
  const [loadingExport, setLoadingExport] = React.useState<boolean>(false);
  const [inputErrors, setInputErrors] = React.useState<FilterInputErrors>({});

  const export_types: Array<ExportType> = loadingEntities
    ? []
    : [
        {
          id: 'full',
          label: 'Full Metric Record Export',
          description: 'All Metric Records',
          filters: (filter_props: ExportFilterProps) => (
            <FullMetricRecordFilters {...filter_props} />
          )
        }
      ];

  const input_valid: boolean = Object.values(inputErrors).every((error) => !error);

  const handleChangeExportType = (export_type: ExportType | null): void => {
    setExportType(export_type);
    setExportFilters([]);
  };

  const handleInputValidity = (field: string, error: string): void => {
    setInputErrors({ ...inputErrors, [field]: error });
  };

  const handleUpdateFilters = (
    input_field:
      | 'country'
      | 'region'
      | 'division'
      | 'subdivision'
      | 'site'
      | 'metric'
      | 'reporting_period',
    input_ids: Array<string>,
    select_all: boolean,
    reset_child_filters?: Array<string>
  ): void => {
    const filtered_export_filters: Array<ExportFilter> = exportFilters.filter(
      (filter: ExportFilter) =>
        filter.field !== input_field && !reset_child_filters?.includes(filter.field)
    );
    if (select_all) {
      setExportFilters([...filtered_export_filters]);
    } else if (input_ids.length === 0) {
      setExportFilters([...filtered_export_filters, { field: input_field, ids: [] }]);
    } else {
      setExportFilters([...filtered_export_filters, { field: input_field, ids: input_ids }]);
    }
  };

  const handleClearFilterBarFilters = (): void => {
    setExportFilters([]);
    handleInputValidity(
      'reporting_period',
      getReportingPeriodDays(exportEntities.reporting_periods) > 366 ? 'MAX_DAYS_EXCEEDED' : ''
    );
  };

  const fetchExportEntities = async (): Promise<void> => {
    setLoadingEntities(true);
    try {
      if (group && company && user_info) {
        const export_entities: MetricRecordEntities = await getMetricRecordEntities(
          group.id,
          company.id,
          user_info
        ).catch((err: unknown) => {
          const tracking_id: string = uuidv4();
          log(
            'error',
            new MetadataError(
              err instanceof Error
                ? err.message
                : 'Error: ExportsHub failed on an unknown error while calling fetchExportEntities.',
              {
                group: group,
                company: company,
                user_info: user_info
              },
              tracking_id
            )
          );
          throw err;
        });
        if (export_entities) {
          setExportEntities({
            countries: export_entities.countries,
            regions: export_entities.regions,
            divisions: export_entities.divisions,
            subdivisions: export_entities.subdivisions,
            sites: export_entities.sites.map((site: Site) => {
              return {
                ...site,
                division: export_entities.subdivisions.find(
                  (subdivision: Subdivision) => subdivision.id === site.subdivision.id
                )?.division,
                region: { ...site.region, id: site.region.id } as DocumentReference
              };
            }),
            reporting_period_groups: export_entities.reporting_period_groups,
            reporting_periods: export_entities.reporting_periods,
            metrics: export_entities.metrics,
            user_types: export_entities.user_types,
            external_companies: export_entities.external_companies,
            emission_factors: export_entities.emission_factors
          });
        }
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: ExportsHub failed on an unknown error while calling fetchExportEntitites.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to load entities from database. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setLoadingEntities(false);
    }
  };

  const handleExport = async (): Promise<void> => {
    setLoadingExport(true);
    try {
      if (group && company && exportEntities) {
        const uuid: string = generateUuidV4();
        const mapping_file: File = new File([JSON.stringify(exportEntities)], 'mapping.json', {
          type: 'application/json'
        });
        const mapping_file_upload: FormData = generateFileUploadRequest(
          mapping_file,
          'mapping',
          atob(GCP_FILE_UPLOAD_BUCKET),
          `${group.id}/${company.id}/exports/${uuid}`,
          'false'
        );
        await axios({
          method: 'post',
          url: `${API_ENDPOINT}/upload_file`,
          data: mapping_file_upload
        }).then(async () => {
          const response = await axios({
            method: 'post',
            url: `${API_ENDPOINT}/generate_raw_export_request`,
            data: {
              group: group.id,
              company: company.id,
              export_filters: exportFilters,
              file_path: `${group.id}/${company.id}/exports/${uuid}/mapping`
            },
            responseType: 'blob'
          }).catch((error) => {
            throw error.response.data;
          });
          if (response) {
            const url: string = window.URL.createObjectURL(new Blob([response.data]));
            const link: HTMLAnchorElement = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `${company.id}_raw_export_${moment().format('YYYY-MM-DDTHH-mm-ss')}.csv`
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
          }
        });
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: ExportsHub failed on an unknown error while calling ExportHub.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to export metric records. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setLoadingExport(false);
    }
  };

  React.useEffect(() => {
    try {
      (async () => {
        setExportType(null);
        setExportEntities({
          countries: [],
          regions: [],
          divisions: [],
          subdivisions: [],
          sites: [],
          reporting_period_groups: [],
          reporting_periods: [],
          metrics: [],
          user_types: [],
          external_companies: [],
          emission_factors: []
        });
        setExportFilters([]);
        await fetchExportEntities();
      })();
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: ExportsHub failed on an unknown error while initialising.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch entities from database. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  }, [company]);

  const filter_props: ExportFilterProps = {
    export_entities: exportEntities,
    export_filters: exportFilters,
    export_type: exportType,
    input_errors: inputErrors,
    handleUpdateFilters: handleUpdateFilters,
    handleInputValidity: handleInputValidity,
    handleClearFilters: handleClearFilterBarFilters
  };

  return (
    <Box>
      <Grid container spacing={6} sx={{ py: 2 }}>
        <Grid item xs={7}>
          <RawExportContext
            export_types={export_types}
            selected_export_type={exportType}
            input_valid={input_valid}
            loading={loadingExport}
            handleExport={handleExport}
            handleChangeExportType={handleChangeExportType}
          />
          {exportType && !loadingEntities && (
            // TODO: Add summary component to export_types object and reference it here, similar to the filters component.
            <Box sx={{ p: 4, textAlign: 'left' }}>
              <Typography fontSize="1.3rem">Export Summary</Typography>
              <Typography fontSize="1.1rem" sx={{ mt: 6, mb: 4 }}>
                {exportType?.description ?? 'Metric Records'} For:
              </Typography>
              <Grid container spacing={2} sx={{ width: '90%' }}>
                {exportFilters.length > 0 ? (
                  exportFilters.map((filter: ExportFilter) => {
                    let filter_display: { label: string; icon: React.ReactNode };
                    switch (filter.field) {
                      case 'reporting_period':
                        filter_display = {
                          label: 'Reporting Periods',
                          icon: <AccessAlarmIcon />
                        };
                        break;
                      case 'metric':
                        filter_display = {
                          label: 'Metrics',
                          icon: <AssessmentOutlinedIcon />
                        };
                        break;
                      case 'country':
                        filter_display = {
                          label: 'Countries',
                          icon: <LocationOnOutlinedIcon />
                        };
                        break;
                      case 'region':
                        filter_display = {
                          label: 'Regions',
                          icon: <LocationOnOutlinedIcon />
                        };
                        break;
                      case 'division':
                        filter_display = {
                          label: 'Divisions',
                          icon: <AccountTreeOutlinedIcon />
                        };
                        break;
                      case 'subdivision':
                        filter_display = {
                          label: 'Subdivisions',
                          icon: <AccountTreeOutlinedIcon />
                        };
                        break;
                      case 'site':
                        filter_display = {
                          label: 'Sites',
                          icon: <AccountTreeOutlinedIcon />
                        };
                        break;
                      default:
                        filter_display = {
                          label: 'Unknown',
                          icon: <></>
                        };
                        break;
                    }
                    return (
                      <Grid item xs={6} key={filter.field}>
                        <AlertBanner
                          open
                          icon={filter_display.icon}
                          severity="info"
                          message={`${filter.ids.length} ${filter_display.label}`}
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item xs={6}>
                    <AlertBanner open severity="info" message={`All Entities`} />
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
        </Grid>
        {exportType && !loadingEntities && (
          <Grid item xs={5}>
            {exportType.filters(filter_props)}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ExportHub;
