import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { SelectChangeEvent } from '@mui/material';

interface Choice {
  value: string;
}

interface InputHandlerFunc {
  (event: SelectChangeEvent<string>, child: React.ReactNode): void;
}

const ContactFormSelect = ({
  id,
  label,
  choices,
  handleInputChange,
  primary_color
}: {
  id: string;
  label: string;
  choices?: Array<Choice>;
  handleInputChange?: InputHandlerFunc;
  primary_color?: string;
}) => {
  return (
    <FormControl
      sx={{
        '& .MuiFormLabel-root': {
          color: primary_color
        },
        '& .MuiFormLabel-root.Mui-focused': {
          color: primary_color
        }
      }}
      fullWidth
      className="select-form-control"
    >
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        defaultValue=""
        variant="standard"
        onChange={handleInputChange}
        role="listbox"
        aria-label="select"
        sx={{
          '& .MuiSelect-select': {
            color: primary_color
          },
          '& .MuiSelect-icon': {
            color: primary_color
          }
        }}
      >
        {choices?.map((choice) => {
          return (
            <MenuItem key={choice.value} value={choice.value}>
              {choice.value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default ContactFormSelect;
