import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Grid, Tab, Tabs, TextField, Typography } from '@mui/material';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import CountrySelect from '../../shared/input/select/CountrySelect';
import MultipleSelectChipRegions from '../../shared/input/select/MultipleSelectChipRegions';
import { Company, Country, Group, Region } from '@esg/esg-global-types';
import {
  Location,
  createRegionJoinCountriesBatch,
  getRegionMasterList
} from '../../../lib/metric_capture/region';
import { LoadingButton } from '@mui/lab';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { hasBannedCharacters } from '../../../lib/validation/text_validation';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';

interface AddLocationWidgetProps {
  existing_regions: Array<Region>;
  handleLocationsToParent: (locations: Array<Region>) => void;
  closeWidget: () => void;
}

export default function AddLocationdWidget(props: AddLocationWidgetProps) {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [tab, setTab] = React.useState(0);
  const [country, setCountry] = React.useState<Country>();
  const [customRegionName, setCustomRegionName] = React.useState<string>('');
  const [regions, setRegions] = React.useState<Array<Region>>([]);
  const [addLoading, setAddLoading] = React.useState<boolean>(false);
  const [newLocations, setNewLocations] = React.useState<Array<Location>>([]);

  const invalidRegion = customRegionName
    ? hasBannedCharacters(customRegionName)
    : customRegionName.length < 1
      ? false
      : true;

  const input_validity = {
    duplicate_name: !props.existing_regions.some((region) => {
      return region.name.toLowerCase() === customRegionName.toLowerCase();
    })
      ? ''
      : `Region name already exists for ${company?.name}`
  };

  const countryFromParent = (country: Array<Country> | Country | null) => {
    if (!Array.isArray(country)) {
      setCountry(country ? country : undefined);
      setNewLocations([]);
    }
  };
  const handleTabChange = (event: React.SyntheticEvent, new_tab_value: number) => {
    setTab(new_tab_value);
    setCustomRegionName('');
    setNewLocations([]);
  };

  const handleNewRegionsFromSelector = (new_regions: Array<string>) => {
    if (country && country.continent !== undefined) {
      setNewLocations(
        new_regions.map((region: string) => {
          const new_region: Region = JSON.parse(region);
          return {
            id: new_region.id,
            deleted: new_region.deleted,
            name: new_region.name,
            country: {
              id: country.code,
              deleted: null,
              name: country.name,
              continent: country.continent,
              code: country.code
            }
          };
        })
      );
    }
  };

  const createLocations = async () => {
    setAddLoading(true);
    try {
      if (group && company) {
        await createRegionJoinCountriesBatch(newLocations, group.id, company.id)
          .then(() => {
            props.handleLocationsToParent(newLocations);
          })
          .catch((error) => {
            throw new Error(error);
          });
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: AddLocationWidget failed on an unknown error while calling createLocation.',
          {
            newLocations: newLocations,
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Error creating location. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setAddLoading(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      const master_list_regions: Array<Region> = await getRegionMasterList();
      setRegions(master_list_regions);
    })();
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={3}>
        {/* Widget Header */}
        <Grid item xs={10}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <AddLocationAltIcon sx={{ marginRight: '2rem' }} fontSize="large" />
            <Typography variant="h5" color="inherit" align="left">
              Add Locations
            </Typography>
          </Box>
        </Grid>
        {props.existing_regions.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography>Configured Regions</Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 1 }}>
              {props.existing_regions.map((region, index) => (
                <Typography
                  key={index}
                  sx={{
                    backgroundColor: '#E7E8E9',
                    color: '#454545',
                    borderRadius: 3,
                    px: 1.3,
                    py: 0.2,
                    whiteSpace: 'nowrap'
                  }}
                >
                  {region.name}
                </Typography>
              ))}
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <CountrySelect master_list handleChangeCountries={countryFromParent} />
        </Grid>
        {country && (
          <Grid item xs={12}>
            <Tabs value={tab} onChange={handleTabChange} aria-label="region-input-tabs">
              <Tab label="Custom Region" />
              <Tab label="Standard Regions" />
            </Tabs>
          </Grid>
        )}
        {country && tab === 0 && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="region-name-input"
              label="Region Name"
              variant="standard"
              error={input_validity.duplicate_name ? true : false || invalidRegion}
              helperText={input_validity.duplicate_name}
              value={customRegionName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setCustomRegionName(event.target.value);
                setNewLocations(
                  event.target.value
                    ? [
                        {
                          id: `${country.code}_${event.target.value[0]}${event.target.value
                            .substring(1)
                            .match(/[^aeiou\d_\W]/gi)
                            ?.join('')}`.toUpperCase(),
                          country: { ...country },
                          deleted: null,
                          name: event.target.value
                        }
                      ]
                    : []
                );
              }}
            />
          </Grid>
        )}
        {country && tab === 1 && (
          <Grid item xs={12}>
            <MultipleSelectChipRegions
              country={country}
              handleNewRegions={handleNewRegionsFromSelector}
              regions_value={newLocations.map((location) => JSON.stringify(location))}
              region_options={regions.filter((region: Region) => {
                return (
                  region.country.id === country?.id &&
                  !props.existing_regions.some(
                    (existing_region) => existing_region.id === region.id
                  )
                );
              })}
            />
          </Grid>
        )}
        {/* Button Input */}
        <Grid item xs={12} alignItems={'right'}>
          <Box m={1} display="flex" justifyContent="flex-end" alignItems="flex-end">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                props.closeWidget();
              }}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            {
              <LoadingButton
                variant="contained"
                loading={addLoading}
                disabled={
                  !(input_validity.duplicate_name === '') ||
                  invalidRegion ||
                  newLocations.length < 1
                }
                color="primary"
                onClick={async () => {
                  await createLocations();
                  props.closeWidget();
                }}
              >
                Create
              </LoadingButton>
            }
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
