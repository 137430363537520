import { Box } from '@mui/material';
import React from 'react';
import ConfigurationWrapper from '../../components/shared/ConfigurationWrapper';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import EmojiTransportationOutlinedIcon from '@mui/icons-material/EmojiTransportationOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PanelReportingPeriods from '../../components/configuration/reporting_periods/PanelReportingPeriods';

const ConfigurationReportingPeriods = () => {
  return (
    <Box>
      <ConfigurationWrapper
        title="Reporting Periods"
        icon={<AccessAlarmIcon fontSize="large" />}
        previous={{
          route: '/configuration/external-companies',
          label: 'External Companies',
          icon: <EmojiTransportationOutlinedIcon />
        }}
        next={{
          route: '/configuration/reporting-period-groups',
          label: 'Reporting Period Groups',
          icon: <CalendarMonthOutlinedIcon />
        }}
      >
        <PanelReportingPeriods />
      </ConfigurationWrapper>
    </Box>
  );
};

export default ConfigurationReportingPeriods;
