import React from 'react';
import { Box, Fab } from '@mui/material';
import Typography from '@mui/material/Typography';
import ei_logo from '../../assets/ei-logo.webp';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const HeroBanner = () => {
  return (
    <Box sx={{ justifyContent: 'flex-start', textAlign: 'start' }}>
      <Box
        sx={{
          backgroundColor: 'rgba(5, 61, 93, 0.8)',
          padding: '5rem',
          width: 1,
          borderRadius: '1rem'
        }}
      >
        <img src={ei_logo} width="450" />
        <Typography color={'white'} fontSize={35} fontWeight={600}>
          ORGANIZATIONAL SUSTAINABILITY &<br></br>
          ESG STRATEGY DEVELOPMENT
        </Typography>
        <Box sx={{ paddingTop: '25px' }}>
          <Link to={'/contact'}>
            <Fab
              variant="extended"
              sx={{
                backgroundColor: '#29B6F6',
                padding: '2rem',
                fontWeight: 'bold',
                color: '#FFFFFF'
              }}
            >
              Start Your ESG Journey
              <ArrowForwardIcon />
            </Fab>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default HeroBanner;
