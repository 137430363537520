import React, { ReactNode } from 'react';
import { Box, Button, Divider, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import gauge_img from '../../assets/readiness-gauge.webp';
import report_img from '../../assets/reporting-report.webp';
import book_img from '../../assets/resource-centre-books.webp';
import checklist_img from '../../assets/assessment-clipboard.webp';
import data_img from '../../assets/analytics-laptop.webp';

const SolutionHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Typography color={'#053d5d'} fontWeight={600} fontSize={16} paddingTop={'1rem'}>
      {children}
    </Typography>
  );
};

const SolutionBody = ({ children }: { children: ReactNode }) => {
  return (
    <Typography color={'#404040'} fontWeight={500} fontSize={14} marginTop={1}>
      {children}
    </Typography>
  );
};

const SolutionsSection = () => {
  return (
    <>
      <Grid container spacing={8}>
        <Grid item xs={4} textAlign={'start'}>
          <Typography color={'#1B97C1'} fontSize={20} fontWeight={500} letterSpacing={2}>
            EXPLORE
          </Typography>
          <Typography color={'#053d5d'} fontSize={35} fontWeight={500}>
            Solutions & <br></br>Services
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Box sx={{ textAlign: 'center' }}>
            <img src={gauge_img} width={'150px'} />
            <SolutionHeader>Readiness Gauge</SolutionHeader>
          </Box>

          <Divider sx={{ paddingTop: '1rem', borderColor: '#053d5d' }} />
          <SolutionBody>Rapidly assess your current ESG position.</SolutionBody>
          <Box
            m={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{ paddingTop: '1rem' }}
          >
            <Link to={'/web/readiness'}>
              <Button variant="outlined" sx={{ backgroundColor: '#fff' }}>
                Learn more
              </Button>
            </Link>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box sx={{ textAlign: 'center' }}>
            <img src={report_img} width={'150px'} />
            <SolutionHeader>Report Compilation</SolutionHeader>
          </Box>
          <Divider sx={{ paddingTop: '1rem', borderColor: '#053d5d' }} />
          <SolutionBody>
            Instant generation of standardised ESG/Sustainability reporting to inform CDP, FTSE, or
            AIR reports.
          </SolutionBody>
          <Box
            m={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{ paddingTop: '1rem' }}
          >
            <Link to={'/web/reporting'}>
              <Button variant="outlined" sx={{ backgroundColor: '#fff' }}>
                Learn more
              </Button>
            </Link>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box sx={{ textAlign: 'center' }}>
            <img src={book_img} width={'150px'} />
            <SolutionHeader>Resource Center</SolutionHeader>
          </Box>
          <Divider sx={{ paddingTop: '1rem', borderColor: '#053d5d' }} />
          <SolutionBody>
            Resource Centre to get Sustainability policies, frameworks, guideline documents, and
            templates
          </SolutionBody>
          <Box
            m={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{ paddingTop: '1rem' }}
          >
            <Link to={'/web/resources'}>
              <Button variant="outlined" sx={{ backgroundColor: '#fff' }}>
                Learn more
              </Button>
            </Link>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box sx={{ textAlign: 'center' }}>
            <img src={checklist_img} width={'150px'} />
            <SolutionHeader>Risk & Opportunites Assessment</SolutionHeader>
          </Box>
          <Divider sx={{ paddingTop: '1rem', borderColor: '#053d5d' }} />
          <SolutionBody>
            Receive questions, guidelines and framework documents to produce a scenario analysis
            related to ESG Risks and Opportunities and executing Materiality Assessments.
          </SolutionBody>
          <Box
            m={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{ paddingTop: '1rem' }}
          >
            <Link to={'/web/assessment'}>
              <Button variant="outlined" sx={{ backgroundColor: '#fff' }}>
                Learn more
              </Button>
            </Link>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box sx={{ textAlign: 'center' }}>
            <img src={data_img} width={'150px'} />
            <SolutionHeader>Metric Collection</SolutionHeader>
          </Box>
          <Divider sx={{ paddingTop: '1rem', borderColor: '#053d5d' }} />
          <SolutionBody>
            Data Acquisition and Handling System (DAHS) to track quantitative and qualitative ESG
            metrics, targets, and goals.
          </SolutionBody>
          <Box
            m={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{ paddingTop: '1rem' }}
          >
            <Link to={'/web/data'}>
              <Button variant="outlined" sx={{ backgroundColor: '#fff' }}>
                Learn more
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SolutionsSection;
