import React from 'react';
import { Button } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';

interface PropTypes {
  handleLogin(state: boolean): void;
}

const LoginButton = (props: PropTypes) => {
  const handleSignIn = () => {
    props.handleLogin(true);
  };

  return (
    <>
      <Button onClick={handleSignIn} color="inherit" sx={{ fontSize: '12px' }}>
        Sign In&nbsp;
        <LoginIcon />
      </Button>
    </>
  );
};

export default LoginButton;
