import React from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Box,
  ListSubheader,
  TextField,
  InputAdornment
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import ArchiveIcon from '@mui/icons-material/Archive';
import SearchIcon from '@mui/icons-material/Search';

interface Choice {
  id?: string;
  name?: string;
  locked?: boolean;
  archived?: Date | null;
}

interface HandleValueChange {
  (event: SelectChangeEvent<string>, child: React.ReactNode): void;
}

const MetricContextSelect = ({
  id,
  label,
  choices,
  disabled,
  value,
  onChangeHandler
}: {
  id: string;
  label: string;
  disabled?: boolean;
  choices?: Array<Choice> | null;
  value?: string;
  onChangeHandler?: HandleValueChange;
}) => {
  const [searchText, setSearchText] = React.useState<string>('');
  return (
    <FormControl fullWidth className="select-form-control">
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        defaultValue=""
        value={value}
        renderValue={(value) => {
          const display_value = (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {value ? JSON.parse(value).name : ''}
              {id === 'reporting-period-select' && JSON.parse(value).locked && (
                <LockIcon
                  sx={{ ml: 2, position: 'relative', color: 'primary', fontSize: '1.3rem' }}
                />
              )}
              {JSON.parse(value).archived && (
                <ArchiveIcon
                  sx={{ ml: 2, position: 'relative', color: '#1b97c1', fontSize: '1.3rem' }}
                />
              )}
            </Box>
          );
          return display_value;
        }}
        MenuProps={{ autoFocus: false }}
        disabled={disabled}
        role="listbox"
        aria-label="select"
        variant="standard"
        onChange={onChangeHandler}
        onClose={() => setSearchText('')}
      >
        <ListSubheader>
          <TextField
            size="small"
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            onChange={(event) => setSearchText(event.target.value)}
            onKeyDown={(event) => {
              if (event.key !== 'Escape') {
                event.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {choices
          ?.filter(
            (choice) =>
              choice.name && choice.name?.toLowerCase().indexOf(searchText.toLowerCase()) > -1
          )
          .map((choice) => {
            return (
              <MenuItem
                key={choice.id ? choice.id : JSON.stringify(choice)}
                value={JSON.stringify(choice)}
              >
                {choice.name}
                {id === 'reporting-period-select' && choice.locked && (
                  <LockIcon sx={{ marginLeft: 2, color: 'primary', fontSize: '1.3rem' }} />
                )}
                {choice.archived && (
                  <ArchiveIcon sx={{ marginLeft: 2, fontSize: '1.3rem', color: '#1b97c1' }} />
                )}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export default MetricContextSelect;
