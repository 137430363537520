import { Box } from '@mui/material';
import React from 'react';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import ConfigurationWrapper from '../../components/shared/ConfigurationWrapper';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import PanelStandards from '../../components/configuration/standards/PanelStandards';

const ConfigurationStandards = () => {
  return (
    <Box>
      <ConfigurationWrapper
        title="Standards"
        icon={<ChecklistRtlIcon fontSize="large" />}
        previous={{
          route: '/configuration/reporting-period-groups',
          label: 'Reporting Period Groups',
          icon: <CalendarMonthOutlinedIcon />
        }}
        next={{
          route: '/configuration/metrics',
          label: 'Metrics',
          icon: <AssessmentOutlinedIcon />
        }}
      >
        <PanelStandards />
      </ConfigurationWrapper>
    </Box>
  );
};

export default ConfigurationStandards;
