import React from 'react';
import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import carbon_img from '../../assets/emissions-co2-cloud.webp';
import money_img from '../../assets/higher-income-money.webp';
import employee_img from '../../assets/employee-turnover.webp';

const SummarySection = () => {
  return (
    <>
      <Typography color={'#053d5d'} fontWeight={600} fontSize={35}>
        The World Is Not Sustainable
      </Typography>
      <Typography color={'#053d5d'} fontWeight={500}>
        Like never before, the 21st century challenges necessitate partnerships to solve them.
        Across countries, companies and communities, no one can act or think in isolation if the
        goal is to change course from current unsustainable trajectories. Sustainability encompasses
        three widely recognised key themes: Environmental, Social, and Governance (ESG).
      </Typography>
      <Grid
        container
        spacing={3}
        sx={{
          textAlign: 'center'
        }}
      >
        <Grid item xs={4}>
          <Box sx={{ textAlign: 'center' }}>
            <img src={carbon_img} width={'120px'} />
          </Box>
          <Typography color={'#053d5d'} fontWeight={500} fontSize={40}>
            Lower
          </Typography>
          <Typography color={'#1B97C1'} fontWeight={600}>
            Carbon Tax
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ textAlign: 'center' }}>
            <img src={money_img} width={'120px'} />
          </Box>
          <Typography color={'#053d5d'} fontWeight={500} fontSize={40}>
            Gain
          </Typography>
          <Typography color={'#1B97C1'} fontWeight={600}>
            Beneficial Funding Rates
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ textAlign: 'center' }}>
            <img src={employee_img} width={'120px'} />
          </Box>
          <Typography color={'#053d5d'} fontWeight={500} fontSize={40}>
            Avoid
          </Typography>
          <Typography color={'#1B97C1'} fontWeight={600}>
            Employee Churn
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default SummarySection;
