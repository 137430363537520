import React from 'react';
import 'typeface-montserrat';
import { ThemeProvider, useTheme } from '@mui/material';

import { auth } from '../../lib/google/firebase';
import { useNavigate } from 'react-router-dom';

const HomeDashboardApp = () => {
  const landing_theme = useTheme;
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!auth.currentUser) {
      navigate('/');
    }
  });
  return <ThemeProvider theme={landing_theme}>Add some graphs.</ThemeProvider>;
};

export default HomeDashboardApp;
