import React from 'react';
import { Box, Fab } from '@mui/material';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import partnership_img from '../../assets/partnership.webp';

const ThankYouPage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        paddingY: 4,
        flexDirection: 'column',
        margin: 'auto',
        alignItems: 'center',
        gap: 3
      }}
      width={'60%'}
    >
      <Typography fontSize={50} fontWeight={500} letterSpacing={2} color={'#053d5d'}>
        Thank You
      </Typography>
      <img src={partnership_img} width={350} />
      <Typography fontSize={18} fontWeight={500} letterSpacing={2} color={'#053d5d'}>
        Your Contact Request was successful! An Energy Partners representative will be in touch
        shortly via the contact details you provided. In the meantime, explore the links above to
        level up your ESG knowledge.
      </Typography>

      <Box sx={{ paddingTop: '25px' }}>
        <Link to={'/'}>
          <Fab
            variant="extended"
            sx={{
              backgroundColor: '#29B6F6',
              padding: '2rem',
              fontWeight: 'bold',
              color: '#FFFFFF'
            }}
          >
            <ArrowBackIcon />
            Back to Home
          </Fab>
        </Link>
      </Box>
    </Box>
  );
};

export default ThankYouPage;
