import * as React from 'react';
import { Drawer, Tooltip } from '@mui/material';
import AddLocationWidget from './AddLocationWidget';
import {
  allowDeleteRegion,
  archiveRegion,
  deleteRegion,
  getRegionJoinCountries,
  unarchiveRegion
} from '../../../lib/metric_capture/region';
import { Company, Group, Region } from '@esg/esg-global-types';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import UnarchiveConfirmModal from '../../shared/modal/UnarchiveConfirmModal';
import DeleteConfirmModal from '../../shared/modal/DeleteConfirmModal';
import DeleteIcon from '@mui/icons-material/Delete';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';
import { DataGrid, GridActionsCellItem, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { PanelGeographicalLoading } from './PanelGeographicalLoading';
import { PanelGeographicalNoRows } from './PanelGeographicalNoRows';
import { PanelGeographicalToolbar } from './PanelGeographicalToolbar';

const PanelGeographical = () => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [locationRows, setLocationRows] = React.useState<Array<Region>>([]);
  const [displayWidgetPanelAdd, setDisplayWidgetPanelAdd] = React.useState(false);
  const [actionRow, setActionRow] = React.useState<Region | null>(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [showUnarchiveModal, setShowUnarchiveModal] = React.useState<boolean>(false);
  const [allowLocationDelete, setAllowLocationDelete] = React.useState<boolean | null>(null);
  const [gridLoading, setGridLoading] = React.useState<boolean>(true);

  const flagFactory = (country_code: string) => {
    return <img src={`https://flagcdn.com/w20/${String(country_code).toLowerCase()}.png`} />;
  };

  // Handler functions
  const handleCreateClick = (): void => {
    setActionRow(null);
    setDisplayWidgetPanelAdd(true);
  };

  const handleDeleteClick = async (row: Region): Promise<void> => {
    if (group && company) {
      const allow_delete: boolean = await allowDeleteRegion(group.id, company.id, row.id);
      setActionRow(row);
      setShowDeleteModal(true);
      setAllowLocationDelete(allow_delete);
    }
  };

  const handleCloseDeleteModal = (): void => {
    setShowDeleteModal(false);
    setAllowLocationDelete(null);
  };

  const handleCloseUnarchiveModal = (): void => {
    setShowUnarchiveModal(false);
  };

  // Row functions
  const fetchRows = async (): Promise<void> => {
    setGridLoading(true);
    try {
      if (group && company) {
        // Load rows from database.
        const locations: Array<Region> = await getRegionJoinCountries(group.id, company.id).catch(
          (err: unknown) => {
            const tracking_id: string = uuidv4();
            throw new MetadataError(
              err instanceof Error
                ? err.message
                : 'Error: PanelGeographical failed on an unknown error while calling getRegionJoinCountries.',
              tracking_id
            );
          }
        );
        // Load rows to memory.
        setLocationRows(locations);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelGeographical failed on an unknown error while calling fetchRows.',
          {
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch locations. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
      return;
    } finally {
      setGridLoading(false);
    }
  };

  React.useEffect(() => {
    try {
      (async () => {
        setLocationRows([]);
        fetchRows();
      })().catch((error) => {
        throw new Error(error);
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelGeographical failed on an unknown error while initialising.',
          {
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `An error occurred. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
    return;
  }, [group, company]);

  const deleteRow = async (delete_region: Region): Promise<void> => {
    try {
      if (group && company && delete_region) {
        // Soft delete Region database record.
        await deleteRegion(group.id, company.id, delete_region.id, delete_region.name)
          .then(() => {
            // Remove from memory.
            setLocationRows(
              locationRows.filter((region: Region) => {
                return region.id !== delete_region.id;
              })
            );
          })
          .catch((err: unknown) => {
            const tracking_id: string = uuidv4();
            throw new MetadataError(
              err instanceof Error
                ? err.message
                : 'Error: PanelGeographical failed on an unknown error while calling deleteRegion.',
              tracking_id
            );
          });
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelGeographical failed on an unknown error while calling deleteRow.',
          {
            group: group,
            company: company,
            delete_region: delete_region,
            locationRows: locationRows
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to remove location. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setAllowLocationDelete(null);
      setShowDeleteModal(false);
    }
  };

  const archiveRow = async (archive_region: Region): Promise<void> => {
    try {
      if (group && company && archive_region) {
        await archiveRegion(group.id, company.id, archive_region.id)
          .then(() => {
            setLocationRows(
              locationRows.map((region: Region) => {
                if (region.id === archive_region.id) {
                  return { ...region, archived: new Date() };
                }
                return region;
              })
            );
          })
          .catch((err: unknown) => {
            const tracking_id: string = uuidv4();
            throw new MetadataError(
              err instanceof Error
                ? err.message
                : 'Error: PanelGeographical failed on an unknown error while calling archiveRegion.',
              tracking_id
            );
          });
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelGeographical failed on an unknown error while calling archiveRow.',
          {
            group: group,
            company: company,
            archive_region: archive_region,
            locationRows: locationRows
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to archive location. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setShowDeleteModal(false);
    }
  };

  const unarchiveRow = async (unarchive_region: Region): Promise<void> => {
    try {
      if (group && company && unarchive_region) {
        await unarchiveRegion(group.id, company.id, unarchive_region.id)
          .then(() => {
            setLocationRows(
              locationRows.map((region: Region) => {
                if (region.id === unarchive_region.id) {
                  const { archived, ...unarchived_region } = region;
                  return unarchived_region;
                }
                return region;
              })
            );
          })
          .catch((err: unknown) => {
            const tracking_id: string = uuidv4();
            throw new MetadataError(
              err instanceof Error
                ? err.message
                : 'Error: PanelGeographical failed on an unknown error while calling unarchiveRegion.',
              tracking_id
            );
          });
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelGeographical failed on an unknown error while calling unarchiveRow.',
          {
            group: group,
            company: company,
            unarchive_region: unarchive_region,
            locationRows: locationRows
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to unarchive location. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setShowDeleteModal(false);
    }
  };

  const handleAddLocations = (new_locations: Array<Region>): void => {
    setLocationRows([...new_locations, ...locationRows]);
  };

  const columns: Array<GridColDef> = [
    {
      field: 'flag',
      headerName: 'Flag',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      maxWidth: 90,
      renderCell: (params) => {
        return flagFactory(params.row.country.code);
      }
    },
    {
      field: 'code',
      headerName: 'Code',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.country.code;
      },
      flex: 1
    },
    {
      field: 'continent',
      headerName: 'Continent',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.country.continent;
      },
      flex: 1
    },
    {
      field: 'country',
      headerName: 'Country',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        return params.value.name;
      },
      flex: 1
    },
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      headerAlign: 'right',
      align: 'right',
      hideable: false,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <>
            {row.archived && (
              <GridActionsCellItem
                key={2}
                icon={
                  <Tooltip title="Unarchive Region">
                    <DeleteIcon color="primary" />
                  </Tooltip>
                }
                size="large"
                label="Unarchive"
                sx={{
                  color: 'primary.main'
                }}
                onClick={() => handleDeleteClick(row)}
              />
            )}
            <GridActionsCellItem
              key={2}
              icon={
                <Tooltip title="Delete Region">
                  <DeleteIcon color="primary" />
                </Tooltip>
              }
              size="large"
              label="Delete"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleDeleteClick(row)}
            />
          </>
        ];
      }
    }
  ];

  return (
    <>
      {/* Unarchive Confirmation Modal */}
      <UnarchiveConfirmModal
        open={showUnarchiveModal}
        handleCloseUnarchiveModal={handleCloseUnarchiveModal}
        unarchive_explainer={`Metric Record and Site data for ${actionRow?.name} will be editable.`}
        unarchive_label={actionRow && actionRow.name}
        handleUnarchive={actionRow && (() => unarchiveRow(actionRow))}
      />

      {/* Delete Confirmation Modal */}
      <DeleteConfirmModal
        open={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        delete_warning={`Deleting: Metric Record and Site data for ${actionRow?.name} will be deleted.`}
        archive_warning={`Archiving: Metric Record and Site data for ${actionRow?.name} will remain but will not
        be editable.`}
        delete_label={actionRow && actionRow.name}
        allow_delete={allowLocationDelete}
        allow_archive={actionRow && !actionRow.archived}
        handleDelete={actionRow && (() => deleteRow(actionRow))}
        handleArchive={actionRow && (() => archiveRow(actionRow))}
      />

      {/* Side Widget Panel */}
      <React.Fragment key={'right'}>
        <Drawer
          anchor={'right'}
          open={displayWidgetPanelAdd}
          onClose={() => {
            setDisplayWidgetPanelAdd(false);
          }}
          PaperProps={{ style: { width: '30%', padding: '1.5rem' } }}
        >
          <AddLocationWidget
            closeWidget={() => {
              setDisplayWidgetPanelAdd(false);
            }}
            existing_regions={locationRows}
            handleLocationsToParent={handleAddLocations}
          />
        </Drawer>
      </React.Fragment>

      {/* Interactive Data Table */}
      <DataGrid
        autoHeight
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          },
          sorting: {
            sortModel: [{ field: 'modified', sort: 'desc' }]
          }
        }}
        hideFooter={locationRows.length > 1 ? false : true}
        columnHeaderHeight={locationRows.length < 1 ? 0 : 56}
        pageSizeOptions={[10, 25, 50, 100]}
        loading={gridLoading}
        rows={locationRows}
        columns={columns}
        disableRowSelectionOnClick
        slots={{
          toolbar: PanelGeographicalToolbar,
          noRowsOverlay: PanelGeographicalNoRows,
          loadingOverlay: PanelGeographicalLoading
        }}
        slotProps={{ toolbar: { handleCreate: handleCreateClick } }}
        sx={{ '&, [class^=MuiDataGrid]': { border: 'none' }, width: '100%' }}
      />
    </>
  );
};

export default PanelGeographical;
