import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, DialogActions, DialogContentText } from '@mui/material';
import CancelIcon from '@mui/icons-material/Replay';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { LoadingButton } from '@mui/lab';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';

const UnarchiveConfirmModal = ({
  open,
  unarchive_label,
  unarchive_explainer,
  handleCloseUnarchiveModal,
  handleUnarchive
}: {
  open: boolean;
  unarchive_label?: string | null;
  unarchive_explainer?: string;
  handleCloseUnarchiveModal: () => void;
  handleUnarchive?: (() => void) | null;
}) => {
  const [unarchiveLoading, setUnarchiveLoading] = React.useState(false);
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);

  const handleUnarchiveClick = async () => {
    setUnarchiveLoading(true);
    try {
      if (handleUnarchive) {
        await handleUnarchive();
      }
      handleCloseUnarchiveModal();
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: UnarchiveConfirmModal failed on an unknown error while calling handleUnarchiveClick.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to unarchive entities. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setUnarchiveLoading(false);
    }
    return;
  };

  return (
    <Dialog open={open} onClose={handleCloseUnarchiveModal}>
      <DialogTitle>{`Are you sure you want to Restore ${unarchive_label} from Archived?`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="unarchive-explainer">{unarchive_explainer}</DialogContentText>
        <br />
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<CancelIcon />}
            onClick={handleCloseUnarchiveModal}
          >
            Cancel
          </Button>
          {handleUnarchive && (
            <LoadingButton
              loading={unarchiveLoading}
              startIcon={<UnarchiveIcon />}
              onClick={async () => {
                await handleUnarchiveClick();
              }}
              variant="contained"
              sx={{ backgroundColor: '#1b97c1' }}
              autoFocus
            >
              Restore
            </LoadingButton>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default UnarchiveConfirmModal;
