import {
  collection,
  getDocs,
  query,
  CollectionReference,
  Query,
  QuerySnapshot,
  where,
  doc,
  updateDoc,
  DocumentReference,
  QueryDocumentSnapshot
} from 'firebase/firestore';
import { refCompanyDoc } from '../app/company';
import { ReportingPeriod, ReportingPeriodGroup } from '@esg/esg-global-types';
import { createAuditLog } from '../app/audit';
import moment, { Moment } from 'moment';
import { createFirestoreDoc, readFirestoreDocs, updateFirestoreDoc } from '../app/db_util';
import { uuidv4 } from '@firebase/util';
import { MetadataError } from '@ep/error-handling';
import { db } from '../google/firebase';
import { assignReportingPeriodGroup } from './reporting_period_group';
import { FirestoreQueryParam } from '../../@types/shared';

interface ReportingPeriodData extends Omit<ReportingPeriod, 'id' | 'start' | 'end'> {
  start: Date;
  end: Date;
}

/**
 * Function to query all reporting period documents for current portal company
 * @param {Group} group Group object of current portal company
 * @param {string} company_id id of current portal company to reference firestore documents
 * @param {boolean} locked Request only locked reporting periods if true
 * @returns {Array<ReportPeriod>}
 */
export const getReportingPeriods = async (
  group_id: string,
  company_id: string,
  locked?: boolean
) => {
  const reporting_periods_collection: CollectionReference = collection(
    refCompanyDoc(group_id, company_id),
    'reporting_periods'
  );
  const reporting_periods_query: Query = locked
    ? query(reporting_periods_collection, where('deleted', '==', null), where('locked', '==', true))
    : query(reporting_periods_collection, where('deleted', '==', null));
  try {
    const reporting_periods_docs: QuerySnapshot = await getDocs(reporting_periods_query);
    const reporting_periods: Array<ReportingPeriod> = reporting_periods_docs.docs.map(
      (reporting_period) => {
        return {
          id: reporting_period.id,
          deleted: reporting_period.data().deleted,
          start: reporting_period.data().start.toDate(),
          name: reporting_period.data().name,
          end: reporting_period.data().end.toDate(),
          locked: reporting_period.data().locked,
          reporting_period_group: reporting_period.data().reporting_period_group,
          reference: reporting_period.ref
        };
      }
    );
    return reporting_periods;
  } catch (err) {
    const error = `Error while getting Reporting Periods from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        reporting_periods_collection: reporting_periods_collection,
        reporting_periods_query: reporting_periods_query
      }
    })}`;
    throw new Error(`Error: getReportingPeriods: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to query all reporting period documents for specific reporting group
 * @param {Group} group Group object of current portal company
 * @param {string} company_id id of current portal company to reference firestore documents
 * @param {boolean} locked Request only locked reporting periods if true
 * @returns {Array<ReportPeriod>}
 */
export const getReportingPeriodsByGroup = async (
  group_id: string,
  company_id: string,
  reporting_period_group: ReportingPeriodGroup
) => {
  try {
    const reporting_periods_collection_path = `groups/${group_id}/companies/${company_id}/reporting_periods`;
    const reporting_period_groups_collection_path = `groups/${group_id}/companies/${company_id}/reporting_period_groups`;
    const reporting_period_query: Array<FirestoreQueryParam> = [
      { field_name: 'deleted', operator: '==', value: null },
      {
        field_name: 'reporting_period_group',
        operator: '==',
        value: doc(db, reporting_period_groups_collection_path, reporting_period_group.id)
      }
    ];
    const reporting_periods_docs: QuerySnapshot = await readFirestoreDocs(
      reporting_periods_collection_path,
      reporting_period_query
    );
    const reporting_periods: Array<ReportingPeriod> = reporting_periods_docs.docs.map(
      (reporting_period: QueryDocumentSnapshot) => {
        return {
          id: reporting_period.id,
          deleted: reporting_period.data().deleted,
          start: reporting_period.data().start.toDate(),
          name: reporting_period.data().name,
          end: reporting_period.data().end.toDate(),
          locked: reporting_period.data().locked,
          reporting_period_group: reporting_period.data().reporting_period_group
        };
      }
    );
    return reporting_periods;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/reporting_period.ts failed on an unknown error while calling getReportingPeriodsByGroup.',
      {
        group_id: group_id,
        company_id: company_id,
        reporting_period_group: reporting_period_group
      },
      tracking_id
    );
  }
};

/**
 * Function to create reporting period with relative data
 * @param {string} group_id ID of Group which Reporting Period is being created for
 * @param {string} company_id ID of Company which Reporting Period is being created for
 * @param {ReportingPeriodData} reporting_period Data of new Reporting Period to add to doc
 * @returns {DocumentReference}
 */
export const createReportingPeriod = async (
  group_id: string,
  company_id: string,
  reporting_period: ReportingPeriodData
) => {
  const collection_path = `groups/${group_id}/companies/${company_id}/reporting_periods`;
  try {
    if (reporting_period.reporting_period_group) {
      reporting_period.reporting_period_group = doc(
        db,
        `groups/${group_id}/companies/${company_id}/reporting_period_groups/${reporting_period.reporting_period_group.id}`
      );
    } else {
      delete reporting_period.reporting_period_group;
    }
    const created_reporting_period: DocumentReference = await createFirestoreDoc(
      collection_path,
      reporting_period
    );
    await createAuditLog(group_id, 'create', '', reporting_period.name, created_reporting_period);
    return created_reporting_period;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/reporting_period.ts failed on an unknown error while calling createReportingPeriod.',
      {
        group_id: group_id,
        company_id: company_id,
        reporting_period: reporting_period
      },
      tracking_id
    );
  }
};

/**
 * Function to update reporting period with relative data and unassign reporting period group if null
 * @param {string} group_id ID of Group to update reporting period for
 * @param {string} company_id ID of Company to update reporting period for
 * @param {ReportingPeriod} updated_reporting_period New Reporting Period data to push into document
 * @returns {void}
 */
export const updateReportingPeriod = async (
  group_id: string,
  company_id: string,
  updated_reporting_period: ReportingPeriod,
  original_reporting_period: ReportingPeriod
) => {
  try {
    const collection_path = `groups/${group_id}/companies/${company_id}/reporting_periods`;
    const reporting_period_data: ReportingPeriodData = {
      deleted: updated_reporting_period.deleted,
      name: updated_reporting_period.name,
      start: moment(updated_reporting_period.start).toDate(),
      end: moment(updated_reporting_period.end).toDate(),
      locked: updated_reporting_period.locked,
      ...(updated_reporting_period.reporting_period_group && {
        reporting_period_group: doc(
          db,
          `groups/${group_id}/companies/${company_id}/reporting_period_groups/${updated_reporting_period.reporting_period_group.id}`
        )
      })
    };
    const updated_reporting_period_doc: DocumentReference = await updateFirestoreDoc(
      collection_path,
      updated_reporting_period.id,
      reporting_period_data
    );
    if (
      original_reporting_period.reporting_period_group &&
      !updated_reporting_period.reporting_period_group
    ) {
      await assignReportingPeriodGroup(group_id, company_id, [updated_reporting_period]);
    }
    await createAuditLog(
      group_id,
      'update',
      JSON.stringify(original_reporting_period),
      JSON.stringify(reporting_period_data),
      updated_reporting_period_doc
    );
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/reporting_period.ts failed on an unknown error while calling updateReportingPeriod.',
      {
        group_id: group_id,
        company_id: company_id,
        updated_reporting_period: updated_reporting_period,
        original_reporting_period: original_reporting_period
      },
      tracking_id
    );
  }
};

/**
 * Function to delete company ReportingPeriod
 * @param {string} group_id ID of Group to delete reporting period for
 * @param {string} company_id ID of Company to delete reporting period for
 * @param {string} reporting_period_id ID of reporting period to delete
 * @returns {void}
 */
export const deleteReportingPeriod = async (
  group_id: string,
  company_id: string,
  reporting_period_id: string,
  reporting_period_name: string
) => {
  if (group_id && company_id && reporting_period_id) {
    const reporting_period_doc = doc(
      collection(refCompanyDoc(group_id, company_id), `reporting_periods`),
      reporting_period_id
    );
    await updateDoc(reporting_period_doc, {
      deleted: new Date()
    });
    await createAuditLog(group_id, 'delete', reporting_period_name, '', reporting_period_doc);
  }
  return;
};

/**
 * Function to check a given time range for a reporting period against an existing list of reporting periods for overlaps
 * @param {string} id ID of reporting period being checked
 * @param {Moment} start_date Start date of reporting period being checked
 * @param {Moment} end_date End date of the reporting period being checked
 * @param {Array<ReportingPeriod>} existing_reporting_periods List of reporting periods to check against
 * @returns {Array<ReportingPeriod>}
 */
export const checkReportingPeriodOverlap = (
  id: string,
  start_date: Moment,
  end_date: Moment,
  existing_reporting_periods: Array<ReportingPeriod>
) => {
  const overlapped_periods: Array<ReportingPeriod> = existing_reporting_periods.filter(
    (reporting_period: ReportingPeriod) => {
      return (
        start_date.isBefore(reporting_period.end) &&
        end_date.isAfter(reporting_period.start) &&
        id !== reporting_period.id
      );
    }
  );
  return overlapped_periods;
};

/**
 * Function to query reporting periods which are unassigned or conditionally belong to a given reporting period group
 * @param {Array<ReportingPeriod>} reporting_periods Reporting Periods to check availability against
 * @param {ReportingPeriodGroup | null} reporting_period_group Conditionally include reporting periods belonging to given RPG
 * @returns {Array<ReportingPeriod>}
 */
export const getAvailableReportingPeriodsForReportingPeriodGroup = (
  reporting_periods: Array<ReportingPeriod>,
  reporting_period_group: ReportingPeriodGroup | null
) => {
  const available_reporting_periods: Array<ReportingPeriod> = reporting_periods.filter(
    (reporting_period: ReportingPeriod) => {
      const available: boolean =
        reporting_period.reporting_period_group === undefined ||
        (!!reporting_period_group &&
          reporting_period.reporting_period_group?.id === reporting_period_group.id);
      return available;
    }
  );
  return available_reporting_periods;
};

/**
 * Function to filter reporting periods based on a reporting period group
 * @param {string} reporting_period_group_id ID of Reproting Period Group to filter reporting periods on
 * @param {Array<ReportingPeriod>} reporting_periods Array of reporting periods to filter on
 * @param {boolean | undefined} sort Sorts returned array of reporting periods by ascending start date
 * @returns {Array<ReportingPeriod>} Array of filtered reporting periods
 */
export const reportingPeriodFilterByGroup = (
  reporting_period_group_id: string,
  reporting_periods: Array<ReportingPeriod>,
  sort?: boolean
) => {
  let filtered_reporting_periods: Array<ReportingPeriod> = [];
  if (reporting_period_group_id === 'all') {
    filtered_reporting_periods = reporting_periods;
  } else if (reporting_period_group_id === 'unnassigned') {
    filtered_reporting_periods = reporting_periods.filter(
      (reporting_period) =>
        !reporting_period.reporting_period_group || reporting_period.reporting_period_group === null
    );
  } else {
    filtered_reporting_periods = reporting_periods.filter(
      (reporting_period) =>
        reporting_period.reporting_period_group?.id === reporting_period_group_id
    );
  }
  if (sort) {
    filtered_reporting_periods.sort((a: ReportingPeriod, b: ReportingPeriod) => {
      return a.start.valueOf() - b.start.valueOf();
    });
  }
  return filtered_reporting_periods;
};

/**
 * Function to count the days included between the start and end dates of a list of Reporting Periods
 * @param {Array<ReportingPeriod>} reporting_periods Reporting Periods to count days for
 * @returns {number} Number of days
 */
export const getReportingPeriodDays = (reporting_periods: Array<ReportingPeriod>): number => {
  return (
    reporting_periods.reduce((total_days: number, reporting_period: ReportingPeriod) => {
      return total_days + moment(reporting_period.end).diff(moment(reporting_period.start), 'days');
    }, 0) + 1
  );
};
