import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Switch,
  Tooltip,
  Typography
} from '@mui/material';
import { Group } from '@esg/esg-global-types';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import ConfigAddWidget, { CreateInput } from '../../configuration/ConfigAddWidget';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { DataGrid, GridActionsCellItem, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { createGroup, getGroupCompanies, updateGroup } from '../../../lib/app/group';
import { PanelGroupsToolbar } from './PanelGroupsToolbar';
import { PanelGroupsNoRows } from './PanelGroupsNoRows';
import { PanelGroupsLoading } from './PanelGroupsLoading';
import { useNavigate } from 'react-router-dom';
import { DocumentReference } from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';

const PanelGroups = () => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [actionRow, setActionRow] = React.useState<Group | null>(null);
  const [groupRows, setGroupRows] = React.useState<Array<Group>>([]);
  const [displayWidgetPanelAdd, setDisplayWidgetPanelAdd] = React.useState<boolean>(false);
  const [openDialogActive, setOpenDialogActive] = React.useState<boolean>(false);
  const [gridLoading, setGridLoading] = React.useState<boolean>(true);
  const [actionLoading, setActionLoading] = React.useState<boolean>(false);

  const navigate = useNavigate();

  const viewGroup = (group: Group) => {
    navigate('/settings/group-management/view', { state: group });
  };

  // Handler functions
  const handleCreateClick = (): void => {
    setActionRow(null);
    setDisplayWidgetPanelAdd(true);
  };

  const handleCloseActiveModal = () => {
    setOpenDialogActive(false);
  };

  const handleEditGroup = (updated_row: Group) => {
    const new_rows = groupRows.map((row) => (updated_row.id === row.id ? { ...updated_row } : row));
    setGroupRows(new_rows);
  };

  const handleGroupActive = async (new_active: boolean) => {
    try {
      if (actionRow) {
        setActionLoading(true);
        await updateGroup({ ...actionRow, active: new_active }).then(() => {
          handleEditGroup({ ...actionRow, active: new_active });
        });
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelGroups failed on an unknown error while calling handleGroupActive.',
          {
            actionRow: actionRow,
            new_active: new_active
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `An error occurred. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      handleCloseActiveModal();
      setActionLoading(false);
    }
  };

  const columns: Array<GridColDef> = [
    {
      field: 'active',
      headerName: 'Active',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.row.active ? true : false}
            onChange={() => {
              setActionRow(params.row);
              setOpenDialogActive(true);
            }}
          />
        );
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'companies',
      headerName: 'Companies',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        return Array.isArray(params.value) ? params.value.length : 0;
      },
      flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      headerAlign: 'left',
      align: 'left',
      hideable: false,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <>
            <GridActionsCellItem
              key={2}
              icon={
                <Tooltip title={`View ${row.name}`}>
                  <ChevronRightOutlinedIcon sx={{ fontSize: '2.5rem' }} color="primary" />
                </Tooltip>
              }
              size="large"
              label="View"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => {
                viewGroup(row);
              }}
              disabled={!row.active}
            />
          </>
        ];
      }
    }
  ];

  const inputs: readonly CreateInput[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Name'
    }
  ];

  // Row functions
  const fetchRows = async () => {
    setGridLoading(true);
    try {
      // Load rows from database.
      const groups: Array<Group> = await getGroupCompanies();
      // Load rows to memory.
      setGroupRows(groups);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelGroups failed on an unknown error while calling fetchRows.',
          {},
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch groups. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
      return;
    } finally {
      setGridLoading(false);
    }
  };

  React.useEffect(() => {
    try {
      (async () => {
        setGroupRows([]);
        fetchRows();
      })().catch((error) => {
        throw new Error(error);
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelGroups failed on an unknown error while initialising.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `An error occurred. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
    return;
  }, []);

  const createRow = async (name: string): Promise<void> => {
    try {
      // Create row in database.
      const group_doc: DocumentReference = await createGroup(name);
      if (group_doc) {
        // Create grid row in memory
        setGroupRows([
          {
            id: group_doc.id,
            name: name,
            active: true,
            companies: []
          },
          ...groupRows
        ]);
      } else {
        throw new MetadataError('Could not create Group in database', {
          group_doc: group_doc
        });
      }
      // Close side menu.
      setDisplayWidgetPanelAdd(false);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelGroups failed on an unknown error while calling createRow.',
          {
            name: name
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to create Group. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  return (
    <>
      {/* Set Group Active Modal */}
      <Dialog open={openDialogActive} onClose={handleCloseActiveModal}>
        <Box sx={{ p: 1.5 }}>
          <DialogTitle id="alert-dialog-title">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <WarningAmberIcon sx={{ marginRight: '1rem' }} fontSize="medium" />
              <Typography variant="h6" color="inherit" align="left">
                {actionRow?.active ? 'Disable' : 'Enable'}{' '}
                {actionRow?.name ? actionRow.name : 'Group'}?
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are about to {actionRow?.active ? 'deactivate' : 'activate'}{' '}
              {actionRow?.name ? actionRow.name : 'group'} {actionRow?.active ? 'from' : 'for'} your
              Groups.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseActiveModal} variant="outlined" color="primary">
              Back
            </Button>
            <LoadingButton
              loading={actionLoading}
              onClick={() => actionRow && handleGroupActive(actionRow.active ? false : true)}
              variant="contained"
              color="error"
              autoFocus
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>

      {/* Side Widget Panel */}
      <Drawer
        anchor={'right'}
        open={displayWidgetPanelAdd}
        onClose={() => setDisplayWidgetPanelAdd(false)}
        PaperProps={{ style: { width: '30%', padding: '1.5rem' } }}
      >
        <ConfigAddWidget
          create_label="Groups"
          create_icon={<DomainAddIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
          create_function_inputs={inputs}
          grid_spacing={3}
          handleClose={() => setDisplayWidgetPanelAdd(false)}
          createFunction={(name: string) => createRow(name)}
        />
      </Drawer>

      {/* Interactive Data Table */}
      <DataGrid
        autoHeight
        rowHeight={65}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          },
          sorting: {
            sortModel: [{ field: 'modified', sort: 'desc' }]
          }
        }}
        hideFooter={groupRows.length > 1 ? false : true}
        columnHeaderHeight={groupRows.length < 1 ? 0 : 56}
        pageSizeOptions={[10, 25, 50, 100]}
        loading={gridLoading}
        rows={groupRows}
        columns={columns}
        disableRowSelectionOnClick
        slots={{
          toolbar: PanelGroupsToolbar,
          noRowsOverlay: PanelGroupsNoRows,
          loadingOverlay: PanelGroupsLoading
        }}
        slotProps={{ toolbar: { handleCreate: handleCreateClick } }}
        sx={{ '&, [class^=MuiDataGrid]': { border: 'none' }, width: '100%' }}
      />
    </>
  );
};

export default PanelGroups;
