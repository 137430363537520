import React from 'react';
import { Tooltip, IconButton, Button, FormControlLabel, Checkbox } from '@mui/material';
import { GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';

export const UsersTableToolbar = ({
  handleCreate,
  handleShowDeleted
}: {
  handleCreate: () => void;
  handleShowDeleted: (show: boolean) => void;
}) => {
  return (
    <>
      <GridToolbarContainer>
        <GridToolbarQuickFilter style={{ flex: 1 }} />
        <Tooltip title="Learn more">
          <span>
            <IconButton
              aria-label="help"
              size="small"
              color="primary"
              sx={{ marginLeft: 'auto' }}
              onClick={() => {
                // Open assistance menu / bot here.
              }}
              disabled
            >
              <HelpOutlineIcon />
            </IconButton>
          </span>
        </Tooltip>
        <FormControlLabel
          sx={{ mx: 2 }}
          control={
            <Checkbox
              defaultChecked={false}
              color="primary"
              onChange={(event) => {
                handleShowDeleted(event.target.checked);
              }}
            />
          }
          label="Show Deleted Users"
        />
        <Tooltip title="Create new user">
          <Button
            variant="contained"
            size="large"
            startIcon={<AddIcon />}
            onClick={handleCreate}
            sx={{ mx: 2 }}
          >
            Create
          </Button>
        </Tooltip>
      </GridToolbarContainer>
    </>
  );
};
