import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import { Link, useLocation } from 'react-router-dom';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { UserContext } from '../../context/UserContext';
import { CompanyContext } from '../../context/CompanyContext';
import { getModuleAccess, getViewAccess } from '../../util/user_access';
import { Group } from '@esg/esg-global-types';
import { GroupContext } from '../../context/GroupContext';
import HelpIcon from '@mui/icons-material/Help';

interface ModuleItem {
  label: string;
  icon: React.ReactNode;
  handler: () => void;
  expanded?: boolean;
  permission: string;
  pages?: Array<ModulePage>;
  route?: string;
}

interface ModulePage extends Omit<ModuleItem, 'handler' | 'expanded' | 'pages'> {
  route: string;
}

const SideMenuNavItems = ({
  title,
  handleCloseNav
}: {
  title: string;
  handleCloseNav: () => void;
}) => {
  const user_info = React.useContext(UserContext);
  const group: Group | null = React.useContext(GroupContext);
  const company = React.useContext(CompanyContext);
  const [openModule, setOpenModule] = React.useState<string>('');

  //current route of app
  const location = useLocation();
  const current_route = location.pathname.substring(1);

  const menu_items: Array<ModuleItem> = [
    {
      label: 'Readiness Gauge',
      icon: <SpeedOutlinedIcon />,
      handler: () => setOpenModule(openModule === 'readiness_gauge' ? '' : 'readiness_gauge'),
      expanded: openModule === 'readiness_gauge',
      permission: 'readiness_gauge',
      pages: [
        {
          label: 'Dashboard',
          route: 'readiness/',
          icon: <ChevronRightOutlinedIcon />,
          permission: 'readiness_gauge.dashboard'
        }
      ]
    },
    {
      label: 'Assessment',
      icon: <PolicyOutlinedIcon />,
      handler: () => setOpenModule(openModule === 'assessment' ? '' : 'assessment'),
      expanded: openModule === 'assessment',
      permission: 'assessment',
      pages: [
        {
          label: 'Dashboard',
          route: 'assessment/',
          icon: <ChevronRightOutlinedIcon />,
          permission: 'assessment.dashboard'
        }
      ]
    },
    {
      label: 'Metric Collection',
      icon: <StorageOutlinedIcon />,
      handler: () => setOpenModule(openModule === 'metric_collection' ? '' : 'metric_collection'),
      expanded: openModule === 'metric_collection',
      permission: 'metric_collection',
      pages: [
        {
          label: 'Dashboard',
          route: 'metrics/',
          icon: <ChevronRightOutlinedIcon />,
          permission: 'metric_collection.dashboard'
        },
        {
          label: 'Capture',
          route: 'metrics/manage',
          icon: <ChevronRightOutlinedIcon />,
          permission: 'metric_collection.management'
        }
      ]
    },
    {
      label: 'Reports',
      icon: <AssessmentOutlinedIcon />,
      handler: () => setOpenModule(openModule === 'reports' ? '' : 'reports'),
      expanded: openModule === 'reports',
      permission: 'reports',
      pages: [
        {
          label: 'Dashboard',
          route: 'report/',
          icon: <ChevronRightOutlinedIcon />,
          permission: 'reports.dashboard'
        },
        {
          label: 'Raw Export',
          route: 'report/export',
          icon: <ChevronRightOutlinedIcon />,
          permission: 'reports.export'
        }
      ]
    },
    {
      label: 'Resource Centre',
      icon: <LibraryBooksOutlinedIcon />,
      handler: () => setOpenModule(openModule === 'resource_center' ? '' : 'resource_center'),
      expanded: openModule === 'resource_center',
      permission: 'resource_centre',
      pages: [
        {
          label: 'Dashboard',
          route: 'resource/',
          icon: <ChevronRightOutlinedIcon />,
          permission: 'resource_centre.dashboard'
        }
      ]
    },
    {
      label: 'User Guide',
      icon: <HelpIcon />,
      handler: () => setOpenModule(openModule === 'user_guide' ? '' : 'user_guide'),
      expanded: openModule === 'user_guide',
      permission: 'user_guide',
      pages: [
        {
          label: 'Dashboard',
          route: 'guide/',
          icon: <ChevronRightOutlinedIcon />,
          permission: 'guide.dashboard'
        }
      ]
    },
    {
      label: 'Configuration',
      icon: <SettingsIcon />,
      handler: () => setOpenModule(''),
      permission: 'configuration',
      route: 'configuration/dashboard'
    }
  ];

  const style_menu_link = {
    color: '#053d5d',
    fontWeight: 'bold'
  };

  const current_style_menu_link = {
    color: '#053d5d',
    backgroundColor: '#edf1f8',
    fontWeight: 'bold'
  };

  const style_sub_menu_link = {
    margin: '1rem',
    textDecoration: 'none',
    color: '#607D8B'
  };

  const style_sub_menu_link_current = {
    margin: '1rem',
    textDecoration: 'none',
    color: '#053d5d',
    backgroundColor: '#F6F5F5'
  };

  return (
    <React.Fragment>
      <div data-testid="sidemenunavitems_id" />
      {user_info && group && company && (
        <List sx={{ width: '100%' }} component="nav">
          {menu_items.map((menu_item: ModuleItem, index: number) => {
            if (getModuleAccess(user_info, group.id, company.id, menu_item.permission)) {
              return (
                <React.Fragment key={`module_${index}`}>
                  {menu_item.pages ? (
                    <>
                      <ListItemButton
                        onClick={menu_item.handler}
                        style={
                          title === menu_item.label ? current_style_menu_link : style_menu_link
                        }
                      >
                        <ListItemIcon>{menu_item.icon}</ListItemIcon>
                        <ListItemText primary={menu_item.label} style={style_menu_link} />
                        {menu_item.expanded ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={menu_item.expanded} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {menu_item.pages.map((page: ModulePage, index: number) => {
                            return getViewAccess(
                              user_info,
                              group.id,
                              company.id,
                              page.permission
                            ) !== 'none' ? (
                              <Link
                                to={page.route}
                                key={`link_${index}`}
                                style={
                                  current_route === page.route
                                    ? style_sub_menu_link_current
                                    : style_sub_menu_link
                                }
                              >
                                <ListItemButton
                                  sx={{ pl: 4, backgroundColor: 'inherit' }}
                                  onClick={handleCloseNav}
                                >
                                  <ListItemText primary={page.label} />
                                </ListItemButton>
                              </Link>
                            ) : (
                              <ListItemButton
                                sx={{ pl: 4, backgroundColor: 'inherit' }}
                                disabled
                                key={index}
                              >
                                <ListItemText primary={page.label} />
                              </ListItemButton>
                            );
                          })}
                        </List>
                      </Collapse>
                    </>
                  ) : (
                    <>
                      <Link
                        to={menu_item.route ? menu_item.route : ''}
                        key={`link_${index}`}
                        style={{ textDecoration: 'none' }}
                      >
                        <ListItemButton
                          onClick={() => {
                            menu_item.handler();
                            handleCloseNav();
                          }}
                          style={
                            title === menu_item.label ? current_style_menu_link : style_menu_link
                          }
                        >
                          <ListItemIcon>{menu_item.icon}</ListItemIcon>
                          <ListItemText primary={menu_item.label} style={style_menu_link} />
                        </ListItemButton>
                      </Link>
                    </>
                  )}
                </React.Fragment>
              );
            }
          })}
        </List>
      )}
    </React.Fragment>
  );
};

export default SideMenuNavItems;
