/**
 * Validate whether the provided string is a valid latitude string.
 * @param {string} latitude String of characters to check for banned characters
 * @returns {boolean}
 */
export const isValidLatitude = (latitude: string): boolean =>
  new RegExp(/^-?(90|[0-8]?\d)(\.\d+)?/).test(latitude);

/**
 * Validate whether the provided string is a valid longitude string.
 * @param {string} longitude String of characters to check for banned characters
 * @returns {boolean}
 */
export const isValidLongitude = (longitude: string): boolean =>
  new RegExp(/-?(180|1[0-7]\d|\d?\d)(\.\d+)?$/).test(longitude);

/**
 * Validate whether the provided string is a valid coordinates string.
 * @param {string} coordinates String of characters to check for banned characters
 * @returns {boolean}
 */
export const isValidCoordinates = (coordinates: string): boolean =>
  new RegExp(/^-?(90|[0-8]?\d)(\.\d+)?, *-?(180|1[0-7]\d|\d?\d)(\.\d+)?$/).test(coordinates);
