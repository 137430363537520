import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SettingsIcon from '@mui/icons-material/Settings';
import LoadingScreen from './LoadingScreen';
import AccessDenied from '../../views/shared/AccessDenied';
import { Company, Group, User } from '@esg/esg-global-types';
import { UserContext } from '../../context/UserContext';
import { GroupContext } from '../../context/GroupContext';
import { CompanyContext } from '../../context/CompanyContext';
import { ViewAccess, getViewAccess } from '../../util/user_access';
import { uuidv4 } from '@firebase/util';
import { log } from '../../util/log';
import { MetadataError } from '@ep/error-handling';
import { FeedbackSnackbarContext } from '../../context/FeedbackSnackbarContext';

interface ConfigNavButton {
  route: string;
  label: string;
  icon: ReactNode;
}

const ConfigurationWrapper = ({
  title,
  icon,
  children,
  previous,
  next
}: {
  title: string;
  icon: ReactNode;
  children: ReactNode;
  previous: ConfigNavButton | null;
  next: ConfigNavButton | null;
}) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const user_info: User | null = React.useContext(UserContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [access, setAccess] = React.useState<ViewAccess>(null);

  React.useEffect(() => {
    try {
      setAccess(
        group && company && user_info
          ? getViewAccess(user_info, group.id, company.id, 'configuration')
          : null
      );
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: ConfigurationWrapper failed on an unknown error while initialising.',
          {
            user_info: user_info,
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Could not verify user access. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  }, [user_info, company]);

  return !user_info || access === null ? (
    <LoadingScreen />
  ) : access !== 'none' ? (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 4, gap: 4 }}>
            {icon}
            <Typography sx={{ fontSize: '1.8rem' }}>{title}</Typography>
          </Box>
          <Box sx={{ minHeight: '60vh', width: '100%' }}>{children}</Box>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', my: 2 }}>
        <Button
          startIcon={<KeyboardArrowLeftIcon />}
          endIcon={<SettingsIcon />}
          size="large"
          variant="outlined"
          component={Link}
          to={'/configuration/dashboard'}
          sx={{ width: '100px', height: '50px', mr: 2 }}
        />
        {previous && (
          <Tooltip title={`Navigate to ${previous.label}`}>
            <Button
              startIcon={<KeyboardArrowLeftIcon />}
              endIcon={previous.icon}
              variant="outlined"
              component={Link}
              to={previous.route}
              sx={{ minWidth: '250px', height: '50px', mr: 'auto', px: 4 }}
            >
              {previous.label}
            </Button>
          </Tooltip>
        )}
        {next && (
          <Tooltip title={`Navigate to ${next.label}`}>
            <Button
              startIcon={next.icon}
              endIcon={<KeyboardArrowRightIcon />}
              variant="outlined"
              component={Link}
              to={next.route}
              sx={{ minWidth: '250px', height: '50px', ml: 'auto', px: 4 }}
            >
              {next.label}
            </Button>
          </Tooltip>
        )}
      </Box>
    </Box>
  ) : (
    <AccessDenied />
  );
};

export default ConfigurationWrapper;
