import { db } from '../google/firebase';
import {
  collection,
  CollectionReference,
  getDocs,
  QuerySnapshot,
  query,
  Query,
  where
} from 'firebase/firestore';
import { Role } from '@esg/esg-global-types';

/**
 * Function to query all Roles from Master List
 * @returns {Array<Role>}
 */
export const getRoles = async () => {
  const roles_collection: CollectionReference = collection(db, 'roles_master_list');
  const roles_query: Query = query(roles_collection, where('deleted', '==', null));
  try {
    const role_docs: QuerySnapshot = await getDocs(roles_query).catch((error) => {
      throw new Error(error);
    });
    const roles: Array<Role> = role_docs.docs.map((role) => {
      return {
        id: role.id,
        deleted: role.data().deleted,
        name: role.data().name
      };
    });
    return roles;
  } catch (err) {
    const error = `Error while getting Roles from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        roles_collection: roles_collection,
        roles_query: roles_query
      }
    })}`;
    throw new Error(`Error: getRoles: ${JSON.stringify(error)}.`);
  }
};
