import { Box } from '@mui/material';
import React from 'react';
import ConfigurationWrapper from '../../components/shared/ConfigurationWrapper';
import EmojiTransportationOutlinedIcon from '@mui/icons-material/EmojiTransportationOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import PanelExternalCompanies from '../../components/configuration/external_companies/PanelExternalCompanies';

const ConfigurationExternalCompanies = () => {
  return (
    <Box>
      <ConfigurationWrapper
        title="External Companies"
        icon={<EmojiTransportationOutlinedIcon fontSize="large" />}
        previous={{
          route: '/configuration/organisation',
          label: 'Organisation',
          icon: <AccountTreeOutlinedIcon />
        }}
        next={{
          route: '/configuration/reporting-periods',
          label: 'Reporting Periods',
          icon: <AccessAlarmIcon />
        }}
      >
        <PanelExternalCompanies />
      </ConfigurationWrapper>
    </Box>
  );
};

export default ConfigurationExternalCompanies;
