import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';

const CookieConsentDrawer = () => {
  const [openCookieDrawer, setOpenCookieDrawer] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!Cookies.get('cookie-consent')) setOpenCookieDrawer(true);
  }, []);

  const handleCookieConsent = () => {
    Cookies.set('cookie-consent', 'accepted', { sameSite: 'None', secure: true });
    setOpenCookieDrawer(false);
  };

  return (
    <>
      <Drawer
        anchor={'bottom'}
        open={openCookieDrawer}
        onClose={() => {
          setOpenCookieDrawer(false);
        }}
      >
        <Box display={'flex'} padding={4} justifyContent={'space-evenly'}>
          <Box display={'flex'} flexDirection={'column'} marginRight={4}>
            <Typography fontSize={20} fontWeight={'bold'} marginBottom={1}>
              Cookie Consent
            </Typography>
            <Typography>
              {`This website makes use of cookies to provide a better user experience and manage user sessions.`}
            </Typography>
            <Typography>{`By continuing to use this website you consent to the use of these cookies.`}</Typography>
          </Box>
          <Box alignSelf={'center'}>
            <Button
              variant="contained"
              onClick={handleCookieConsent}
              style={{
                width: '150px',
                height: '50px'
              }}
            >
              OK
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default CookieConsentDrawer;
