/**
 * Creates a form data object with information required to put files in the correct cloud storage folder
 * @param {File} new_file File object being uploaded
 * @param {string} file_name Name to assign file when uploaded to bucket, please follow correct naming procedure
 * @param {string} bucket_name Name of bucket on cloud storage to put files into
 * @param {string} path Instance name for root level of cloud storage folder
 * @param {string} make_public Setting to make the uploaded file public, has to be a string of either 'true' or 'false as formdata doesn't take booleans
 * @param {string} file_name Optional parameter to give a custom file name, if not given file will just be named its original name
 * @returns {FormData}
 */

export const generateFileUploadRequest = (
  new_file: File,
  file_name: string,
  bucket_name: string,
  path: string,
  make_public: 'true' | 'false'
) => {
  const file_upload = new FormData();
  file_upload.append('file', new_file);
  file_upload.append('bucket', bucket_name);
  file_upload.append('path', path);
  file_upload.append('make_public', make_public);
  if (file_name) file_upload.append('file_name', file_name);

  return file_upload;
};
