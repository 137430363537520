import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../../lib/google/firebase';
import CardHeader from '@mui/material/CardHeader';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SortIcon from '@mui/icons-material/Sort';
import { UserContext } from '../../context/UserContext';
import { FeedbackSnackbarContext } from '../../context/FeedbackSnackbarContext';
import { User } from '@esg/esg-global-types';
import { LocationCity } from '@mui/icons-material';
import { getAdminGroups } from '../../util/user_access';
import { useTheme } from '@mui/material';
import { log } from '../../util/log';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import SettingsIcon from '@mui/icons-material/Settings';

const AccountMenu = ({ resetAppContext }: { resetAppContext: () => void }) => {
  const user_info: User | null = React.useContext(UserContext);
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const theme = useTheme();

  const admin_groups: Array<string> = user_info ? getAdminGroups(user_info) : [];
  const user_title = user_info?.super_admin
    ? 'App Administrator'
    : admin_groups.length > 0
      ? 'Group Administrator'
      : user_info?.type === 'external'
        ? 'External Contributor'
        : 'Company Contributor';

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    try {
      signOut(auth)
        .then(() => {
          localStorage.removeItem('current_group');
          localStorage.removeItem('current_company');
          resetAppContext();
          theme.palette.primary.main = '#053d5d';
          theme.palette.secondary.main = '#000000';
          // Sign-out successful.
          navigate('/');
        })
        .catch((err: unknown) => {
          const tracking_id: string = uuidv4();
          log(
            'error',
            new MetadataError(
              err instanceof Error
                ? err.message
                : 'Error: AccountMenu failed on an unknown error while calling handleSignOut.',
              { user: user_info },
              tracking_id
            )
          );
          setFeedbackData({
            message: `Unable to sign out at the moment. Tracking ID: ${tracking_id}`,
            state: true,
            type: 'error'
          });
        });
      handleClose();
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: AccountMenu failed on an unknown error while calling handleSignOut.',
          { user: user_info },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to sign out at the moment. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  return (
    <React.Fragment>
      <div data-testid="accountmenu_id" />
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreVertIcon sx={{ color: '#FAFAFA' }} fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {user_info && (
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                {user_info.first_name[0].toUpperCase()}
              </Avatar>
            }
            action={<></>}
            title={user_info.email}
            subheader={user_title}
          />
        )}
        {(user_info?.super_admin || admin_groups.length > 0) && (
          <Link
            to="/settings/group-management"
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            <MenuItem onClick={handleClose} sx={{ p: 1.5 }}>
              <ListItemIcon>
                <LocationCity fontSize="small" />
              </ListItemIcon>
              Group Management
            </MenuItem>
          </Link>
        )}
        {(user_info?.super_admin || admin_groups.length > 0) && (
          <Link to="/settings/user-management" style={{ color: 'inherit', textDecoration: 'none' }}>
            <MenuItem onClick={handleClose} sx={{ p: 1.5 }}>
              <ListItemIcon>
                <ManageAccountsIcon fontSize="small" />
              </ListItemIcon>
              User Management
            </MenuItem>
          </Link>
        )}
        {(user_info?.super_admin || admin_groups.length > 0) && (
          <Link to="/metric-record-activity" style={{ color: 'inherit', textDecoration: 'none' }}>
            <MenuItem onClick={handleClose} sx={{ p: 1.5 }}>
              <ListItemIcon>
                <SortIcon fontSize="small" />
              </ListItemIcon>
              Metric Record Activity
            </MenuItem>
          </Link>
        )}
        {user_info?.super_admin && (
          <Link
            to="/settings/master/emission-factors"
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            <MenuItem onClick={handleClose} sx={{ p: 1.5 }}>
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              Emission Factors
            </MenuItem>
          </Link>
        )}
        <MenuItem onClick={handleSignOut} sx={{ p: 3 }}>
          <Typography variant="subtitle1">Sign Out</Typography>
          <ListItemIcon sx={{ ml: 1 }}>
            <Logout fontSize="small" />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default AccountMenu;
