import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import ReportingPeriodSelect from '../../shared/input/select/ReportingPeriodSelect';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { ExportFilter, ExportFilterProps } from '../ExportHub';
import {
  Country,
  Division,
  Region,
  ReportingPeriod,
  ReportingPeriodGroup,
  Site,
  Subdivision
} from '@esg/esg-global-types';
import MetricSelect from '../../shared/input/select/MetricSelect';
import { MetricExtended } from '../../../lib/metric_capture/metric';
import FilterCheckboxDropdown from '../../shared/input/checkbox/FilterCheckboxDropdown';
import moment from 'moment';
import AlertBanner from '../../shared/banner/AlertBanner';
import { getReportingPeriodDays } from '../../../lib/metric_capture/reporting_period';
import CountrySelect from '../../shared/input/select/CountrySelect';
import ReportingPeriodGroupSelect from '../../shared/input/select/ReportingPeriodGroupSelect';

/**
 * A widget to add inclusive filters to an export of all Metric Records
 * @param {MetricRecordEntities} export_entities Lists of entities to use as options for filters
 * @param {ExportType} export_type Type of Export chosen by the user
 * @param {FilterInputErrors} input_errors Object listing invalid inputs from filter selections
 * @param {void} handleUpdateFilters Function to update the export filters in the parent
 * @param {void} handleClearFilters Function to clear all filters in the parent
 * @param {ExportFilter | undefined} getFilter Function to get a filter for a given entity if it exists from parent
 * @param {void} handleInputValidity Function to update input_errors object in parent
 * @returns {JSX.Element}
 */
const FullMetricRecordFilters = ({
  export_entities,
  export_filters,
  export_type,
  input_errors,
  handleUpdateFilters,
  handleClearFilters,
  handleInputValidity
}: ExportFilterProps) => {
  const [reportingPeriodGroupFilters, setReportingPeriodGroupFilters] = React.useState<
    Array<ReportingPeriodGroup>
  >([]);

  const reporting_period_group_filter_ids: Array<string> = reportingPeriodGroupFilters.map(
    (reporting_period_group: ReportingPeriodGroup) => reporting_period_group.id
  );

  const tag_limit = 3;

  const getFilter = (filter_field: string): ExportFilter | undefined => {
    return export_filters.find((filter: ExportFilter) => filter.field === filter_field);
  };

  const filterMaxLatestReportingPeriods = (
    total_reporting_periods: Array<ReportingPeriod>
  ): void => {
    const latest_reporting_periods: Array<ReportingPeriod> = [];
    const reporting_periods_sorted_end_desc: Array<ReportingPeriod> = total_reporting_periods.sort(
      (rp_1, rp_2: ReportingPeriod) => {
        return moment(rp_2.end).diff(moment(rp_1.end));
      }
    );
    for (const reporting_period of reporting_periods_sorted_end_desc) {
      if (getReportingPeriodDays([...latest_reporting_periods, reporting_period]) <= 366) {
        latest_reporting_periods.push(reporting_period);
      } else {
        break;
      }
    }
    handleUpdateFilters(
      'reporting_period',
      latest_reporting_periods.map((reporting_period: ReportingPeriod) => reporting_period.id),
      latest_reporting_periods.length === export_entities.reporting_periods.length
    );
    handleInputValidity('reporting_period', '');
  };

  React.useEffect(() => {
    filterMaxLatestReportingPeriods(export_entities.reporting_periods);
  }, [export_entities, export_type]);

  return (
    <Box sx={{ py: 4, px: 2 }}>
      <Box sx={{ display: 'flex' }}>
        <Typography fontSize="1.3rem" textAlign="left">
          Apply Filters to Export
        </Typography>
        <Tooltip title="Reset Filters">
          <IconButton
            onClick={() => {
              handleClearFilters();
              setReportingPeriodGroupFilters([]);
            }}
            sx={{ ml: 'auto' }}
          >
            <FilterAltOffIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Grid container spacing={4} sx={{ my: 2 }}>
        <Grid item xs={11}>
          <ReportingPeriodGroupSelect
            allow_multi_select
            tag_limit={tag_limit}
            reporting_period_group_options={export_entities.reporting_period_groups}
            selected_reporting_period_group={reportingPeriodGroupFilters}
            handleChangeReportingPeriodGroups={(
              reporting_period_groups: Array<ReportingPeriodGroup> | ReportingPeriodGroup | null
            ) => {
              if (Array.isArray(reporting_period_groups)) {
                setReportingPeriodGroupFilters(reporting_period_groups);
              }
              const reporting_period_group_ids: Array<string> = (
                Array.isArray(reporting_period_groups) ? reporting_period_groups : []
              ).map((reporting_period_group: ReportingPeriodGroup) => reporting_period_group.id);
              const reporting_periods_for_groups: Array<ReportingPeriod> =
                export_entities.reporting_periods.filter((reporting_period: ReportingPeriod) =>
                  reporting_period_group_ids.includes(
                    reporting_period.reporting_period_group?.id ?? ''
                  )
                );
              handleUpdateFilters(
                'reporting_period',
                reporting_periods_for_groups.map(
                  (reporting_period: ReportingPeriod) => reporting_period.id
                ),
                reporting_periods_for_groups.length === 0
              );
              if (reporting_period_group_ids.length === 0) {
                filterMaxLatestReportingPeriods(export_entities.reporting_periods);
              }
              handleInputValidity(
                'reporting_period',
                getReportingPeriodDays(
                  reporting_periods_for_groups.length > 0 ? reporting_periods_for_groups : []
                ) > 366
                  ? 'MAX_DAYS_EXCEEDED'
                  : ''
              );
            }}
          />
        </Grid>
        <Grid item xs={11}>
          <ReportingPeriodSelect
            allow_multi_select
            tag_limit={tag_limit}
            reporting_period_options={
              reporting_period_group_filter_ids.length > 0
                ? export_entities.reporting_periods.filter((reporting_period: ReportingPeriod) =>
                    reporting_period_group_filter_ids.includes(
                      reporting_period.reporting_period_group?.id ?? ''
                    )
                  )
                : export_entities.reporting_periods
            }
            selected_reporting_periods={export_entities.reporting_periods.filter(
              (reporting_period: ReportingPeriod) => {
                const reporting_period_filter: ExportFilter | undefined =
                  getFilter('reporting_period');
                return (reporting_period_filter?.ids ?? []).includes(reporting_period.id);
              }
            )}
            handleChangeReportingPeriods={(
              reporting_periods: ReportingPeriod | Array<ReportingPeriod> | null
            ) => {
              if (Array.isArray(reporting_periods)) {
                handleUpdateFilters(
                  'reporting_period',
                  reporting_periods.map((reporting_period: ReportingPeriod) => reporting_period.id),
                  reporting_periods.length === 0
                );
                handleInputValidity(
                  'reporting_period',
                  getReportingPeriodDays(
                    reporting_periods.length > 0
                      ? reporting_periods
                      : export_entities.reporting_periods
                  ) > 366
                    ? 'MAX_DAYS_EXCEEDED'
                    : ''
                );
              }
            }}
          />
          {input_errors.reporting_period === 'MAX_DAYS_EXCEEDED' && (
            <Box sx={{ mt: 2 }}>
              <AlertBanner
                severity="warning"
                message={'Total Reporting Period date range cannot exceed 366 days'}
                open
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={11}>
          <MetricSelect
            multi_select
            tag_limit={tag_limit}
            options={export_entities.metrics}
            selected_options={export_entities.metrics.filter((metric: MetricExtended) => {
              const metric_filter: ExportFilter | undefined = getFilter('metric');
              return (metric_filter?.ids ?? []).includes(metric.id);
            })}
            group_by="metric_group.name"
            handleChangeMetrics={(metrics: MetricExtended | Array<MetricExtended> | null) => {
              if (Array.isArray(metrics)) {
                handleUpdateFilters(
                  'metric',
                  metrics.map((metric: MetricExtended) => metric.id),
                  metrics.length === 0
                );
              }
            }}
          />
        </Grid>
        <Grid item xs={11}>
          <CountrySelect
            multi_select
            tag_limit={tag_limit}
            options={export_entities.countries}
            selected_options={export_entities.countries.filter((country: Country) => {
              const country_filter: ExportFilter | undefined = getFilter('country');
              return (country_filter?.ids ?? []).includes(country.id);
            })}
            handleChangeCountries={(countries: Country | Array<Country> | null) => {
              if (Array.isArray(countries)) {
                handleUpdateFilters(
                  'country',
                  countries.map((country: Country) => country.id),
                  countries.length === 0,
                  ['region']
                );
              }
            }}
            variant="standard"
          />
        </Grid>
        <Grid item xs={11}>
          <FilterCheckboxDropdown
            label="Regions"
            show_all={false}
            input_items={export_entities.regions.filter((region: Region) => {
              const country_ids: Array<string> = getFilter('country')?.ids ?? [];
              return country_ids.length === 0 || country_ids.includes(region.country.id);
            })}
            selected_items={export_entities.regions.filter((region: Region) => {
              const region_filter: ExportFilter | undefined = getFilter('region');
              return (region_filter?.ids ?? []).includes(region.id);
            })}
            handleSelectionToParent={(region_ids) =>
              handleUpdateFilters('region', region_ids, region_ids.length === 0)
            }
            processSelectedCount={(regions) => (regions.length === 0 ? 'All' : regions.length)}
          />
        </Grid>
        <Grid item xs={11}>
          <FilterCheckboxDropdown
            label="Divisions"
            show_all={false}
            input_items={export_entities.divisions}
            selected_items={export_entities.divisions.filter((division: Division) => {
              const division_filter: ExportFilter | undefined = getFilter('division');
              return (division_filter?.ids ?? []).includes(division.id);
            })}
            handleSelectionToParent={(division_ids) =>
              handleUpdateFilters('division', division_ids, division_ids.length === 0, [
                'subdivision',
                'site'
              ])
            }
            processSelectedCount={(divisions) =>
              divisions.length === 0 ? 'All' : divisions.length
            }
          />
        </Grid>
        <Grid item xs={11}>
          <FilterCheckboxDropdown
            label="Subdivisions"
            show_all={false}
            input_items={export_entities.subdivisions.filter((subdivision: Subdivision) => {
              const division_ids: Array<string> = getFilter('division')?.ids ?? [];
              return division_ids.length === 0 || division_ids.includes(subdivision.division.id);
            })}
            selected_items={export_entities.subdivisions.filter((subdivision: Subdivision) => {
              const subdivision_filter: ExportFilter | undefined = getFilter('subdivision');
              return (subdivision_filter?.ids ?? []).includes(subdivision.id);
            })}
            handleSelectionToParent={(subdivision_ids) =>
              handleUpdateFilters('subdivision', subdivision_ids, subdivision_ids.length === 0, [
                'site'
              ])
            }
            processSelectedCount={(subdivisions) =>
              subdivisions.length === 0 ? 'All' : subdivisions.length
            }
          />
        </Grid>
        <Grid item xs={11}>
          <FilterCheckboxDropdown
            label="Sites"
            show_all={false}
            input_items={export_entities.sites.filter((site: Site) => {
              const division_ids: Array<string> = getFilter('division')?.ids ?? [];
              const subdivision_ids: Array<string> = getFilter('subdivision')?.ids ?? [];
              return (
                (subdivision_ids.length === 0 || subdivision_ids.includes(site.subdivision.id)) &&
                (division_ids.length === 0 ||
                  division_ids.includes(
                    export_entities.subdivisions.find(
                      (subdivision) => subdivision.id === site.subdivision.id
                    )?.division.id ?? '-1'
                  ))
              );
            })}
            selected_items={export_entities.sites.filter((site: Site) => {
              const site_filter: ExportFilter | undefined = getFilter('site');
              return (site_filter?.ids ?? []).includes(site.id);
            })}
            handleSelectionToParent={(site_ids) =>
              handleUpdateFilters('site', site_ids, site_ids.length === 0)
            }
            processSelectedCount={(sites) => (sites.length === 0 ? 'All' : sites.length)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FullMetricRecordFilters;
