import {
  CollectionReference,
  Query,
  QuerySnapshot,
  collection,
  getDocs,
  query
} from 'firebase/firestore';
import { db } from '../google/firebase';
import { Module } from '@esg/esg-global-types';

/**
 * Function to query all module documents from master list
 * @returns {Array<Module>}
 */
export const getModulesMasterList = async () => {
  const modules_collection: CollectionReference = collection(db, 'module_master_list');
  const modules_query: Query = query(modules_collection);
  try {
    const module_docs: QuerySnapshot = await getDocs(modules_query);
    const modules: Array<Module> = module_docs.docs.map((module) => {
      return {
        id: module.id,
        name: module.data().name,
        description: module.data().description,
        views: module.data().views
      };
    });
    return modules;
  } catch (err) {
    const error = `Error while getting Modules from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        modules_collection: modules_collection,
        modules_query: modules_query
      }
    })}`;
    throw new Error(`Error: getModulesMasterList: ${JSON.stringify(error)}.`);
  }
};
