import * as React from 'react';
import Box from '@mui/material/Box';
import { Skeleton } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Company, Group, Standard } from '@esg/esg-global-types';
import Typography from '@mui/material/Typography';
import { getStandards, getStandardsMasterList } from '../../../../lib/app/standard';
import { FeedbackSnackbarContext } from '../../../../context/FeedbackSnackbarContext';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../../util/log';

export default function MultipleSelectChipStandards({
  standardsToParent,
  handleInputChange,
  fetch_from_master_list,
  group,
  company,
  existing_standards
}: {
  standardsToParent?: any;
  handleInputChange?: (input_id: string, value: any) => void;
  fetch_from_master_list: boolean;
  group?: Group | null;
  company?: Company | null;
  existing_standards?: Array<Standard>;
}) {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [selected_standard_list, setStandardSelectedList] = React.useState<Array<string>>([]);
  const [standard_list, setStandardList] = React.useState<Array<Standard>>([]);
  const [loadingStandards, setLoadingStandards] = React.useState<boolean>(false);

  const handleChange = (event: SelectChangeEvent<typeof selected_standard_list>): void => {
    const {
      target: { value }
    } = event;

    const list: Array<string> = typeof value === 'string' ? value.split(',') : value;

    setStandardSelectedList(list);
    standardsToParent && standardsToParent(list);
    handleInputChange && handleInputChange('standards', list);
  };

  React.useEffect(() => {
    try {
      (async () => {
        setLoadingStandards(true);
        const standards =
          !fetch_from_master_list && company && group
            ? await getStandards(group.id, company.id)
            : await getStandardsMasterList().then((master_list_standards: Array<Standard>) => {
                return existing_standards
                  ? master_list_standards.filter((master_list_standard) => {
                      return !existing_standards.some(
                        (standard) => standard.master_list_standard?.id === master_list_standard.id
                      );
                    })
                  : master_list_standards;
              });
        setStandardList(standards);
        setLoadingStandards(false);
      })().catch((err: unknown) => {
        throw new Error(
          err instanceof Error
            ? err.message
            : 'Error: MultipleChipStandards failed on an unknown error while fetching standards.'
        );
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: MultipleChipStandards failed on an unknown error while initialising.',
          {
            fetch_from_master_list: fetch_from_master_list,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch standards. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setLoadingStandards(false);
    }
  }, []);

  return (
    <React.Fragment>
      <InputLabel id="standard-list" sx={{ textAlign: 'left' }}>
        <Typography variant="subtitle1" color="grey" align="left" paddingBottom={'10px'}>
          Standards
        </Typography>
      </InputLabel>
      {loadingStandards ? (
        <Skeleton variant="rounded" height={50} />
      ) : standard_list.length > 0 ? (
        <Select
          labelId="standard-list"
          id="standard-list"
          multiple
          value={selected_standard_list}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Standards" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((standard: any) => (
                <Chip key={standard.id} label={standard.version} />
              ))}
            </Box>
          )}
          fullWidth
        >
          {standard_list.map((standard: any) => (
            <MenuItem key={standard.id} value={standard}>
              {standard.version}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Typography>
          {fetch_from_master_list
            ? `All Standards from Master List have been configured for ${company?.name}`
            : `No Standards found for ${company?.name}, configure them under the Standards tab`}
        </Typography>
      )}
    </React.Fragment>
  );
}
