import {
  Country,
  Division,
  EmissionFactor,
  ExternalCompany,
  Region,
  ReportingPeriodGroup,
  ReportingPeriod,
  Site,
  Subdivision,
  User,
  UserType
} from '@esg/esg-global-types';
import { MetricRecordEntities } from '../@types/shared';
import { getCountries } from '../lib/metric_capture/country';
import { getRegions } from '../lib/metric_capture/region';
import { getReportingPeriods } from '../lib/metric_capture/reporting_period';
import { DocumentReference } from 'firebase/firestore';
import { getEmissionFactors } from '../lib/app/emission_factor';
import { MetricExtended } from '../lib/metric_capture/metric';
import {
  getUserDivisions,
  getUserExternalCompanyList,
  getUserMetrics,
  getUserSites,
  getUserSubdivisions,
  getUserTypes
} from './user_access';
import { getReportingPeriodGroups } from '../lib/metric_capture/reporting_period_group';

export const getMetricRecordEntities = async (
  group_id: string,
  company_id: string,
  user: User
): Promise<MetricRecordEntities> => {
  const [
    countries,
    regions,
    divisions,
    subdivisions,
    sites,
    metrics,
    reporting_period_groups,
    reporting_periods,
    user_types,
    external_companies,
    emission_factors
  ]: [
    Array<Country>,
    Array<Region>,
    Array<Division>,
    Array<Subdivision>,
    Array<Site>,
    Array<MetricExtended>,
    Array<ReportingPeriodGroup>,
    Array<ReportingPeriod>,
    Array<UserType>,
    Array<ExternalCompany>,
    Array<EmissionFactor>
  ] = await Promise.all([
    getCountries(group_id, company_id),
    getRegions(group_id, company_id),
    getUserDivisions(user, group_id, company_id),
    getUserSubdivisions(user, group_id, company_id),
    getUserSites(user, group_id, company_id),
    getUserMetrics(user, group_id, company_id),
    getReportingPeriodGroups(group_id, company_id),
    getReportingPeriods(group_id, company_id),
    getUserTypes(),
    getUserExternalCompanyList(user, group_id, company_id),
    getEmissionFactors(group_id, company_id)
  ]);

  return {
    countries: countries,
    regions: regions,
    divisions: divisions,
    subdivisions: subdivisions,
    sites: sites.map((site: Site) => {
      return { ...site, region: { ...site.region, id: site.region.id } as DocumentReference };
    }),
    reporting_period_groups: reporting_period_groups,
    reporting_periods: reporting_periods.sort((a: ReportingPeriod, b: ReportingPeriod) => {
      return b.start.valueOf() - a.end.valueOf();
    }),
    metrics: metrics,
    user_types: user_types,
    external_companies: external_companies,
    emission_factors: emission_factors
  };
};
