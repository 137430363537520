import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Typography
} from '@mui/material';
import hike_graphic from '../../assets/hike-guide.webp';
import ep_sus_logo from '../../assets/ep-sustainability-horizontal-colour.webp';
import React from 'react';

const SupportContactModal = ({
  open,
  handleCloseModal
}: {
  open: boolean;
  handleCloseModal: () => void;
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '700px'
          }
        }
      }}
    >
      <DialogTitle sx={{ fontSize: '1.6rem', letterSpacing: 1.5 }}>Need Support?</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Typography sx={{ my: 2, fontSize: '1.1rem' }}>
              Contact our EP Platform Support Team:
            </Typography>
            <Link href="mailto:platformsupport@energypartners.co.za">
              platformsupport@energypartners.co.za
            </Link>
            <Box sx={{ mt: 6.5 }}>
              <img src={ep_sus_logo} width={250} />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <img src={hike_graphic} width={350} />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SupportContactModal;
