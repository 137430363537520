import React from 'react';
import { Tooltip, IconButton, Button } from '@mui/material';
import { GridToolbarContainer, GridToolbarQuickFilter, GridToolbarExport } from '@mui/x-data-grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';

export const PanelReportingPeriodsToolbar = ({ handleCreate }: { handleCreate: () => void }) => {
  return (
    <>
      <GridToolbarContainer>
        <GridToolbarQuickFilter style={{ flex: 1 }} />
        <Tooltip title="Export Reporting Periods to device">
          <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        </Tooltip>
        <Tooltip title="Learn more">
          <IconButton
            aria-label="help"
            size="small"
            color="primary"
            sx={{ marginLeft: 'auto' }}
            onClick={() => {
              // Open assistance menu / bot here.
            }}
          >
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Add new Reporting Period">
          <Button variant="contained" size="large" startIcon={<AddIcon />} onClick={handleCreate}>
            Create
          </Button>
        </Tooltip>
      </GridToolbarContainer>
    </>
  );
};
