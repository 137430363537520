import React from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import ContactForm from '../contact/ContactForm';

const GetInTouchSection = () => {
  return (
    <Box>
      <Typography fontWeight={600} fontSize={35}>
        Get In Touch
      </Typography>
      <Box marginY={3}>
        <ContactForm styles={{ colors: { primary: 'white' } }} />
      </Box>
    </Box>
  );
};

export default GetInTouchSection;
