import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, DialogActions, DialogContentText } from '@mui/material';
import CancelIcon from '@mui/icons-material/Replay';
import { LoadingButton } from '@mui/lab';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';

const ImportConfirmModal = ({
  open,
  import_file,
  capture_level,
  label,
  handleCloseImportModal,
  handleImport
}: {
  open: boolean;
  import_file: File | null;
  capture_level: 'group' | 'period';
  label: string;
  handleCloseImportModal: () => void;
  handleImport: () => void;
}) => {
  const [importLoading, setImportLoading] = React.useState(false);
  const [importHeader, setImportHeader] = React.useState<Array<string>>([]);
  const [importRows, setImportRows] = React.useState<number>(0);
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);

  const required_headers = [
    'Division',
    'Subdivision',
    'Site',
    'Metric',
    'Unit',
    'Value',
    'Proportion',
    'Note'
  ];

  capture_level === 'period'
    ? required_headers.unshift('Reporting Period')
    : required_headers.unshift('Reporting Period Group');
  const errors = {
    row_count: importRows <= 10001 ? '' : 'MAX ROW LIMIT EXCEEDED',
    headers: required_headers.every((header) => importHeader.includes(header.toLowerCase()))
      ? ''
      : 'MISSING HEADERS'
  };
  const valid_import = !errors.row_count && !errors.headers;

  const preProcessImportCSV = async () => {
    if (import_file) {
      const file_url = URL.createObjectURL(import_file);
      const response = await fetch(file_url);
      const csv_string = await response.text();
      const rows = csv_string.replace(/\r/g, '').split('\n');
      setImportHeader(rows[0].toLowerCase().split(','));
      setImportRows(rows.length - 1);
    }
  };

  React.useEffect(() => {
    try {
      (async () => {
        await preProcessImportCSV();
      })();
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: ImportConfirmModal failed on an unknown error while initialising.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `An error occurred while pre-processing import. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
    return;
  }, [import_file]);

  const handleImportClick = async () => {
    setImportLoading(true);
    try {
      await handleImport();
      handleCloseImportModal();
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: ImportConfirmModal failed on an unknown error while calling handleImportClick.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to import metric records. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setImportLoading(false);
    }
    return;
  };

  return (
    <Dialog open={open} onClose={handleCloseImportModal}>
      <DialogTitle>Import For {label}?</DialogTitle>
      <DialogContent>
        <DialogContentText id="file_name">{import_file?.name}</DialogContentText>
        <br />
        {errors.row_count && (
          <DialogContentText sx={{ color: '#D32F2F', mb: 2 }}>
            Maximum Import Count of 10,000 Metric Records Exceeded
          </DialogContentText>
        )}
        {errors.headers && (
          <DialogContentText color={'#D32F2F'}>
            {`Import CSV must have the required headers: \n ${capture_level === 'group' ? 'Reporting Period Group' : 'Reporting Period'}, Division, Subdivision, Site, Metric, Unit, Value, Proportion and Note`}
          </DialogContentText>
        )}
        {valid_import && (
          <DialogContentText>{importRows} rows of data will be Imported</DialogContentText>
        )}
        <br />
        <DialogActions sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="warning"
            startIcon={<CancelIcon />}
            onClick={handleCloseImportModal}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            disabled={!valid_import}
            startIcon={<ImportExportIcon />}
            loading={importLoading}
            onClick={async () => {
              await handleImportClick();
            }}
            color="primary"
          >
            Import
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ImportConfirmModal;
