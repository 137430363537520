import React from 'react';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEditSingleSelectCellProps,
  GridRenderCellParams,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  useGridApiContext
} from '@mui/x-data-grid';
import { CaptureContext, MetricRecordEntities } from '../../../../@types/shared';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../../util/log';
import { MetadataError } from '@ep/error-handling';
import { MetricRecordGridQualitativeToolbar } from './MetricRecordGridQualitativeToolbar';
import { FeedbackSnackbarContext } from '../../../../context/FeedbackSnackbarContext';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FolderIcon from '@mui/icons-material/Folder';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { Box, IconButton, InputBase, Tooltip, Typography } from '@mui/material';
import { MetricRecordGridQualitativeNoRows } from './MetricRecordGridQualitativeNoRows';
import { MetricExtended } from '../../../../lib/metric_capture/metric';
import {
  Company,
  File,
  Group,
  MetricGroup,
  MetricRecord,
  ReportingPeriod,
  User
} from '@esg/esg-global-types';
import { GroupContext } from '../../../../context/GroupContext';
import { CompanyContext } from '../../../../context/CompanyContext';
import {
  MetricRecordInputData,
  MetricRecordWithMetricExtended,
  createMetricRecord,
  getMetricRecordsForContext,
  joinMetricRecordsMetrics,
  updateMetricRecord
} from '../../../../lib/metric_capture/metric_record';
import { getFiles } from '../../../../lib/metric_capture/file';
import { DocumentReference } from 'firebase/firestore';
import UploadSupportingDocsModal from '../../supporting_docs/UploadSupportingDocsModal';
import ManageSupportingDocsModal from '../../supporting_docs/ManageSupportingDocsModal';
import CopyQualitativeMetricRecordModal from './CopyQualitativeMetricRecordModal';
import ExpandValueEditModal from './ExpandValueEditModal';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { reportingPeriodFilterByGroup } from '../../../../lib/metric_capture/reporting_period';
import { ViewAccess, getViewAccess } from '../../../../util/user_access';
import { UserContext } from '../../../../context/UserContext';

interface MetricRecordGridQualitativeProps {
  capture_context: CaptureContext;
  capture_entities: MetricRecordEntities;
}

export interface QualitativeMetricRecordGridRow {
  id: string;
  category: MetricGroup;
  metric: MetricExtended;
  status: boolean;
  value: string;
  docs: boolean;
  saved: boolean;
}

/**
 * Custom text input for editing the value of a qualitative Metric Record.
 * @param {GridEditSingleSelectCellProps} props Predefined attributes of an MUI Datagrid Edit Cell element.
 * @returns {JSX.Element}
 */
const CustomValueEditComponent = (props: GridEditSingleSelectCellProps) => {
  const { id, field, value }: { id: GridRowId; field: string; value?: string | number } = props;
  const [openExpand, setOpenExpand] = React.useState<boolean>(false);
  const [valueState, setValueState] = React.useState<string | number | undefined>(value);
  const api_ref: React.MutableRefObject<GridApiCommunity> = useGridApiContext();
  const metric: MetricExtended = api_ref.current.getCellValue(id, 'metric');

  const handleOpenExpandEditModal = (): void => {
    setOpenExpand(true);
  };

  const handleCloseExpandEditModal = (): void => {
    setOpenExpand(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const new_value: string = event.target.value;
    setValueState(new_value);
    api_ref.current.setEditCellValue({ id, field, value: new_value, debounceMs: 200 }, event);
  };

  return (
    <>
      <ExpandValueEditModal
        open={openExpand}
        value={valueState}
        title={metric.name}
        handleCloseModal={handleCloseExpandEditModal}
        handleChange={handleChange}
      />
      <Box sx={{ display: 'flex', width: '100%', alignItems: 'start' }}>
        <InputBase
          multiline
          rows={4}
          value={valueState}
          sx={{ width: '100%', fontSize: '0.9rem', px: 1.5 }}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
            handleChange(event)
          }
        />
        <IconButton onClick={handleOpenExpandEditModal}>
          <OpenInFullIcon sx={{ fontSize: '1rem' }} />
        </IconButton>
      </Box>
    </>
  );
};

/**
 * Datagrid handling full CRUD functionality for qualitative Metric Records
 * @param {CaptureContext} capture_context Object of context fields to use for Metric Record creation and editing
 * @param {MetricRecordEntities} capture_entities List of options to check for Metric Record data
 * @returns {JSX.Element}
 */
const MetricRecordGridQualitative = ({
  capture_context,
  capture_entities
}: MetricRecordGridQualitativeProps) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const user_info: User | null = React.useContext(UserContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);

  const [metricRecordRows, setMetricRecordRows] = React.useState<
    Array<QualitativeMetricRecordGridRow>
  >([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [supportingDocs, setSupportingDocs] = React.useState<Array<File>>([]);
  const [showUploadDocsModal, setShowUploadDocsModal] = React.useState<boolean>(false);
  const [showManageDocsModal, setShowManageDocsModal] = React.useState<boolean>(false);
  const [showCopyMetricRecordModal, setShowCopyMetricRecordModal] = React.useState<boolean>(false);
  const [actionRow, setActionRow] = React.useState<QualitativeMetricRecordGridRow | null>(null);
  const [gridLoading, setGridLoading] = React.useState<boolean>(true);

  // Component variables
  const reporting_period_group_locked: boolean =
    (capture_context.reporting_period_group &&
      reportingPeriodFilterByGroup(
        capture_context.reporting_period_group?.id,
        capture_entities.reporting_periods
      ).every((reporting_period: ReportingPeriod) => {
        return reporting_period.locked === true;
      })) ??
    false;

  const view_access: ViewAccess =
    user_info && group && company
      ? getViewAccess(user_info, group.id, company.id, 'metric_collection.management')
      : null;

  const view_only: boolean = reporting_period_group_locked || view_access === 'read' ? true : false;

  const standard_metrics: Array<MetricExtended> = capture_entities.metrics.filter(
    (metric: MetricExtended) => metric.standard.id === capture_context.standard?.id
  );

  // Handler functions
  const handleEditClick = (row_id: string): void => {
    setRowModesModel({ ...rowModesModel, [row_id]: { mode: GridRowModes.Edit } });
  };

  const handleConfirmClick = (row_id: string): void => {
    setRowModesModel({ ...rowModesModel, [row_id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (row_id: string): void => {
    setRowModesModel({
      ...rowModesModel,
      [row_id]: { mode: GridRowModes.View, ignoreModifications: true }
    });
  };

  const handleAddSupportingDoc = (row_id: string, supporting_doc: File): void => {
    setSupportingDocs([...supportingDocs, supporting_doc]);
    const new_rows = metricRecordRows.map((row: QualitativeMetricRecordGridRow) =>
      row_id === row.id ? { ...row, docs: true } : row
    );
    setMetricRecordRows(new_rows);
  };

  const handleRemoveSupportingDoc = (row_id: string, delete_doc_id: string): void => {
    const metric_record_docs: Array<File> = supportingDocs.filter(
      (doc: File) => doc.metric_record.id === row_id && doc.id !== delete_doc_id
    );
    if (metric_record_docs.length === 0) {
      const new_rows: Array<QualitativeMetricRecordGridRow> = metricRecordRows.map(
        (row: QualitativeMetricRecordGridRow) => (row_id === row.id ? { ...row, docs: false } : row)
      );
      setMetricRecordRows(new_rows);
    }
    setSupportingDocs(supportingDocs.filter((doc) => doc.id !== delete_doc_id));
  };

  const handleOpenUploadDocsModal = (action_row: QualitativeMetricRecordGridRow): void => {
    setActionRow(action_row);
    setShowUploadDocsModal(true);
  };

  const handleOpenManageDocsModal = (action_row: QualitativeMetricRecordGridRow): void => {
    setActionRow(action_row);
    setShowManageDocsModal(true);
  };

  const handleCloseUploadDocsModal = (): void => {
    setShowUploadDocsModal(false);
  };

  const handleCloseManageDocsModal = (): void => {
    setShowManageDocsModal(false);
  };

  const handleOpenCopyMetricRecordModal = (action_row: QualitativeMetricRecordGridRow): void => {
    setActionRow(action_row);
    setShowCopyMetricRecordModal(true);
  };

  const handleCloseCopyMetricRecordModal = (): void => {
    setShowCopyMetricRecordModal(false);
  };

  // Row functions
  const fetchRows = async (): Promise<void> => {
    try {
      if (group && company) {
        const metric_records: Array<MetricRecord> = await getMetricRecordsForContext(
          group.id,
          company.id,
          capture_context
        );
        const supporting_docs: Array<File> = await getFiles(group.id, company.id);
        const metric_records_with_metrics: Array<MetricRecordWithMetricExtended> =
          joinMetricRecordsMetrics(metric_records, standard_metrics);
        const metric_record_rows: Array<QualitativeMetricRecordGridRow> =
          metric_records_with_metrics.map((metric_record: MetricRecordWithMetricExtended) => {
            return {
              id: metric_record.id,
              category: metric_record.metric.metric_group,
              metric: metric_record.metric,
              status: metric_record.status ?? false,
              value: metric_record.value.toString(),
              docs:
                supporting_docs.filter((file: File) => {
                  return file.metric_record.id === metric_record.id;
                }).length > 0,
              saved: true
            };
          });
        setSupportingDocs(supporting_docs);
        standard_metrics.forEach((metric: MetricExtended) => {
          const existing_row: QualitativeMetricRecordGridRow | undefined = metric_record_rows.find(
            (row: QualitativeMetricRecordGridRow) => row.metric.id === metric.id
          );
          if (!existing_row) {
            metric_record_rows.unshift({
              id: uuidv4(),
              category: metric.metric_group,
              metric: metric,
              status: false,
              value: '',
              docs: false,
              saved: false
            });
          }
        });
        setMetricRecordRows(metric_record_rows);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: MetricRecordGridQuantitative failed on an unknown error while calling fetchMetricRecordsForGrid.',
          {
            group: group,
            company: company,
            capture_context: capture_context
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch metric records. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setGridLoading(false);
    }
  };

  const validateMetricRecord = (metric_record: QualitativeMetricRecordGridRow): boolean => {
    return (
      !!metric_record.category &&
      !!metric_record.metric &&
      (metric_record.status === false ||
        (metric_record.status === true && metric_record.value !== ''))
    );
  };

  const updateRow = async (
    old_metric_record: QualitativeMetricRecordGridRow,
    new_metric_record: QualitativeMetricRecordGridRow
  ): Promise<QualitativeMetricRecordGridRow | undefined> => {
    try {
      setGridLoading(true);
      const valid_input: boolean = validateMetricRecord(new_metric_record);
      if (!valid_input) {
        setFeedbackData({
          message: `Details must be included for TRUE Status`,
          state: true,
          type: 'warning'
        });
        return old_metric_record;
      }
      if (
        new_metric_record.status === old_metric_record.status &&
        new_metric_record.value === old_metric_record.value
      ) {
        return old_metric_record;
      }
      if (group && company) {
        if (new_metric_record.saved) {
          const original_metric_record_data: MetricRecordInputData = {
            value: old_metric_record.value,
            status: old_metric_record.status
          };
          const updated_metric_record_data: MetricRecordInputData = {
            value: new_metric_record.value,
            status: new_metric_record.status
          };
          await updateMetricRecord(
            group.id,
            company.id,
            old_metric_record.id,
            original_metric_record_data,
            updated_metric_record_data
          );
          setMetricRecordRows([
            ...metricRecordRows.map((metric_record) => {
              return metric_record.id === new_metric_record.id ? new_metric_record : metric_record;
            })
          ]);
        } else {
          const created_metric_record: DocumentReference | undefined = await createMetricRecord(
            group.id,
            company.id,
            capture_context,
            new_metric_record.metric,
            new_metric_record.value,
            { status: new_metric_record.status }
          );
          if (created_metric_record) {
            setMetricRecordRows([
              ...metricRecordRows.map((metric_record) => {
                return metric_record.id === new_metric_record.id
                  ? { ...new_metric_record, id: created_metric_record.id, saved: true }
                  : metric_record;
              })
            ]);
          }
        }
        return new_metric_record;
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: MetricRecordGridQuantitative failed on an unknown error while calling handleEditMetricRecord.',
          {
            old_metric_record: old_metric_record,
            new_metric_record: new_metric_record
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update metric record. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setGridLoading(false);
    }
  };

  React.useEffect(() => {
    try {
      fetchRows();
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: MetricRecordGridQualitative failed on an unknown error while initialising.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch metric records. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'category',
      headerName: 'Category',
      renderCell: (params: GridRenderCellParams) => {
        return params.row.category.name;
      },
      getApplyQuickFilterFn: (value: string) => {
        return (params) => {
          return params.value.name.includes(value);
        };
      },
      flex: 1.2
    },
    {
      field: 'metric',
      headerName: 'Metric',
      renderCell: (params: GridRenderCellParams) => {
        return params.row.metric.name;
      },
      getApplyQuickFilterFn: (value: string) => {
        return (params) => {
          return params.value.name.includes(value);
        };
      },
      flex: 1.2
    },
    {
      field: 'status',
      headerName: 'Yes/No',
      headerAlign: 'center',
      align: 'center',
      type: 'boolean',
      flex: 0.5,
      editable: true
    },
    {
      field: 'value',
      headerName: 'Details',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ py: 2, maxHeight: '100px' }}>
          <Typography
            sx={{
              fontSize: '0.9rem',
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              maxHeight: '90px',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
      renderEditCell: (params) => <CustomValueEditComponent {...params} />,
      flex: 1.25,
      editable: true
    }
  ];
  if (!view_only) {
    columns.push({
      field: 'actions',
      type: 'actions',
      headerName: '',
      align: 'right',
      flex: 0.75,
      getActions: ({ row }) => {
        const edit_row: boolean = rowModesModel[row.id]?.mode === GridRowModes.Edit;
        if (edit_row) {
          return [
            <Tooltip title="Save Record" key={1}>
              <GridActionsCellItem
                icon={<CheckIcon sx={{ fontSize: '1.5rem' }} />}
                label="Confirm"
                sx={{
                  color: '#053d5d'
                }}
                onClick={() => handleConfirmClick(row.id)}
              />
            </Tooltip>,
            <Tooltip title="Discard Changes" key={2}>
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                color="inherit"
                onClick={() => {
                  handleCancelClick(row.id);
                }}
              />
            </Tooltip>
          ];
        }
        return [
          <>
            {row.docs && (
              <Tooltip title="Manage Supporting Documents" key={1}>
                <GridActionsCellItem
                  icon={<FolderIcon sx={{ fontSize: '1.5rem' }} />}
                  label="Uploaded Documents"
                  onClick={() => handleOpenManageDocsModal(row)}
                  sx={{
                    color: '#053d5d'
                  }}
                />
              </Tooltip>
            )}
            {row.saved && (
              <Tooltip title="Upload Supporting Document" key={2}>
                <GridActionsCellItem
                  icon={<AttachFileIcon sx={{ fontSize: '1.5rem' }} />}
                  label="Supporting Documents"
                  sx={{
                    color: '#053d5d'
                  }}
                  onClick={() => handleOpenUploadDocsModal(row)}
                />
              </Tooltip>
            )}
            <Tooltip title="Edit Record" key={3}>
              <GridActionsCellItem
                icon={<EditIcon sx={{ fontSize: '1.5rem' }} />}
                label="Edit"
                sx={{
                  color: 'primary.main'
                }}
                onClick={() => handleEditClick(row.id)}
              />
            </Tooltip>
            <Tooltip title="Copy from Reporting Period Group" key={4}>
              <GridActionsCellItem
                icon={<ContentCopyIcon sx={{ fontSize: '1.5rem' }} />}
                label="Copy"
                sx={{
                  color: 'primary.main'
                }}
                onClick={() => handleOpenCopyMetricRecordModal(row)}
              />
            </Tooltip>
          </>
        ];
      }
    });
  }

  return (
    <>
      <CopyQualitativeMetricRecordModal
        open={showCopyMetricRecordModal}
        capture_entities={capture_entities}
        metric_record={actionRow}
        capture_context={capture_context}
        handleUpdateRow={async (
          old_metric_record: QualitativeMetricRecordGridRow,
          new_metric_record: QualitativeMetricRecordGridRow
        ) => await updateRow(old_metric_record, new_metric_record)}
        handleCloseCopyModal={handleCloseCopyMetricRecordModal}
      />
      <UploadSupportingDocsModal
        open={showUploadDocsModal}
        handleCloseDocsModal={handleCloseUploadDocsModal}
        metric_record={actionRow}
        metric_record_title={actionRow?.metric.name ?? 'Metric Record'}
        handleAddSupportingDoc={handleAddSupportingDoc}
      />
      <ManageSupportingDocsModal
        open={showManageDocsModal}
        handleCloseDocsModal={handleCloseManageDocsModal}
        handleRemoveSupportingDoc={handleRemoveSupportingDoc}
        capture_context={capture_context}
        metric_record={actionRow}
        metric_record_title={actionRow?.metric.name ?? 'Metric Record'}
        supporting_docs={supportingDocs.filter((supporting_doc: File) => {
          return supporting_doc.metric_record.id === actionRow?.id;
        })}
      />
      <DataGrid
        autoHeight
        rowHeight={100}
        loading={gridLoading}
        rows={metricRecordRows}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          }
        }}
        onProcessRowUpdateError={(err: unknown) => {
          const tracking_id: string = uuidv4();
          log(
            'error',
            new MetadataError(
              err instanceof Error
                ? err.message
                : 'Error: MetricRecordGridQuantitative: DataGrid failed on an unknown error.',
              null,
              tracking_id
            )
          );
          setFeedbackData({
            message: `Unable to update row. Tracking ID: ${tracking_id}`,
            state: true,
            type: 'error'
          });
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        columns={columns}
        editMode="row"
        slots={{
          toolbar: MetricRecordGridQualitativeToolbar,
          noRowsOverlay: MetricRecordGridQualitativeNoRows
        }}
        slotProps={{
          toolbar: {
            metric_record_rows: metricRecordRows
          }
        }}
        rowModesModel={rowModesModel}
        disableRowSelectionOnClick
        processRowUpdate={(updatedRow, originalRow) => {
          try {
            return updateRow(originalRow, updatedRow);
          } catch (err: unknown) {
            const tracking_id: string = uuidv4();
            log(
              'error',
              new MetadataError(
                err instanceof Error
                  ? err.message
                  : 'Error: MetricRecordGridQualititative failed on an unknown error while calling editRow.',
                {
                  originalRow: originalRow,
                  updatedRow: updatedRow
                },
                tracking_id
              )
            );
            setFeedbackData({
              message: `Unable to process row update. Tracking ID: ${tracking_id}`,
              state: true,
              type: 'error'
            });
          }
        }}
        sx={{
          '& .MuiDataGrid-row': {
            boxSizing: 'border-box'
          },
          '&, [class^=MuiDataGrid]': { border: 'none' }
        }}
      />
    </>
  );
};

export default MetricRecordGridQualitative;
