import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Sector } from '@esg/esg-global-types';
import Typography from '@mui/material/Typography';
import { getSectorMasterList } from '../../../../lib/app/sector';

// TODO: Upate types at source.
export default function MultipleSelectChipSectors({ sectorsToParent }: { sectorsToParent: any }) {
  const [selected_sector_list, setSectorList] = React.useState<Array<string>>([]);
  const [sector_master_list, setSectorMasterList] = React.useState<Array<Sector>>([]);

  const handleChange = (event: SelectChangeEvent<typeof selected_sector_list>): void => {
    const {
      target: { value }
    } = event;

    const list: Array<string> = typeof value === 'string' ? value.split(',') : value;

    setSectorList(list);
    sectorsToParent(list);
  };

  React.useEffect(() => {
    (async () => {
      const sectors: Array<Sector> = await getSectorMasterList();
      setSectorMasterList(sectors);
    })();
  }, []);
  return (
    <React.Fragment>
      <InputLabel id="sector-list" sx={{ textAlign: 'left' }}>
        <Typography variant="subtitle1" color="grey" align="left" paddingBottom={'10px'}>
          Sectors
        </Typography>
      </InputLabel>
      <Select
        labelId="sector-list"
        id="sector-list"
        multiple
        value={selected_sector_list}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Sectors" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((sector: any) => {
              return <Chip key={sector.id} label={sector.name} />;
            })}
          </Box>
        )}
        fullWidth
      >
        {sector_master_list.map((sector: any) => (
          <MenuItem key={sector.id} value={sector}>
            {sector.name}
          </MenuItem>
        ))}
      </Select>
    </React.Fragment>
  );
}
