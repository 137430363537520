import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Country, Region } from '@esg/esg-global-types';
import { Typography } from '@mui/material';

interface RegionSelectorProps {
  handleNewRegions?: (new_regions: Array<string>) => void;
  regions_value: Array<string>;
  region_options: Array<Region>;
  country?: Country;
}

export default function RegionSelector(props: RegionSelectorProps) {
  return (
    <>
      {props.region_options?.length > 0 ? (
        <Autocomplete
          multiple
          limitTags={10}
          fullWidth
          id="multiple-region-tags"
          disableCloseOnSelect={true}
          value={props.regions_value}
          clearOnBlur={true}
          options={props.region_options?.map((region) => JSON.stringify(region)) || []}
          getOptionLabel={(option: string) => JSON.parse(option).name}
          defaultValue={[]}
          renderInput={(params) => (
            <TextField {...params} label="Regions" placeholder="Add region" />
          )}
          onChange={(event, value) => {
            value && props.handleNewRegions && props.handleNewRegions(value);
          }}
          isOptionEqualToValue={(option, value) => {
            return JSON.parse(option).id === JSON.parse(value).id;
          }}
        />
      ) : (
        <Typography>{`No unconfigured Regions found for ${props.country?.name}`}</Typography>
      )}
    </>
  );
}
