import {
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  Query,
  QuerySnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore';
import { db } from '../google/firebase';
import { ExternalCompany } from '@esg/esg-global-types';
import moment from 'moment';
import { createAuditLog } from './audit';

/**
 * Function to query all External Company docs for a Company in firestore.
 * @param {string} group_id id of Group to query External Companies for
 * @param {string} company_id id of Company to query External Companies for
 * @returns {Array<ExternalCompany>}
 */
export const getExternalCompanies = async (group_id: string, company_id: string) => {
  const external_companies_collection: CollectionReference = collection(
    doc(db, `/groups/${group_id}/companies`, company_id),
    'external_companies'
  );
  const external_companies_query: Query = query(
    external_companies_collection,
    where('deleted', '==', null)
  );
  try {
    const external_companies_docs: QuerySnapshot = await getDocs(external_companies_query);
    const external_companies: Array<ExternalCompany> = external_companies_docs.docs.map(
      (external_company) => {
        return {
          id: external_company.id,
          deleted: external_company.data().deleted,
          name: external_company.data().name,
          active: external_company.data().active,
          modified: external_company.data().modified?.toDate() ?? null,
          created: external_company.data().created.toDate()
        };
      }
    );
    return external_companies;
  } catch (err) {
    const error = `Error while getting External Companies from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        external_companies_collection: external_companies_collection,
        external_companies_query: external_companies_query
      }
    })}`;
    throw new Error(`Error: getExternalCompanies: ${JSON.stringify(error)}.`);
  }
};

/**
 * Create new External Company database record.
 * @param {string} group_id ID of Group to delete external company for
 * @param {string} company_id ID of Company to delete emission factor for
 * @param {any} external_company Object to be created
 * @returns {Promise<DocumentReference>}
 */
export const createExternalCompany = async (
  group_id: string,
  company_id: string,
  external_company: {
    name: string;
  }
): Promise<DocumentReference> => {
  try {
    const external_company_doc = doc(
      collection(db, `/groups/${group_id}/companies/${company_id}/external_companies`)
    );
    await setDoc(external_company_doc, {
      name: external_company.name,
      active: true,
      created: moment().toDate(),
      modified: moment().toDate(),
      deleted: null
    });
    createAuditLog(group_id, 'create', '', external_company.name, external_company_doc);
    return external_company_doc;
  } catch (err) {
    const error = `Error while creating External Company on Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: createExternalCompany: ${JSON.stringify(error)}.`);
  }
};

/**
 * Soft delete External Company.
 * @param {string} group_id ID of Group to delete external company for
 * @param {string} company_id ID of Company to delete emission factor for
 * @param {ExternalCompany} external_company Object to be deleted
 * @returns {Promise<void>}
 */
export const deleteExternalCompany = async (
  group_id: string,
  company_id: string,
  external_company: ExternalCompany
): Promise<void> => {
  try {
    const external_company_doc = doc(
      collection(db, `/groups/${group_id}/companies/${company_id}/external_companies`),
      external_company.id
    );
    await updateDoc(external_company_doc, {
      deleted: moment().toDate(),
      active: false
    });
    await createAuditLog(group_id, 'delete', external_company.name, '', external_company_doc);
  } catch (err) {
    const error = `Error while deleting Emission Factor from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: deleteEmissionFactor: ${JSON.stringify(error)}.`);
  }
};

/**
 * Update existing External Company database record.
 * @param {string} group_id ID of Group to delete external company for
 * @param {string} company_id ID of Company to delete emission factor for
 * @param {ExternalCompany} external_company Object to be updated.
 * @returns {Promise<void>}
 */
export const updateExternalCompany = async (
  group_id: string,
  company_id: string,
  external_company: ExternalCompany,
  original_company_data: ExternalCompany
): Promise<DocumentReference> => {
  try {
    const external_company_doc = doc(
      collection(db, `/groups/${group_id}/companies/${company_id}/external_companies`),
      external_company.id
    );
    await updateDoc(external_company_doc, {
      name: external_company.name,
      created: moment(external_company.created).toDate(),
      modified: moment().toDate(),
      deleted: null
    });
    createAuditLog(
      group_id,
      'update',
      original_company_data.name,
      external_company.name,
      external_company_doc
    );
    return external_company_doc;
  } catch (err) {
    const error = `Error while updating External Company from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: updateExternalCompany: ${JSON.stringify(error)}.`);
  }
};

/**
 * Get a database object reference for an External Company.
 * @param {string} group_id ID of Group to delete external company for
 * @param {string} company_id ID of Company to delete emission factor for
 * @param {ExternalCompany} external_company Object for which a reference should be located
 * @returns {Promise<void>}
 */
export const getExternalCompanyRef = async (
  group_id: string,
  company_id: string,
  external_company: ExternalCompany
): Promise<DocumentReference> => {
  try {
    const external_company_doc = await doc(
      collection(db, `/groups/${group_id}/companies/${company_id}/external_companies`),
      external_company.id
    );
    return external_company_doc;
  } catch (err) {
    const error = `Error while getting External Company reference from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: getExternalCompanyRef: ${JSON.stringify(error)}.`);
  }
};

/**
 * Get a database object reference for an External Company by provided ID.
 * @param {string} group_id ID of Group to delete external company for
 * @param {string} company_id ID of Company to delete emission factor for
 * @param {string} external_company_id ID for which an External Company reference should be located
 * @returns {Promise<void>}
 */
export const getExternalCompanyById = async (
  group_id: string,
  company_id: string,
  external_company_id: string
): Promise<DocumentSnapshot> => {
  try {
    const external_company_doc = doc(
      db,
      `/groups/${group_id}/companies/${company_id}/external_companies`,
      external_company_id
    );
    const external_company = await getDoc(external_company_doc);
    return external_company;
  } catch (err) {
    const error = `Error while getting External Company reference from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: getExternalCompanyRef: ${JSON.stringify(error)}.`);
  }
};
