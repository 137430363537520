export const NODE_ENV: string = process.env.REACT_APP_NODE_ENV as string;
export const GCP_PROJECT: string = process.env.REACT_APP_GCP_PROJECT as string;
export const GCP_REGION: string = process.env.REACT_APP_GCP_REGION as string;
export const GCP_TASKS_QUEUE: string = process.env.REACT_APP_GCP_TASKS_QUEUE as string;
export const GCP_FILE_UPLOAD_BUCKET: string = process.env
  .REACT_APP_GCP_FILE_UPLOAD_BUCKET as string;
export const API_ENDPOINT: string = process.env.REACT_APP_API_ENDPOINT as string;
export const CLOUD_FUNCTION_URL: string = process.env.REACT_APP_CLOUD_FUNCTION_URL as string;

export const METRIC_BI_ID: string = process.env.REACT_APP_METRIC_BI_ID as string;
export const METRIC_DATASET_ID: string = process.env.REACT_APP_METRIC_DATASET_ID as string;
export const METRIC_BI_EMBED_URL: string = process.env.REACT_APP_METRIC_BI_EMBED_URL as string;

export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY as string,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID as string,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET as string,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID as string,
  appId: process.env.REACT_APP_FIREBASE_APP_ID as string
};

export const CLOUD_LOGGER_ENDPOINT: string = process.env.REACT_APP_CLOUD_LOGGER_ENDPOINT as string;
