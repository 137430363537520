import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, DialogActions, DialogContentText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import { LoadingButton } from '@mui/lab';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';

const DeleteConfirmModal = ({
  open,
  delete_label,
  delete_warning,
  allow_delete,
  allow_archive,
  alternate_message_flag,
  alternate_message,
  item_type,
  archive_warning,
  handleCloseDeleteModal,
  handleDelete,
  handleArchive
}: {
  open: boolean;
  delete_label?: string | null;
  delete_warning?: string;
  allow_delete: boolean | null;
  allow_archive: boolean | null;
  alternate_message_flag?: boolean | null;
  alternate_message?: string | null;
  item_type?: string;
  archive_warning?: string;
  handleCloseDeleteModal: () => void;
  handleDelete: (() => void) | null;
  handleArchive?: (() => void) | null;
}) => {
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [archiveLoading, setArchiveLoading] = React.useState(false);
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);

  const handleDeleteClick = async () => {
    setDeleteLoading(true);
    try {
      if (handleDelete) {
        await handleDelete();
      }
      handleCloseDeleteModal();
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: DeleteConfirmModal failed on an unknown error while calling handleArchiveClick.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to delete entity. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setDeleteLoading(false);
    }
    return;
  };
  const handleArchiveClick = async () => {
    setArchiveLoading(true);
    try {
      if (handleArchive) {
        await handleArchive();
      }
      handleCloseDeleteModal();
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: DeleteConfirmModal failed on an unknown error while calling handleArchiveClick.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to archive entity. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setArchiveLoading(false);
    }
    return;
  };

  return (
    <Dialog open={open} onClose={handleCloseDeleteModal}>
      {allow_delete ? (
        <DialogTitle
          textAlign={'justify'}
        >{`Are you sure you want to delete ${delete_label}?`}</DialogTitle>
      ) : (
        <DialogTitle>{`Cannot delete ${delete_label}`}</DialogTitle>
      )}
      <DialogContent>
        {archive_warning && allow_archive && (
          <DialogContentText id="archive-explainer">{archive_warning}</DialogContentText>
        )}
        {handleArchive && !allow_archive && (
          <DialogContentText id="archive-explainer">
            {delete_label} is already Archived
          </DialogContentText>
        )}
        {delete_warning && allow_delete && (
          <>
            <br />
            <DialogContentText id="delete-explainer" textAlign={'justify'}>
              {alternate_message_flag ? alternate_message : delete_warning}
            </DialogContentText>
            <br />
          </>
        )}
        {allow_delete === false && (
          <>
            <br />
            <DialogContentText id="delete-explainer">
              Metric Records found that reference {delete_label}. You must first delete all related
              Metric Records before deleting this {item_type ?? 'item'}.
            </DialogContentText>
          </>
        )}
        <DialogActions sx={{ mt: 2 }}>
          <Button variant="text" size="medium" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          {handleArchive && (
            <LoadingButton
              loading={archiveLoading}
              startIcon={<ArchiveIcon />}
              disabled={deleteLoading || !allow_archive}
              onClick={async () => {
                await handleArchiveClick();
              }}
              variant="contained"
              sx={{ backgroundColor: '#1b97c1' }}
              autoFocus
            >
              Archive
            </LoadingButton>
          )}
          <LoadingButton
            variant="contained"
            size="medium"
            startIcon={<DeleteIcon />}
            disabled={!allow_delete || archiveLoading}
            loading={deleteLoading || allow_delete === null}
            onClick={async () => {
              await handleDeleteClick();
            }}
            color="error"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConfirmModal;
