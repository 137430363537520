import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { FeedbackSnackbarContext } from '../../context/FeedbackSnackbarContext';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function FeedbackSnackbar() {
  const { feedbackData, setFeedbackData } = React.useContext(FeedbackSnackbarContext);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setFeedbackData({ state: false, message: feedbackData.message, type: feedbackData.type });
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={feedbackData.state} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={feedbackData.type} sx={{ width: '100%' }}>
          {feedbackData.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
