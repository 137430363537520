import { User } from '@esg/esg-global-types';
import React from 'react';
import { UserContext } from '../../context/UserContext';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { getAdminGroups } from '../../util/user_access';
import AccessDenied from '../shared/AccessDenied';
import AuditLogsGrid from '../../components/audit_logs/AuditLogsGrid';

const AuditLogs = () => {
  const user_info: User | null = React.useContext(UserContext);
  return user_info === null ? (
    <LoadingScreen />
  ) : user_info.super_admin || getAdminGroups(user_info).length > 0 ? (
    <AuditLogsGrid></AuditLogsGrid>
  ) : (
    <AccessDenied />
  );
};

export default AuditLogs;
