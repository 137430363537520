import {
  DocumentReference,
  addDoc,
  collection,
  CollectionReference,
  doc,
  QuerySnapshot,
  QueryDocumentSnapshot
} from 'firebase/firestore';
import { db, auth } from '../google/firebase';
import { readFirestoreDocs } from './db_util';
import { AuditLog } from '@esg/esg-global-types';
import { uuidv4 } from '@firebase/util';
import { log } from '../../util/log';
import { MetadataError } from '@ep/error-handling';
import moment from 'moment';
import { AuditLogValue } from '../../@types/shared';

/**
 * Function to query all audit logs for a given group
 * @param {Group} group Group to query audit logs for
 * @returns {Array<AuditLog>}
 */
export const getAuditLogs = async (group_id: string) => {
  const collection_path = `groups/${group_id}/audit_logs`;
  try {
    const audit_logs_snapshot: QuerySnapshot = await readFirestoreDocs(collection_path, [
      { field_name: 'created', operator: '>=', value: moment().subtract(2, 'months').toDate() }
    ]);
    const audit_logs: Array<AuditLog> = audit_logs_snapshot.docs.map(
      (audit_log: QueryDocumentSnapshot) => {
        return {
          id: audit_log.id,
          created: audit_log.data().created.toDate(),
          operation: audit_log.data().operation,
          previous_value: audit_log.data().previous_value,
          next_value: audit_log.data().next_value,
          user: audit_log.data().user,
          reference: audit_log.data().reference
        };
      }
    );
    return audit_logs;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    log(
      'error',
      new MetadataError(
        err instanceof Error
          ? err.message
          : 'Error: lib/app/audit.ts failed on an unknown error while calling getAuditLogs.',
        {
          group_id: group_id
        },
        tracking_id
      )
    );
    throw new Error(`Error: getAuditLogs: ${JSON.stringify(err)}.`);
  }
};

/**
 * Create an audit log record on Firebase for any database record modification.
 * @param {string} group Group ID of relative firebase document
 * @param {string} operation Type of database transaction.
 * @param {string} previous_value Value before it was changed.
 * @returns {ReturnType<typeof addDoc>} A reference to the created Firebase document.
 */
export const createAuditLog = async (
  group_id: string,
  operation: 'update' | 'delete' | 'archive' | 'create',
  previous_value: string | AuditLogValue,
  next_value: string | AuditLogValue,
  reference_doc: DocumentReference
): Promise<DocumentReference> => {
  const data_record: {
    created: Date;
    operation: 'insert' | 'update' | 'delete' | 'archive' | 'create';
    previous_value: string | AuditLogValue;
    next_value: string | AuditLogValue;
    user: string;
    reference: DocumentReference;
  } = {
    created: new Date(),
    operation: operation,
    previous_value: previous_value,
    next_value: next_value,
    user: auth.currentUser?.email || '',
    reference: reference_doc
  };

  try {
    // TODO: Get company name dynamically from config.
    const doc_ref: DocumentReference = await addDoc(
      collection(db, `groups/${group_id}/audit_logs`),
      data_record
    );
    return doc_ref;
  } catch (err) {
    const error = `Error while creating an audit record on Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        data_record: data_record
      }
    })}`;
    throw new Error(`Error: createAuditLog: ${JSON.stringify(error)}.`);
  }
};

export const generateAuditLogDoc = (group_id: string) => {
  const group_doc: DocumentReference = doc(db, 'groups', group_id);
  const audit_logs_collection: CollectionReference = collection(group_doc, `/audit_logs`);
  const audit_logs_doc: DocumentReference = doc(audit_logs_collection);
  return audit_logs_doc;
};

export const generateAuditLogData = (
  next_value: string | AuditLogValue,
  operation: string,
  previous_value: string | undefined | AuditLogValue,
  reference: DocumentReference,
  user_email: string | undefined | null
) => {
  return {
    created: new Date(),
    next_value: next_value,
    operation: operation,
    previous_value: previous_value,
    reference: reference,
    user: user_email
  };
};

// TODO: INDEX BY created AND operation.
