import {
  collection,
  getDocs,
  updateDoc,
  query,
  CollectionReference,
  Query,
  QuerySnapshot,
  doc,
  where,
  addDoc
} from 'firebase/firestore';
import { refCompanyDoc } from '../app/company';
import { File as DBFile, File } from '@esg/esg-global-types';
import { createAuditLog } from '../app/audit';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { API_ENDPOINT, GCP_FILE_UPLOAD_BUCKET } from '../../env';
import { uuidv4 } from '@firebase/util';
import { log } from '../../util/log';
import { MetadataError } from '@ep/error-handling';

/**
 * Function to query all file documents for current portal company
 * @param {Group} group Group object of current portal company
 * @param {string} company_id id of current portal company to reference firestore documents
 * @returns {Array<File>}
 */
export const getFiles = async (group_id: string, company_id: string, metric_record_id?: string) => {
  const files_collection: CollectionReference = collection(
    refCompanyDoc(group_id, company_id),
    'files'
  );
  let metric_record_fk = null;
  if (metric_record_id) {
    metric_record_fk = doc(
      collection(refCompanyDoc(group_id, company_id), 'metric_records'),
      metric_record_id
    );
  }
  const files_query: Query = metric_record_fk
    ? query(
        files_collection,
        where('metric_record', '==', metric_record_fk),
        where('deleted', '==', null)
      )
    : query(files_collection, where('deleted', '==', null));
  try {
    const files_docs: QuerySnapshot = await getDocs(files_query);
    const files: Array<DBFile> = files_docs.docs.map((file) => {
      return {
        id: file.id,
        deleted: file.data().deleted,
        name: file.data().name,
        type: file.data().type,
        uploaded: file.data().uploaded,
        metric_record: file.data().metric_record,
        file_path: file.data().file_path
      };
    });
    return files;
  } catch (err) {
    const error = `Error while getting Files from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        files_collection: files_collection,
        files_query: files_query
      }
    })}`;
    throw new Error(`Error: getFiles: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to upload a file to Cloud Storage and create the metadata document in firestore on successful upload
 * @param {string} group_id ID of Group to create File for
 * @param {string} company_id ID of Company to create File for
 * @param {File} file file that was uploaded by the user
 * @param {string} path path of file from root of cloud storage bucket
 * @param {string} metric_record_id id of metric record relating to file to be used for reference document
 * @returns {DBFile}
 */
export const createFile = async (
  group_id: string,
  company_id: string,
  file_name: string,
  metric_record_id: string,
  file_path: string
) => {
  const company_doc = refCompanyDoc(group_id, company_id);
  const files_collection: CollectionReference = collection(company_doc, 'files');
  const file_data = {
    deleted: null,
    file_path: file_path,
    metric_record: doc(collection(company_doc, 'metric_records'), metric_record_id),
    name: file_name,
    type: `.${file_name.split('.')[1].toLowerCase()}`,
    uploaded: new Date()
  };
  try {
    const new_file = await addDoc(files_collection, file_data);
    await createAuditLog(group_id, 'create', '', file_name, new_file);
    return { id: new_file.id, ...file_data };
  } catch (err) {
    const error = `Error while creating file in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        files_collection: files_collection,
        file_data: file_data
      }
    })}`;
    throw new Error(`Error: createFile: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to soft delete relative file meta record on firestore
 * @param {string} group_id ID of Group to Delete file for
 * @param {string} company_id ID of Company to Delete file for
 * @param {string} file_id file to delete
 * @returns {void}
 */
export const deleteFile = async (group_id: string, company_id: string, file: DBFile) => {
  const file_doc = doc(collection(refCompanyDoc(group_id, company_id), 'files'), file.id);
  try {
    await updateDoc(file_doc, {
      deleted: new Date()
    });
    await createAuditLog(group_id, 'delete', file.name, '', file_doc);
    return;
  } catch (err) {
    const error = `Error while deleting file in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        file_doc: file_doc
      }
    })}`;
    throw new Error(`Error: deleteFile: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to return duplicate file meta data of an uploaded file name prioritising metric record level duplication
 * @param {string} group_id ID of Group containing files collection
 * @param {string} company_id ID of company to check duplication against
 * @param {string} file_name File name to check
 * @param {string} metric_record_id ID of metric record to check duplication against
 * @returns {DBFile | null} Duplicated file meta data
 */
export const getDuplicateFile = async (
  group_id: string,
  company_id: string,
  file_name: string,
  metric_record_id: string
) => {
  const company_doc = refCompanyDoc(group_id, company_id);
  const files_collection: CollectionReference = collection(company_doc, 'files');
  const files_query: Query = query(
    files_collection,
    where('name', '==', file_name),
    where('deleted', '==', null)
  );
  try {
    const duplicate_files: QuerySnapshot = await getDocs(files_query);
    if (duplicate_files.docs.length > 0) {
      const duplicate_file =
        duplicate_files.docs.find((file) => file.data().metric_record.id === metric_record_id) ??
        duplicate_files.docs[0];
      return {
        id: duplicate_file.id,
        deleted: duplicate_file.data().deleted,
        name: duplicate_file.data().name,
        type: duplicate_file.data().type,
        uploaded: duplicate_file.data().uploaded,
        metric_record: duplicate_file.data().metric_record,
        file_path: duplicate_file.data().file_path
      };
    }
    return null;
  } catch (err) {
    const error = `Error while getting duplicate files from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        files_collection: files_collection
      }
    })}`;
    throw new Error(`Error: getDuplicateFile: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to download file from cloud storage
 * @param {File} file File to download
 * @returns {Promise<void>}
 */
export const downloadFile = async (file: File): Promise<void> => {
  try {
    const user_token: string = (await getAuth().currentUser?.getIdToken()) ?? '';
    const response = await axios({
      method: 'post',
      url: `${API_ENDPOINT}/fetch_file`,
      data: {
        bucket: atob(GCP_FILE_UPLOAD_BUCKET),
        path: file.file_path
      },
      headers: {
        authorization: String('Bearer ' + user_token)
      },
      responseType: 'blob'
    }).catch((error) => {
      throw error.response;
    });
    if (response) {
      const url = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      const file_name = file.name;
      link.href = url;
      link.download = file_name;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    log(
      'error',
      new MetadataError(
        err instanceof Error
          ? err.message
          : 'Error: lib/metric_capture/metric_record.ts failed on an unknown error while calling downloadMetricRecordFile.',
        {
          file: file
        },
        tracking_id
      )
    );
    throw new Error(`Error: downloadMetricRecordFile: ${JSON.stringify(err)}.`);
  }
};
