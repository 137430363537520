import axios from 'axios';
import { API_ENDPOINT, NODE_ENV } from '../env';
import { serializeError } from 'serialize-error';
import { LogData, LogLevel, MetadataError } from '@ep/error-handling';

/**
 * Send transformed log to a backend service.
 * @param {LogLevel} level Type of message being logged.
 * @param {LogContent | string} data Data object or string being logged.
 * @param {boolean} [transport_console=false] Optionally log message to the client console.
 * @returns {void} The response is not important and therefore the promise is omitted.
 */
export const log = async (
  level: LogLevel,
  data: LogData | string,
  transport_console = false
): Promise<void> => {
  const request_body = {
    level: level,
    data: data ? serializeError(data) : ''
  };
  // Optionally log to the console.
  if (transport_console || NODE_ENV == 'development') {
    const log_message: string =
      typeof request_body.data === 'string' ? request_body.data : request_body.data.message || '';
    switch (level) {
      case 'error':
        console.error(log_message, request_body);
        break;
      case 'warn':
        console.warn(log_message, request_body);
        break;
      case 'debug':
        console.debug(log_message, request_body);
        break;
      case 'verbose':
        console.trace(log_message, request_body);
        break;
      case 'info':
        console.info(log_message, request_body);
        break;
      default:
        console.log(log_message, request_body);
        break;
    }
  }
  // Send the log to a backend logging service.
  axios({
    method: 'post',
    url: `${API_ENDPOINT}/create_log`,
    data: request_body
  });
};

/**
 * Catch unhandled exceptions.
 * @param {string | Event} message Error message.
 * @param {string | undefined} source Error origin.
 * @param {number | undefined} lineno Error line number at origin.
 * @param {number | undefined} colno Error column numner at origin.
 * @param {Error | undefined} error Complete error object.
 * @returns {boolean} Always returns true to prevent the default browser error handling.
 */
window.onerror = (
  message: string | Event,
  source: string | undefined,
  lineno: number | undefined,
  colno: number | undefined,
  error: Error | undefined
): boolean => {
  // Define the unhandled error.
  const err = new MetadataError('Unhandled error occurred.', {
    message,
    source,
    lineno,
    colno,
    error: error ? error.stack : null
  });
  // Send the unhandled error to a backend logging service.
  log('error', err);
  // Prevent the default browser error handling.
  return true;
};

/*

CLIENT LOGGING USAGE EXAMPLES:

    import { log } from '../../util/log';
    import { MetadataError, appendMetadataToError } from '@ep/error-handling';

    // With custom metadata object.
    const handleClickMetadataError = () => {
        try {
          throw new MetadataError("handleClickMetadataError: Something happend!", { "global_var_1": "global_val_1" });
        } catch (err: any) {
          if (err instanceof MetadataError) appendMetadataToError(err, "variables", { "appended_var_1": "appended_val_1" });
          log("error", err);
        }
      };

    // Default error.
    const handleClickError = () => {
      try {
        throw new Error("handleClickError: Something happend!");
      } catch (err: any) {
        log("error", err);
      }
    };

    // Information log with only a message string.
    const handleClickInfo = () => {
      log("info", "handleClickInfo: Something happend!");
    };

    // Warning log, also logged to the client console.
    const handleClickWarn = () => {
      log("warn", "handleClickWarn: Something happend!", true);
    };

*/
