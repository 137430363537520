import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Redirect = () => {
  const location = useLocation();
  const domains = window.location.hostname.split('.');

  useEffect(() => {
    if (domains[domains.length - 1] === '') {
      if (domains[0] === 'localhost') {
        window.location.replace(
          `http://localhost:${window.location.port}` + window.location.pathname
        );
      } else {
        window.location.replace(
          'https://' + domains.slice(0, -1).join('.') + window.location.pathname
        );
      }
    }
  }, [location]);

  return null;
};

export default Redirect;
