import * as React from 'react';
import { Box, Drawer, Tooltip, Typography } from '@mui/material';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import { Company, Group, Standard } from '@esg/esg-global-types';
import {
  allowDeleteStandard,
  createStandardBatchFromMasterList,
  deleteStandardWithMetrics,
  getStandards,
  standard_label
} from '../../../lib/app/standard';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import DeleteConfirmModal from '../../shared/modal/DeleteConfirmModal';
import ConfigCreateWidget, { CreateInput } from '../ConfigAddWidget';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridValueFormatterParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import moment from 'moment';
import { PanelStandardsToolbar } from './PanelStandardsToolbar';
import { PanelStandardsNoRows } from './PanelStandardsNoRows';
import { PanelStandardsLoading } from './PanelStandardsLoading';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';
import DeleteIcon from '@mui/icons-material/Delete';
import StandardSelect from '../../shared/input/select/StandardSelect';
import { PanelTabs, PanelTabsWrapper } from '../../shared/tab/PanelTabsWrapper';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const PanelStandards = () => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [actionRow, setActionRow] = React.useState<Standard | null>(null);
  const [standardRows, setStandardRows] = React.useState<Array<Standard>>([]);
  const [displayWidgetPanelAdd, setDisplayWidgetPanelAdd] = React.useState<boolean>(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState<boolean>(false);
  const [allowStandardDelete, setAllowStandardDelete] = React.useState<boolean | null>(null);
  const [gridLoading, setGridLoading] = React.useState<boolean>(true);

  const standardsInputFactory = (
    handleInputChange: (input_id: string, value: Standard | Array<Standard> | null) => void
  ) => {
    return (
      <StandardSelect
        group_id={group ? group.id : ''}
        company_id={company ? company.id : ''}
        master_list={false}
        multi_select={true}
        unconfigured_only={true}
        group_by="category"
        handleChangeStandards={(standards) => {
          handleInputChange('standards', standards);
        }}
      />
    );
  };

  // Handler functions
  const handleCreateClick = (): void => {
    setActionRow(null);
    setDisplayWidgetPanelAdd(true);
  };

  const handleDeleteClick = async (row: Standard): Promise<void> => {
    try {
      if (group && company) {
        setActionRow(row);
        setAllowStandardDelete(await allowDeleteStandard(group.id, company.id, row.id));
        setOpenDialogDelete(true);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelStandards failed on an unknown error while calling handleDeleteClick.',
          {
            group: group,
            company: company,
            row: row
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to delete standard. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const handleUpdateRows = (rows_data: Array<Standard>) => {
    setStandardRows(rows_data);
  };

  const handleCloseDeleteModal = () => {
    setOpenDialogDelete(false);
  };

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'is_quantitative',
      headerName: 'Type',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return params.value ? 'Quantitative' : 'Qualitative';
      }
    },
    {
      field: 'category',
      headerName: 'Category',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'version',
      headerName: 'Version',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'require_emission_factor',
      headerName: 'Emission Factors',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      renderCell: (params) => {
        return params.value ? <CheckIcon /> : <CloseIcon />;
      }
    },
    {
      field: 'require_site_level',
      headerName: 'Site Level',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      renderCell: (params) => {
        return params.value ? <CheckIcon /> : <CloseIcon />;
      }
    },
    {
      field: 'created',
      headerName: 'Added',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        return moment(params.value).format('DD MMM YYYY').toString();
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      headerAlign: 'right',
      align: 'right',
      hideable: false,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <>
            <GridActionsCellItem
              key={2}
              icon={
                <Tooltip title="Delete Standard">
                  <DeleteIcon color="primary" />
                </Tooltip>
              }
              size="large"
              label="Delete"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleDeleteClick(row)}
            />
          </>
        ];
      }
    }
  ];

  const inputs: readonly CreateInput[] = [
    {
      id: 'standards',
      type: 'node',
      label: 'Standards',
      inputNodeFactory: standardsInputFactory
    }
  ];

  // Row functions
  const fetchRows = async () => {
    setGridLoading(true);
    try {
      if (group && company) {
        // Load rows from database.
        const standards: Array<Standard> = await getStandards(group.id, company.id);
        // Load rows to memory.
        setStandardRows(standards);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelStandards failed on an unknown error while calling fetchRows.',
          {
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch standards. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
      return;
    } finally {
      setGridLoading(false);
    }
  };

  React.useEffect(() => {
    try {
      (async () => {
        setStandardRows([]);
        fetchRows();
      })().catch((error) => {
        throw new Error(error);
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelStandards failed on an unknown error while initialising.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `An error occurred. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
    return;
  }, [group, company]);

  const createRow = async (standard_list: Array<Standard>) => {
    try {
      if (group && company) {
        const new_standard_writes = await createStandardBatchFromMasterList(
          standard_list,
          group.id,
          company.id
        );
        if (new_standard_writes) {
          handleUpdateRows([
            ...new_standard_writes.map((new_standard_write) => {
              return {
                id: new_standard_write.reference.id,
                created: new_standard_write.data.created,
                deleted: new_standard_write.data.deleted,
                version: new_standard_write.data.version,
                name: new_standard_write.data.name,
                category: new_standard_write.data.category,
                sector: new_standard_write.data.sector,
                master_list_standard: new_standard_write.data.master_list_standard,
                is_quantitative: new_standard_write.data.is_quantitative,
                require_emission_factor: new_standard_write.data.require_emission_factor,
                require_site_level: new_standard_write.data.require_site_level,
                reference: new_standard_write.reference
              };
            }),
            ...standardRows
          ]);
        }
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelStandards failed on an unknown error while calling createRow.',
          {
            group: group,
            company: company,
            standard_list: standard_list
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to create standards. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const deleteRow = async (standard: Standard): Promise<void> => {
    try {
      if (group && company) {
        await deleteStandardWithMetrics(group.id, company.id, standard.id, standard.name).then(
          () => {
            setStandardRows(
              standardRows.filter((row: Standard) => {
                return row.id !== standard.id;
              })
            );
          }
        );
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelStandards failed on an unknown error while calling deleteRow.',
          {
            group: group,
            company: company,
            standard: standard
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to remove standard. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setOpenDialogDelete(false);
    }
  };

  return (
    <>
      {/* Delete Confirmation Modal */}
      <DeleteConfirmModal
        open={openDialogDelete}
        allow_delete={allowStandardDelete}
        delete_warning="Any Metrics and Emission Factors related to this Standard will also be deleted"
        handleCloseDeleteModal={handleCloseDeleteModal}
        delete_label={actionRow && actionRow.name}
        handleDelete={actionRow && (() => deleteRow(actionRow))}
        allow_archive={false}
      />

      {/* Side Widget Panel */}
      <Drawer
        anchor={'right'}
        open={displayWidgetPanelAdd}
        onClose={() => setDisplayWidgetPanelAdd(false)}
        PaperProps={{ style: { width: '30%', padding: '1.5rem' } }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}
        >
          <AddModeratorIcon sx={{ marginRight: '2rem' }} fontSize="large" />
          <Typography variant="h5" color="inherit" align="left">
            Add {standard_label.one}
          </Typography>
        </Box>
        <PanelTabsWrapper>
          <PanelTabs label="Generic" disabled={false}>
            <ConfigCreateWidget
              create_label={standard_label.one}
              create_icon={<AddModeratorIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
              create_function_inputs={inputs}
              existing_entities={standardRows}
              grid_spacing={3}
              handleClose={() => setDisplayWidgetPanelAdd(false)}
              createFunction={(standard_list: Array<Standard>) => createRow(standard_list)}
              hide_configured_entities={true}
              hide_title={true}
            />
          </PanelTabs>
          <PanelTabs label={'Custom'} disabled={true} />
        </PanelTabsWrapper>
      </Drawer>

      {/* Interactive Data Table */}
      <DataGrid
        autoHeight
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          },
          sorting: {
            sortModel: [{ field: 'modified', sort: 'desc' }]
          }
        }}
        hideFooter={standardRows.length > 1 ? false : true}
        columnHeaderHeight={standardRows.length < 1 ? 0 : 56}
        pageSizeOptions={[10, 25, 50, 100]}
        loading={gridLoading}
        rows={standardRows}
        columns={columns}
        disableRowSelectionOnClick
        slots={{
          toolbar: PanelStandardsToolbar,
          noRowsOverlay: PanelStandardsNoRows,
          loadingOverlay: PanelStandardsLoading
        }}
        slotProps={{ toolbar: { handleCreate: handleCreateClick } }}
        sx={{ '&, [class^=MuiDataGrid]': { border: 'none' }, width: '100%' }}
      />
    </>
  );
};

export default PanelStandards;
