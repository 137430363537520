import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getLatestEmissionFactorForMetrics } from '../../../../lib/app/emission_factor';
import { EmissionFactor } from '@esg/esg-global-types';
import { MetricExtended } from '../../../../lib/metric_capture/metric';

interface IncludeEmissionFactorCheckboxProps {
  handleChange?: (emission_factors: Array<EmissionFactor> | null) => void;
  metrics: Array<MetricExtended>;
  label?: string;
}

// Checkbox icons
const check_icon_blank = <CheckBoxOutlineBlankIcon fontSize="small" />;
const check_icon_filled = <CheckBoxIcon fontSize="small" />;

/**
 * A checkbox that returns the latest Emission Factors from the master list.
 * @param {Array<EmissionFactor> | any | null} props.handleChange Callback function to notify the parent component when the selected value have changed.
 * @param {Array<MetricExtended>} props.metrics List of Metrics for which the latest Emission Factor should be obtained from the master list.
 * @returns {JSX.Element}
 */
const IncludeLatestEmissionFactorCheckbox = (
  props: IncludeEmissionFactorCheckboxProps
): JSX.Element => {
  const [includeLatestEmissionFactors, setIncludeLatestEmissionFactors] =
    React.useState<boolean>(false);
  // TODO: Limit amount of queries to Firestore to reduce cost and increase peformance.
  React.useEffect(() => {
    (async () => {
      if (includeLatestEmissionFactors) {
        const emission_factors: Array<EmissionFactor> = await getLatestEmissionFactorForMetrics(
          true,
          props.metrics
        );
        if (props.handleChange) props.handleChange(emission_factors);
      }
    })();
  }, [props.metrics, includeLatestEmissionFactors]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={check_icon_blank}
          checkedIcon={check_icon_filled}
          checked={includeLatestEmissionFactors}
          color="primary"
          onChange={(event) => {
            setIncludeLatestEmissionFactors(event.target.checked);
          }}
        />
      }
      label="Include Latest Emission Factors"
    />
  );
};

export default IncludeLatestEmissionFactorCheckbox;
