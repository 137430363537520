import * as React from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Switch,
  Tooltip,
  Typography
} from '@mui/material';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import TodayIcon from '@mui/icons-material/Today';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Company, Group, ReportingPeriod, ReportingPeriodGroup } from '@esg/esg-global-types';
import {
  checkReportingPeriodOverlap,
  createReportingPeriod,
  deleteReportingPeriod,
  getReportingPeriods,
  updateReportingPeriod
} from '../../../lib/metric_capture/reporting_period';
import { LoadingButton } from '@mui/lab';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmModal from '../../shared/modal/DeleteConfirmModal';
import ConfigAddWidget, { CreateInput } from '../ConfigAddWidget';
import moment, { Moment } from 'moment';
import ConfigEditWidget, { EditInput } from '../ConfigEditWidget';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { getReportingPeriodGroups } from '../../../lib/metric_capture/reporting_period_group';
import ReportingPeriodGroupSelect from '../../shared/input/select/ReportingPeriodGroupSelect';
import { DocumentReference } from 'firebase/firestore';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridValueFormatterParams
} from '@mui/x-data-grid';
import { PanelReportingPeriodsToolbar } from './PanelReportingPeriodsToolbar';
import { PanelReportingPeriodsLoading } from './PanelReportingPeriodsLoading';
import { PanelReportingPeriodsNoRows } from './PanelReportingPeriodsNoRows';

const PanelReportingPeriods = () => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [reportingPeriodRows, setReportingPeriodRows] = React.useState<Array<ReportingPeriod>>([]);
  const [displayWidgetPanelRight, setDisplayWidgetPanelRight] = React.useState(false);
  const [isCreateWidget, setIsCreateWidget] = React.useState(true);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [actionLoading, setActionLoading] = React.useState<boolean>(false);
  const [actionRow, setActionRow] = React.useState<ReportingPeriod | null>(null);
  const [showLockConfirmModal, setShowLockConfirmModal] = React.useState(false);
  const [reportingPeriodGroups, setReportingPeriodGroups] = React.useState<
    Array<ReportingPeriodGroup>
  >([]);
  const [gridLoading, setGridLoading] = React.useState<boolean>(true);

  // Component variables
  const current_reporting_period_group: ReportingPeriodGroup | undefined =
    reportingPeriodGroups.find(
      (reporting_period_group: ReportingPeriodGroup) =>
        actionRow?.reporting_period_group?.id === reporting_period_group.id
    );

  const reportingPeriodGroupInputFactory = (
    handleInputChange: (input_id: string, value: ReportingPeriodGroup | null) => void
  ) => {
    return (
      <ReportingPeriodGroupSelect
        reporting_period_group_options={reportingPeriodGroups}
        selected_reporting_period_group={actionRow ? current_reporting_period_group : undefined}
        handleChangeReportingPeriodGroups={(
          reporting_period_group: Array<ReportingPeriodGroup> | ReportingPeriodGroup | null
        ) => {
          handleInputChange(
            'reporting_period_group',
            reporting_period_group as ReportingPeriod | null
          );
        }}
      />
    );
  };

  // Handler Functions
  const handleCreateClick = (): void => {
    setActionRow(null);
    setDisplayWidgetPanelRight(true);
    setIsCreateWidget(true);
  };

  const handleEditClick = (reporting_period: ReportingPeriod): void => {
    setActionRow(reporting_period);
    setIsCreateWidget(false);
    setDisplayWidgetPanelRight(true);
  };

  const handleDeleteClick = (reporting_period: ReportingPeriod): void => {
    setActionRow(reporting_period);
    setOpenDialogDelete(true);
  };

  // Row functions
  const fetchRows = async () => {
    setGridLoading(true);
    try {
      if (group && company) {
        // Load rows from database.
        const [reporting_periods, reporting_period_groups]: [
          Array<ReportingPeriod>,
          Array<ReportingPeriodGroup>
        ] = await Promise.all([
          getReportingPeriods(group.id, company.id),
          getReportingPeriodGroups(group.id, company.id)
        ]);
        // Load rows to memory.
        setReportingPeriodRows(reporting_periods);
        setReportingPeriodGroups(reporting_period_groups);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelReportingPeriods failed on an unknown error while calling fetchRows.',
          {
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch reporting periods. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
      return;
    } finally {
      setGridLoading(false);
    }
  };

  React.useEffect(() => {
    try {
      (async () => {
        setReportingPeriodRows([]);
        await fetchRows();
      })().catch((err: unknown) => {
        throw new Error(
          err instanceof Error
            ? err.message
            : 'Error: PanelReportingPeriods failed on an unknown error while calling fetchRows.'
        );
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelReportingPeriods failed on an unknown error while initialising.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `An error occurred. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  }, [group, company]);

  const columns: Array<GridColDef> = [
    {
      field: 'locked',
      headerName: 'Locked',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.locked ? 'Locked' : 'Lock'}>
            <Switch
              checked={params.row.locked ? true : false}
              onChange={() => {
                setActionRow(params.row);
                setShowLockConfirmModal(true);
              }}
            />
          </Tooltip>
        );
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'start',
      headerName: 'Start',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        return moment(params.value).format('DD MMM YYYY').toString();
      }
    },
    {
      field: 'end',
      headerName: 'End',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        return moment(params.value).format('DD MMM YYYY').toString();
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      headerAlign: 'right',
      align: 'right',
      hideable: false,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <>
            <GridActionsCellItem
              key={1}
              icon={
                <Tooltip title="Edit Reporting Period">
                  <EditIcon color={!row.locked ? 'primary' : 'action'} />
                </Tooltip>
              }
              size="large"
              label="Edit"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleEditClick(row)}
              disabled={row.locked}
            />
            <GridActionsCellItem
              key={2}
              icon={
                <Tooltip title="Delete Reporting Period">
                  <DeleteIcon color="primary" />
                </Tooltip>
              }
              size="large"
              label="Delete"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleDeleteClick(row)}
            />
          </>
        ];
      }
    }
  ];

  const create_inputs: readonly CreateInput[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Name'
    },
    {
      id: 'start-date',
      type: 'date',
      label: 'Start',
      defaultValue: moment().startOf('month')
    },
    {
      id: 'end-date',
      type: 'date',
      label: 'End',
      defaultValue: moment().endOf('month')
    },
    {
      id: 'reporting_period_group',
      type: 'node',
      label: 'Reporting Period Group',
      inputNodeFactory: reportingPeriodGroupInputFactory,
      optional: true
    }
  ];

  const edit_inputs: Array<EditInput> = [
    {
      id: 'name',
      type: 'text',
      label: 'Name',
      defaultValue: actionRow?.name
    },
    {
      id: 'start',
      type: 'date',
      label: 'Start',
      defaultValue: actionRow?.start
    },
    {
      id: 'end',
      type: 'date',
      label: 'End',
      defaultValue: actionRow?.end
    },
    {
      id: 'reporting_period_group',
      type: 'node',
      label: 'Reporting Period Group',
      inputNodeFactory: reportingPeriodGroupInputFactory
    }
  ];

  const toggleLockRow = async () => {
    try {
      if (actionRow) {
        setActionLoading(true);
        await updateRow({ ...actionRow, locked: !actionRow.locked }, { ...actionRow });
      } else {
        throw new Error('Error updating Reporting Period, try again later');
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelReportingPeriods failed on an unknown error while calling toggleLockRow.',
          {
            actionRow: actionRow,
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to lock reporting period. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setShowLockConfirmModal(false);
      setActionLoading(false);
    }
  };

  const handleCloseLockConfirmModal = () => {
    setShowLockConfirmModal(false);
  };

  const handleClickBackDialogDelete = () => {
    setOpenDialogDelete(false);
  };

  const createRow = async (
    name: string,
    start_date: Moment,
    end_date: Moment,
    reporting_period_group: ReportingPeriodGroup | null
  ) => {
    try {
      if (group && company) {
        const overlapping_periods: Array<ReportingPeriod> = checkReportingPeriodOverlap(
          '1',
          start_date,
          end_date,
          reportingPeriodRows
        );
        if (overlapping_periods.length > 0) {
          setFeedbackData({
            message: `Cannot create Reporting Period overlapping: ${overlapping_periods.map((overlap) => overlap.name).join(', ')}`,
            state: true,
            type: 'error'
          });
          return;
        }
        const new_reporting_period: DocumentReference = await createReportingPeriod(
          group.id,
          company.id,
          {
            name: name,
            deleted: null,
            locked: false,
            start: start_date.toDate(),
            end: end_date.toDate(),
            reporting_period_group: reporting_period_group ?? undefined
          }
        ).catch((error) => {
          throw new Error(error);
        });
        if (new_reporting_period) {
          handleUpdateRows([
            {
              id: new_reporting_period.id,
              deleted: null,
              name: name,
              start: moment(start_date),
              end: moment(end_date),
              locked: false
            },
            ...reportingPeriodRows
          ]);
        }
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelReportingPeriods failed on an unknown error while calling updateRow.',
          {
            group: group,
            company: company,
            name: name,
            start_date: start_date,
            end_date: end_date
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to create reporting period. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const updateRow = async (updated_row: ReportingPeriod, original_data: ReportingPeriod) => {
    try {
      if (group && company) {
        const overlapping_periods: Array<ReportingPeriod> = checkReportingPeriodOverlap(
          updated_row.id,
          moment(updated_row.start),
          moment(updated_row.end),
          reportingPeriodRows
        );
        if (overlapping_periods.length > 0) {
          setFeedbackData({
            message: `Cannot create Reporting Period overlapping: ${overlapping_periods.map((overlap) => overlap.name).join(', ')}`,
            state: true,
            type: 'error'
          });
          return;
        }
        await updateReportingPeriod(group.id, company.id, updated_row, original_data).then(() => {
          handleUpdateRows(
            reportingPeriodRows.map((row) => (updated_row.id === row.id ? { ...updated_row } : row))
          );
        });
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelReportingPeriods failed on an unknown error while calling updateRow.',
          {
            group: group,
            company: company,
            updated_row: updated_row,
            original_data: original_data
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update reporting period. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const handleUpdateRows = (rows_data: Array<ReportingPeriod>) => {
    setReportingPeriodRows(rows_data);
  };

  const deleteRow = async (delete_reporting_period: ReportingPeriod) => {
    try {
      if (group && company && delete_reporting_period) {
        setActionLoading(true);
        await deleteReportingPeriod(
          group.id,
          company.id,
          delete_reporting_period.id,
          delete_reporting_period.name
        )
          .then(() => {
            handleUpdateRows(
              reportingPeriodRows.filter((reporting_period: ReportingPeriod) => {
                return reporting_period.id !== delete_reporting_period.id;
              })
            );
          })
          .catch((error) => {
            throw new Error(error);
          });
      } else {
        throw new Error('Error deleting reporting period, try again later');
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelReportingPeriods failed on an unknown error while calling deleteRow.',
          {
            group: group,
            company: company,
            'delete_reporting_period.id': delete_reporting_period.id,
            'delete_reporting_period.name': delete_reporting_period.name
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to remove reporting period. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setActionLoading(false);
      setOpenDialogDelete(false);
    }
  };

  return (
    <>
      {/* Delete Confirmation Modal */}
      <DeleteConfirmModal
        open={openDialogDelete}
        handleCloseDeleteModal={handleClickBackDialogDelete}
        delete_label={actionRow && actionRow.name}
        handleDelete={actionRow && (() => deleteRow(actionRow))}
        allow_archive={false}
        allow_delete={true}
      />

      {/* Side Widget Panel */}
      <React.Fragment key={'right_add'}>
        <Drawer
          anchor={'right'}
          open={displayWidgetPanelRight}
          onClose={() => {
            setDisplayWidgetPanelRight(false);
          }}
          PaperProps={{ style: { width: '40%', padding: '1.5rem' } }}
        >
          {/* Create / Edit Widget */}
          {isCreateWidget ? (
            <ConfigAddWidget
              create_label="Reporting Period"
              create_icon={<MoreTimeIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
              create_function_inputs={create_inputs}
              handleClose={() => setDisplayWidgetPanelRight(false)}
              createFunction={(
                name: string,
                start_date: Moment,
                end_date: Moment,
                reporting_period_group: ReportingPeriodGroup | null
              ) => createRow(name, start_date, end_date, reporting_period_group)}
            />
          ) : (
            <ConfigEditWidget
              edit_label="Reporting Period"
              edit_icon={<TodayIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
              handleClose={() => setDisplayWidgetPanelRight(false)}
              edit_entity={actionRow}
              edit_function_inputs={edit_inputs}
              handleEditInput={(input_id: string, value: unknown) =>
                actionRow && setActionRow({ ...actionRow, [input_id]: value })
              }
              confirmEditFunction={(
                updated_reporting_period: ReportingPeriod,
                original_data: ReportingPeriod
              ) => updateRow(updated_reporting_period, original_data)}
            />
          )}
        </Drawer>
      </React.Fragment>

      {/* Lock Confirmation Modal */}
      <Dialog
        open={showLockConfirmModal}
        onClose={handleCloseLockConfirmModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 1.5 }}>
          <DialogTitle id="alert-dialog-title">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <LockOpenIcon sx={{ marginRight: '1rem' }} fontSize="medium" />
              <Typography variant="h6" color="inherit" align="left">
                {actionRow?.locked ? 'Unlock' : 'Lock'} {actionRow?.name ? actionRow.name : 'Row'}?
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are about to {actionRow?.locked ? 'unlock' : 'lock'}{' '}
              {actionRow?.name ? actionRow.name : 'row'} {actionRow?.locked ? 'from' : 'for'} your
              reporting periods.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseLockConfirmModal} variant="outlined" color="primary">
              Back
            </Button>
            <LoadingButton
              loading={actionLoading}
              onClick={toggleLockRow}
              variant="contained"
              color="error"
              autoFocus
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>

      {/* Interactive Data Table */}
      <DataGrid
        autoHeight
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          },
          sorting: {
            sortModel: [{ field: 'modified', sort: 'desc' }]
          }
        }}
        hideFooter={reportingPeriodRows.length > 1 ? false : true}
        columnHeaderHeight={reportingPeriodRows.length < 1 ? 0 : 56}
        pageSizeOptions={[10, 25, 50, 100]}
        loading={gridLoading}
        rows={reportingPeriodRows}
        columns={columns}
        disableRowSelectionOnClick
        slots={{
          toolbar: PanelReportingPeriodsToolbar,
          noRowsOverlay: PanelReportingPeriodsNoRows,
          loadingOverlay: PanelReportingPeriodsLoading
        }}
        slotProps={{ toolbar: { handleCreate: handleCreateClick } }}
        sx={{ '&, [class^=MuiDataGrid]': { border: 'none' } }}
      />
    </>
  );
};

export default PanelReportingPeriods;
