import { Box } from '@mui/material';
import React from 'react';
import AirIcon from '@mui/icons-material/Air';
import ConfigurationWrapper from '../../components/shared/ConfigurationWrapper';
import PanelEmissionFactors from '../../components/configuration/emission_factors/PanelEmissionFactors';

const ConfigurationEmissionFactors = () => {
  return (
    <Box>
      <ConfigurationWrapper
        title="Emission Factors"
        icon={<AirIcon fontSize="large" />}
        previous={null}
        next={null}
      >
        <PanelEmissionFactors />
      </ConfigurationWrapper>
    </Box>
  );
};

export default ConfigurationEmissionFactors;
