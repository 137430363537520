import React from 'react';
import { Tooltip, Drawer, Box, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridActionsCellItem, GridValueGetterParams } from '@mui/x-data-grid';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmModal from '../../shared/modal/DeleteConfirmModal';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { PanelMetricsLoading } from './PanelMetricsLoading';
import { Company, EmissionFactor, Group, Standard } from '@esg/esg-global-types';
import { getStandards } from '../../../lib/app/standard';
import { PanelMetricsToolbar } from './PanelMetricsToolbar';
import { PanelMetricsNoRows } from './PanelMetricsNoRows';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';
import {
  getMetrics,
  MetricExtended,
  createMetrics,
  getUnconfiguredMetrics,
  metric_label,
  deleteMetricWithEmissionFactors,
  allowDeleteMetric
} from '../../../lib/metric_capture/metric';
import { DocumentReference, getDoc } from 'firebase/firestore';
import StandardSelect from '../../shared/input/select/StandardSelect';
import ConfigAddWidget, { CreateInput } from '../ConfigAddWidget';
import { PanelTabs, PanelTabsWrapper } from '../../shared/tab/PanelTabsWrapper';
import MetricSelect from '../../shared/input/select/MetricSelect';
import IncludeLatestEmissionFactorCheckbox from '../../shared/input/checkbox/IncludeEmissionFactorCheckbox';

export interface MetricGridRow extends MetricExtended {
  standard: Standard;
}

/**
 * Datagrid handling the display of configured Metrics with relative Standard data for Company
 * @returns {JSX.Element}
 */
const PanelMetrics = () => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [displayWidgetPanelAdd, setDisplayWidgetPanelAdd] = React.useState<boolean>(false);
  const [gridLoading, setGridLoading] = React.useState(true);
  const [loadingUnconfiguredMetrics, setloadingUnconfiguredMetrics] = React.useState<boolean>(true);
  const [metricRows, setMetricRows] = React.useState<Array<MetricGridRow>>([]);
  const [actionRow, setActionRow] = React.useState<MetricGridRow | null>(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [allowMetricDelete, setAllowMetricDelete] = React.useState<boolean | null>(null);
  const [unconfiguredMetrics, setUnconfiguredMetrics] = React.useState<Array<MetricExtended>>([]);
  const [configuredStandards, setConfiguredStandards] = React.useState<Array<Standard>>([]);
  const [selectedStandards, setSelectedStandards] = React.useState<Array<Standard>>([]);
  const [selectedMetrics, setSelectedMetrics] = React.useState<Array<MetricExtended>>([]);
  const [selectedEmissionFactors, setSelectedEmissionFactors] = React.useState<
    Array<EmissionFactor>
  >([]);
  const [filteredUnconfiguredMetrics, setFilteredUnconfiguredMetrics] = React.useState<
    Array<MetricExtended>
  >([]);

  const handleCreateMetrics = async (): Promise<void> => {
    try {
      if (group && company) {
        selectedEmissionFactors.length > 0
          ? await createMetrics(group, company, selectedMetrics, selectedEmissionFactors)
          : await createMetrics(group, company, selectedMetrics);
        setUnconfiguredMetrics(
          unconfiguredMetrics.filter((metric: MetricExtended) => !selectedMetrics.includes(metric))
        );
        setSelectedStandards([]);
        fetchRows();
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      throw new MetadataError(
        err instanceof Error
          ? err.message
          : 'Error: PanelMetrics failed on an unknown error while calling handleCreateMetrics.',
        {
          group: group,
          company: company
        },
        tracking_id
      );
    }
  };

  const fetchMetricsForStandards = async (): Promise<void> => {
    if (group && company) {
      try {
        setloadingUnconfiguredMetrics(true);
        const unconfigured_metrics: Array<MetricExtended> = await getUnconfiguredMetrics(
          group?.id,
          company?.id,
          undefined,
          true
        );
        setUnconfiguredMetrics(unconfigured_metrics);
        setloadingUnconfiguredMetrics(false);
      } catch (err: unknown) {
        const tracking_id: string = uuidv4();
        throw new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelMetrics failed on an unknown error while calling fetchMetricsForStandards.',
          {
            group: group,
            company: company
          },
          tracking_id
        );
      }
    }
  };

  React.useEffect(() => {
    try {
      setUnconfiguredMetrics([]);
      if (group && company) {
        (async () => {
          const company_configured_standards: Array<Standard> = await getStandards(
            group?.id,
            company.id
          );
          await Promise.all(
            company_configured_standards.map(async (standard) => {
              const document_reference = await getDoc(
                standard.master_list_standard as DocumentReference
              );
              standard.id = document_reference.id;
            })
          );
          setConfiguredStandards(company_configured_standards); //This is master level standards
          await fetchMetricsForStandards();
        })();
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      throw new MetadataError(
        err instanceof Error
          ? err.message
          : 'Error: PanelMetrics failed on an unknown error while initializing unconfiguredMetrics.',
        {
          group: group,
          company: company
        },
        tracking_id
      );
    }
  }, [group, company]);

  React.useEffect(() => {
    if (selectedStandards.length > 0) {
      const selected_standard_ids = selectedStandards.map((standard: Standard) => standard.id);
      setFilteredUnconfiguredMetrics(
        unconfiguredMetrics
          .filter((metric: MetricExtended) => selected_standard_ids.includes(metric.standard.id))
          .sort((metric_a: MetricExtended, metric_b: MetricExtended) => {
            const standard_name_a: string = (metric_a.standard as Standard).name;
            const standard_name_b: string = (metric_b.standard as Standard).name;
            return standard_name_a < standard_name_b
              ? -1
              : standard_name_b > standard_name_a
                ? 1
                : 0;
          })
      );
    } else {
      setFilteredUnconfiguredMetrics([]);
    }
  }, [selectedStandards, unconfiguredMetrics]);

  const standardsInputFactory = (
    handleInputChange: (
      input_id: string,
      value: MetricExtended | Array<MetricExtended> | null
    ) => void
  ): JSX.Element => {
    return (
      <StandardSelect
        options={configuredStandards}
        multi_select={true}
        handleChangeStandards={(standards) => {
          setSelectedStandards(standards as Array<Standard>);
          if ((standards as Array<Standard>).length < 1) handleInputChange('metrics', []);
        }}
      />
    );
  };

  const metricInputFactory = (
    handleInputChange: (
      input_id: string,
      value: MetricExtended | Array<MetricExtended> | null
    ) => void
  ): JSX.Element => {
    return (
      <MetricSelect
        options={filteredUnconfiguredMetrics}
        tag_limit={5}
        multi_select={true}
        group_by="standard.name"
        handleChangeMetrics={(metrics) => {
          setSelectedMetrics(metrics as Array<MetricExtended>);
          handleInputChange('metrics', metrics);
        }}
        loading={loadingUnconfiguredMetrics}
      />
    );
  };

  const emissionFactorInputFactory = (
    handleInputChange: (input_id: string, value: Array<EmissionFactor> | null) => void
  ): JSX.Element => {
    if (selectedStandards.some((standard: Standard) => standard.is_quantitative === true)) {
      return (
        <IncludeLatestEmissionFactorCheckbox
          metrics={selectedMetrics}
          handleChange={(emission_factors) => {
            setSelectedEmissionFactors(emission_factors as Array<EmissionFactor>);
            handleInputChange('emisson_factors', emission_factors);
          }}
        />
      );
    }
    return <></>;
  };

  const inputs: readonly CreateInput[] = [
    {
      id: 'standards',
      type: 'node',
      label: 'Standards',
      inputNodeFactory: standardsInputFactory,
      optional: true
    },
    {
      id: 'metrics',
      type: 'node',
      label: 'Metrics',
      inputNodeFactory: metricInputFactory
    },
    {
      id: 'emission_factors',
      type: 'node',
      label: 'Emission Factors',
      inputNodeFactory: emissionFactorInputFactory,
      optional: true
    }
  ];

  // Handler functions
  const handleCreateClick = (): void => {
    setActionRow(null);
    setDisplayWidgetPanelAdd(true);
  };

  const handleDeleteClick = async (metric_row: MetricGridRow): Promise<void> => {
    try {
      if (group && company) {
        setActionRow(metric_row);
        setAllowMetricDelete(
          await allowDeleteMetric(group.id, company.id, metric_row.id, metric_row.metric_group.id)
        );
        setShowDeleteModal(true);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelMetrics failed on an unknown error while calling handleClickClick.',
          {
            group: group,
            company: company,
            'metric_row.id': metric_row.id,
            metric_group_id: metric_row.metric_group.id
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to delete metric. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const handleCloseDeleteModal = (): void => {
    setShowDeleteModal(false);
  };

  // Row functions
  const fetchRows = async (): Promise<void> => {
    setGridLoading(true);
    try {
      if (group && company) {
        // Load rows from database.
        const [metrics, standards]: [Array<MetricExtended>, Array<Standard>] = await Promise.all([
          getMetrics(false, group.id, company.id),
          getStandards(group.id, company.id)
        ]).catch((err: unknown) => {
          throw new Error(
            err instanceof Error
              ? err.message
              : 'Error: PanelMetrics failed on an unknown error while calling fetchRows.'
          );
        });
        const metric_rows: Array<MetricGridRow> = [];
        metrics.forEach((metric: MetricExtended) => {
          const standard: Standard | undefined = standards.find(
            (standard: Standard) => standard.id === metric.standard.id
          );
          if (standard) {
            metric_rows.push({
              ...metric,
              standard: standard
            });
          }
        });
        // Load rows to memory.
        setMetricRows(metric_rows);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelMetrics failed on an unknown error while calling fetchRows.',
          {
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch metrics. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setGridLoading(false);
    }
  };

  const deleteRow = async (row: MetricGridRow): Promise<void> => {
    try {
      if (group && company) {
        // Soft delete Metric database record.
        await deleteMetricWithEmissionFactors(
          group.id,
          company.id,
          row.id,
          row.metric_group.id,
          row.name
        ).catch((err: unknown) => {
          throw new Error(
            err instanceof Error
              ? err.message
              : 'Error: PanelMetrics failed on an unknown error while calling deleteRow.'
          );
        });
        // Remove from memory.
        setMetricRows(metricRows.filter((obj) => obj.id !== row.id));
        fetchMetricsForStandards();
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelMetrics failed on an unknown error while calling deleteRow.',
          {
            group: group,
            company: company,
            row: row
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to delete metric. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  React.useEffect(() => {
    try {
      (async () => {
        setMetricRows([]);
        fetchRows();
      })().catch((error) => {
        throw new Error(error);
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelMetrics failed on an unknown error while calling fetchRows.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `An error occurred. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
    return;
  }, [group, company]);

  const columns: GridColDef[] = [
    {
      field: 'standard_name',
      headerName: 'Standard',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.standard.name;
      },
      flex: 1
    },
    {
      field: 'standard_is_quantitative',
      headerName: 'Type',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.standard.is_quantitative ? 'Quantitative' : 'Qualitative';
      },
      flex: 1
    },
    {
      field: 'group_name',
      headerName: 'Category',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.metric_group.name;
      },
      flex: 1
    },
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'unit',
      headerName: 'Unit',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.standard.is_quantitative ? params.value : 'Text';
      },
      flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      headerAlign: 'right',
      align: 'right',
      hideable: false,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            key={2}
            icon={
              <Tooltip title="Delete metric">
                <DeleteIcon color="primary" />
              </Tooltip>
            }
            size="large"
            label="Delete"
            sx={{
              color: 'primary.main'
            }}
            onClick={() => handleDeleteClick(row)}
          />
        ];
      }
    }
  ];
  return (
    <>
      {/* Delete Confirmation Modal */}
      <DeleteConfirmModal
        allow_delete={allowMetricDelete}
        allow_archive={false}
        open={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        item_type={'Metric'}
        delete_warning={
          'You will be required to create this Metric again if deleted. All Emission Factors configured for this Metric will also be deleted'
        }
        delete_label={actionRow && `${actionRow.metric_group.name}: ${actionRow.name}`}
        handleDelete={actionRow && (() => deleteRow(actionRow))}
      />

      {/* Side Widget Panel */}
      <React.Fragment key={'right'}>
        <Drawer
          anchor={'right'}
          open={displayWidgetPanelAdd}
          onClose={() => {
            setDisplayWidgetPanelAdd(false);
            setSelectedStandards([]);
            setSelectedMetrics([]);
          }}
          PaperProps={{ style: { width: '40%', padding: '1.5rem' } }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <AddModeratorIcon sx={{ marginRight: '2rem' }} fontSize="large" />
            <Typography variant="h5" color="inherit" align="left">
              Add {metric_label.many}
            </Typography>
          </Box>
          <PanelTabsWrapper>
            <PanelTabs label="Generic" disabled={false}>
              <ConfigAddWidget
                create_label={'Metrics'}
                create_icon={<AddModeratorIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
                create_function_inputs={inputs}
                grid_spacing={3}
                handleClose={() => setDisplayWidgetPanelAdd(false)}
                createFunction={() => handleCreateMetrics()}
                hide_configured_entities={true}
                hide_title={true}
              />
            </PanelTabs>
            <PanelTabs label={'Custom'} disabled={true} />
          </PanelTabsWrapper>
        </Drawer>
      </React.Fragment>

      {/* Interactive Data Table */}
      <DataGrid
        autoHeight
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          }
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        loading={gridLoading}
        rows={metricRows}
        columns={columns}
        disableRowSelectionOnClick
        slots={{
          toolbar: PanelMetricsToolbar,
          noRowsOverlay: PanelMetricsNoRows,
          loadingOverlay: PanelMetricsLoading
        }}
        slotProps={{ toolbar: { handleCreate: handleCreateClick, buttonDisabled: gridLoading } }}
        sx={{ '&, [class^=MuiDataGrid]': { border: 'none' } }}
      />
    </>
  );
};

export default PanelMetrics;
