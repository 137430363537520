import React from 'react';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Skeleton,
  Tooltip,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  TextField,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Drawer
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import EditNoteIcon from '@mui/icons-material/EditNote';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import {
  Company,
  Country,
  Division,
  Group,
  Region,
  Site,
  Subdivision
} from '@esg/esg-global-types';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  allowDeleteDivision,
  archiveDivision,
  deleteDivision,
  getDivisions,
  unarchiveDivision,
  createDivision,
  updateDivision
} from '../../../lib/metric_capture/division';
import {
  allowDeleteSubdivision,
  archiveSubdivision,
  deleteSubdivision,
  getSubdivisions,
  unarchiveSubdivision,
  createSubdivision,
  updateSubdivision
} from '../../../lib/metric_capture/subdivision';
import {
  allowDeleteSite,
  archiveSite,
  createSite,
  deleteSite,
  getSites,
  unarchiveSite,
  updateSite
} from '../../../lib/metric_capture/site';
import { getRegionJoinCountries } from '../../../lib/metric_capture/region';
import DeleteConfirmModal from '../../shared/modal/DeleteConfirmModal';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import ConfigCreateWidget, { CreateInput } from '../ConfigAddWidget';
import UnarchiveConfirmModal from '../../shared/modal/UnarchiveConfirmModal';
import ConfigEditWidget, { EditInput } from '../ConfigEditWidget';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';

interface ActionEntity {
  entity: Division | Subdivision | Site;
  level: 'division' | 'subdivision' | 'site';
}

interface WidgetOptions {
  level: 'division' | 'subdivision' | 'site';
  open: boolean;
}

const PanelOrganisation = () => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [divisions, setDivisions] = React.useState<Array<Division>>([]);
  const [subdivisions, setSubdivisions] = React.useState<Array<Subdivision>>([]);
  const [sites, setSites] = React.useState<Array<Site>>([]);
  const [regions, setRegions] = React.useState<Array<Region>>([]);
  const [selectedDivision, setSelectedDivision] = React.useState<Division | null>(null);
  const [selectedSubdivision, setSelectedSubdivision] = React.useState<Subdivision | null>(null);
  const [actionEntity, setActionEntity] = React.useState<ActionEntity | null>(null);
  const [allowEntityDelete, setAllowEntityDelete] = React.useState<boolean | null>(null);
  const [addWidget, setAddWidget] = React.useState<WidgetOptions>({
    level: 'division',
    open: false
  });
  const [editWidget, setEditWidget] = React.useState<WidgetOptions>({
    level: 'division',
    open: false
  });
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showUnarchiveModal, setShowUnarchiveModal] = React.useState<boolean>(false);
  const [showArchived, setShowArchived] = React.useState<boolean>(false);
  const [loadingEntities, setLoadingEntities] = React.useState<boolean>(true);
  const [filterDivisionValue, setFilterDivisionValue] = React.useState<string>('');
  const [filterSubdivisionValue, setFilterSubdivisionValue] = React.useState<string>('');
  const [filterSiteValue, setFilterSiteValue] = React.useState<string>('');

  const regionInputFactory = (handleInputChange: (input_id: string, value: unknown) => void) => {
    return (
      <Autocomplete
        key={
          actionEntity &&
          JSON.stringify(
            regions.find((region: Region) => {
              return region.id === (actionEntity?.entity as Site)?.region.id;
            })
          )
        }
        fullWidth
        id="region-select"
        options={regions.map((region) => JSON.stringify(region))}
        autoHighlight
        groupBy={(option) => (JSON.parse(option).country as Country).name}
        onChange={(event, value) => value && handleInputChange('region', JSON.parse(value))}
        defaultValue={
          actionEntity &&
          JSON.stringify(
            regions.find((region: Region) => {
              return region.id === (actionEntity?.entity as Site)?.region.id;
            })
          )
        }
        getOptionLabel={(option) => JSON.parse(option).name}
        renderInput={(params) => <TextField {...params} label="Site Region" />}
      />
    );
  };

  const create_inputs: readonly CreateInput[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Name'
    },
    {
      id: 'region',
      type: 'node',
      label: 'Region',
      inputNodeFactory: regionInputFactory
    },
    {
      id: 'latitude',
      type: 'number',
      label: 'Latitude'
    },
    {
      id: 'longitude',
      type: 'number',
      label: 'Longitude'
    }
  ];

  const edit_inputs: Array<EditInput> = [
    {
      id: 'name',
      type: 'text',
      label: 'Name',
      defaultValue: actionEntity?.entity.name
    },
    {
      id: 'region',
      type: 'node',
      label: 'Region',
      inputNodeFactory: regionInputFactory,
      defaultValue: actionEntity ? (actionEntity.entity as Site).region : undefined
    },
    {
      id: 'latitude',
      type: 'text',
      label: 'Latitude'
    },
    {
      id: 'longitude',
      type: 'text',
      label: 'Longitude'
    }
  ];

  const fetchStructureEntities = async () => {
    try {
      setLoadingEntities(true);
      if (group && company) {
        const [divisions, subdivisions, sites, regions]: [
          Array<Division>,
          Array<Subdivision>,
          Array<Site>,
          Array<Region>
        ] = await Promise.all([
          getDivisions(group.id, company.id),
          getSubdivisions(group.id, company.id),
          getSites(group.id, company.id),
          getRegionJoinCountries(group.id, company.id)
        ]);
        setDivisions(divisions);
        setSubdivisions(subdivisions);
        setSites(sites);
        setRegions(regions);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelOrganisation failed on an unknown error while calling fetcbStructureEntities.',
          {
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch structured entities. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setLoadingEntities(false);
    }
  };

  React.useEffect(() => {
    try {
      (async () => {
        setDivisions([]);
        setSubdivisions([]);
        setSites([]);
        setSelectedDivision(null);
        setSelectedSubdivision(null);
        setActionEntity(null);
        fetchStructureEntities();
      })().catch((error) => {
        throw new Error(error);
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelOrganisation failed on an unknown error while initialising.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `An error occurred. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  }, [group, company]);

  const closeAddWidget = () => {
    setAddWidget({ ...addWidget, open: false });
  };

  const handleSelectDivision = (division: Division) => {
    if (selectedDivision?.id !== division.id) {
      setSelectedSubdivision(null);
    }
    setSelectedDivision(division);
  };

  const handleNewDivision = (new_division: Division) => {
    setDivisions([new_division, ...divisions]);
    setSelectedDivision(new_division);
    setSelectedSubdivision(null);
  };

  const handleNewSubdivision = (new_subdivision: Subdivision) => {
    setSubdivisions([new_subdivision, ...subdivisions]);
    setSelectedSubdivision(new_subdivision);
  };

  const handleNewSite = (new_site: Site) => {
    setSites([new_site, ...sites]);
  };

  const editEntity = async (
    updated_entity: Division | Subdivision | Site,
    original_data: Division | Subdivision | Site
  ) => {
    try {
      if (group && company) {
        updated_entity &&
          (await (async () => {
            switch (actionEntity?.level) {
              case 'division':
                await updateDivision(
                  group.id,
                  company.id,
                  updated_entity as Division,
                  original_data as Division
                ).then(() => {
                  setDivisions(
                    divisions.map((division) =>
                      updated_entity.id === division.id
                        ? { ...(updated_entity as Division) }
                        : division
                    )
                  );
                });
                break;
              case 'subdivision':
                await updateSubdivision(
                  group.id,
                  company.id,
                  updated_entity as Subdivision,
                  original_data as Subdivision
                ).then(() => {
                  setSubdivisions(
                    subdivisions.map((subdivision) =>
                      updated_entity.id === subdivision.id
                        ? { ...(updated_entity as Subdivision) }
                        : subdivision
                    )
                  );
                });
                break;
              case 'site':
                await updateSite(
                  group.id,
                  company.id,
                  updated_entity as Site,
                  original_data as Site
                ).then(() => {
                  setSites(
                    sites.map((site) =>
                      updated_entity.id === site.id ? { ...(updated_entity as Site) } : site
                    )
                  );
                });
                break;
              default:
                break;
            }
          })());
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelOrganisation failed on an unknown error while calling editEntity.',
          {
            group: group,
            company: company,
            updated_entity: updated_entity,
            original_data: original_data
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update entity. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const createEntity = async (
    name: string,
    region: Region | null,
    latitude: string,
    longitude: string
  ) => {
    try {
      if (group && company) {
        if (addWidget.level === 'division') {
          const new_division = await createDivision(group.id, company.id, name).catch(
            (err: unknown) => {
              throw new Error(
                err instanceof Error
                  ? err.message
                  : 'Error: PanelOrganisation failed on an unknown error while calling createDivision.'
              );
            }
          );
          if (new_division) {
            handleNewDivision({
              id: new_division.id,
              deleted: null,
              name: name
            });
          }
        } else if (addWidget.level === 'subdivision' && selectedDivision) {
          const new_subdivision = await createSubdivision(
            group.id,
            company.id,
            name,
            selectedDivision
          ).catch((error) => {
            throw new Error(error);
          });
          if (new_subdivision) {
            handleNewSubdivision({
              id: new_subdivision.id,
              deleted: null,
              name: name,
              division: selectedDivision
            });
          }
        } else if (addWidget.level === 'site' && selectedSubdivision && region) {
          const latitude_str = latitude.toString();
          const longitude_str = longitude.toString();
          const new_site = await createSite(
            group.id,
            company.id,
            name,
            selectedSubdivision,
            region,
            latitude_str,
            longitude_str
          ).catch((error) => {
            throw new Error(error);
          });
          if (new_site) {
            handleNewSite({
              id: new_site.id,
              deleted: null,
              name: name,
              region: region,
              subdivision: selectedSubdivision,
              latitude: latitude_str,
              longitude: longitude_str
            });
          }
        }
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelOrganisation failed on an unknown error while calling createEntity.',
          {
            group: group,
            company: company,
            'addWidget.level': addWidget.level,
            selectedDivision: selectedDivision,
            selectedSubdivision: selectedSubdivision,
            name: name,
            region: region
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to create entity. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const deleteEntity = async (
    delete_entity: Division | Subdivision | Site,
    level: 'division' | 'subdivision' | 'site'
  ): Promise<void> => {
    try {
      group &&
        company &&
        delete_entity &&
        (await (async () => {
          switch (level) {
            case 'division':
              await deleteDivision(
                group.id,
                company.id,
                delete_entity.id,
                delete_entity.name,
                subdivisions.filter((subdivision) => {
                  return subdivision.division.id === delete_entity.id;
                }),
                sites.filter((site) => {
                  return (
                    subdivisions.find((subdivision) => site.subdivision.id === subdivision.id)
                      ?.division.id === delete_entity.id
                  );
                })
              ).then(() => {
                setDivisions(
                  divisions.filter((division: Division) => {
                    return division.id !== delete_entity.id;
                  })
                );
                setSubdivisions(
                  subdivisions.filter((subdivision) => {
                    return subdivision.division.id !== delete_entity.id;
                  })
                );
                setSites(
                  sites.filter((site) => {
                    return (
                      subdivisions.find((subdivision) => site.subdivision.id === subdivision.id)
                        ?.division.id !== delete_entity.id
                    );
                  })
                );
                if (selectedDivision?.id === delete_entity.id) {
                  setSelectedDivision(null);
                }
              });
              break;
            case 'subdivision':
              await deleteSubdivision(
                group.id,
                company.id,
                delete_entity.id,
                delete_entity.name,
                sites.filter((site) => {
                  return site.subdivision.id === delete_entity.id;
                })
              ).then(() => {
                setSubdivisions(
                  subdivisions.filter((subdivision: Subdivision) => {
                    return subdivision.id !== delete_entity.id;
                  })
                );
                setSites(
                  sites.filter((site) => {
                    return site.subdivision.id !== delete_entity.id;
                  })
                );
                if (selectedSubdivision?.id === delete_entity.id) {
                  setSelectedSubdivision(null);
                }
              });
              break;
            case 'site':
              await deleteSite(group.id, company.id, delete_entity.id, delete_entity.name).then(
                () => {
                  setSites(
                    sites.filter((site: Site) => {
                      return site.id !== delete_entity.id;
                    })
                  );
                }
              );
              break;
            default:
              break;
          }
        })());
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelOrganisation failed on an unknown error while calling deleteEntity.',
          {
            group: group,
            company: company,
            delete_entity: delete_entity,
            level: level,
            subdivisions: subdivisions,
            sites: sites,
            selectedDivision: selectedDivision,
            selectedSubdivision: selectedSubdivision
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to remove entity. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const archiveEntity = async (
    archive_entity: Division | Subdivision | Site,
    level: 'division' | 'subdivision' | 'site'
  ): Promise<void> => {
    try {
      group &&
        company &&
        archive_entity &&
        (await (async () => {
          switch (level) {
            case 'division':
              await archiveDivision(
                group.id,
                company.id,
                archive_entity.id,
                subdivisions.filter((subdivision) => {
                  return subdivision.division.id === archive_entity.id;
                }),
                sites.filter((site) => {
                  return (
                    subdivisions.find((subdivision) => site.subdivision.id === subdivision.id)
                      ?.division.id === archive_entity.id
                  );
                })
              ).then(() => {
                setDivisions(
                  divisions.map((division: Division) => {
                    if (division.id === archive_entity.id) {
                      return { ...division, archived: new Date() };
                    }
                    return division;
                  })
                );
                setSubdivisions(
                  subdivisions.map((subdivision: Subdivision) => {
                    if (subdivision.division.id === archive_entity.id) {
                      return { ...subdivision, archived: new Date() };
                    }
                    return subdivision;
                  })
                );
                setSites(
                  sites.map((site: Site) => {
                    if (
                      subdivisions.find((subdivision) => site.subdivision.id === subdivision.id)
                        ?.division.id === archive_entity.id
                    ) {
                      return { ...site, archived: new Date() };
                    }
                    return site;
                  })
                );
                if (selectedDivision?.id === archive_entity.id && !showArchived) {
                  setSelectedDivision(null);
                }
              });
              break;
            case 'subdivision':
              await archiveSubdivision(
                group.id,
                company.id,
                archive_entity.id,
                sites.filter((site) => {
                  return site.subdivision.id === archive_entity.id;
                })
              ).then(() => {
                setSubdivisions(
                  subdivisions.map((subdivision: Subdivision) => {
                    if (subdivision.id === archive_entity.id) {
                      return { ...subdivision, archived: new Date() };
                    }
                    return subdivision;
                  })
                );
                setSites(
                  sites.map((site: Site) => {
                    if (site.subdivision.id === archive_entity.id) {
                      return { ...site, archived: new Date() };
                    }
                    return site;
                  })
                );
                if (selectedSubdivision?.id === archive_entity.id && !showArchived) {
                  setSelectedSubdivision(null);
                }
              });
              break;
            case 'site':
              await archiveSite(group.id, company.id, archive_entity.id).then(() => {
                setSites(
                  sites.map((site: Site) => {
                    if (site.id === archive_entity.id) {
                      return { ...site, archived: new Date() };
                    }
                    return site;
                  })
                );
              });
              break;
            default:
              break;
          }
        })());
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelOrganisation failed on an unknown error while calling archiveEntity.',
          {
            group: group,
            company: company,
            archive_entity: archive_entity,
            level: level,
            subdivisions: subdivisions,
            sites: sites,
            selectedDivision: selectedDivision,
            selectedSubdivision: selectedSubdivision
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to archive entity. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const unarchiveEntity = async (
    unarchive_entity: Division | Subdivision | Site,
    level: 'division' | 'subdivision' | 'site'
  ): Promise<void> => {
    try {
      group &&
        company &&
        unarchive_entity &&
        (await (async () => {
          switch (level) {
            case 'division':
              await unarchiveDivision(
                group.id,
                company.id,
                unarchive_entity.id,
                subdivisions.filter((subdivision) => {
                  return subdivision.division.id === unarchive_entity.id;
                }),
                sites.filter((site) => {
                  return (
                    subdivisions.find((subdivision) => site.subdivision.id === subdivision.id)
                      ?.division.id === unarchive_entity.id
                  );
                })
              ).then(() => {
                setDivisions(
                  divisions.map((division: Division) => {
                    if (division.id === unarchive_entity.id) {
                      return { ...division, archived: null };
                    }
                    return division;
                  })
                );
                setSubdivisions(
                  subdivisions.map((subdivision: Subdivision) => {
                    if (subdivision.division.id === unarchive_entity.id) {
                      return { ...subdivision, archived: null };
                    }
                    return subdivision;
                  })
                );
                setSites(
                  sites.map((site: Site) => {
                    if (
                      subdivisions.find((subdivision) => site.subdivision.id === subdivision.id)
                        ?.division.id === unarchive_entity.id
                    ) {
                      return { ...site, archived: null };
                    }
                    return site;
                  })
                );
              });
              break;
            case 'subdivision':
              await unarchiveSubdivision(
                group.id,
                company.id,
                unarchive_entity.id,
                sites.filter((site) => {
                  return site.subdivision.id === unarchive_entity.id;
                })
              ).then(() => {
                setSubdivisions(
                  subdivisions.map((subdivision: Subdivision) => {
                    if (subdivision.id === unarchive_entity.id) {
                      return { ...subdivision, archived: null };
                    }
                    return subdivision;
                  })
                );
                setSites(
                  sites.map((site: Site) => {
                    if (site.subdivision.id === unarchive_entity.id) {
                      return { ...site, archived: null };
                    }
                    return site;
                  })
                );
              });
              break;
            case 'site':
              await unarchiveSite(group.id, company.id, unarchive_entity.id).then(() => {
                setSites(
                  sites.map((site: Site) => {
                    if (site.id === unarchive_entity.id) {
                      return { ...site, archived: null };
                    }
                    return site;
                  })
                );
              });
              break;
            default:
              break;
          }
        })());
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelOrganisation failed on an unknown error while calling unarchiveEntity.',
          {
            group: group,
            company: company,
            unarchive_entity: unarchive_entity,
            level: level,
            subdivisions: subdivisions,
            sites: sites,
            selectedDivision: selectedDivision,
            selectedSubdivision: selectedSubdivision
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to unarchive entity. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const handleCloseDeleteModal = (): void => {
    setShowDeleteModal(false);
    setAllowEntityDelete(null);
  };

  const handleCloseUnarchiveModal = (): void => {
    setShowUnarchiveModal(false);
  };

  const visible_subdivisions = subdivisions.filter((subdivision) => {
    return (
      subdivision.division.id === selectedDivision?.id &&
      (filterSubdivisionValue === '' ||
        String(subdivision.name).toLowerCase().includes(filterSubdivisionValue.toLowerCase())) &&
      (showArchived || !subdivision.archived)
    );
  });

  const visible_sites = sites.filter((site: Site) => {
    return (
      site.subdivision.id === selectedSubdivision?.id &&
      (filterSiteValue === '' ||
        String(site.name).toLowerCase().includes(filterSiteValue.toLowerCase())) &&
      (showArchived || !site.archived)
    );
  });

  return (
    <>
      <UnarchiveConfirmModal
        open={showUnarchiveModal}
        handleCloseUnarchiveModal={handleCloseUnarchiveModal}
        unarchive_explainer={(() => {
          switch (actionEntity?.level) {
            case 'division':
              return 'Metric Record, Subdivision and Site data for Division will be restored and editabled';
            case 'subdivision':
              return 'Metric Record and Site data for Subdivision will be restored and editable';
            case 'site':
              return `Metric Record data for Site will be restored and editable`;
            default:
              return '';
          }
        })()}
        unarchive_label={actionEntity && actionEntity.entity.name}
        handleUnarchive={
          actionEntity && (() => unarchiveEntity(actionEntity.entity, actionEntity.level))
        }
      />
      <DeleteConfirmModal
        open={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        delete_warning={(() => {
          switch (actionEntity?.level) {
            case 'division':
              return 'Deleting: Metric Record, Subdivision and Site data for Division will be lost';
            case 'subdivision':
              return 'Deleting: Metric Record and Site data for Subdivision will be lost';
            case 'site':
              return `Deleting: Metric Record data for Site will be lost`;
            default:
              return '';
          }
        })()}
        archive_warning={(() => {
          switch (actionEntity?.level) {
            case 'division':
              return 'Archiving: Metric Record, Subdivision and Site data for Division will remain but will not be editable';
            case 'subdivision':
              return 'Archiving: Metric Record and Site data for Subdivision will remain but will not be editable';
            case 'site':
              return `Archiving: Metric Record data for Site will be remain but will not be editable`;
            default:
              return '';
          }
        })()}
        allow_delete={allowEntityDelete}
        allow_archive={actionEntity && !actionEntity.entity.archived}
        delete_label={actionEntity && actionEntity.entity.name}
        handleDelete={actionEntity && (() => deleteEntity(actionEntity.entity, actionEntity.level))}
        handleArchive={
          actionEntity && (() => archiveEntity(actionEntity.entity, actionEntity.level))
        }
      />
      <Drawer
        anchor={'right'}
        open={addWidget.open}
        onClose={closeAddWidget}
        PaperProps={{ style: { width: '30%', padding: '1.5rem' } }}
      >
        <ConfigCreateWidget
          create_label={
            addWidget.level.charAt(0).toUpperCase() + addWidget.level.slice(1).toLowerCase()
          }
          create_icon={<DomainAddIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
          create_function_inputs={addWidget.level === 'site' ? create_inputs : [create_inputs[0]]}
          handleClose={closeAddWidget}
          createFunction={(
            name: string,
            region: Region | null,
            latitude: string,
            longitude: string
          ) => createEntity(name, region, latitude, longitude)}
        />
      </Drawer>
      <Drawer
        anchor={'right'}
        open={editWidget.open}
        onClose={() => {
          setEditWidget({ ...editWidget, open: false });
          setActionEntity(null);
        }}
        PaperProps={{ style: { width: '30%', padding: '1.5rem' } }}
      >
        <ConfigEditWidget
          edit_label={
            editWidget.level.charAt(0).toUpperCase() + editWidget.level.slice(1).toLowerCase()
          }
          edit_icon={<EditNoteIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
          handleClose={() => {
            setEditWidget({ ...editWidget, open: false });
            setActionEntity(null);
          }}
          edit_entity={actionEntity?.entity}
          edit_function_inputs={editWidget.level === 'site' ? edit_inputs : [edit_inputs[0]]}
          handleEditInput={(input_id: string, value: unknown) =>
            actionEntity &&
            setActionEntity({
              ...actionEntity,
              entity: { ...actionEntity.entity, [input_id]: value }
            })
          }
          confirmEditFunction={async (
            updated_entity: Division | Subdivision | Site,
            original_data: Division | Subdivision | Site
          ) => {
            await editEntity(updated_entity, original_data);
          }}
        />
      </Drawer>

      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            ml: 'auto',
            px: 4,
            borderRadius: 2,
            mb: 2,
            width: 'fit-content'
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={false}
                color="primary"
                onChange={(event) => {
                  setShowArchived(event.target.checked);
                }}
              />
            }
            label="Show Archived"
          />
        </Box>
        <Grid container sx={{ height: '100%', minHeight: '50vh' }}>
          <Grid item xs={4} sx={{ borderRight: '1px solid #053d5d', position: 'relative' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography sx={{ fontSize: '1.1rem' }}>Divisions</Typography>
              <Tooltip title={`Create Division`}>
                <IconButton
                  aria-label="create"
                  size="large"
                  color="primary"
                  onClick={() => {
                    setAddWidget({ level: 'division', open: true });
                  }}
                  sx={{ ml: 'auto', mr: 8, p: 0 }}
                >
                  <AddCircleIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </Box>
            {loadingEntities ? (
              <Skeleton variant="rounded" height={300} sx={{ mr: 8, mt: 3, width: '90%' }} />
            ) : (
              <Box>
                <TextField
                  id="filter-divisions"
                  label={`Filter Divisions`}
                  variant="standard"
                  sx={{
                    width: '60%',
                    mb: 2
                  }}
                  onChange={(e) => setFilterDivisionValue(e.target.value)}
                />
                {divisions.length > 0 ? (
                  <List
                    sx={{
                      overflow: 'auto',
                      maxHeight: '60vh'
                    }}
                  >
                    {divisions
                      .filter((division) => {
                        return (
                          (filterDivisionValue === '' ||
                            String(division.name)
                              .toLowerCase()
                              .includes(filterDivisionValue.toLowerCase())) &&
                          (showArchived || !division.archived)
                        );
                      })
                      .map((division) => {
                        return (
                          <ListItem key={division.id}>
                            <Card
                              sx={{
                                width: '90%',
                                display: 'flex',
                                backgroundImage:
                                  division.id === selectedDivision?.id
                                    ? 'linear-gradient(to right, #053d5d, 0.03%, transparent)'
                                    : 'transparent'
                              }}
                            >
                              <CardActionArea
                                onClick={() => {
                                  handleSelectDivision(division);
                                }}
                              >
                                <CardContent sx={{ display: 'flex', ml: 2 }}>
                                  <Typography>{division.name}</Typography>
                                  <NavigateNextIcon color="primary" sx={{ ml: 'auto', mr: 2 }} />
                                </CardContent>
                              </CardActionArea>
                              {division.archived && (
                                <IconButton
                                  aria-label="archived"
                                  sx={{ color: '#1b97c1' }}
                                  onClick={() => {
                                    setActionEntity({ entity: division, level: 'division' });
                                    setShowUnarchiveModal(true);
                                  }}
                                >
                                  <UnarchiveIcon fontSize={'medium'} />
                                </IconButton>
                              )}
                              {!division.archived && (
                                <IconButton
                                  aria-label="edit"
                                  color="primary"
                                  onClick={() => {
                                    setActionEntity({ entity: division, level: 'division' });
                                    setEditWidget({ open: true, level: 'division' });
                                  }}
                                >
                                  <EditIcon fontSize={'medium'} />
                                </IconButton>
                              )}
                              <IconButton
                                aria-label="delete"
                                color="primary"
                                onClick={async () => {
                                  try {
                                    if (group && company) {
                                      setActionEntity({ entity: division, level: 'division' });
                                      setShowDeleteModal(true);
                                      setAllowEntityDelete(
                                        await allowDeleteDivision(
                                          group.id,
                                          company.id,
                                          division.id
                                        ).catch((err: unknown) => {
                                          setShowDeleteModal(false);
                                          throw new Error(
                                            err instanceof Error
                                              ? err.message
                                              : 'Error: PanelOrganisation failed on an unknown error while calling allowDeleteSubdivision.'
                                          );
                                        })
                                      );
                                    }
                                  } catch (err: unknown) {
                                    const tracking_id: string = uuidv4();
                                    log(
                                      'error',
                                      new MetadataError(
                                        err instanceof Error
                                          ? err.message
                                          : 'Error: PanelOrganisation failed on an unknown error while calling allowDeleteDivision.',
                                        {
                                          group: group,
                                          company: company,
                                          'division.id': division.id
                                        },
                                        tracking_id
                                      )
                                    );
                                    setFeedbackData({
                                      message: `${
                                        err instanceof Error
                                          ? err.message
                                          : 'Error: PanelOrganisation failed on an unknown error while calling allowDeleteDivision.'
                                      }. Tracking ID: ${tracking_id}`,
                                      state: true,
                                      type: 'error'
                                    });
                                  }
                                }}
                              >
                                <DeleteIcon fontSize={'medium'} />
                              </IconButton>
                            </Card>
                          </ListItem>
                        );
                      })}
                  </List>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      color: '#494949',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)'
                    }}
                  >
                    <DomainAddIcon sx={{ fontSize: '2.5rem', marginBottom: 1.5 }} />
                    Add a Division
                  </Box>
                )}
              </Box>
            )}
          </Grid>
          <Grid item xs={4} sx={{ borderRight: '1px solid #053d5d', pl: 4, position: 'relative' }}>
            {selectedDivision ? (
              <>
                <Box sx={{ display: 'flex', mb: 2 }}>
                  <Typography
                    sx={{
                      fontSize: '1.1rem'
                    }}
                  >{`${selectedDivision.name} Subdivisions`}</Typography>

                  <Tooltip title={`Create Subdivision`}>
                    <IconButton
                      aria-label="create"
                      size="large"
                      color="primary"
                      onClick={() => {
                        setAddWidget({ level: 'subdivision', open: true });
                      }}
                      sx={{ ml: 'auto', mr: 8, p: 0 }}
                    >
                      <AddCircleIcon fontSize="large" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <TextField
                  id="filter-subdivisions"
                  label={`Filter Subdivisions`}
                  variant="standard"
                  sx={{
                    width: '60%',
                    mb: 2
                  }}
                  onChange={(e) => setFilterSubdivisionValue(e.target.value)}
                />
                {visible_subdivisions.length > 0 ? (
                  <List
                    sx={{
                      overflow: 'auto',
                      maxHeight: '60vh'
                    }}
                  >
                    {visible_subdivisions.map((subdivision) => {
                      return (
                        <ListItem key={subdivision.id}>
                          <Card
                            sx={{
                              width: '90%',
                              display: 'flex',
                              backgroundImage:
                                subdivision.id === selectedSubdivision?.id
                                  ? 'linear-gradient(to right, #053d5d, 0.03%, transparent)'
                                  : 'transparent'
                            }}
                          >
                            <CardActionArea onClick={() => setSelectedSubdivision(subdivision)}>
                              <CardContent sx={{ display: 'flex', ml: 2 }}>
                                <Typography>{subdivision.name}</Typography>
                                <NavigateNextIcon color="primary" sx={{ ml: 'auto', mr: 2 }} />
                              </CardContent>
                            </CardActionArea>
                            {subdivision.archived && (
                              <IconButton
                                aria-label="archived"
                                sx={{ color: '#1b97c1' }}
                                onClick={() => {
                                  setActionEntity({ entity: subdivision, level: 'subdivision' });
                                  setShowUnarchiveModal(true);
                                }}
                              >
                                <UnarchiveIcon fontSize={'medium'} />
                              </IconButton>
                            )}
                            {!subdivision.archived && (
                              <IconButton
                                aria-label="edit"
                                color="primary"
                                onClick={() => {
                                  setActionEntity({ entity: subdivision, level: 'subdivision' });
                                  setEditWidget({ open: true, level: 'subdivision' });
                                }}
                              >
                                <EditIcon fontSize={'medium'} />
                              </IconButton>
                            )}
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              onClick={async () => {
                                try {
                                  if (group && company) {
                                    setActionEntity({ entity: subdivision, level: 'subdivision' });
                                    setShowDeleteModal(true);
                                    setAllowEntityDelete(
                                      await allowDeleteSubdivision(
                                        group.id,
                                        company.id,
                                        subdivision.id
                                      ).catch((error) => {
                                        setShowDeleteModal(false);
                                        throw new Error(error);
                                      })
                                    );
                                  }
                                } catch (err: unknown) {
                                  const tracking_id: string = uuidv4();
                                  log(
                                    'error',
                                    new MetadataError(
                                      err instanceof Error
                                        ? err.message
                                        : 'Error: PanelOrganisation failed on an unknown error while calling allowDeleteSubdivision.',
                                      {
                                        group: group,
                                        company: company,
                                        'subdivision.id': subdivision.id
                                      },
                                      tracking_id
                                    )
                                  );
                                  setFeedbackData({
                                    message: `${
                                      err instanceof Error
                                        ? err.message
                                        : 'Error: PanelOrganisation failed on an unknown error while calling allowDeleteSubdivision.'
                                    }. Tracking ID: ${tracking_id}`,
                                    state: true,
                                    type: 'error'
                                  });
                                }
                              }}
                            >
                              <DeleteIcon fontSize={'medium'} />
                            </IconButton>
                          </Card>
                        </ListItem>
                      );
                    })}
                  </List>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      color: '#494949',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)'
                    }}
                  >
                    <DomainAddIcon sx={{ fontSize: '2.5rem', marginBottom: 1.5 }} />
                    Add a Subdivision
                  </Box>
                )}
              </>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#494949',
                  height: '100%'
                }}
              >
                <AutoAwesomeMosaicIcon sx={{ fontSize: '2.5rem', marginBottom: 1.5 }} />
                Select A Division to View Subdivisions
              </Box>
            )}
          </Grid>
          <Grid item xs={4} sx={{ pl: 4, position: 'relative' }}>
            {selectedDivision && selectedSubdivision ? (
              <>
                <Box sx={{ display: 'flex', mb: 2 }}>
                  <Typography
                    sx={{ fontSize: '1.1rem' }}
                  >{`${selectedSubdivision.name} Sites`}</Typography>

                  <Tooltip title={`Create Site`}>
                    <IconButton
                      aria-label="create"
                      size="large"
                      color="primary"
                      onClick={() => {
                        setAddWidget({ level: 'site', open: true });
                      }}
                      sx={{ ml: 'auto', mr: 8, p: 0 }}
                    >
                      <AddCircleIcon fontSize="large" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <TextField
                  id="filter-sites"
                  label={`Filter Sites`}
                  variant="standard"
                  sx={{
                    width: '60%',
                    mb: 2
                  }}
                  onChange={(e) => setFilterSiteValue(e.target.value)}
                />

                {visible_sites.length > 0 ? (
                  <List
                    sx={{
                      overflow: 'auto',
                      maxHeight: '60vh'
                    }}
                  >
                    {visible_sites.map((site) => {
                      return (
                        <ListItem key={site.id}>
                          <Card sx={{ width: '90%', display: 'flex' }}>
                            <CardContent sx={{ ml: 2 }}>
                              <Typography>{site.name}</Typography>
                            </CardContent>
                            {site.archived && (
                              <IconButton
                                sx={{ ml: 'auto', color: '#1b97c1' }}
                                aria-label="archived"
                                onClick={() => {
                                  setActionEntity({ entity: site, level: 'site' });
                                  setShowUnarchiveModal(true);
                                }}
                              >
                                <UnarchiveIcon fontSize={'medium'} />
                              </IconButton>
                            )}
                            {!site.archived && (
                              <IconButton
                                aria-label="edit"
                                color="primary"
                                onClick={() => {
                                  setActionEntity({ entity: site, level: 'site' });
                                  setEditWidget({ open: true, level: 'site' });
                                }}
                                sx={{ ml: 'auto' }}
                              >
                                <EditIcon fontSize={'medium'} />
                              </IconButton>
                            )}
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              onClick={async () => {
                                try {
                                  if (group && company) {
                                    setActionEntity({ entity: site, level: 'site' });
                                    setShowDeleteModal(true);
                                    setAllowEntityDelete(
                                      await allowDeleteSite(group.id, company.id, site.id).catch(
                                        (error) => {
                                          setShowDeleteModal(false);
                                          throw new Error(error);
                                        }
                                      )
                                    );
                                  }
                                } catch (err: unknown) {
                                  const tracking_id: string = uuidv4();
                                  log(
                                    'error',
                                    new MetadataError(
                                      err instanceof Error
                                        ? err.message
                                        : 'Error: PanelOrganisation failed on an unknown error while calling allowDeleteSite.',
                                      {
                                        group: group,
                                        company: company,
                                        'site.id': site.id
                                      },
                                      tracking_id
                                    )
                                  );
                                  setFeedbackData({
                                    message: `${
                                      err instanceof Error ? err.message : 'Unknown Error Occured.'
                                    }. Tracking ID: ${tracking_id}`,
                                    state: true,
                                    type: 'error'
                                  });
                                }
                              }}
                            >
                              <DeleteIcon fontSize={'medium'} />
                            </IconButton>
                          </Card>
                        </ListItem>
                      );
                    })}
                  </List>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      color: '#494949',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)'
                    }}
                  >
                    <DomainAddIcon sx={{ fontSize: '2.5rem', marginBottom: 1.5 }} />
                    Add a Site
                  </Box>
                )}
              </>
            ) : (
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#494949'
                }}
              >
                <AutoAwesomeMosaicIcon sx={{ fontSize: '2.5rem', marginBottom: 1.5 }} />
                Select A Subdivision to View Sites
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PanelOrganisation;
