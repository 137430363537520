import React, { ReactNode, CSSProperties } from 'react';
import { Box, Grid } from '@mui/material';

const SectionContent = ({ children, style }: { children?: ReactNode; style?: CSSProperties }) => {
  return (
    <Box paddingY={10} style={style} className="landing-section">
      <Grid
        container
        spacing={5}
        sx={{
          justifyContent: 'center'
        }}
      >
        <Grid
          item
          xs={7}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '60px',
            textAlign: 'start'
          }}
        >
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SectionContent;
