import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import {
  getAuth,
  RecaptchaVerifier,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
  MultiFactorSession,
  MultiFactorInfo,
  MultiFactorResolver,
  PhoneInfoOptions,
  MultiFactorError,
  ParsedToken,
  User
} from 'firebase/auth';

import { FIREBASE_CONFIG } from '../../env';

export interface Claims extends ParsedToken {
  assessment: { active: boolean };
  metric_collection: { active: boolean; type: string };
  readiness_guage: { active: boolean };
  reports: { active: boolean };
  resource_centre: { active: boolean };
}

// Initialize Firebase
const app = initializeApp(FIREBASE_CONFIG);
export const db = getFirestore(app);

export const auth = getAuth(app);

let verification_id: string | null = null;
let multi_factor_resolver: MultiFactorResolver | null = null;

/**
 * Function to facilitate a user submitting their phone number for MFA
 * @param {string | null} phone_number Phone number that user wishes to register to their account
 * @returns {void}
 */

export const startEnrollMultiFactor = async (phone_number: string | null) => {
  try {
    const recaptcha_verifier = new RecaptchaVerifier(auth, 'sign-in-button', { size: 'invisible' });

    if (auth.currentUser) {
      verification_id = await multiFactor(auth.currentUser)
        .getSession()
        .then(function (multiFactorSession) {
          // Specify the phone number and pass the MFA session.
          const phone_info_options = {
            phone_number: phone_number,
            session: multiFactorSession
          };

          const phone_auth_provider = new PhoneAuthProvider(auth);

          // Send SMS verification code.
          return phone_auth_provider.verifyPhoneNumber(phone_info_options, recaptcha_verifier);
        })
        .catch(function (error) {
          throw error;
        });
    } else {
      // The user is not verified.
      return;
    }
  } catch (err) {
    return err;
  }
};

/**
 * Function to facilitate verification of newly registered MFA phone number
 * @param {string} verification_code verification code sent through during startEnrollMulitfactor
 * @returns {void}
 */

export const finishEnrollMultiFactor = async (verification_code: string) => {
  if (verification_id && auth.currentUser) {
    try {
      const cred = PhoneAuthProvider.credential(verification_id, verification_code);
      const multi_factor_assertioin = PhoneMultiFactorGenerator.assertion(cred);

      // Complete enrollment.
      await multiFactor(auth.currentUser)
        .enroll(multi_factor_assertioin, 'My cellphone number')
        .catch(function (error) {
          throw error;
        });
      verification_id = null;
    } catch (err) {
      return err;
    }
  }
};

/**
 * Function to facilitate MFA login process
 * @param {MultiFactorError} error Firebase error allowing the trigger of MFA Resolver generation
 * @returns {MultiFactorResolver} Generated MFA Resolved that allows MFA login to occur
 */

export const getMfaResolver = (error: MultiFactorError) => {
  multi_factor_resolver = getMultiFactorResolver(auth, error);
  return multi_factor_resolver;
};

/**
 * Function to facilitate the start of sign in in with MFA
 * @param {MultiFactorInfo} error A structure containing the information of a second factor entity.
 * @param {MultiFactorSession} session An interface defining the multi-factor session object used for helping sign in an enrolled user with a second factor.
 * @returns {void}
 */

export const startMfaSignin = async (
  multi_factor_hint: MultiFactorInfo,
  session: MultiFactorSession,
  recaptcha_verifier: RecaptchaVerifier
) => {
  try {
    // const recaptcha_verifier = new RecaptchaVerifier(auth, 'sign-in-button', { size: 'invisible' });
    if (multi_factor_hint.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
      const phone_info_options: PhoneInfoOptions = {
        multiFactorHint: multi_factor_hint,
        session: session
      };
      const phone_auth_provider = new PhoneAuthProvider(auth);
      // Send SMS verification code
      verification_id = await phone_auth_provider
        .verifyPhoneNumber(phone_info_options, recaptcha_verifier)
        .catch(function (error) {
          throw new Error(error);
        });
    } else {
      return;
    }
  } catch (err: unknown) {
    throw new Error(
      err instanceof Error
        ? err.message
        : 'Error: lib/google/firebase.ts failed on an unknown error while calling startMfaSignin.'
    );
  }
};

/**
 * Function to facilitate the verification of sign in in with MFA
 * @param {string} verification_code Verification code sent through during startMfaSignIn
 * @returns {void}
 */

export const finishMfaSignIn = async (verification_code: string) => {
  // Get the SMS verification code sent to the user.
  try {
    if (verification_id && multi_factor_resolver) {
      const cred = PhoneAuthProvider.credential(verification_id, verification_code);
      const multi_factor_assertion = PhoneMultiFactorGenerator.assertion(cred);

      // Complete sign-in.
      await multi_factor_resolver
        .resolveSignIn(multi_factor_assertion)
        .then(function (user_credential) {
          return user_credential;
        })
        .catch(function (error: unknown) {
          throw error;
        });
    }
  } catch (err: unknown) {
    throw new Error(
      err instanceof Error
        ? err.message
        : 'Error: lib/google/firebase.ts failed on an unknown error while calling finishMfaSignIn.'
    );
  } finally {
    multi_factor_resolver = null;
    verification_id = null;
  }
};

/**
 * Function to obtain the claims object assigned to a user through Firebase Auth
 * @param {User | null} user User record object returned throuh Firebase JWT token auth access
 * @returns {void}
 */

export const getUserClaims = async () => {
  return new Promise<Claims>((resolve, reject) => {
    try {
      auth.onAuthStateChanged((user: User | null) => {
        if (user) {
          user
            .getIdTokenResult()
            .then((idTokenResult) => {
              resolve(idTokenResult.claims as Claims);
            })
            .catch((err: unknown) => {
              reject(err);
            });
        }
      });
    } catch (err: unknown) {
      throw new Error(
        err instanceof Error
          ? err.message
          : 'Error: lib/google/firebase.ts failed on an unknown error while calling getUserClaims.'
      );
    }
  }).catch((error) => {
    throw new Error(error);
  });
};

export default app;
