import * as React from 'react';
import { UserContext } from '../../context/UserContext';
import { User } from '@esg/esg-global-types';
import AccessDenied from '../shared/AccessDenied';
import LoadingScreen from '../../components/shared/LoadingScreen';
import PanelEmissionFactors from '../../components/configuration/emission_factors/PanelEmissionFactors';

const EmissionFactorMasterView = () => {
  const user_info: User | null = React.useContext(UserContext);
  return user_info === null ? (
    <LoadingScreen />
  ) : user_info.super_admin ? (
    <PanelEmissionFactors master_list />
  ) : (
    <AccessDenied />
  );
};

export default EmissionFactorMasterView;
