import React from 'react';
import { Box, Fab } from '@mui/material';
import 'typeface-montserrat';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import tree_img from '../../assets/bad-link-tree-stump.webp';

const InvalidURL = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2
        }}
      >
        <Typography fontWeight={600} fontSize={100} letterSpacing={10}>
          404
        </Typography>
        <img src={tree_img} width={250} />
        <Typography sx={{ mt: 4 }}>
          Sorry, we were unable to find your page. Please double check that the URL is correct and
          try again later.
        </Typography>
        <Box sx={{ paddingTop: '25px', mb: 4 }}>
          <Link to={'/contact'}>
            <Fab
              variant="extended"
              sx={{
                backgroundColor: '#29B6F6',
                padding: '2rem',
                fontWeight: 'bold',
                color: '#FFFFFF'
              }}
            >
              Start Your ESG Journey
              <ArrowForwardIcon />
            </Fab>
          </Link>
        </Box>
        <Link to="/">No thanks, take me back to home</Link>
      </Box>
    </>
  );
};

export default InvalidURL;
