import React from 'react';
import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material';
import { resolveObjectFromString } from '../../../../util/validation';
import { CompanyExtended, company_label, getCompanyJoinGroups } from '../../../../lib/app/company';

interface CompanySelectProps {
  handleChangeCompanies?: (companies: Array<CompanyExtended> | CompanyExtended | null) => void;
  options?: Array<CompanyExtended>;
  selected_options?: Array<CompanyExtended> | CompanyExtended | null;
  disabled?: boolean;
  input_label?: string;
  group_by?: 'group.name';
  multi_select?: boolean;
  tag_limit?: number;
  clearable?: boolean;
  searchable?: boolean;
  group_id?: string;
  loading?: boolean;
  color?: string;
}

/**
 * Company selection auto-complete component that handles a variety of built-in scenarios.
 * @param {Array<CompanyExtended> | CompanyExtended | null} props.handleChangeCompanies Callback function to notify the parent component when the selected options have changed.
 * @param {Array<CompanyExtended>} props.options Custom list of options provided by the parent component. Defaults to none.
 * @param {Array<CompanyExtended> | CompanyExtended | null} props.selected_options Custom list of selected options provided by the parent component. Changes component from an uncontrolled to controlled.
 * @param {boolean} props.disabled Renders the component in a disabled state along with any other provided components. Defaults to enabled.
 * @param {string} props.input_label Sets a custom component input label. Defaults to the singular version of the main entity name.
 * @param {string} props.group_by Group the options by any one of a variety of grouping options. Defaults to ungrouped.
 * @param {boolean} props.multi_select Allow multi option selection and keeps the select open while selecting. Defaults to a single select and closes after selecting an option.
 * @param {number} props.tag_limit Sets a limit on the amount of tags rendered in the input. Defaults to unlimited.
 * @param {boolean} props.clearable Allows the autocomplete to be cleared. Defaults to true.
 * @param {boolean} props.searchable Allows the user to type to search in the autocomplete. Defaults to true.
 * @param {string} props.group_id Query companies for the provided group.
 * @param {boolean} props.loading A flag to set the autocomplete into a loading state for things such as fetching entries from database.
 * @param {string} props.color A custom color used for the input component of the autocomplete. Defaults to standard MUI color.
 */
const CompanySelect = (props: CompanySelectProps) => {
  const [optionList, setOptions] = React.useState<Array<CompanyExtended>>([]);
  const [selectedOptions, setSelectedOptions] = React.useState<
    Array<CompanyExtended> | CompanyExtended | null
  >(props.multi_select ? [] : null);

  // Define autocomplete options
  React.useEffect(() => {
    (async () => {
      const options: Array<CompanyExtended> = props.options
        ? // Companies supplied by the parent component.
          props.options
        : props.group_id
          ? // Companies for specific Group
            await getCompanyJoinGroups(props.group_id)
          : // All Companies for all Groups
            await getCompanyJoinGroups();
      setOptions(options);
    })();
  }, [props.group_id, props.options]);

  // Define selected options
  React.useEffect(() => {
    setSelectedOptions(
      props.multi_select
        ? Array.isArray(props.selected_options)
          ? props.selected_options
          : []
        : props.selected_options
          ? props.selected_options
          : null
    );
  }, [props.options]);

  return (
    <Autocomplete
      id="company-select"
      fullWidth
      disabled={props.disabled}
      loading={props.loading}
      limitTags={props.tag_limit}
      disableClearable={props.clearable === false}
      options={optionList}
      groupBy={
        props.group_by
          ? (option: CompanyExtended) =>
              props.group_by ? resolveObjectFromString(props.group_by, option) : ''
          : undefined
      }
      value={selectedOptions}
      getOptionLabel={(option: CompanyExtended) => {
        return option.name;
      }}
      disableCloseOnSelect={props.multi_select}
      isOptionEqualToValue={(option: CompanyExtended, value: CompanyExtended) =>
        option.id === value.id && option.group.id === value.group.id
      }
      multiple={props.multi_select}
      noOptionsText={`No ${company_label.many.toLowerCase()}`}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label={props.input_label ?? company_label.one}
          variant="standard"
          inputProps={{ ...params.inputProps, readOnly: props.searchable === false }}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          sx={{
            input: { color: props.color ? props.color : 'currentcolor' },
            '& .MuiSvgIcon-root': {
              color: props.color ? props.color : 'currentcolor'
            }
          }}
        />
      )}
      onChange={(
        event: React.SyntheticEvent<Element, Event>,
        value: Array<CompanyExtended> | CompanyExtended | null
      ) => {
        setSelectedOptions(value);
        props.handleChangeCompanies && props.handleChangeCompanies(value);
      }}
    />
  );
};

export default CompanySelect;
