import React from 'react';
import { Tooltip, IconButton, Box, Typography } from '@mui/material';
import { GridToolbarContainer, GridToolbarQuickFilter, GridToolbarExport } from '@mui/x-data-grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import { QualitativeMetricRecordGridRow } from './MetricRecordGridQualitative';

export const MetricRecordGridQualitativeToolbar = ({
  metric_record_rows
}: {
  metric_record_rows: Array<QualitativeMetricRecordGridRow>;
}) => {
  const completed_responses: number = metric_record_rows.filter(
    (row: QualitativeMetricRecordGridRow) => {
      return row.value;
    }
  ).length;
  return (
    <>
      <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
        <Typography>{`${completed_responses}/${metric_record_rows.length} Completed Responses`}</Typography>
        <AnnouncementOutlinedIcon />
      </Box>
      <GridToolbarContainer>
        <GridToolbarQuickFilter style={{ flex: 1 }} />
        <Tooltip title="Export Metric Records to device">
          <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        </Tooltip>
        <Tooltip title="Learn more">
          <span>
            <IconButton
              aria-label="help"
              size="small"
              color="primary"
              sx={{ marginLeft: 'auto' }}
              onClick={() => {
                // Open assistance menu / bot here.
              }}
            >
              <HelpOutlineIcon />
            </IconButton>
          </span>
        </Tooltip>
      </GridToolbarContainer>
    </>
  );
};
