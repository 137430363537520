import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import React from 'react';
import { auth } from '../../../lib/google/firebase';
import {
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  multiFactor
} from 'firebase/auth';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';

const EnrollMfa = ({
  setAuthenticated,
  closeLogin
}: {
  setAuthenticated: (authenticated: boolean) => void;
  closeLogin: () => void;
}) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [enrollConfirmed, setEnrollConfirmed] = React.useState(false);
  const [code, setCode] = React.useState('');
  const [validCode, setValidCode] = React.useState(false);
  const [verificationId, setVerificationId] = React.useState('');
  const [userNumber, setUserNumber] = React.useState('');

  const handleCancel = () => {
    auth.signOut();
    closeLogin();
  };

  const handleCodeSend = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const recaptcha_verifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible'
    });
    try {
      (async () => {
        try {
          const phone_auth_provider = new PhoneAuthProvider(auth);
          if (auth.currentUser != null) {
            const phone_info_options = await multiFactor(auth.currentUser)
              .getSession()
              .then((multiFactorSession) => {
                return {
                  phoneNumber: userNumber,
                  session: multiFactorSession
                };
              })
              .catch((error) => {
                throw error;
              });
            setEnrollConfirmed(true);
            await phone_auth_provider
              .verifyPhoneNumber(phone_info_options, recaptcha_verifier)
              .then(function (verificationId) {
                setVerificationId(verificationId);
              })
              .catch((err) => {
                if (err.code.includes('auth/requires-recent-login')) {
                  const tracking_id: string = uuidv4();
                  log(
                    'error',
                    new MetadataError(err, { 'auth.currentUser': auth.currentUser }, tracking_id)
                  );
                  setFeedbackData({
                    message: `Login wait time expired for Two-Factor Enrollment, please sign in again to register a phone number. Tracking ID: ${tracking_id}`,
                    state: true,
                    type: 'error'
                  });
                } else if (err.message === 'reCAPTCHA has already been rendered in this element') {
                  log(
                    'error',
                    new MetadataError(err, { 'auth.currentUser': auth.currentUser }, uuidv4())
                  );
                  return;
                } else if (err.message.includes('auth/too-many-requests')) {
                  const tracking_id: string = uuidv4();
                  log(
                    'error',
                    new MetadataError(err, { 'auth.currentUser': auth.currentUser }, tracking_id)
                  );
                  setFeedbackData({
                    message: `Too many requests attempted, please try again later. Tracking ID: ${tracking_id}`,
                    state: true,
                    type: 'error'
                  });
                } else {
                  const tracking_id: string = uuidv4();
                  log(
                    'error',
                    new MetadataError(err, { 'auth.currentUser': auth.currentUser }, tracking_id)
                  );
                  setFeedbackData({
                    message: `${err.message} Tracking ID: ${tracking_id}`,
                    state: true,
                    type: 'error'
                  });
                }
                recaptcha_verifier.clear();
                auth.signOut();
              });
          }
        } catch (error: unknown) {
          throw new Error(
            error instanceof Error
              ? error.message
              : 'Error: EnrollMfa failed on an unknown error while calling handleCodeSend.'
          );
        }
      })().catch((error) => {
        throw new Error(error);
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: EnrollMfa failed on an unknown error while calling handleCodeSend.',
          { 'auth.currentUser': auth.currentUser },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Failed to send verification code. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
      recaptcha_verifier.clear();
    }
  };

  const handleNumberRegistration = () => {
    try {
      if (auth.currentUser != null) {
        const cred = PhoneAuthProvider.credential(verificationId, code);
        const multi_factor_assertion = PhoneMultiFactorGenerator.assertion(cred);
        multiFactor(auth.currentUser).enroll(multi_factor_assertion, 'My personal phone number');
        setFeedbackData({
          message:
            'Number has been succesfully verified, please note that this number will be used for verification on all future logins to this platform.',
          state: true,
          type: 'success'
        });
        setAuthenticated(true);
        closeLogin();
      } else {
        throw new Error('Could not obtain current users credentials, please try again');
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: EnrollMfa failed on an unknown error while calling handleNumberRegistration.',
          { 'auth.currentUser': auth.currentUser },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Failed to register MFA phone number: Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  React.useEffect(() => {
    if (auth.currentUser?.phoneNumber) {
      setUserNumber(auth.currentUser.phoneNumber);
    }
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      setValidCode(code.length > 0);
    }, 250);
    return () => {
      null;
    };
  }, [code]);

  return (
    <Box>
      <DialogTitle>Multi Factor Enrollment</DialogTitle>
      {!enrollConfirmed && (
        <Box>
          <DialogContentText sx={{ px: 3, py: 1 }}>
            Multifactor is required on this account, an SMS will been sent to the number{' '}
            <b>
              {userNumber.slice(0, -4).replace(/./g, '*')}
              {userNumber.slice(-4)}
            </b>{' '}
            which was registered with your account.
          </DialogContentText>
          <DialogContentText sx={{ px: 3, py: 1, mb: 4 }}>
            If this is incorrect please contact Energy Partners, otherwise please click confirm
            below.
          </DialogContentText>
          <DialogActions sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              onClick={handleCodeSend}
              id="send-code-button"
              variant="contained"
              sx={{ minWidth: 150 }}
            >
              Confirm
            </Button>
            <Button
              onClick={handleCancel}
              id="send-code-button"
              variant="contained"
              sx={{ minWidth: 150 }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Box>
      )}
      {enrollConfirmed && (
        <>
          <DialogContentText sx={{ px: 3, py: 1 }}>
            Please enter the code sent to the phone number being verified.
          </DialogContentText>
          <form>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Verification Code"
                type="text"
                fullWidth
                variant="outlined"
                sx={{ my: 4 }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setCode(event.target.value)
                }
              />
            </DialogContent>
            <Box textAlign={'center'}>
              <Button
                data-testid="verify-button"
                onClick={handleNumberRegistration}
                disabled={!validCode}
                variant="contained"
                sx={{ minWidth: '200px' }}
                type="submit"
              >
                Verify
              </Button>
            </Box>
          </form>
        </>
      )}
    </Box>
  );
};

export default EnrollMfa;
