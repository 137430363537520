import React from 'react';
import { Box, Button, DialogContentText, DialogTitle, Typography } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { User, sendEmailVerification } from 'firebase/auth';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';

const VerifyEmail = ({
  sign_in_user,
  verify_timeout,
  returnToSignIn,
  resetVerifyTimeout
}: {
  sign_in_user: User | null;
  verify_timeout: number;
  returnToSignIn: () => void;
  resetVerifyTimeout: () => void;
}) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const resendEmailVerification = () => {
    try {
      if (sign_in_user && verify_timeout === 0) {
        sendEmailVerification(sign_in_user).catch((err) => {
          const tracking_id: string = uuidv4();
          log(
            'error',
            new MetadataError(
              err,
              {
                sign_in_user: sign_in_user,
                verify_timeout: verify_timeout
              },
              tracking_id
            )
          );
          setFeedbackData({
            message: `${err.message}. Tracking ID: ${tracking_id}`,
            state: true,
            type: 'error'
          });
        });
        resetVerifyTimeout();
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: VerifyEmail failed on an unknown error while calling resendEmailVerification.',
          {
            sign_in_user: sign_in_user,
            verify_timeout: verify_timeout
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Failed to resend email verification. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <MailOutlineIcon sx={{ fontSize: '1.7rem' }} />
        <DialogTitle>Check your Inbox</DialogTitle>
      </Box>
      <DialogContentText sx={{ my: 4 }}>
        Account Verification link sent to Email Address. Sign in again after clicking the link to
        confirm your Account.
      </DialogContentText>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 6, gap: 2 }}>
        <Button
          disabled={verify_timeout > 0}
          variant="contained"
          sx={{ backgroundColor: '#053d5d' }}
          id="resend-button"
          onClick={() => resendEmailVerification()}
        >
          Resend Email {verify_timeout > 0 ? verify_timeout : ''}
        </Button>
        <Button variant="outlined" id="back-button" onClick={() => returnToSignIn()}>
          Return to Sign In
        </Button>
      </Box>

      <Typography sx={{ textAlign: 'center', color: '#053d5d', my: 2, fontSize: '0.9rem' }}>
        Contact us if you have any questions
      </Typography>
    </Box>
  );
};

export default VerifyEmail;
