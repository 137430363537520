import { Sector } from '@esg/esg-global-types';
import {
  CollectionReference,
  Query,
  QuerySnapshot,
  collection,
  doc,
  getDocs,
  query,
  where
} from 'firebase/firestore';
import { db } from '../google/firebase';

/**
 * Function to query all sector documents from master list
 * @returns {Array<Sector>}
 */
export const getSectorMasterList = async () => {
  const sector_collection: CollectionReference = collection(db, 'sector_master_list');
  const sectors_query: Query = query(sector_collection, where('deleted', '==', null));
  try {
    const sector_docs: QuerySnapshot = await getDocs(sectors_query);
    const sectors: Array<Sector> = sector_docs.docs.map((sector) => {
      return {
        id: sector.id,
        deleted: sector.data().deleted,
        name: sector.data().name
      };
    });
    return sectors;
  } catch (err) {
    const error = `Error while getting Sectors from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        sector_collection: sector_collection,
        sectors_query: sectors_query
      }
    })}`;
    throw new Error(`Error: getSectorMasterList: ${JSON.stringify(error)}.`);
  }
};

export const getSectors = async (group_id: string, company_id: string) => {
  const sectors_collection: CollectionReference = collection(
    doc(db, `/groups/${group_id}/companies`, company_id),
    'sectors'
  );
  const sectors_query: Query = query(sectors_collection, where('deleted', '==', null));
  try {
    const sector_docs: QuerySnapshot = await getDocs(sectors_query);
    const sectors: Array<Sector> = sector_docs.docs.map((sector) => {
      return {
        id: sector.id,
        deleted: sector.data().deleted,
        name: sector.data().name
      };
    });
    return sectors;
  } catch (err) {
    const error = `Error while getting Sectors from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        sectors_collection: sectors_collection,
        sectors: sectors_query
      }
    })}`;
    throw new Error(`Error: getSectors: ${JSON.stringify(error)}.`);
  }
};
