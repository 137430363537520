import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { UserContext } from '../../context/UserContext';
import { useSearchParams } from 'react-router-dom';
import { Company, Group, User } from '@esg/esg-global-types';
import AccessDenied from '../shared/AccessDenied';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { getAdminGroups } from '../../util/user_access';
import DomainIcon from '@mui/icons-material/Domain';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { getGroupCompanies, updateGroup } from '../../lib/app/group';
import PanelCompanies from '../../components/group_management/companies/PanelCompanies';
import { Drawer } from '@mui/material';
import ConfigEditWidget, { EditInput } from '../../components/configuration/ConfigEditWidget';
import { uuidv4 } from '@firebase/util';
import { log } from '../../util/log';
import { MetadataError } from '@ep/error-handling';
import { FeedbackSnackbarContext } from '../../context/FeedbackSnackbarContext';
import { CompanyExtended } from '../../lib/app/company';

/**
 * View for displaying the details and companies of a chosen Group
 * @param {void} addGroupCompany Function to add a newly created company in memory to be displayed within the app
 * @param {void} handleGroupChange Function to change the Group context of the app in the parent component
 * @param {void} handleCompanyChange Function to change the Company context of the app in the parent component
 * @returns {JSX.Element}
 */
const GroupView = ({
  addGroupCompany,
  handleGroupChange,
  handleCompanyChange
}: {
  addGroupCompany: (new_company: CompanyExtended) => void;
  handleGroupChange: (group: Group | null) => void;
  handleCompanyChange: (company: Company | null) => void;
}) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [group, setGroup] = React.useState<Group | null>();
  const [displayWidgetPanelEdit, setDisplayWidgetPanelEdit] = React.useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  const user_info: User | null = React.useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const admin_groups: Array<string> = user_info ? getAdminGroups(user_info) : [];

  const edit_inputs: Array<EditInput> = [
    {
      id: 'name',
      type: 'text',
      label: 'Name',
      defaultValue: group?.name
    }
  ];

  const handleEditClick = (): void => {
    setDisplayWidgetPanelEdit(true);
  };

  const handleUpdateGroup = async (updated_group: Group): Promise<void> => {
    try {
      // Update row in database.
      await updateGroup(updated_group);
      // Update row in memory.
      setGroup(updated_group);
      setDisplayWidgetPanelEdit(false);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: GroupView failed on an unknown error while calling handleUpdateGroup.',
          {
            updated_group: updated_group
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update Group. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  React.useEffect(() => {
    let group_found = false;
    if (location.state) {
      setGroup(location.state);
      setSearchParams({ group: location.state.id });
    } else {
      (async () => {
        const groups: Array<Group> = await getGroupCompanies();
        if (groups.length === 0) {
          navigate('/settings/group-management');
        }
        for (let i = 0; i < groups.length; i++) {
          if (groups[i].id === searchParams.get('group')) {
            setGroup(groups[i]);
            group_found = true;
          }
        }
        if (!group_found) {
          navigate('/settings/group-management');
        }
      })();
    }
  }, []);

  const handleButtonClickBack = () => {
    navigate('/settings/group-management');
  };

  return user_info === null || !group ? (
    <LoadingScreen />
  ) : user_info.super_admin || admin_groups.includes(group.id) ? (
    <>
      {/* Side Widget Panel */}
      <Drawer
        anchor={'right'}
        open={displayWidgetPanelEdit}
        onClose={() => setDisplayWidgetPanelEdit(false)}
        PaperProps={{ style: { width: '30%', padding: '1.5rem' } }}
      >
        <ConfigEditWidget
          edit_label="Group"
          edit_icon={<DomainIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
          handleClose={() => setDisplayWidgetPanelEdit(false)}
          edit_entity={group}
          edit_function_inputs={edit_inputs}
          handleEditInput={(input_id: string, value: string) =>
            group && setGroup({ ...group, [input_id]: value })
          }
          confirmEditFunction={(updated_group: Group) => handleUpdateGroup(updated_group)}
        />
      </Drawer>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              color: 'grey',
              mb: '0.5rem'
            }}
          >
            <IconButton
              size="large"
              aria-label="close"
              color="primary"
              onClick={handleButtonClickBack}
            >
              <Tooltip title="Back to groups">
                <ArrowBackIosNewIcon fontSize="large" />
              </Tooltip>
            </IconButton>
            <Box sx={{ mx: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <DomainIcon fontSize="large" />
                <Typography variant="h4" color="inherit" align="left">
                  {group.name}
                </Typography>
              </Box>

              <Typography variant="subtitle2" color="grey" align="left" component="div">
                <b>Last Modified:</b> {moment().format('dddd, MMMM Do YYYY, hh:mm:ss')}
              </Typography>
            </Box>
            {user_info && user_info.super_admin && (
              <IconButton onClick={handleEditClick}>
                <EditIcon fontSize="large" />
              </IconButton>
            )}
          </Box>
          <Box sx={{ px: 8 }}>
            <Typography sx={{ textAlign: 'left', fontSize: '1.3rem', my: 6 }}>Companies</Typography>
            <PanelCompanies
              group={group}
              addGroupCompany={addGroupCompany}
              handleCompanyChange={handleCompanyChange}
              handleGroupChange={handleGroupChange}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  ) : (
    <AccessDenied />
  );
};

export default GroupView;
