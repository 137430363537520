import {
  collection,
  getDocs,
  query,
  CollectionReference,
  Query,
  QuerySnapshot,
  where,
  DocumentData
} from 'firebase/firestore';
import { refCompanyDoc } from '../app/company';
import { Country, EntityLabel } from '@esg/esg-global-types';
import { db } from '../google/firebase';

// Singular and plural label for model entity.
export const country_label: EntityLabel = {
  one: 'Country',
  many: 'Countries'
};

/**
 * Get all country documents for current portal company
 * @param {Group} group_id Group object of current portal company
 * @param {string} company_id id of current portal company to reference firestore documents
 * @returns {Array<Country>}
 */
export const getCountries = async (group_id: string, company_id: string) => {
  const countries_collection: CollectionReference = collection(
    refCompanyDoc(group_id, company_id),
    'countries'
  );
  const countries_query: Query = query(countries_collection, where('deleted', '==', null));
  try {
    const country_docs: QuerySnapshot = await getDocs(countries_query);
    const countries: Array<Country> = country_docs.docs.map((country) => {
      const country_data: DocumentData = country.data();
      return {
        id: country.id,
        deleted: country_data.deleted,
        name: country_data.name,
        continent: country_data.continent,
        code: country_data.code,
        phone_code: country_data.phone_code,
        phone_regex: country_data.phone_regex
      };
    });
    return countries;
  } catch (err) {
    const error = `Error while getting Countries from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        countries_collection: countries_collection,
        countries_query: countries_query
      }
    })}`;
    throw new Error(`Error: getCountries: ${JSON.stringify(error)}.`);
  }
};

/**
 * Get all country documents from master list
 * @returns {Array<Country>}
 */
export const getCountryMasterList = async () => {
  const country_collection: CollectionReference = collection(db, 'country_master_list');
  const countries_query: Query = query(country_collection, where('deleted', '==', null));
  try {
    const country_docs: QuerySnapshot = await getDocs(countries_query);
    const countries: Array<Country> = country_docs.docs.map((country) => {
      const country_data: DocumentData = country.data();
      return {
        id: country.id,
        deleted: country_data.deleted,
        name: country_data.name,
        code: country_data.code,
        continent: country_data.continent,
        phone_code: country_data.phone_code,
        phone_regex: country_data.phone_regex
      };
    });
    return countries;
  } catch (err) {
    const error = `Error while getting Countries from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        sector_collection: country_collection,
        sectors_query: countries_query
      }
    })}`;
    throw new Error(`Error: getCountryMasterList: ${JSON.stringify(error)}.`);
  }
};

/**
 * Get the relevant Country object by international phone number from a list of countries.
 * @param {string} phone_number Phone number provided in an internation format starting with + followed by the country dialing code.
 * @param {Array<Country>} country_list A list of Country objects to validate the phone number against.
 * @returns {Country | null} Null is returned when no Country object was found within the provided list.
 */
export const getCountryFromListByPhone = (
  phone_number: string,
  country_list: Array<Country>
): Country | null => {
  const filtered_countries: Array<Country> =
    country_list && country_list.length > 1
      ? country_list.filter((country: Country) => {
          return country && country.phone_code
            ? phone_number.startsWith('+' + country.phone_code)
            : false;
        })
      : [];
  return filtered_countries.length === 1 ? filtered_countries[0] : null;
};

/**
 * Get a Flag CDN URI to a flag icon.
 * @param {string} country_code ISO 3166 country code.
 * @returns {string}
 */
export const generateFlagURI = (country_code: string): string =>
  `https://flagcdn.com/${country_code.toLowerCase()}.svg`;
