import {
  CollectionReference,
  DocumentData,
  collection,
  doc,
  onSnapshot,
  setDoc
} from 'firebase/firestore';
import { db } from '../google/firebase';
import { refCompanyDoc } from '../app/company';
import { ImportMetaData, MetricRecord } from '@esg/esg-global-types';
import { getMetricRecords } from './metric_record';
import { BatchWrite, processBatchWrites, readFirestoreDocs } from '../app/db_util';
import { FirestoreQueryParam } from '../../@types/shared';

/**
 * Function to query all import documents for current portal company
 * @param {Group} group Group object of current portal company
 * @param {string} company_id id of current portal company to reference firestore documents
 * @param {string} reporting_period_id optional id of reporting period to filter query by
 * @returns {Array<ImportMetaData>}
 */
export const getImports = async (
  group_id: string,
  company_id: string,
  level: 'group' | 'period',
  filter_id: string,
  external_company_id?: string | null
) => {
  const imports_collection_path = `groups/${group_id}/companies/${company_id}/imports`;
  let id_fk = null;
  if (filter_id) {
    if (level == 'period') {
      id_fk = doc(db, `groups/${group_id}/companies/${company_id}/reporting_periods/${filter_id}`);
    } else {
      id_fk = doc(
        db,
        `groups/${group_id}/companies/${company_id}/reporting_period_groups/${filter_id}`
      );
    }
  }
  let external_company_fk = null;
  if (external_company_id) {
    external_company_fk = doc(
      collection(refCompanyDoc(group_id, company_id), 'external_companies'),
      external_company_id
    );
  }
  const firestore_query_params_list: Array<FirestoreQueryParam> =
    id_fk && level == 'period'
      ? [
          { field_name: 'reporting_period', operator: '==', value: id_fk },
          { field_name: 'external_company', operator: '==', value: external_company_fk },
          { field_name: 'deleted', operator: '==', value: null }
        ]
      : [
          { field_name: 'reporting_period_group', operator: '==', value: id_fk },
          { field_name: 'external_company', operator: '==', value: external_company_fk },
          { field_name: 'deleted', operator: '==', value: null }
        ];

  try {
    const import_docs = await readFirestoreDocs(
      imports_collection_path,
      firestore_query_params_list
    );
    // const import_docs: QuerySnapshot = await getDocs(imports_query);
    const imports: Array<ImportMetaData> = import_docs.docs.map((import_doc) => {
      return {
        id: import_doc.id,
        file_name: import_doc.data().file_name,
        errors: import_doc.data().errors,
        reporting_period_group: import_doc.data().reporting_period_group,
        reporting_period: import_doc.data().reporting_period,
        external_company: import_doc.data().external_company,
        valid_metric_records: import_doc.data().valid_metric_records,
        invalid_metric_records: import_doc.data().invalid_metric_records,
        null_value_records: import_doc.data().null_value_records,
        user: import_doc.data().user,
        created: import_doc.data().created.toDate(),
        deleted: import_doc.data().deleted,
        pending: import_doc.data().pending
      };
    });
    return imports;
  } catch (err) {
    const error = `Error while getting Imports from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        divisions_collection_path: imports_collection_path,
        firestore_query_params_list: firestore_query_params_list
      }
    })}`;
    throw new Error(`Error: getImports: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to create Import meta data in firestore
 * @param {string} group_id ID of Group which Import is being created for
 * @param {string} company_id ID of Company which Import is being created for
 * @param {string} import_id ID of Import to create in Firestore
 * @returns {DocumentReference}
 */
export const createPendingImport = async (
  group_id: string,
  company_id: string,
  import_id: string,
  level: 'group' | 'period',
  doc_id: string,
  user: string,
  file_name: string
) => {
  const company_doc = refCompanyDoc(group_id, company_id);
  const imports_collection: CollectionReference = collection(company_doc, 'imports');
  const document_ref =
    level === 'period'
      ? doc(collection(company_doc, 'reporting_periods'), doc_id)
      : doc(collection(company_doc, 'reporting_period_groups'), doc_id);
  const new_import_doc_template = {
    deleted: null,
    pending: true,
    created: new Date(),
    user: user,
    file_name: file_name
  };

  const new_import =
    level === 'period'
      ? await setDoc(doc(imports_collection, import_id), {
          ...new_import_doc_template,
          reporting_period: document_ref
        })
      : await setDoc(doc(imports_collection, import_id), {
          ...new_import_doc_template,
          reporting_period_group: document_ref
        });
  return new_import;
};

/**
 * Function to create listener in client for import doc
 * @param group_id Group to create import listener for
 * @param company_id Company to create import listener for
 * @param import_id ID of import document to listen for changes on
 */
export const generateImportListener = async (
  group_id: string,
  company_id: string,
  import_id: string,
  eventHandler: (doc: DocumentData | undefined) => void
) => {
  const unsubscribe = onSnapshot(
    doc(refCompanyDoc(group_id, company_id), 'imports', import_id),
    (doc) => {
      if (!doc.data()?.pending) {
        eventHandler({ id: doc.id, ...doc.data() });
        unsubscribe();
      }
    }
  );
  unsubscribe;
};

/**
 * Function to Delete all Metric Records for an import
 */
export const deleteImportMetricRecords = async (
  group_id: string,
  company_id: string,
  import_id: string
) => {
  const import_doc = doc(collection(refCompanyDoc(group_id, company_id), 'imports'), import_id);
  const delete_writes: Array<BatchWrite> = [];
  const metric_records: Array<MetricRecord> = await getMetricRecords(group_id, company_id, [
    {
      field_name: 'import_id',
      operator: '==',
      value: import_id
    }
  ]);
  for (const metric_record of metric_records) {
    const metric_record_ref = doc(
      collection(db, `/groups/${group_id}/companies/${company_id}/metric_records`),
      metric_record.id
    );
    delete_writes.push({ reference: metric_record_ref, operation: 'delete', data: {} });
  }
  delete_writes.push({ reference: import_doc, operation: 'delete', data: {} });
  await processBatchWrites(delete_writes);
};
