import React from 'react';
import { Tooltip, IconButton, Button } from '@mui/material';
import { GridToolbarContainer, GridToolbarQuickFilter, GridToolbarExport } from '@mui/x-data-grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';

interface PanelMetricsToolbarProps {
  handleCreate: () => void;
  buttonDisabled: boolean;
}

export const PanelMetricsToolbar = ({ handleCreate, buttonDisabled }: PanelMetricsToolbarProps) => {
  return (
    <>
      <GridToolbarContainer>
        <GridToolbarQuickFilter style={{ flex: 1 }} />
        <Tooltip title="Export Metrics to device">
          <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        </Tooltip>
        <Tooltip title="Learn more">
          <IconButton
            aria-label="help"
            size="small"
            color="primary"
            sx={{ marginLeft: 'auto' }}
            onClick={() => {
              // Open assistance menu / bot here.
            }}
          >
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Add new Metric">
          <Button
            variant="contained"
            size="large"
            startIcon={<AddIcon />}
            onClick={handleCreate}
            disabled={buttonDisabled}
          >
            Create
          </Button>
        </Tooltip>
      </GridToolbarContainer>
    </>
  );
};
