import * as React from 'react';

type FeedbackData = {
  message: string;
  state: boolean;
  type: 'error' | 'warning' | 'info' | 'success';
};

interface ContextProps {
  feedbackData: FeedbackData;
  setFeedbackData: (feedbackData: FeedbackData) => void;
}

export const FeedbackSnackbarContext = React.createContext<ContextProps>({
  feedbackData: { message: '', state: false, type: 'info' },
  setFeedbackData: () => null
});

const FeedbackSnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  const [feedbackData, setFeedbackData] = React.useState<FeedbackData>({
    message: '',
    state: false,
    type: 'info'
  });

  const value = {
    feedbackData,
    setFeedbackData
  };

  return (
    <FeedbackSnackbarContext.Provider value={value}>{children}</FeedbackSnackbarContext.Provider>
  );
};

export default FeedbackSnackbarProvider;
