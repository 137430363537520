import { LoadingButton } from '@mui/lab';
import { Box, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import { finishMfaSignIn } from '../../../lib/google/firebase';
import { FirebaseError, uuidv4 } from '@firebase/util';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { MetadataError } from '@ep/error-handling';
import { log } from '../../../util/log';

const default_mfa_error = 'Unknown Error Occured';

const VerifyMfa = ({
  setAuthenticated,
  closeLogin
}: {
  setAuthenticated: (authenticated: boolean) => void;
  closeLogin: () => void;
}) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [code, setCode] = React.useState('');
  const [validCode, setValidCode] = React.useState(false);
  const [loginError, setLoginError] = React.useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = React.useState(default_mfa_error);
  const [verifyLoad, setVerifyLoad] = React.useState(false);

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  const handleMfaError = (state: boolean, message: string) => {
    setLoginError(state);
    setLoginErrorMessage(message);
  };

  const handleClose = () => {
    closeLogin();
    setVerifyLoad(false);
  };

  const handleMfaVerification = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      setVerifyLoad(true);
      await finishMfaSignIn(code);
      setCode('');
      setAuthenticated(true);
      handleClose();
      if (loginError === true) handleMfaError(false, default_mfa_error);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      if (err instanceof FirebaseError) {
        if (err.code == 'auth/invalid-verification-code') {
          handleMfaError(true, 'Incorrect verification code, please try again!');
        } else if (err.code == 'auth/too-many-requests') {
          handleMfaError(true, 'Too many login requests, please try again later');
        } else {
          log(
            'error',
            new MetadataError(
              err.message,
              {
                firebase_error_code: err.code,
                code: code
              },
              tracking_id
            )
          );
          setFeedbackData({
            message: `${err.message}. Tracking ID: ${tracking_id}`,
            state: true,
            type: 'error'
          });
        }
      } else {
        log(
          'error',
          new MetadataError(
            err instanceof Error
              ? err.message
              : 'Error: AuditLogsGrid failed on an unknown error while calling fetchGroups.',
            err instanceof FirebaseError
              ? {
                  firebase_error_code: err.code,
                  code: code
                }
              : {
                  code: code
                },
            tracking_id
          )
        );
        setFeedbackData({
          message: `An unknown error has occured. Tracking ID: ${tracking_id}`,
          state: true,
          type: 'error'
        });
      }
      setVerifyLoad(false);
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      setValidCode(code.length > 0);
    }, 250);
    return () => {
      null;
    };
  }, [code]);

  return (
    <Box>
      <DialogTitle>Multi Factor Verification</DialogTitle>
      <DialogContentText sx={{ px: 3, py: 1 }}>
        Verification code has been sent to your registered number, please enter it below.
      </DialogContentText>
      <form>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="text"
            label="Verification Code"
            type="text"
            fullWidth
            variant="outlined"
            onChange={handleCodeChange}
            sx={{ my: 4 }}
          />
          {loginError && (
            <DialogContentText sx={{ color: 'red' }}>{loginErrorMessage}</DialogContentText>
          )}
        </DialogContent>
        <Box textAlign={'center'}>
          <LoadingButton
            loading={verifyLoad}
            data-testid="verify-button"
            onClick={handleMfaVerification}
            variant="contained"
            sx={{ minWidth: '200px' }}
            disabled={!validCode}
            type="submit"
          >
            Verify
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};

export default VerifyMfa;
