import React from 'react';
import {
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import { GridToolbarContainer, GridToolbarQuickFilter, GridToolbarExport } from '@mui/x-data-grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Group } from '@esg/esg-global-types';

const AuditLogsGridToolbar = ({
  groups,
  selected_group,
  handleChangeGroup
}: {
  groups: Array<Group>;
  selected_group: Group;
  handleChangeGroup: (group: Group) => void;
}) => {
  return (
    <>
      <GridToolbarContainer sx={{ my: 6 }}>
        <GridToolbarQuickFilter style={{ flex: 1 }} />
        <Tooltip title="Export audit logs to device">
          <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        </Tooltip>
        <Tooltip title="Learn more">
          <span>
            <IconButton
              aria-label="help"
              size="small"
              color="primary"
              sx={{ marginLeft: 'auto' }}
              onClick={() => {
                // Open assistance menu / bot here.
              }}
              disabled
            >
              <HelpOutlineIcon />
            </IconButton>
          </span>
        </Tooltip>
        <FormControl sx={{ flex: 0.3, mr: 2 }}>
          <InputLabel id={'metric-select'}>Group</InputLabel>
          <Select
            value={selected_group ? JSON.stringify(selected_group) : ''}
            onChange={(event: SelectChangeEvent) => {
              handleChangeGroup(JSON.parse(event.target.value));
            }}
            inputProps={{ 'aria-label': 'Without label' }}
            variant="outlined"
            sx={{ backgroundColor: 'white' }}
            label="Group"
          >
            {groups.map((group: Group) => {
              return (
                <MenuItem key={group.id} value={JSON.stringify(group)}>
                  {group.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </GridToolbarContainer>
    </>
  );
};

export default AuditLogsGridToolbar;
