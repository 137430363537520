import {
  collection,
  QuerySnapshot,
  doc,
  DocumentReference,
  updateDoc,
  QueryDocumentSnapshot,
  DocumentData,
  runTransaction,
  Transaction,
  DocumentSnapshot
} from 'firebase/firestore';
import { db } from '../google/firebase';
import { refCompanyDoc } from './company';
import { Standard, EntityLabel, MetricRecord, EmissionFactor } from '@esg/esg-global-types';
import { BatchWrite, processBatchWrites, readFirestoreDocs } from './db_util';
import { createAuditLog } from './audit';
import { auth } from '../google/firebase';
import { generateAuditLogData, generateAuditLogDoc } from './audit';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { FirestoreQueryParam } from '../../@types/shared';
import { validateMasterListComparison } from '../../util/validation';
import { getMetricRecords } from '../metric_capture/metric_record';
import { MetricExtended, getMetrics, refMetric } from '../metric_capture/metric';
import { getEmissionFactorsForMetrics } from './emission_factor';

export type StandardData = Omit<Standard, 'id' | 'reference'>;

export interface CachedStandard {
  [key: string]: Standard;
}

// Singular and plural label for model entity.
export const standard_label: EntityLabel = {
  one: 'Standard',
  many: 'Standards'
};

/**
 * Create Firestore database reference for either configured Standard or Master List Standard.
 * @param {string} id ID of object to reference.
 * @param {Group} group_id ID of configured group.
 * @param {string} company_id ID of configured company.
 * @returns {DocumentReference}
 */
export const refStandard = (
  id: string,
  group_id?: string,
  company_id?: string
): DocumentReference =>
  doc(
    collection(
      db,
      group_id && company_id
        ? `groups/${group_id}/companies/${company_id}/standards`
        : 'standard_master_list'
    ),
    id
  );

/**
 * Query standard documents for specified group and company.
 * @param {Group} group_id ID of configured group.
 * @param {string} company_id ID of configured company.
 * @param {boolean} is_quantitative retrieve only quantitative or only qualitative documents.
 * @returns {Promise<Array<Standard>>}
 */
export const getStandards = async (
  group_id: string,
  company_id: string,
  is_quantitative?: boolean,
  references?: Array<DocumentReference>
): Promise<Array<Standard>> => {
  const collection_path = `groups/${group_id}/companies/${company_id}/standards`;
  const query_params: Array<FirestoreQueryParam> = [
    { field_name: 'deleted', operator: '==', value: null }
  ];
  if (is_quantitative !== undefined)
    query_params.push({ field_name: 'is_quantitative', operator: '==', value: is_quantitative });
  if (references !== undefined && references.length > 0)
    query_params.push({ field_name: '__name__', operator: 'in', value: references });
  try {
    const standards_snapshot: QuerySnapshot = await readFirestoreDocs(
      collection_path,
      query_params
    );
    const standards: Array<Standard> = standards_snapshot.docs.map(
      (standard: QueryDocumentSnapshot) => {
        const standard_data: DocumentData = standard.data();
        return {
          id: standard.id,
          deleted: standard_data.deleted,
          name: standard_data.name,
          created: standard_data.created.toDate(),
          version: standard_data.version,
          category: standard_data.category,
          sector: standard_data.sector,
          is_quantitative: standard_data.is_quantitative,
          require_emission_factor: standard_data.require_emission_factor,
          require_site_level: standard_data.require_site_level,
          master_list_standard: standard_data.master_list_standard,
          reference: standard.ref
        };
      }
    );
    return standards;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/app/standard.ts failed on an unknown error while calling getStandards.',
      {
        collection_path: collection_path,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Query all unconfigured standard documents for current portal company.
 * @param {Group} group_id id of current portal company.
 * @param {string} company_id id of current portal company.
 * @param {boolean} is_quantitative retrieve only quantitative or only qualitative documents.
 * @returns {Array<Standard>}
 */
export const getUnconfiguredStandards = async (
  group_id: string,
  company_id: string,
  is_quantitative?: boolean
) => {
  try {
    const standard_master_list: Array<Standard> = await getStandardsMasterList(is_quantitative);
    const standards: Array<Standard> = await getStandards(group_id, company_id, is_quantitative);
    validateMasterListComparison(standard_master_list, standards);
    return standard_master_list.filter((standard_master) =>
      standards.every(
        (standard) =>
          !(standard.master_list_standard
            ? standard.master_list_standard.id.includes(standard_master.id)
            : '')
      )
    );
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/google/standard.ts failed on an unknown error while calling getUnconfiguredStandards.',
      {
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Query all standard documents from master list
 * @param {boolean} is_quantitative Query only quantitative or quantitative Standards from master list.
 * @returns {Promise<Array<Standard>>}
 */
export const getStandardsMasterList = async (
  is_quantitative?: boolean
): Promise<Array<Standard>> => {
  const collection_path = `standard_master_list`;
  const query_params: Array<FirestoreQueryParam> = [
    { field_name: 'deleted', operator: '==', value: null }
  ];
  if (is_quantitative !== undefined)
    query_params.push({ field_name: 'is_quantitative', operator: '==', value: is_quantitative });
  try {
    const standards_snapshot: QuerySnapshot = await readFirestoreDocs(
      collection_path,
      query_params
    );
    const standards: Array<Standard> = standards_snapshot.docs.map(
      (standard: QueryDocumentSnapshot) => {
        const standard_data: DocumentData = standard.data();
        return {
          id: standard.id,
          created: standard_data.created,
          deleted: standard_data.deleted,
          version: standard_data.version,
          name: standard_data.name,
          category: standard_data.category,
          sector: standard_data.sector,
          is_quantitative: standard_data.is_quantitative,
          require_emission_factor: standard_data.require_emission_factor,
          require_site_level: standard_data.require_site_level,
          reference: standard.ref
        };
      }
    );
    return standards;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/app/standard.ts failed on an unknown error while calling getStandardsMasterList.',
      {
        collection_path: collection_path,
        is_quantitative: is_quantitative
      },
      tracking_id
    );
  }
};

/**
 * Create Standards on company level from master list
 * @param { Array<Standard>} master_list_standards Master list standards to copy to company level
 * @param {string} group_id ID of Group to create standards for
 * @param {string} company_id ID of Company to create standards for
 * @returns {Promise<Array<BatchWrite>>}
 */
export const createStandardBatchFromMasterList = async (
  master_list_standards: Array<Standard>,
  group_id: string,
  company_id: string
): Promise<Array<BatchWrite>> => {
  try {
    if (!(group_id && company_id && master_list_standards.length > 0)) {
      throw new MetadataError('Required parameters missing.', {
        group_id: group_id,
        company_id: company_id,
        master_list_standards: master_list_standards
      });
    }
    const standard_writes: Array<BatchWrite> = [];
    const audit_log_batch_writes: Array<BatchWrite> = [];
    master_list_standards.forEach((master_list_standard: Standard) => {
      const standard_ref: DocumentReference = doc(
        collection(db, `/groups/${group_id}/companies/${company_id}/standards`)
      );
      const standard_data = {
        category: master_list_standard.category,
        created: new Date(),
        deleted: null,
        name: master_list_standard.name,
        sector: master_list_standard.sector,
        version: master_list_standard.version,
        is_quantitative: master_list_standard.is_quantitative,
        require_emission_factor: master_list_standard.require_emission_factor,
        require_site_level: master_list_standard.require_site_level,
        master_list_standard: doc(collection(db, 'standard_master_list'), master_list_standard.id)
      };
      standard_writes.push({ reference: standard_ref, operation: 'create', data: standard_data });

      const audit_log_ref: DocumentReference = generateAuditLogDoc(group_id);
      const audit_log_data = generateAuditLogData(
        master_list_standard.name,
        'create',
        '',
        standard_ref,
        auth.currentUser?.email
      );
      audit_log_batch_writes.push({
        reference: audit_log_ref,
        operation: 'create',
        data: audit_log_data
      });
    });
    await processBatchWrites(standard_writes).catch((error) => {
      throw new Error(error);
    });
    await processBatchWrites(audit_log_batch_writes).catch((error) => {
      throw new Error(error);
    });

    return standard_writes;
  } catch (err) {
    const error = `Error while creating Standards in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: createStandardBatchFromMasterList: ${JSON.stringify(error)}.`);
  }
};

/**
 * Delete company Standard
 * @param {string} group_id ID of Group to delete standard for
 * @param {string} company_id ID of Company to delete standard for
 * @param {string} standard_id ID of standard to delete
 * @returns {Promise<void>}
 */
export const deleteStandard = async (
  group_id: string,
  company_id: string,
  standard_id: string,
  standard_name: string
): Promise<void> => {
  try {
    if (group_id && company_id && standard_id) {
      const standard_doc = doc(
        collection(refCompanyDoc(group_id, company_id), `standards`),
        standard_id
      );
      await updateDoc(standard_doc, {
        deleted: new Date()
      });
      await createAuditLog(group_id, 'delete', standard_name, '', standard_doc);
    }
    return;
  } catch (err) {
    const error = `Error while deleting Standard from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: deleteStandard: ${JSON.stringify(error)}.`);
  }
};

/**
 * Soft delete a Standard and any related Metrics and Emission Factors in Firestore transaction
 * @param {string} group_id Group to delete standard from
 * @param {string} company_id Company to delete standard from
 * @param {string} standard_id ID of standard to delete
 * @param {string} standard_name Name of standard being deleted
 * @return {void}
 */
export const deleteStandardWithMetrics = async (
  group_id: string,
  company_id: string,
  standard_id: string,
  standard_name: string
): Promise<void> => {
  try {
    const standard_doc: DocumentReference = refStandard(standard_id, group_id, company_id);
    const standard_metrics: Array<MetricExtended> = await getMetrics(false, group_id, company_id, [
      standard_id
    ]);
    const emission_factors: Array<EmissionFactor> = await getEmissionFactorsForMetrics(
      false,
      standard_metrics,
      group_id,
      company_id
    );

    await runTransaction(db, async (transaction: Transaction) => {
      const standard_snapshot: DocumentSnapshot = await transaction.get(standard_doc);
      if (!standard_snapshot.exists()) {
        throw 'Could not find Standard document to delete';
      }
      transaction.update(standard_doc, { deleted: new Date() });
      standard_metrics.forEach((metric: MetricExtended) => {
        transaction.update(refMetric(metric.id, metric.metric_group.id, group_id, company_id), {
          deleted: new Date()
        });
      });
      emission_factors.forEach((emission_factor: EmissionFactor) => {
        emission_factor.ref && transaction.update(emission_factor.ref, { deleted: new Date() });
      });
    });
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/standard.ts failed on an unknown error while calling deleteStandardWithMetrics.',
      {
        group_id: group_id,
        company_id: company_id,
        standard_id: standard_id,
        standard_name: standard_name
      },
      tracking_id
    );
  }
};

/**
 * Check if a Standard can be safely deleted
 * @param {string} group_id ID of Group to check Standard for
 * @param {string} company_id ID of Company to check Standard for
 * @param {string} standard_id ID of standard to check
 * @returns {Promise<boolean>}
 */
export const allowDeleteStandard = async (
  group_id: string,
  company_id: string,
  standard_id: string
): Promise<boolean> => {
  try {
    const query_params: Array<FirestoreQueryParam> = [
      {
        field_name: 'standard',
        operator: '==',
        value: doc(collection(refCompanyDoc(group_id, company_id), `standards`), standard_id)
      }
    ];
    const metric_records: Array<MetricRecord> = await getMetricRecords(
      group_id,
      company_id,
      query_params
    );
    const allow_delete: boolean = metric_records.length === 0;
    return allow_delete;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/app/standard.ts failed on an unknown error while calling allowDeleteStandard.',
      {
        standard_id: standard_id,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};
