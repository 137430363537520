import React from 'react';
import Typography from '@mui/material/Typography';
import DefaultContent from './DefaultContent';

interface DefaultViewPropTypes {
  title: string;
}

const DefaultView = ({ title }: DefaultViewPropTypes) => {
  return (
    <>
      <Typography variant="h6" component="h6">
        {title}
      </Typography>
      <br />
      <DefaultContent />
    </>
  );
};

export default DefaultView;
