import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

interface InputHandlerFunc {
  (event: React.ChangeEvent<HTMLInputElement>): void;
}

const ContactFormTextInput = ({
  id,
  type,
  input_label,
  handleInputChange,
  options,
  primary_color
}: {
  id: string;
  type?: string;
  value?: string;
  input_label?: string;
  handleInputChange?: InputHandlerFunc;
  options?: object;
  primary_color?: string;
}) => {
  return (
    <Box>
      <TextField
        id={id}
        type={type}
        variant="standard"
        label={input_label}
        onChange={handleInputChange}
        {...options}
        fullWidth
        sx={{
          '& .MuiFormLabel-root': {
            color: primary_color
          },
          '& .MuiInputBase-root': {
            color: primary_color
          }
        }}
      />
    </Box>
  );
};

export default ContactFormTextInput;
