import { Box } from '@mui/material';
import React from 'react';
import ConfigurationWrapper from '../../components/shared/ConfigurationWrapper';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PanelGeographical from '../../components/configuration/geographical/PanelGeographical';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';

const ConfigurationGeographical = () => {
  return (
    <Box>
      <ConfigurationWrapper
        title="Geographical"
        icon={<LocationOnOutlinedIcon fontSize="large" />}
        previous={{
          route: '/configuration/personalisation',
          label: 'Personalisation',
          icon: <BrushOutlinedIcon />
        }}
        next={{
          route: '/configuration/organisation',
          label: 'Organisation',
          icon: <AccountTreeOutlinedIcon />
        }}
      >
        <PanelGeographical />
      </ConfigurationWrapper>
    </Box>
  );
};

export default ConfigurationGeographical;
