import React from 'react';
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  Box,
  Checkbox,
  TextField
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  MetricExtended,
  getUnconfiguredMetrics,
  metric_label,
  getMetrics
} from '../../../../lib/metric_capture/metric';
import { resolveObjectFromString } from '../../../../util/validation';

interface MetricSelectProps {
  handleChangeMetrics?: (metrics: Array<MetricExtended> | MetricExtended | null) => void;
  options?: Array<MetricExtended>;
  selected_options?: Array<MetricExtended> | MetricExtended | null;
  disabled?: boolean;
  input_label?: string;
  group_by?: 'metric_group.name' | 'standard.name' | 'standard.version' | 'standard.category';
  multi_select?: boolean;
  tag_limit?: number;
  group_id?: string;
  company_id?: string;
  unconfigured_only?: boolean;
  standard_ids?: Array<string>;
  master_list?: boolean;
  loading?: boolean;
}

// Checkbox icons
const check_icon_blank = <CheckBoxOutlineBlankIcon fontSize="small" />;
const check_icon_filled = <CheckBoxIcon fontSize="small" />;

/**
 * Metric selection auto-complete component that handles a variety of built-in scenarios.
 * @param {Array<MetricExtended> | MetricExtended | null} props.handleChangeMetrics Callback function to notify the parent component when the selected options have changed.
 * @param {Array<MetricExtended>} props.options Custom list of options provided by the parent component. Defaults to none.
 * @param {Array<MetricExtended> | MetricExtended | null} props.selected_options Custom list of selected options provided by the parent component. Changes component from an uncontrolled to controlled.
 * @param {boolean} props.disabled Renders the component in a disabled state along with any other provided components. Defaults to enabled.
 * @param {string} props.input_label Sets a custom component input label. Defaults to the singular version of the main entity name.
 * @param {string} props.group_by Group the options by any one of a variety of grouping options. Defaults to ungrouped.
 * @param {boolean} props.multi_select Allow multi option selection and keeps the select open while selecting. Defaults to a single select and closes after selecting an option.
 * @param {number} props.tag_limit Sets a limit on the amount of tags rendered in the input. Defaults to unlimited.
 * @param {string} props.group_id Query configured metrics for the provided group. Works in conjunction with props.company_id. Optionally works in conjunction with unconfigured_only or standard_ids. Defaults to empty list.
 * @param {string} props.company_id Query configured metrics for the provided company. Works in conjunction with props.group_id. Optionally works in conjunction with unconfigured_only or standard_ids. Defaults to empty list.
 * @param {boolean} props.unconfigured_only Query only unconfigured metrics for the provided group_id and company_id. Optionally works in conjunction with standard_ids. Defaults to false.
 * @param {Array<string>} props.standard_ids Query metrics for a custom list of standard id's. Not specifying group_id or company_id will query the master list metrics for specified standards.
 * @param {boolean} props.master_list Forcefully query metrics from the master list. Does not require group_id and company_id. Defaults to false.
 * @param {boolean} props.loading A flag to set the autocomplete into a loading state for things such as fetching entries from database.
 */
const MetricSelect = (props: MetricSelectProps) => {
  const [optionList, setOptions] = React.useState<Array<MetricExtended>>([]);
  const [selectedOptions, setSelectedOptions] = React.useState<
    Array<MetricExtended> | MetricExtended | null
  >(props.multi_select ? [] : null);

  // Query validations
  const get_unconfigured_metrics: boolean =
    props.options === undefined &&
    !props.master_list &&
    props.group_id &&
    props.company_id &&
    props.unconfigured_only &&
    !props.standard_ids
      ? true
      : false;
  const get_configured_metrics: boolean =
    props.options === undefined &&
    !props.master_list &&
    props.group_id &&
    props.company_id &&
    !props.unconfigured_only &&
    !props.standard_ids
      ? true
      : false;

  const get_configured_metrics_for_standards: boolean =
    props.options === undefined &&
    !props.master_list &&
    props.group_id &&
    props.company_id &&
    !props.unconfigured_only &&
    props.standard_ids
      ? true
      : false;

  const get_master_list_metrics_for_standards: boolean =
    props.options === undefined &&
    !props.master_list &&
    !props.group_id &&
    !props.company_id &&
    props.standard_ids
      ? true
      : false;

  // Define autocomplete options
  React.useEffect(() => {
    (async () => {
      const options: Array<MetricExtended> = props.options
        ? // Metrics supplied by the parent component.
          props.options
        : // All master list metrics.
          props.master_list
          ? await getMetrics(true, undefined, undefined, props.standard_ids, true)
          : get_unconfigured_metrics
            ? // Unconfigured metrics per company.
              await getUnconfiguredMetrics(
                props.group_id || '',
                props.company_id || '',
                props.standard_ids,
                true
              )
            : get_configured_metrics
              ? // Configured metrics per company.
                await getMetrics(false, props.group_id, props.company_id, props.standard_ids, true)
              : get_master_list_metrics_for_standards
                ? // Metrics for specific standards from the master list.
                  await getMetrics(true, undefined, undefined, props.standard_ids, true)
                : // Configured metrics for specific standards per company.
                  get_configured_metrics_for_standards
                  ? await getMetrics(
                      false,
                      props.group_id,
                      props.company_id,
                      props.standard_ids,
                      true
                    )
                  : [];
      if (options) setOptions(options);
    })();
  }, [props.group_id, props.company_id, props.options]);

  // Define selected options
  React.useEffect(() => {
    setSelectedOptions(
      props.multi_select
        ? Array.isArray(props.selected_options)
          ? props.selected_options
          : []
        : props.selected_options
          ? props.selected_options
          : null
    );
  }, [props.options, props.selected_options]);

  // Group selection
  const selected_options_array: Array<MetricExtended> =
    selectedOptions && !Array.isArray(selectedOptions)
      ? [selectedOptions]
      : selectedOptions && Array.isArray(selectedOptions)
        ? selectedOptions
        : [];

  const checkGroup = (group_key: string): boolean => {
    const group_length: number = optionList.filter(
      (option: MetricExtended) =>
        (props.group_by ? resolveObjectFromString(props.group_by, option) : '') === group_key
    ).length;
    const selected_group_length: number = selected_options_array.filter(
      (option: MetricExtended) =>
        (props.group_by ? resolveObjectFromString(props.group_by, option) : '') === group_key
    ).length;
    return group_length === selected_group_length;
  };

  const changeGroup = (group_key: string): void => {
    const grouped_options: Array<MetricExtended> = optionList.filter(
      (option: MetricExtended) =>
        (props.group_by ? resolveObjectFromString(props.group_by, option) : '') === group_key
    );
    const selected_group_options: Array<MetricExtended> = selected_options_array.filter(
      (option: MetricExtended) =>
        (props.group_by ? resolveObjectFromString(props.group_by, option) : '') === group_key
    );
    if (selected_group_options.length > 0) {
      const selected_without_group: Array<MetricExtended> = (
        Array.isArray(selectedOptions) ? selectedOptions : []
      ).filter(
        (option: MetricExtended) =>
          (props.group_by ? resolveObjectFromString(props.group_by, option) : '') !== group_key
      );
      setSelectedOptions(selected_without_group);
      props.handleChangeMetrics && props.handleChangeMetrics(selected_without_group);
    } else {
      const selected_with_group: Array<MetricExtended> = [
        ...(Array.isArray(selectedOptions) ? selectedOptions : []),
        ...grouped_options
      ];
      setSelectedOptions(selected_with_group);
      props.handleChangeMetrics && props.handleChangeMetrics(selected_with_group);
    }
  };

  return (
    <Autocomplete
      id="metric-select"
      fullWidth
      disabled={props.disabled}
      loading={props.loading}
      limitTags={props.tag_limit}
      options={optionList}
      groupBy={
        props.group_by
          ? (option: MetricExtended) =>
              props.group_by ? resolveObjectFromString(props.group_by, option) : ''
          : undefined
      }
      value={selectedOptions}
      getOptionLabel={(option: MetricExtended) => {
        return option.name;
      }}
      disableCloseOnSelect={props.multi_select}
      isOptionEqualToValue={(option: MetricExtended, value: MetricExtended) =>
        option.id === value.id
      }
      multiple={props.multi_select}
      noOptionsText={`No ${metric_label.many.toLowerCase()}`}
      renderOption={(render_props, option: MetricExtended, { selected }) => (
        <Box
          component="li"
          sx={{ display: 'flex', alignItems: 'center' }}
          {...render_props}
          key={option.id}
        >
          {props.multi_select && (
            <Checkbox
              icon={check_icon_blank}
              checkedIcon={check_icon_filled}
              style={{ marginRight: 8 }}
              checked={selected}
            />
          )}
          {option.name}
        </Box>
      )}
      renderGroup={
        props.multi_select
          ? (params: AutocompleteRenderGroupParams) => (
              <Box component="div" key={params.key}>
                <Box
                  component="li"
                  style={{
                    fontSize: '0.85rem',
                    color: '#616161',
                    fontWeight: 500
                  }}
                >
                  <Checkbox
                    icon={check_icon_blank}
                    checkedIcon={check_icon_filled}
                    checked={checkGroup(params.group)}
                    onChange={() => {
                      changeGroup(params.group);
                    }}
                  />
                  {params.group}
                </Box>
                <span>{params.children}</span>
              </Box>
            )
          : undefined
      }
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} label={props.input_label ?? metric_label.one} variant="standard" />
      )}
      onChange={(
        event: React.SyntheticEvent<Element, Event>,
        value: Array<MetricExtended> | MetricExtended | null
      ) => {
        setSelectedOptions(value);
        props.handleChangeMetrics && props.handleChangeMetrics(value);
      }}
    />
  );
};

export default MetricSelect;
