import React from 'react';
import { Tooltip, Drawer } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridValueFormatterParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Company, Group, ReportingPeriod, ReportingPeriodGroup } from '@esg/esg-global-types';
import DeleteConfirmModal from '../../shared/modal/DeleteConfirmModal';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import ConfigAddWidget, { CreateInput } from '../ConfigAddWidget';
import ConfigEditWidget, { EditInput } from '../ConfigEditWidget';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { DocumentReference } from 'firebase/firestore';
import { ReportingPeriodGroupExtended } from '../../../lib/metric_capture/reporting_period_group';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import {
  createReportingPeriodGroup,
  deleteReportingPeriodGroup,
  getReportingPeriodGroups,
  joinReportingPeriodGroupsReportingPeriods,
  updateReportingPeriodGroup
} from '../../../lib/metric_capture/reporting_period_group';
import ReportingPeriodSelect from '../../shared/input/select/ReportingPeriodSelect';
import {
  getAvailableReportingPeriodsForReportingPeriodGroup,
  getReportingPeriods
} from '../../../lib/metric_capture/reporting_period';
import moment from 'moment';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';
import { PanelReportingPeriodGroupsToolbar } from './PanelReportingPeriodGroupsToolbar';
import { PanelReportingPeriodGroupsNoRows } from './PanelReportingPeriodGroupsNoRows';
import { PanelReportingPeriodGroupsLoading } from './PanelReportingPeriodGroupsLoading';

const PanelReportingPeriodGroups = () => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [displayWidgetPanelRight, setDisplayWidgetPanelRight] = React.useState<boolean>(false);
  const [isCreateWidget, setIsCreateWidget] = React.useState<boolean>(true);
  const [gridLoading, setGridLoading] = React.useState<boolean>(true);
  const [reportingPeriodGroupRows, setReportingPeriodGroupRows] = React.useState<
    Array<ReportingPeriodGroupExtended>
  >([]);
  const [actionRow, setActionRow] = React.useState<ReportingPeriodGroupExtended | null>(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [reportingPeriods, setReportingPeriods] = React.useState<Array<ReportingPeriod>>([]);

  // Component variables
  const available_reporting_periods: Array<ReportingPeriod> =
    getAvailableReportingPeriodsForReportingPeriodGroup(reportingPeriods, actionRow);

  const reportingPeriodsInputFactory = (
    handleInputChange: (
      input_id: string,
      value: Array<ReportingPeriod> | ReportingPeriod | null
    ) => void
  ) => {
    return (
      <ReportingPeriodSelect
        reporting_period_options={available_reporting_periods}
        selected_reporting_periods={actionRow ? actionRow.reporting_periods : undefined}
        input_label="Assign Reporting Periods"
        handleChangeReportingPeriods={(
          reporting_periods: Array<ReportingPeriod> | ReportingPeriod | null
        ) => {
          handleInputChange('reporting_periods', reporting_periods);
        }}
        allow_multi_select
        tag_limit={3}
      />
    );
  };

  // Handler functions
  const handleCreateClick = (): void => {
    setActionRow(null);
    setDisplayWidgetPanelRight(true);
    setIsCreateWidget(true);
  };

  const handleEditClick = (reporting_period_group: ReportingPeriodGroupExtended): void => {
    setActionRow(reporting_period_group);
    setIsCreateWidget(false);
    setDisplayWidgetPanelRight(true);
  };

  const handleDeleteClick = (reporting_period_group: ReportingPeriodGroupExtended): void => {
    setActionRow(reporting_period_group);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = (): void => {
    setShowDeleteModal(false);
  };

  // Row functions
  const fetchRows = async (): Promise<void> => {
    setGridLoading(true);
    try {
      if (group && company) {
        // Load rows from database.
        const [reporting_period_groups, reporting_periods]: [
          Array<ReportingPeriodGroup>,
          Array<ReportingPeriod>
        ] = await Promise.all([
          getReportingPeriodGroups(group.id, company.id),
          getReportingPeriods(group.id, company.id)
        ]);
        const reporting_period_groups_with_reporting_periods: Array<ReportingPeriodGroupExtended> =
          joinReportingPeriodGroupsReportingPeriods(reporting_period_groups, reporting_periods);
        // Load rows to memory.
        setReportingPeriodGroupRows(reporting_period_groups_with_reporting_periods);
        setReportingPeriods(reporting_periods);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelReportingPeriodGroups failed on an unknown error while calling fetchRow.',
          {
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to load Reporting Period Groups. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setGridLoading(false);
    }
  };

  const deleteRow = async (reporting_period_group: ReportingPeriodGroupExtended): Promise<void> => {
    try {
      if (group && company) {
        // Soft delete row from database.
        await deleteReportingPeriodGroup(
          group.id,
          company.id,
          reporting_period_group.id,
          reporting_period_group.name,
          reporting_period_group.reporting_periods
        );
        // Delete row from memory.
        setReportingPeriodGroupRows(
          reportingPeriodGroupRows.filter((obj) => {
            return obj.id != reporting_period_group.id;
          })
        );
        setReportingPeriods(
          reportingPeriods.map((reporting_period: ReportingPeriod) => {
            if (reporting_period.reporting_period_group?.id === reporting_period_group.id) {
              const unassigned_reporting_period: ReportingPeriod = reporting_period;
              delete unassigned_reporting_period.reporting_period_group;
              return unassigned_reporting_period;
            }
            return reporting_period;
          })
        );
      }
      // Close delete modal.
      setShowDeleteModal(false);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelReportingPeriodGroups failed on an unknown error while calling deleteRow.',
          {
            group: group,
            company: company,
            reporting_period_group: reporting_period_group,
            reportingPeriodGroupRows: reportingPeriodGroupRows
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to delete ${reporting_period_group.name}. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const createRow = async (
    name: string,
    reporting_periods: Array<ReportingPeriod>
  ): Promise<void> => {
    try {
      if (group && company) {
        // Create row in database.
        const reporting_period_group_doc: DocumentReference = await createReportingPeriodGroup(
          group.id,
          company.id,
          name,
          reporting_periods
        );
        if (reporting_period_group_doc) {
          const new_reporting_period_group: ReportingPeriodGroupExtended = {
            id: reporting_period_group_doc.id,
            deleted: null,
            name: name,
            reporting_periods: reporting_periods.map((reporting_period) => {
              return { ...reporting_period, reporting_period_group: reporting_period_group_doc };
            })
          };
          // Create grid row in memory
          setReportingPeriodGroupRows([new_reporting_period_group, ...reportingPeriodGroupRows]);
          const updated_reporting_period_ids: Array<string> = reporting_periods.map(
            (reporting_period: ReportingPeriod) => reporting_period.id
          );
          setReportingPeriods(
            reportingPeriods.map((reporting_period: ReportingPeriod) => {
              if (updated_reporting_period_ids.includes(reporting_period.id)) {
                return { ...reporting_period, reporting_period_group: reporting_period_group_doc };
              }
              return reporting_period;
            })
          );
        }
      }
      // Close side menu.
      setDisplayWidgetPanelRight(false);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelReportingPeriodGroups failed on an unknown error while calling createRow.',
          {
            group: group,
            company: company,
            name: name
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to create Reporting Period Group. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const updateRow = async (
    new_reporting_period_group_data: ReportingPeriodGroupExtended,
    original_reporting_period_group_data: ReportingPeriodGroupExtended
  ): Promise<void> => {
    try {
      if (group && company) {
        const added_reporting_period_ids: Array<string> =
          new_reporting_period_group_data.reporting_periods.map(
            (reporting_period: ReportingPeriod) => reporting_period.id
          );
        const removed_reporting_period_ids: Array<string> =
          original_reporting_period_group_data.reporting_periods
            .map((old_reporting_period: ReportingPeriod) => old_reporting_period.id)
            .filter(
              (old_reporting_period_id: string) =>
                !added_reporting_period_ids.includes(old_reporting_period_id)
            );
        // Update row in database.
        const updated_reporting_period_group_doc: DocumentReference =
          await updateReportingPeriodGroup(
            group.id,
            company.id,
            new_reporting_period_group_data,
            original_reporting_period_group_data
          );
        // Update row in memory.
        setReportingPeriodGroupRows(
          reportingPeriodGroupRows.map((reporting_period_group: ReportingPeriodGroupExtended) => {
            return reporting_period_group.id === new_reporting_period_group_data.id
              ? new_reporting_period_group_data
              : reporting_period_group;
          })
        );
        setReportingPeriods(
          reportingPeriods.map((reporting_period: ReportingPeriod) => {
            if (added_reporting_period_ids.includes(reporting_period.id)) {
              return {
                ...reporting_period,
                reporting_period_group: updated_reporting_period_group_doc
              };
            } else if (removed_reporting_period_ids.includes(reporting_period.id)) {
              const unassigned_reporting_period: ReportingPeriod = reporting_period;
              delete unassigned_reporting_period.reporting_period_group;
              return unassigned_reporting_period;
            }
            return reporting_period;
          })
        );
      }
      setDisplayWidgetPanelRight(false);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelReportingPeriodGroups failed on an unknown error while calling updateRow.',
          {
            group: group,
            company: company,
            new_reporting_period_group_data: new_reporting_period_group_data,
            reportingPeriodGroupRows: reportingPeriodGroupRows,
            original_reporting_period_group_data: original_reporting_period_group_data
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update Reporting Period Group. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'reporting_periods',
      headerName: 'Reporting Periods',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (params: GridValueFormatterParams) => {
        return params.value.length;
      },
      flex: 1
    },
    {
      field: 'start',
      headerName: 'Start',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.reporting_periods.length === 0) return '-';
        return moment
          .min(
            params.row.reporting_periods.map((reporting_period: ReportingPeriod) =>
              moment(reporting_period.start)
            )
          )
          .format('DD MMM YYYY')
          .toString();
      },
      flex: 1
    },
    {
      field: 'end',
      headerName: 'End',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.reporting_periods.length === 0) return '-';
        return moment
          .max(
            params.row.reporting_periods.map((reporting_period: ReportingPeriod) =>
              moment(reporting_period.end)
            )
          )
          .format('DD MMM YYYY')
          .toString();
      },
      flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      headerAlign: 'right',
      align: 'right',
      hideable: false,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <>
            <GridActionsCellItem
              key={1}
              icon={
                <Tooltip title="Edit Reporting Period Group">
                  <EditIcon />
                </Tooltip>
              }
              size="large"
              label="Edit"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleEditClick(row)}
            />
            <GridActionsCellItem
              key={2}
              icon={
                <Tooltip title="Delete external company">
                  <DeleteIcon color="primary" />
                </Tooltip>
              }
              size="large"
              label="Delete"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleDeleteClick(row)}
            />
          </>
        ];
      }
    }
  ];

  const create_widget_inputs: readonly CreateInput[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Name'
    },
    {
      id: 'reporting_periods',
      type: 'node',
      label: 'Reporting Periods',
      inputNodeFactory: reportingPeriodsInputFactory
    }
  ];

  const edit_widget_inputs: Array<EditInput> = [
    {
      id: 'name',
      type: 'text',
      label: 'Name'
    },
    {
      id: 'reporting_periods',
      type: 'node',
      label: 'Reporting Periods',
      inputNodeFactory: reportingPeriodsInputFactory
    }
  ];

  React.useEffect(() => {
    try {
      (async () => {
        setReportingPeriodGroupRows([]);
        fetchRows();
      })().catch((error) => {
        throw new Error(error);
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelReportingPeriodGroups failed on an unknown error while initialising.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `An error occurred. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
    return;
  }, [group, company]);

  return (
    <>
      {/* Delete Confirmation Modal */}
      <DeleteConfirmModal
        open={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        delete_label={actionRow && actionRow.name}
        delete_warning="This will not delete any associated Reporting Periods or their Metric Records"
        handleDelete={actionRow && (() => deleteRow(actionRow))}
        allow_archive={false}
        allow_delete={true}
      />

      {/* Side Widget Panel */}
      <React.Fragment key={'right_add'}>
        <Drawer
          anchor={'right'}
          open={displayWidgetPanelRight}
          onClose={() => {
            setDisplayWidgetPanelRight(false);
          }}
          PaperProps={{ style: { width: '40%', padding: '1.5rem' } }}
        >
          {/* Create / Edit Widget */}
          {isCreateWidget ? (
            <ConfigAddWidget
              create_label="Reporting Period Group"
              create_icon={<MoreTimeIcon sx={{ marginRight: '1rem' }} fontSize="medium" />}
              handleClose={() => setDisplayWidgetPanelRight(false)}
              create_function_inputs={create_widget_inputs}
              createFunction={(name: string, reporting_periods: Array<ReportingPeriod>) =>
                createRow(name, reporting_periods)
              }
            />
          ) : (
            <ConfigEditWidget
              edit_label={'Reporting Period Group'}
              edit_icon={<EditIcon sx={{ marginRight: '1rem' }} fontSize="medium" />}
              handleClose={() => setDisplayWidgetPanelRight(false)}
              edit_entity={actionRow}
              edit_function_inputs={edit_widget_inputs}
              handleEditInput={(
                input_id: string,
                value: Array<ReportingPeriod> | ReportingPeriod | null
              ) => actionRow && setActionRow({ ...actionRow, [input_id]: value })}
              confirmEditFunction={(
                updated_reporting_period_group,
                original_reporting_period_group_data
              ) => updateRow(updated_reporting_period_group, original_reporting_period_group_data)}
            />
          )}
        </Drawer>
      </React.Fragment>

      {/* Interactive Data Table */}
      <DataGrid
        autoHeight
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          },
          sorting: {
            sortModel: [{ field: 'modified', sort: 'desc' }]
          }
        }}
        hideFooter={reportingPeriodGroupRows.length > 1 ? false : true}
        columnHeaderHeight={reportingPeriodGroupRows.length < 1 ? 0 : 56}
        pageSizeOptions={[10, 25, 50, 100]}
        loading={gridLoading}
        rows={reportingPeriodGroupRows}
        columns={columns}
        disableRowSelectionOnClick
        slots={{
          toolbar: PanelReportingPeriodGroupsToolbar,
          noRowsOverlay: PanelReportingPeriodGroupsNoRows,
          loadingOverlay: PanelReportingPeriodGroupsLoading
        }}
        slotProps={{ toolbar: { handleCreate: handleCreateClick } }}
        sx={{ '&, [class^=MuiDataGrid]': { border: 'none' } }}
      />
    </>
  );
};

export default PanelReportingPeriodGroups;
