import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/material';
import Logo from '../../../assets/logo.webp';
import { User } from 'firebase/auth';
import VerifyEmail from './VerifyEmail';
import VerifyMfa from './VerifyMfa';
import SignIn from './SignIn';
import EnrollMfa from './EnrollMfa';
import RecoverAccount from './RecoverAccount';

interface PropTypes {
  setShowLoginModal(state: boolean): void;
  setAuthenticated(authenticated: boolean): void;
  showLoginModal: boolean;
}

export type LoginState = 'sign_in' | 'verify_email' | 'verify_mfa' | 'enroll_mfa' | 'recovery';

const LoginModal = (props: PropTypes) => {
  const [signInUser, setSignInUser] = React.useState<User | null>(null);
  const [loginState, setLoginState] = React.useState<LoginState>('sign_in');
  const [verifyTimeout, setVerifyTimeout] = React.useState<number>(0);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;
    if (verifyTimeout > 0) {
      timer = setTimeout(() => {
        setVerifyTimeout(verifyTimeout - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [verifyTimeout]);

  const handleCloseLoginModal = (): void => {
    props.setShowLoginModal(false);
  };

  return (
    <div>
      <Dialog
        open={props.showLoginModal}
        onClose={handleCloseLoginModal}
        sx={{ maxWidth: 500, m: 'auto' }}
      >
        <Box sx={{ p: 5 }}>
          <Box id="recaptcha-container"></Box>
          <Box component="img" alt="Esg Logo" src={Logo} sx={{ mb: 3, width: '70%' }} />
          {loginState === 'sign_in' && (
            <SignIn
              data-testid="sign-in-button"
              setSignInUser={(user: User | null) => setSignInUser(user)}
              handleLoginState={(login_state: LoginState) => setLoginState(login_state)}
              resetVerifyTimeout={() => setVerifyTimeout(60)}
            />
          )}
          {loginState === 'verify_email' && (
            <VerifyEmail
              resetVerifyTimeout={() => setVerifyTimeout(60)}
              sign_in_user={signInUser}
              verify_timeout={verifyTimeout}
              returnToSignIn={() => setLoginState('sign_in')}
            />
          )}
          {loginState === 'verify_mfa' && (
            <VerifyMfa
              setAuthenticated={(authenticated: boolean) => props.setAuthenticated(authenticated)}
              closeLogin={() => props.setShowLoginModal(false)}
            />
          )}
          {loginState === 'enroll_mfa' && (
            <EnrollMfa
              setAuthenticated={(authenticated: boolean) => props.setAuthenticated(authenticated)}
              closeLogin={() => props.setShowLoginModal(false)}
            />
          )}
          {loginState === 'recovery' && (
            <RecoverAccount returnToSignIn={() => setLoginState('sign_in')} />
          )}
        </Box>
      </Dialog>
    </div>
  );
};

export default LoginModal;
