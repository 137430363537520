import React from 'react';
import SectionContent from '../../components/landing/SectionContent';
import HeroBanner from '../../components/landing/HeroBanner';
import SummarySection from '../../components/landing/SummarySection';
import InfoSection from '../../components/landing/InfoSection';
import ProcessSection from '../../components/landing/ProcessSection';
import GetInTouchSection from '../../components/landing/GetInTouchSection';
import SolutionsSection from '../../components/landing/SolutionsSection';
import hero_background from '../../assets/solar-panels.webp';
import info_background from '../../assets/wind-turbines.webp';
import Fade from '@mui/material/Fade';
import { Box } from '@mui/material';
import 'typeface-montserrat';
import { ThemeProvider, useTheme } from '@mui/material';

const HomeDashboard = () => {
  const landing_theme = useTheme();

  return (
    <ThemeProvider theme={landing_theme}>
      <Fade in timeout={500}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            letterSpacing: 1,
            margin: landing_theme.spacing(-3)
          }}
        >
          <SectionContent
            style={{
              backgroundImage: `url(${hero_background})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: 400,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <HeroBanner />
          </SectionContent>
          <SectionContent>
            <SummarySection />
          </SectionContent>
          <SectionContent
            style={{
              backgroundImage: `url(${info_background})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <InfoSection />
          </SectionContent>
          <SectionContent
            style={{
              backgroundImage: 'linear-gradient(225deg,#1b97c1 0%,#053d5d 100%)',
              color: 'white',
              minHeight: 751
            }}
          >
            <ProcessSection />
          </SectionContent>
          <SectionContent style={{ backgroundColor: '#fff', minHeight: 1048 }}>
            <SolutionsSection />
          </SectionContent>
          <SectionContent
            style={{
              backgroundImage: 'linear-gradient(225deg,#1b97c1 0%,#053d5d 100%)',
              color: 'white'
            }}
          >
            <GetInTouchSection />
          </SectionContent>
        </Box>
      </Fade>
    </ThemeProvider>
  );
};

export default HomeDashboard;
