/**
 * Checks input string if they contain a character using a regular expression
 * @param {string} character_string String of characters to check for banned characters
 * @returns {boolean}
 */
export const hasBannedCharacters = (character_string: string) => {
  const regular_expression = new RegExp("^[a-zA-Z0-9- .()'&]+$");
  return !regular_expression.test(character_string);
};

/**
 * Validates email address
 * @param {string} email Email to validate
 * @returns {boolean}
 */
export const validateEmail = (email: string) => {
  return email.includes('@') && email.includes('.') && email.length > 4;
};

/**
 * Validates international phone number
 * @param {string} phone Phone number to validate
 * @param {string} regex Specific regex string to validate, otherwise default will be used
 * @returns {boolean}
 */
export const validatePhoneNumber = (phone: string, regex?: string) => {
  const regular_expression = new RegExp(regex ? regex : '^\\+[1-9]{1}[0-9]{3,14}$');
  return regular_expression.test(phone);
};

/**
 * Validates password
 * @param {string} password Password to validate
 * @returns {boolean}
 */
export const validatePasswordInput = (password: string) => {
  return password.length > 5;
};
