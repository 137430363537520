import React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import axios, { AxiosError } from 'axios';
import { API_ENDPOINT } from '../../../env';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { Box, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { CompanyContext } from '../../../context/CompanyContext';
import { Company } from '@esg/esg-global-types';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';

const PowerBIReport = ({
  report_id,
  dataset_id,
  embed_url
}: {
  report_id: string;
  dataset_id: string;
  embed_url: string;
}) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [embedToken, setEmbedToken] = React.useState<string>('');
  const [error, setError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const company: Company | null = React.useContext(CompanyContext);
  const getEmbedToken = async () => {
    try {
      const embed_token = await axios({
        method: 'post',
        url: `${API_ENDPOINT}/get_bi_embed_token`,
        data: {
          datasets: [{ id: dataset_id }],
          reports: [
            {
              id: report_id
            }
          ]
        }
      }).catch((err: unknown) => {
        if (err instanceof AxiosError && err.code !== 'ERR_NETWORK') {
          const tracking_id: string = uuidv4();
          log(
            'error',
            new MetadataError(
              err instanceof Error
                ? err.message
                : 'Error: PowerBiReport failed on an unknown error while calling getEmbedToken.',
              {
                dataset_id: dataset_id,
                report_id: report_id
              },
              tracking_id
            )
          );
        }
        throw new Error(
          err instanceof Error
            ? err.message
            : 'Error: PowerBiReport failed on an unknown error while calling getEmbedToken.'
        );
      });
      if (embed_token) {
        setEmbedToken(embed_token.data.token);
      }
    } catch (err: unknown) {
      setError(true);
      setLoading(false);
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PowerBiReport failed on an unknown error while calling getEmbedToken.',
          {
            dataset_id: dataset_id,
            report_id: report_id
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Report authentication failed. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  React.useEffect(() => {
    (async () => {
      await getEmbedToken().catch((error) => {
        throw new Error(error);
      });
    })().catch((error) => {
      throw new Error(error);
    });
  }, []);

  return (
    <div>
      {loading && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '85vh'
          }}
        >
          <CircularProgress size="20vh" color="primary" />
          <Typography sx={{ padding: 2 }}>Loading BI Report</Typography>
        </Box>
      )}
      {error && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '85vh'
          }}
        >
          <SyncProblemIcon sx={{ fontSize: '10vh', marginBottom: 1.5 }} />
          <Typography sx={{ padding: 2 }}>Unable to load report at the moment.</Typography>
          <Button variant="contained" color="primary">
            Send Feedback
          </Button>
        </Box>
      )}
      <Box
        sx={{
          display: loading ? 'none' : 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '85vh'
        }}
      >
        {embedToken && (
          <PowerBIEmbed
            cssClassName={'power-bi-embedded-report'}
            eventHandlers={
              new Map([
                [
                  'rendered',
                  () => {
                    setLoading(false);
                  }
                ]
              ])
            }
            embedConfig={{
              type: 'report',
              id: report_id,
              embedUrl: embed_url,
              accessToken: embedToken,
              tokenType: models.TokenType.Embed,
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: false
                  }
                }
              },
              filters: [
                {
                  $schema: 'http://powerbi.com/product/schema#basic',
                  target: {
                    table: 'bi_reporting_data',
                    column: 'company_id'
                  },
                  operator: 'In',
                  values: [company ? company.id : ''],
                  filterType: models.FilterType.Basic,
                  requireSingleSelection: true
                }
              ]
            }}
          />
        )}
      </Box>
    </div>
  );
};

export default PowerBIReport;
