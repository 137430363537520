import { Company, Group, Standard } from '@esg/esg-global-types';
import { Box, Divider, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';
import { getStandards } from '../../../lib/app/standard';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { MetadataError } from '@ep/error-handling';
import ConfigurationCard from '../../shared/cards/ConfigurationCard';
import AirIcon from '@mui/icons-material/Air';
import AlertBanner from '../../shared/banner/AlertBanner';

const PanelOtherConfiguration = () => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [standards, setStandards] = React.useState<Array<Standard>>([]);
  const [standardsLoading, setStandardsLoading] = React.useState<boolean>(true);

  const additional_config_standards: Array<Standard> = standards.filter((standard: Standard) => {
    return standard.require_emission_factor;
  });

  const fetchStandards = async (): Promise<void> => {
    setStandardsLoading(true);
    try {
      if (group && company) {
        const standards: Array<Standard> = await getStandards(group.id, company.id);
        setStandards(standards);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelOtherConfiguration failed on an unknown error while calling fetchStandards.',
          {
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch standards. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
      return;
    } finally {
      setStandardsLoading(false);
    }
  };

  React.useEffect(() => {
    try {
      (async () => {
        setStandards([]);
        fetchStandards();
      })().catch((error) => {
        throw new Error(error);
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelOtherConfiguration failed on an unknown error while initialising.',
          {
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `An error occurred. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
    return;
  }, [group, company]);

  return (
    <Box>
      <Typography sx={{ textAlign: 'left', fontSize: '1.3rem', my: 6 }}>
        Standards to Configure
      </Typography>
      {standardsLoading ? (
        <Skeleton height={100} />
      ) : additional_config_standards.length > 0 ? (
        additional_config_standards.map((standard: Standard) => {
          return (
            <Box key={standard.id} sx={{ textAlign: 'left' }}>
              <Typography>{standard.name}</Typography>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ display: 'flex' }}>
                {standard.require_emission_factor && (
                  <ConfigurationCard
                    title="Emission Factors"
                    description="Convert your GHG Metrics to Carbon Emission data (CO2e)"
                    icon={<AirIcon />}
                    route="configuration/emission-factors"
                  />
                )}
              </Box>
            </Box>
          );
        })
      ) : (
        <AlertBanner
          severity="info"
          message={`No Standards requiring additional configuration for ${company?.name}`}
          open
        />
      )}
    </Box>
  );
};

export default PanelOtherConfiguration;
