import { db } from '../google/firebase';
import {
  collection,
  doc,
  DocumentReference,
  CollectionReference,
  getDocs,
  QuerySnapshot,
  query,
  Query,
  where,
  updateDoc,
  setDoc,
  QueryDocumentSnapshot,
  DocumentSnapshot,
  getDoc
} from 'firebase/firestore';
import { Company, EntityLabel, Group, Sector, Standard } from '@esg/esg-global-types';
import { createAuditLog } from './audit';
import { BatchWrite, processBatchWrites, readFirestoreDocs } from './db_util';
import { generateAuditLogData, generateAuditLogDoc } from './audit';
import { auth } from '../google/firebase';
import { StandardData } from './standard';
import { FirestoreQueryParam } from '../../@types/shared';

export interface CompanyExtended extends Company {
  group: Group;
}

// Singular and plural label for model entity.
export const company_label: EntityLabel = {
  one: 'Company',
  many: 'Companies'
};

/**
 * Function to construct a commonly used reference to the company firestore document of the current portal company
 * @param {string} group_id id of Group to generate Company reference for
 * @param {string} company_id id of current portal company to reference firestore document
 * @returns {DocumentReference}
 */
export const refCompanyDoc = (group_id: string, company_id: string) => {
  const group_doc: DocumentReference = doc(db, 'groups', group_id);
  const companies_collection: CollectionReference = collection(group_doc, 'companies');
  const company_doc: DocumentReference = doc(companies_collection, company_id);
  return company_doc;
};

/**
 * Function to query all company documents for given group
 * @param {string} group_id ID of group to fetch companies for.
 * @returns {Array<Company>}
 */
export const getCompanies = async (group_id: string) => {
  const companies_collection: CollectionReference = collection(
    doc(db, 'groups', group_id),
    'companies'
  );
  const companies_query: Query = query(companies_collection, where('deleted', '==', null));
  try {
    const company_docs: QuerySnapshot = await getDocs(companies_query);
    const companies: Array<Company> = company_docs.docs.map((company) => {
      return {
        id: company.id,
        deleted: company.data().deleted,
        name: company.data().name,
        active: company.data().active,
        configuration: company.data().configuration
      };
    });
    return companies;
  } catch (err) {
    const error = `Error while getting Companies from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        companies_collection: companies_collection,
        companies_query: companies_query
      }
    })}`;
    throw new Error(`Error: getCompanies: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to query all Company documents joined with relative Group data
 * @param {string | undefined} group_id Optional ID of Group to get Companies for specifically
 * @returns {Array<CompanyExtended>}
 */
export const getCompanyJoinGroups = async (group_id?: string): Promise<Array<CompanyExtended>> => {
  const companies: Array<CompanyExtended> = [];
  try {
    if (group_id) {
      const group_snapshot: DocumentSnapshot = await getDoc(doc(db, 'groups', group_id));
      const collection_path = `groups/${group_id}/companies`;
      const company_query: Array<FirestoreQueryParam> = [
        { field_name: 'deleted', operator: '==', value: null }
      ];
      const companies_docs: QuerySnapshot = await readFirestoreDocs(collection_path, company_query);
      companies_docs.docs.forEach((company: QueryDocumentSnapshot) => {
        companies.push({
          id: company.id,
          group: {
            id: group_snapshot.id,
            name: group_snapshot.data()?.name,
            active: group_snapshot.data()?.active
          },
          active: company.data().active,
          deleted: company.data().deleted,
          name: company.data().name,
          configuration: company.data().configuration
        });
      });
    } else {
      const groups_snapshot: QuerySnapshot = await readFirestoreDocs('groups', []);
      const group_promises: Array<Promise<void>> = groups_snapshot.docs.map(
        async (group: QueryDocumentSnapshot) => {
          const collection_path = `groups/${group.id}/companies`;
          const company_query: Array<FirestoreQueryParam> = [
            { field_name: 'deleted', operator: '==', value: null }
          ];
          const companies_docs: QuerySnapshot = await readFirestoreDocs(
            collection_path,
            company_query
          );
          companies_docs.docs.forEach((company) => {
            companies.push({
              id: company.id,
              group: {
                id: group.id,
                name: group.data().name,
                active: group.data().active
              },
              active: company.data().active,
              deleted: company.data().deleted,
              name: company.data().name,
              configuration: company.data().configuration
            });
          });
        }
      );
      await Promise.all(group_promises);
    }

    return companies;
  } catch (err) {
    const error = `Error while getting Companies from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        companies: companies,
        group_id: group_id
      }
    })}`;
    throw new Error(`Error: getCompaniesJoinGroups: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to create company with relative data
 * @param {string} group_id ID of Group which Company is being created for
 * @param {string} company_name Name of new Company
 * @param {string} company_logo logo url of new Company
 * @param {string} company_primary_color Primary color of new Company
 * @param {string} company_secondary_color Secondary color of new Company
 * @returns {DocumentReference}
 */
export const createCompany = async (
  group_id: string,
  company_name: string,
  company_logo: string,
  company_primary_color: string,
  company_secondary_color: string,
  company_sectors?: Array<Sector>,
  company_standards?: Array<Standard>
) => {
  const companies_collection: CollectionReference = collection(db, `groups/${group_id}/companies`);
  const batch_writes: Array<BatchWrite> = [];
  const audit_log_batch_writes: Array<BatchWrite> = [];
  const new_company_ref = doc(
    companies_collection,
    String(company_name).toLowerCase().replace(/\s/g, '_')
  );
  const company_doc_data = {
    deleted: null,
    name: company_name,
    active: true,
    configuration: {
      logo: company_logo,
      primary_color: company_primary_color,
      secondary_color: company_secondary_color
    }
  };
  await setDoc(new_company_ref, company_doc_data);
  if (company_sectors) {
    company_sectors.forEach((sector: Sector) => {
      const sector_ref: DocumentReference = doc(
        collection(db, `/groups/${group_id}/companies/${new_company_ref.id}/sectors`)
      );
      const sector_data = {
        deleted: null,
        name: sector.name,
        master_list_sector: doc(collection(db, 'sector_master_list'), sector.id)
      };
      batch_writes.push({ reference: sector_ref, operation: 'create', data: sector_data });
    });
  }
  if (company_standards) {
    company_standards.forEach((master_list_standard: Standard) => {
      const standard_ref: DocumentReference = doc(
        collection(db, `/groups/${group_id}/companies/${new_company_ref.id}/standards`)
      );
      const standard_data: StandardData = {
        category: master_list_standard.category,
        created: master_list_standard.created,
        deleted: null,
        name: master_list_standard.name,
        sector: master_list_standard.sector,
        version: master_list_standard.version,
        is_quantitative: master_list_standard.is_quantitative,
        require_emission_factor: master_list_standard.require_emission_factor,
        require_site_level: master_list_standard.require_site_level,
        master_list_standard: doc(collection(db, 'standard_master_list'), master_list_standard.id)
      };
      batch_writes.push({ reference: standard_ref, operation: 'create', data: standard_data });

      const audit_log_ref: DocumentReference = generateAuditLogDoc(group_id);
      const audit_log_data = generateAuditLogData(
        master_list_standard.name,
        'create',
        '',
        standard_ref,
        auth.currentUser?.email
      );
      audit_log_batch_writes.push({
        reference: audit_log_ref,
        operation: 'create',
        data: audit_log_data
      });
    });
  }
  await processBatchWrites(batch_writes);
  await processBatchWrites(audit_log_batch_writes);
  await createAuditLog(group_id, 'create', '', company_doc_data.name, new_company_ref);
  return new_company_ref;
};

/**
 * Function to update company with relative data
 * @param {string} group_id ID of Group to update company for
 * @param {Company} updated_company New Company data to push into document
 * @returns {void}
 */
export const updateCompany = async (
  group_id: string,
  updated_company: Company,
  initial_company: Company
) => {
  if (group_id) {
    const company_doc = doc(collection(db, `groups/${group_id}/companies`), updated_company.id);
    await updateDoc(company_doc, {
      deleted: updated_company.deleted,
      name: updated_company.name,
      active: updated_company.active,
      configuration: updated_company.configuration
    });
    await createAuditLog(
      group_id,
      'update',
      JSON.stringify(updated_company),
      JSON.stringify(initial_company),
      company_doc
    );
  }
};
