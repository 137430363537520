import React from 'react';
import { Box } from '@mui/material';
import ContactHeader from '../../components/contact/ContactHeader';
import ContactForm from '../../components/contact/ContactForm';

const ContactPage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        letterSpacing: 1,
        alignItems: 'center',
        mb: 8
      }}
    >
      <ContactHeader />
      <Box width={'70%'} sx={{ backgroundColor: '#edf1f8' }}>
        <ContactForm styles={{ colors: { primary: '#595959' } }} />
      </Box>
    </Box>
  );
};

export default ContactPage;
