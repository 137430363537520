import React from 'react';
import { Tooltip, Drawer, Switch } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridValueFormatterParams
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Company, ExternalCompany, Group } from '@esg/esg-global-types';
import DeleteConfirmModal from '../../shared/modal/DeleteConfirmModal';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { PanelExternalCompaniesLoading } from './PanelExternalCompaniesLoading';
import { PanelExternalCompaniesNoRows } from './PanelExternalCompaniesNoRows';
import { PanelExternalCompaniesToolbar } from './PanelExternalCompaniesToolbar';
import ConfigAddWidget, { CreateInput } from '../ConfigAddWidget';
import ConfigEditWidget, { EditInput } from '../ConfigEditWidget';
import moment, { Moment } from 'moment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DocumentReference } from 'firebase/firestore';
import {
  createExternalCompany,
  deleteExternalCompany,
  getExternalCompanies,
  updateExternalCompany
} from '../../../lib/app/external_company';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';

const PanelExternalCompanies = () => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [displayWidgetPanelRight, setDisplayWidgetPanelRight] = React.useState(false);
  const [isCreateWidget, setIsCreateWidget] = React.useState(true);
  const [gridLoading, setGridLoading] = React.useState(true);
  const [externalCompanyRows, setExternalCompanyRows] = React.useState<Array<ExternalCompany>>([]);
  const [actionRow, setActionRow] = React.useState<ExternalCompany | null>(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  // Component variables
  const grid_row_format_timestamp = 'DD MMM yyyy hh:mm';
  const entity_name = (is_plural = false) => {
    is_plural ? 'External Companies' : 'External Company';
  };

  // Handler functions
  const handleCreateClick = (): void => {
    setActionRow(null);
    setDisplayWidgetPanelRight(true);
    setIsCreateWidget(true);
  };

  const handleEditClick = (row: ExternalCompany): void => {
    setActionRow(row);
    setIsCreateWidget(false);
    setDisplayWidgetPanelRight(true);
  };

  const handleDeleteClick = (row: ExternalCompany): void => {
    setActionRow(row);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = (): void => {
    setShowDeleteModal(false);
  };

  // Row functions
  const fetchRows = async (): Promise<void> => {
    setGridLoading(true);
    try {
      if (group && company) {
        // Load rows from database.
        const rows_loaded: Array<ExternalCompany> = await getExternalCompanies(
          group.id,
          company.id
        );
        // Load rows to memory.
        rows_loaded.map((external_company: ExternalCompany) => {
          external_company.active = !external_company.active ? false : true;
          external_company.created = moment(external_company.created);
          external_company.modified = external_company.modified
            ? moment(external_company.modified)
            : moment(external_company.created);
          return {
            ...external_company
          };
        });
        setExternalCompanyRows(rows_loaded);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelExternalCompanies failed on an unknown error while calling fetchRows.',
          {
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to load ${String(entity_name(true)).toLowerCase()}. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setGridLoading(false);
    }
  };

  const deleteRow = async (row: ExternalCompany): Promise<void> => {
    try {
      if (group && company) {
        // Soft delete row from database.
        await deleteExternalCompany(group.id, company.id, row);
        // Delete row from memory.
        setExternalCompanyRows(
          externalCompanyRows.filter((obj: ExternalCompany) => {
            return obj.id != row.id;
          })
        );
      }
      // Close delete modal.
      setShowDeleteModal(false);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelExternalCompanies failed on an unknown error while calling deleteRow.',
          {
            group: group,
            company: company,
            row: row,
            externalCompanyRows: externalCompanyRows
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to delete ${String(entity_name()).toLowerCase()}. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const createRow = async (row: {
    active: boolean;
    name: string;
    modified: Moment;
    created: Moment;
    deleted: null;
  }): Promise<void> => {
    try {
      if (group && company) {
        // Create row in database.
        const external_company_doc: DocumentReference = await createExternalCompany(
          group.id,
          company.id,
          {
            name: row.name
          }
        );
        if (external_company_doc) {
          // Create grid row in memory
          setExternalCompanyRows([
            {
              id: external_company_doc.id,
              ...row
            },
            ...externalCompanyRows
          ]);
        } else {
          throw new MetadataError('Could not create External Company in database', {
            external_company_doc: external_company_doc
          });
        }
      }
      // Close side menu.
      setDisplayWidgetPanelRight(false);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelExternalCompanies failed on an unknown error while calling createRow.',
          {
            group: group,
            company: company,
            external_company: row
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to create external company. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const updateRow = async (
    new_row_data: ExternalCompany,
    old_row_data: ExternalCompany
  ): Promise<void> => {
    try {
      if (group && company) {
        // Update row in database.
        await updateExternalCompany(group.id, company.id, new_row_data, old_row_data);
        // Update row in memory.
        setExternalCompanyRows(
          externalCompanyRows.map((external_company: ExternalCompany) => {
            return external_company.id === new_row_data.id
              ? { ...new_row_data, modified: moment() }
              : external_company;
          })
        );
      }
      setDisplayWidgetPanelRight(false);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelExternalCompanies failed on an unknown error while calling updateRow.',
          {
            group: group,
            company: company,
            new_row_data: new_row_data,
            externalCompanyRows: externalCompanyRows,
            old_row_data: old_row_data
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update external company. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  React.useEffect(() => {
    try {
      (async () => {
        setExternalCompanyRows([]);
        fetchRows();
      })().catch((err: unknown) => {
        throw new Error(
          err instanceof Error
            ? err.message
            : 'Error: PanelExternalCompanies failed on an unknown error while calling fetchRows.'
        );
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelExternalCompanies failed on an unknown error while initialising.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `An error occurred. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
    return;
  }, [group, company]);

  const columns: Array<GridColDef> = [
    {
      field: 'active',
      headerName: 'Active',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      maxWidth: 90,
      renderCell: (params) => {
        return <Switch checked={params.row.active ? true : false} disabled />;
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'created',
      headerName: 'Created',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value == null) return '';
        return params.value.format(grid_row_format_timestamp);
      }
    },
    {
      field: 'modified',
      headerName: 'Modified',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<Moment>) => {
        if (params.value == null) return '';
        return params.value.format(grid_row_format_timestamp);
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      headerAlign: 'right',
      align: 'right',
      hideable: false,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <>
            <GridActionsCellItem
              key={1}
              icon={
                <Tooltip title="Edit external company">
                  <EditIcon color={row.active ? 'primary' : 'action'} />
                </Tooltip>
              }
              size="large"
              label="Edit"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleEditClick(row)}
              disabled={!row.active}
            />
            <GridActionsCellItem
              key={2}
              icon={
                <Tooltip title="Delete external company">
                  <DeleteIcon color="primary" />
                </Tooltip>
              }
              size="large"
              label="Delete"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleDeleteClick(row)}
            />
          </>
        ];
      }
    }
  ];

  const create_widget_inputs: readonly CreateInput[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Name'
    }
  ];

  const edit_widget_inputs: Array<EditInput> = [
    {
      id: 'name',
      type: 'text',
      label: 'Name'
    }
  ];

  return (
    <>
      {/* Delete Confirmation Modal */}
      <DeleteConfirmModal
        open={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        delete_label={actionRow && actionRow.name}
        handleDelete={actionRow && (() => deleteRow(actionRow))}
        allow_archive={false}
        allow_delete={true}
      />

      {/* Side Widget Panel */}
      <React.Fragment key={'right_add'}>
        <Drawer
          anchor={'right'}
          open={displayWidgetPanelRight}
          onClose={() => {
            setDisplayWidgetPanelRight(false);
          }}
          PaperProps={{ style: { width: '40%', padding: '1.5rem' } }}
        >
          {/* Create / Edit Widget */}
          {isCreateWidget ? (
            <ConfigAddWidget
              create_label="External Company"
              create_icon={<AddCircleOutlineIcon sx={{ marginRight: '1rem' }} fontSize="medium" />}
              handleClose={() => setDisplayWidgetPanelRight(false)}
              create_function_inputs={create_widget_inputs}
              createFunction={(name: string) =>
                createRow({
                  active: true,
                  name: name,
                  modified: moment(),
                  created: moment(),
                  deleted: null
                })
              }
            />
          ) : (
            <ConfigEditWidget
              edit_label={'External Company'}
              edit_icon={<EditIcon sx={{ marginRight: '1rem' }} fontSize="medium" />}
              handleClose={() => setDisplayWidgetPanelRight(false)}
              edit_entity={actionRow}
              edit_function_inputs={edit_widget_inputs}
              handleEditInput={(input_id: string, value: unknown) =>
                actionRow && setActionRow({ ...actionRow, [input_id]: value })
              }
              confirmEditFunction={(
                updated_company: ExternalCompany,
                old_row_data: ExternalCompany
              ) => updateRow(updated_company, old_row_data)}
            />
          )}
        </Drawer>
      </React.Fragment>

      {/* Interactive Data Table */}
      <DataGrid
        autoHeight
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          },
          sorting: {
            sortModel: [{ field: 'modified', sort: 'desc' }]
          }
        }}
        hideFooter={externalCompanyRows.length > 1 ? false : true}
        columnHeaderHeight={externalCompanyRows.length < 1 ? 0 : 56}
        pageSizeOptions={[10, 25, 50, 100]}
        loading={gridLoading}
        rows={externalCompanyRows}
        columns={columns}
        disableRowSelectionOnClick
        slots={{
          toolbar: PanelExternalCompaniesToolbar,
          noRowsOverlay: PanelExternalCompaniesNoRows,
          loadingOverlay: PanelExternalCompaniesLoading
        }}
        slotProps={{ toolbar: { handleCreate: handleCreateClick } }}
        sx={{ '&, [class^=MuiDataGrid]': { border: 'none' } }}
      />
    </>
  );
};

export default PanelExternalCompanies;
