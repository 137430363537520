import React from 'react';
import PowerBIReport from '../../components/shared/template/PowerBIReport';
import { METRIC_BI_ID, METRIC_BI_EMBED_URL, METRIC_DATASET_ID } from '../../env';
import { UserContext } from '../../context/UserContext';
import { Company, Group } from '@esg/esg-global-types';
import { CompanyContext } from '../../context/CompanyContext';
import { ViewAccess, getViewAccess } from '../../util/user_access';
import AccessDenied from '../shared/AccessDenied';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { GroupContext } from '../../context/GroupContext';

const MetricCollectionDashboard = () => {
  const user_info = React.useContext(UserContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [access, setAccess] = React.useState<ViewAccess>(null);

  React.useEffect(() => {
    setAccess(
      group && company && user_info
        ? getViewAccess(user_info, group.id, company.id, 'metric_collection.dashboard')
        : null
    );
  }, [user_info, company]);

  return !user_info || access === null ? (
    <LoadingScreen />
  ) : access !== 'none' ? (
    <PowerBIReport
      report_id={METRIC_BI_ID}
      dataset_id={METRIC_DATASET_ID}
      embed_url={METRIC_BI_EMBED_URL}
    />
  ) : (
    <AccessDenied />
  );
};

export default MetricCollectionDashboard;
