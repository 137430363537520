import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface PanelTabsProps {
  children?: React.ReactNode;
  label: string;
  disabled?: boolean;
}

interface PanelTabsWrapperProps {
  children: React.ReactElement | Array<React.ReactElement>;
  handleTabChange?: (tab_number: number) => void;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 4 }}>{children}</Box>}
    </div>
  );
}

const PanelTabsWrapper = (props: PanelTabsWrapperProps) => {
  const { children, handleTabChange } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    handleTabChange && handleTabChange(newValue);
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          {React.Children.map(children, (child, index) => {
            return <Tab label={child.props.label} disabled={child.props.disabled} key={index} />;
          })}
        </Tabs>
      </Box>
      {React.Children.map(children, (child: React.ReactElement, index) => {
        return (
          <TabPanel value={value} index={index}>
            {child}
          </TabPanel>
        );
      })}
    </Box>
  );
};

const PanelTabs = (props: PanelTabsProps) => {
  return <>{props.children}</>;
};

export { PanelTabsWrapper, PanelTabs };
