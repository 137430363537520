import { Box } from '@mui/material';
import React from 'react';
import ConfigurationWrapper from '../../components/shared/ConfigurationWrapper';
import PanelPersonalisation from '../../components/configuration/personalisation/PanelPersonalisation';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const ConfigurationPersonalisation = () => {
  return (
    <Box>
      <ConfigurationWrapper
        title="Personalisation"
        icon={<BrushOutlinedIcon fontSize="large" />}
        previous={null}
        next={{
          route: '/configuration/geographical',
          label: 'Geographical',
          icon: <LocationOnOutlinedIcon />
        }}
      >
        <PanelPersonalisation />
      </ConfigurationWrapper>
    </Box>
  );
};

export default ConfigurationPersonalisation;
