import React from 'react';
import Typography from '@mui/material/Typography';
import process_img from '../../assets/sustainable-engineer.webp';
import Fade from '@mui/material/Fade';
import { Box } from '@mui/material';

const InfoSection = () => {
  return (
    <>
      <Typography color={'white'} fontSize={35} fontWeight={600}>
        Real World Sustainability Approach
      </Typography>
      <Typography color={'white'} fontWeight={500}>
        With R1.3 billion of energy assets invested in and being operated across diverse (Power,
        Heating and Cooling) energy systems on a 24/7/365 basis, we share operational risk with
        clients and are critically dependant on accurate measurement and reporting. Data is the
        backbone from which EP not only manages energy systems, it is also crucially the foundation
        to our Modular Sustainability Management model. There are a plethora of guidelines and
        frameworks considered as enablers of long-term organisational sustainability. Through the EP
        model clients gain access to a leading company that faces the same risks and challenges,
        while sharing and evolving best-practice.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Fade in timeout={500}>
          <img src={process_img} width={550} alt="image" />
        </Fade>
      </Box>
    </>
  );
};

export default InfoSection;
