import { Company, Group, User } from '@esg/esg-global-types';
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Typography
} from '@mui/material';
import React from 'react';
import { UserContext } from '../../../context/UserContext';
import { getUserTypes } from '../../../util/user_access';
import { InputErrors } from '../../../@types/shared';
import AlertBanner from '../../shared/banner/AlertBanner';

const CompanyAccessSelect = ({
  user,
  chosen_group,
  chosen_company,
  group_choices,
  input_errors,
  handleUserChange,
  handleGroupChange,
  handleCompanyChange,
  handleInputErrors
}: {
  user: User;
  chosen_group: Group | null;
  chosen_company: Company | null;
  group_choices: Array<Group>;
  input_errors: InputErrors;
  handleUserChange: (user_data: User) => void;
  handleGroupChange: (group_select: Group) => void;
  handleCompanyChange: (company_select: Company) => void;
  handleInputErrors: (input_id: string, error: string) => void;
}) => {
  const current_user: User | null = React.useContext(UserContext);

  const handleChangeSuperAdmin = (super_admin: boolean) => {
    handleUserChange({ ...user, super_admin: super_admin });
  };

  const handleChangeGroupAdmin = (group_id: string, group_admin: boolean) => {
    handleUserChange({
      ...user,
      groups: {
        ...user.groups,
        [group_id]: group_admin
          ? {
              admin: true
            }
          : {
              ...user.groups[group_id],
              admin: false
            }
      }
    });
  };

  const handleChangeUserType = (type: 'internal' | 'external' | 'any') => {
    handleUserChange({ ...user, type: type, groups: {} });
  };

  return (
    <Box>
      <Typography sx={{ fontSize: '1.4rem', letterSpacing: 2.5, mt: 6, mx: 4 }}>
        Choose a Company to Configure for {user.first_name ? user.first_name : 'User'}
      </Typography>
      <Box sx={{ m: 4 }}>
        <Grid container spacing={3}>
          {current_user?.super_admin && (
            <Grid item xs={10}>
              <Box display={'flex'}>
                <Switch
                  checked={user.super_admin}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeSuperAdmin(event.target.checked)
                  }
                />
                <Typography alignSelf={'center'}>Super Admin</Typography>
              </Box>
            </Grid>
          )}
          {!user.super_admin ? (
            <>
              <Grid item xs={10}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, mb: 4 }}>
                  <FormControl>
                    <FormLabel sx={{ m: 1 }}>User Type</FormLabel>
                    <Select
                      value={user.type}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      onChange={(event) =>
                        handleChangeUserType(event.target.value as 'internal' | 'external' | 'any')
                      }
                      sx={{ minWidth: 200 }}
                    >
                      {getUserTypes().map((type) => {
                        return (
                          <MenuItem key={type.id} value={type.id}>
                            {type.name.charAt(0).toUpperCase() + type.name.slice(1).toLowerCase()}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      Control the scope of data this user can access, whether internally or from
                      external companies.
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={10}>
                <FormControl
                  fullWidth
                  sx={{ mb: 4 }}
                  error={!chosen_group && !!input_errors.chosen_group}
                >
                  <InputLabel id={'group-select'}>Group</InputLabel>
                  <Select
                    required
                    value={chosen_group ? JSON.stringify(chosen_group) : ''}
                    onChange={(event: SelectChangeEvent) => {
                      handleGroupChange(JSON.parse(event.target.value));
                    }}
                    onBlur={() => handleInputErrors('chosen_group', chosen_group ? '' : 'Required')}
                    inputProps={{ 'aria-label': 'Without label' }}
                    variant="standard"
                  >
                    {group_choices.map((group: Group) => {
                      return (
                        <MenuItem key={group.id} value={JSON.stringify(group)}>
                          {group.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>
                    <>
                      The parent organization the User can access
                      <br></br>
                      {input_errors.chosen_group && input_errors.chosen_group}
                    </>
                  </FormHelperText>
                </FormControl>
              </Grid>
              {chosen_group && (
                <>
                  {current_user?.super_admin && (
                    <Grid item xs={10}>
                      <Box display={'flex'}>
                        <Switch
                          checked={user.groups[chosen_group.id]?.admin ?? false}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeGroupAdmin(chosen_group.id, event.target.checked)
                          }
                        />
                        <Typography alignSelf={'center'}>Group Admin</Typography>
                      </Box>
                    </Grid>
                  )}
                  {!user.groups[chosen_group.id]?.admin ? (
                    <Grid item xs={10}>
                      <FormControl
                        fullWidth
                        sx={{ mb: 4 }}
                        error={!chosen_company && !!input_errors.chosen_company}
                      >
                        <InputLabel id={'company-select'}>Company</InputLabel>
                        <Select
                          value={chosen_company ? JSON.stringify(chosen_company) : ''}
                          onChange={(event: SelectChangeEvent) => {
                            handleCompanyChange(JSON.parse(event.target.value));
                          }}
                          onBlur={() =>
                            handleInputErrors('chosen_company', chosen_company ? '' : 'Required')
                          }
                          inputProps={{ 'aria-label': 'Without label' }}
                          variant="standard"
                        >
                          {chosen_group.companies?.map((company: Company) => {
                            return (
                              <MenuItem key={company.id} value={JSON.stringify(company)}>
                                {company.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          <>
                            The company portal the User can access
                            <br></br>
                            {input_errors.chosen_company && input_errors.chosen_company}
                          </>
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Box sx={{ my: 4 }}>
                      <AlertBanner
                        severity="info"
                        open
                        message={`${user.email} is a Group Admin for ${chosen_group.name} with access to all Company data`}
                      />
                    </Box>
                  )}
                </>
              )}
            </>
          ) : (
            <Box sx={{ my: 4 }}>
              <AlertBanner
                severity="info"
                open
                message={`${user.email} is a Super Admin with access to all Group data`}
              />
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default CompanyAccessSelect;
