import React from 'react';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import { AuditLog, Group, User } from '@esg/esg-global-types';
import { MetadataError, appendMetadataToError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { FeedbackSnackbarContext } from '../../context/FeedbackSnackbarContext';
import { log } from '../../util/log';
import AuditLogsGridToolbar from './AuditLogsGridToolbar';
import AuditLogsGridNoRows from './AuditLogsGridNoRows';
import AuditLogsGridLoading from './AuditLogsGridLoading';
import { getAuditLogs } from '../../lib/app/audit';
import { getAdminGroups } from '../../util/user_access';
import { UserContext } from '../../context/UserContext';
import { getGroups } from '../../lib/app/group';
import moment from 'moment';
import { AuditLogValue } from '../../@types/shared';

declare module '@mui/x-data-grid' {
  interface NoRowsOverlayPropsOverrides {
    selected_group: Group | null;
  }
}

const AuditLogsGrid = () => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const user_info: User | null = React.useContext(UserContext);
  const [auditLogs, setAuditLogs] = React.useState<Array<AuditLog>>([]);
  const [groups, setGroups] = React.useState<Array<Group>>([]);
  const [selectedGroup, setSelectedGroup] = React.useState<Group | null>(null);
  const [gridLoading, setGridLoading] = React.useState<boolean>(false);

  const fetchGroups = async (): Promise<void> => {
    try {
      if (user_info) {
        const admin_groups: Array<string> = getAdminGroups(user_info);
        const groups: Array<Group> = await getGroups().catch(() => {
          throw new MetadataError('getGroups: Could not fetch Groups', {
            admin_groups: admin_groups
          });
        });
        const allowed_groups: Array<Group> = groups.filter(
          (group: Group) => admin_groups.includes(group.id) || user_info.super_admin
        );
        setGroups(allowed_groups);
        if (allowed_groups.length === 1) {
          handleChangeGroup(allowed_groups[0]);
        }
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      if (err instanceof MetadataError)
        appendMetadataToError(err, 'variables', { user_info: user_info });
      log(
        'error',
        err instanceof Error
          ? err.message
          : 'Error: AuditLogsGrid failed on an unknown error while calling fetchGroups.'
      );
      setFeedbackData({
        message: `Unable to load Groups. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const handleChangeGroup = (group: Group) => {
    setSelectedGroup(group);
    setAuditLogs([]);
    fetchAuditLogs(group.id);
  };

  const fetchAuditLogs = async (group_id: string): Promise<void> => {
    try {
      setGridLoading(true);
      const audit_logs: Array<AuditLog> = await getAuditLogs(group_id);
      const metric_record_audit_logs: Array<AuditLog> = audit_logs.filter((audit_log: AuditLog) => {
        return audit_log.reference.path.includes('metric_records');
      });
      setAuditLogs(metric_record_audit_logs);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: AuditLogsGrid failed on an unknown error while calling fetchAuditLogs.',
          {
            group_id: group_id
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to load Audit Logs. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setGridLoading(false);
    }
  };

  const getAdditionalFieldChanges = (
    previous_data: AuditLogValue,
    current_data: AuditLogValue,
    ignore_fields: Array<string>
  ) => {
    const updated_fields: Array<string> = [];
    Object.keys(current_data).forEach((field) => {
      if (
        current_data[field] !== previous_data[field] &&
        !ignore_fields.includes(field) &&
        (previous_data[field] || current_data[field])
      )
        updated_fields.push(field);
    });
    return updated_fields.join(', ');
  };

  React.useEffect(() => {
    try {
      (async () => {
        fetchGroups();
      })().catch((error) => {
        throw new Error(error);
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: AuditLogsGrid failed on an unknown error while initialising.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `An error occurred. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
    return;
  }, []);

  const columns: Array<GridColDef> = [
    {
      field: 'user',
      headerName: 'User',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'created',
      headerName: 'Timestamp',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value == null) return '';
        return moment(params.value).format('DD MMM yyyy hh:mm').toString();
      },
      flex: 1
    },
    {
      field: 'operation',
      headerName: 'Action',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params: GridRenderCellParams) => {
        return params.row.operation.toUpperCase();
      },
      flex: 1
    },
    {
      field: 'previous_value',
      headerName: 'Previous Record',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (params: GridValueFormatterParams) => {
        return params.value.metric !== undefined && params.value.value !== undefined
          ? `${params.value.metric}: ${params.value.value}`
          : params.value;
      },
      flex: 1
    },
    {
      field: 'next_value',
      headerName: 'Current Record',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (params: GridValueFormatterParams) => {
        return params.value.metric !== undefined && params.value.value !== undefined
          ? `${params.value.metric}: ${params.value.value}`
          : params.value;
      },
      flex: 1
    },
    {
      field: 'additional_updates',
      headerName: 'Additional Field Updates',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.previous_value.metric || params.row.next_value.metric
          ? getAdditionalFieldChanges(params.row.previous_value, params.row.next_value, [
              'metric',
              'value'
            ])
          : '';
      },
      flex: 1
    }
  ];

  return (
    <DataGrid
      autoHeight
      initialState={{
        pagination: {
          paginationModel: { pageSize: 10, page: 0 }
        },
        sorting: {
          sortModel: [{ field: 'created', sort: 'desc' }]
        }
      }}
      hideFooter={auditLogs.length > 1 ? false : true}
      columnHeaderHeight={auditLogs.length < 1 ? 0 : 56}
      pageSizeOptions={[10, 25, 50, 100]}
      loading={gridLoading}
      rows={auditLogs}
      columns={columns}
      disableRowSelectionOnClick
      slots={{
        toolbar: AuditLogsGridToolbar,
        noRowsOverlay: AuditLogsGridNoRows,
        loadingOverlay: AuditLogsGridLoading
      }}
      slotProps={{
        toolbar: {
          groups: groups,
          selected_group: selectedGroup,
          handleChangeGroup: (group: Group) => handleChangeGroup(group)
        },
        noRowsOverlay: {
          selected_group: selectedGroup
        }
      }}
      sx={{ '&, [class^=MuiDataGrid]': { border: 'none' } }}
    />
  );
};

export default AuditLogsGrid;
