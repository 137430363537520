import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import {
  DataGrid,
  GridToolbar,
  GridColDef,
  GridActionsCellItem,
  GridRenderCellParams
} from '@mui/x-data-grid';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import DeleteIcon from '@mui/icons-material/Delete';
import { Company, Group, ImportErrors, ImportMetaData, User } from '@esg/esg-global-types';
import moment from 'moment';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ImportErrorsModal from './ImportErrorsModal';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteConfirmModal from '../../shared/modal/DeleteConfirmModal';
import { deleteImportMetricRecords } from '../../../lib/metric_capture/import';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { CaptureContext } from '../../../@types/shared';
import { UserContext } from '../../../context/UserContext';
import { ViewAccess, getViewAccess } from '../../../util/user_access';
import SyncIcon from '@mui/icons-material/Sync';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';

const CustomNoRowsOverlay = () => {
  return (
    <div>
      <Box
        sx={{
          height: 100,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <ThunderstormIcon sx={{ fontSize: '2.5rem', mt: 4 }} />
        <Typography sx={{ my: 2 }}>No Imports Found</Typography>
      </Box>
    </div>
  );
};

const ImportsGrid = ({
  import_rows,
  grid_loading,
  capture_context,
  view_only,
  handleRemoveImport,
  refreshImports
}: {
  import_rows: Array<ImportMetaData>;
  grid_loading: boolean;
  capture_context: CaptureContext;
  view_only: boolean;
  handleRemoveImport: (import_id: string) => void;
  refreshImports: () => void;
}) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const user_info: User | null = React.useContext(UserContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [actionRow, setActionRow] = React.useState<ImportMetaData | null>(null);
  const [showErrorsModal, setShowErrorsModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

  const view_access: ViewAccess =
    user_info && group && company
      ? getViewAccess(user_info, group.id, company.id, 'metric_collection.management')
      : null;

  const handleOpenErrorsModal = (action_row: ImportMetaData) => {
    setActionRow(action_row);
    setShowErrorsModal(true);
  };

  const handleDeleteImport = async (import_id: string) => {
    try {
      if (group && company) {
        await deleteImportMetricRecords(group.id, company.id, import_id).then(() => {
          handleRemoveImport(import_id);
        });
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: ImportsGrid failed on an unknown error while calling handleDeleteImport.',
          {
            group: group,
            company: company,
            import_id: import_id
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to delete metric records. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'file_name',
      headerName: 'File',
      headerAlign: 'left',
      align: 'left',
      flex: 1.5
    },
    {
      field: 'created',
      headerName: 'Imported',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        return params.row.pending ? (
          <CircularProgress size={'1rem'} />
        ) : (
          moment(params.row.created).format('DD MMM YYYY HH:mm:ss').toString()
        );
      },
      align: 'left',
      flex: 1
    },
    {
      field: 'errors',
      headerName: 'Status',
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => {
        const { row, value }: { row: ImportMetaData; value?: ImportErrors } = params;
        if (params.row.pending) {
          return <CircularProgress size={'1rem'} />;
        }
        return value?.sites.length === 0 &&
          value?.metrics.length === 0 &&
          value?.values.length === 0 &&
          value?.proportions.length === 0 &&
          value?.notes.length === 0 &&
          value?.bannedCharacters.length === 0 ? (
          <CheckCircleOutlineIcon color="success" />
        ) : (
          <IconButton onClick={() => handleOpenErrorsModal(row)}>
            <WarningAmberIcon color="warning" />
          </IconButton>
        );
      },
      align: 'center',
      flex: 0.5,
      sortable: false
    },
    {
      field: 'valid_metric_records',
      headerName: 'Imported Metric Records',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        return params.row.pending ? <CircularProgress size={'1rem'} /> : params.value;
      },
      align: 'left',
      type: 'number',
      flex: 1
    },
    {
      field: 'null_value_records',
      headerName: 'Null Value Rows',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        return params.row.pending ? <CircularProgress size={'1rem'} /> : params.value;
      },
      align: 'left',
      type: 'number',
      flex: 1
    },
    {
      field: 'user',
      headerName: 'User',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    }
  ];
  if (!view_only && (view_access === 'admin' || view_access === 'write')) {
    columns.push({
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            key={2}
            icon={<DeleteIcon sx={{ fontSize: '1.5rem' }} />}
            disabled={row.pending}
            label="Delete"
            sx={{
              color: 'primary.main'
            }}
            onClick={() => {
              setActionRow(row);
              setShowDeleteModal(true);
            }}
          />
        ];
      }
    });
  }

  return (
    <>
      <DeleteConfirmModal
        allow_delete={true}
        allow_archive={false}
        open={showDeleteModal}
        handleCloseDeleteModal={() => setShowDeleteModal(false)}
        delete_label={actionRow && `${actionRow.file_name}`}
        delete_warning={`${actionRow?.valid_metric_records} Metric Records will be permanently deleted`}
        handleDelete={actionRow && (() => handleDeleteImport(actionRow.id))}
      />
      <ImportErrorsModal
        open={showErrorsModal}
        import_data={actionRow}
        handleCloseModal={() => setShowErrorsModal(false)}
      />
      <Box
        sx={{
          padding: '2%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Box display="flex" justifyContent="flex-start" alignItems="center" gap={2}>
          <Typography>
            Imports for{' '}
            {capture_context.reporting_period?.name ?? capture_context.reporting_period_group?.name}
          </Typography>
          <IconButton onClick={refreshImports}>
            <SyncIcon color="primary" />
          </IconButton>
        </Box>
        <DataGrid
          autoHeight
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 }
            },
            sorting: {
              sortModel: [{ field: 'created', sort: 'desc' }]
            }
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          sx={{ mt: 4 }}
          loading={grid_loading}
          rows={import_rows}
          columns={columns}
          checkboxSelection
          disableRowSelectionOnClick
          slots={{ toolbar: GridToolbar, noRowsOverlay: CustomNoRowsOverlay }}
        />
      </Box>
    </>
  );
};

export default ImportsGrid;
