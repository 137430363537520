import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { QuantitativeMetricRecordGridRow } from '../metric_records/quantitative/MetricRecordGridQuantitative';
import { CaptureContext } from '../../../@types/shared';
import {
  Box,
  Button,
  List,
  ListItem,
  IconButton,
  Typography,
  DialogActions,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { downloadFile } from '../../../lib/metric_capture/file';
import { Company, File, Group } from '@esg/esg-global-types';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { LoadingButton } from '@mui/lab';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { QualitativeMetricRecordGridRow } from '../metric_records/qualitative/MetricRecordGridQualitative';
import { removeMetricRecordFile } from '../../../lib/metric_capture/metric_record';

/**
 * A modal allowing the user to view and delete supporting documents uploaded for a Metric Record
 * @param {boolean} open The display state of the modal.
 * @param {QualitativeMetricRecordGridRow | QuantitativeMetricRecordGridRow | null} metric_record The metric record to manage docs for
 * @param {string} metric_record_title The display name of the metric record
 * @param {CaptureContext} capture_context The context of the metric record
 * @param {Array<File>} supporting_docs List of supporting documents belonging to given metric record
 * @param {void} handleCloseDocsModal Handler to close the modal
 * @param {void} handleRemoveSupportingDoc Handler function to remove a supporting document from a metric record row in parent grid
 * @returns {JSX.Element}
 */
const ManageSupportingDocsModal = ({
  open,
  metric_record,
  metric_record_title,
  capture_context,
  supporting_docs,
  handleCloseDocsModal,
  handleRemoveSupportingDoc
}: {
  open: boolean;
  metric_record: QualitativeMetricRecordGridRow | QuantitativeMetricRecordGridRow | null;
  metric_record_title: string;
  capture_context: CaptureContext;
  supporting_docs: Array<File>;
  handleCloseDocsModal: () => void;
  handleRemoveSupportingDoc: (metric_record_id: string, doc_id: string) => void;
}) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [delete_file, setDeleteFile] = React.useState<File | null>(null);
  const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false);

  const handleCloseModal = (): void => {
    handleCloseDocsModal && handleCloseDocsModal();
  };

  const deleteSupportingDoc = async (delete_file: File): Promise<void> => {
    setDeleteLoading(true);
    try {
      if (group && company && metric_record) {
        await removeMetricRecordFile(group.id, company.id, metric_record.id, delete_file);
        handleRemoveSupportingDoc(metric_record.id, delete_file.id);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: ManageSupportingDocs failed on an unknown error while calling removeFile.',
          {
            group: group,
            company: company,
            metric_record: metric_record,
            delete_file: delete_file
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to remove supporting document. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setDeleteLoading(false);
    }
  };

  const downloadSupportingDocHandler = async (supporting_doc: File): Promise<void> => {
    try {
      await downloadFile(supporting_doc);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: ManageSupportingDocs failed on an unknown error while calling downloadSupportDocHandler.',
          {
            supporting_doc: supporting_doc
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to download supporting document. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  return (
    <Dialog open={open} onClose={handleCloseModal}>
      <DialogTitle>Manage Supporting Documents</DialogTitle>
      {delete_file && (
        <DialogContent>
          <DialogContentText sx={{ marginY: 2 }}>
            Are you sure you want to Delete <b>{delete_file.name}</b>?
          </DialogContentText>

          <DialogActions sx={{ mt: 2 }}>
            <Button variant="text" size="medium" onClick={() => setDeleteFile(null)}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              size="medium"
              startIcon={<DeleteIcon />}
              loading={deleteLoading}
              onClick={async () => {
                await deleteSupportingDoc(delete_file);
                setDeleteFile(null);
              }}
              color="error"
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      )}
      {!delete_file && (
        <DialogContent>
          <DialogContentText>{metric_record_title}</DialogContentText>
          <Box
            sx={{
              marginY: 6,
              border: 1,
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderRadius: 1,
              paddingY: 3,
              paddingX: 1,
              textAlign: 'center'
            }}
          >
            {supporting_docs.length > 0 ? (
              <List dense={true}>
                {supporting_docs.map((doc: File) => {
                  return (
                    <ListItem key={doc.id}>
                      <ListItemIcon>
                        <InsertDriveFileIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={doc.name} />
                      <Tooltip title="Download File">
                        <IconButton
                          size="medium"
                          edge="end"
                          aria-label="delete"
                          color="primary"
                          sx={{ ml: 'auto' }}
                          onClick={() => {
                            downloadSupportingDocHandler(doc);
                          }}
                        >
                          <DownloadIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      {capture_context.reporting_period?.locked ? null : (
                        <Tooltip title="Delete File">
                          <IconButton
                            size="medium"
                            edge="end"
                            aria-label="delete"
                            color="primary"
                            sx={{ ml: 1 }}
                            onClick={() => {
                              setDeleteFile(doc);
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              <Typography fontSize={'0.9rem'}>No Supporting Docs for Metric Record</Typography>
            )}
          </Box>
          <DialogActions sx={{ mt: 2 }}>
            <Button variant="text" onClick={handleCloseModal}>
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ManageSupportingDocsModal;
