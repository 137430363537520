import {
  QuerySnapshot,
  QueryDocumentSnapshot,
  DocumentData,
  DocumentReference,
  CollectionReference,
  collection,
  doc,
  runTransaction,
  Transaction,
  DocumentSnapshot,
  getDocs
} from 'firebase/firestore';
import {
  MetricGroup,
  Metric,
  EntityLabel,
  Standard,
  Group,
  Company,
  EmissionFactor,
  EmissionFactorData
} from '@esg/esg-global-types';
import { FirestoreQueryParam } from '../../@types/shared';
import {
  BatchWrite,
  createFirestoreDoc,
  deleteFirestoreDoc,
  processBatchWrites,
  readFirestoreDocs
} from '../app/db_util';
import { uuidv4 } from '@firebase/util';
import { MetadataError } from '@ep/error-handling';
import { getMetricGroups } from './metric_group';
import { validateMasterListComparison, validateMasterListParams } from '../../util/validation';
import { CachedStandard, getStandards, getStandardsMasterList, refStandard } from '../app/standard';
import { db } from '../google/firebase';
import { createAuditLog } from '../app/audit';
import { refCompanyDoc } from '../app/company';
import { getMetricRecords } from './metric_record';

export interface MetricExtended extends Metric {
  metric_group: MetricGroup;
}

export interface CompanyStandardNameToReferenceMap {
  [name: string]: DocumentReference;
}
export interface CompanyMetricGroupNameToIdMap {
  [name: string]: string;
}

export type MetricData = Omit<Metric, 'id'>;

// Singular and plural label for model entity.
export const metric_label: EntityLabel = {
  one: 'Metric',
  many: 'Metrics'
};

/**
 * Creates a database reference for a Metric entity, either master list or for a configured company.
 * @param {string | undefined} id Id for a specific document. An undefined value is used to reference the collection to create a new document.
 * @param {string} metric_group_id The metric group to which a metric belongs or should belong.
 * @param {string} group_id When not referencing a master list document or collection, a group id for the configured company is required.
 * @param {string} company_id When not referencing a master list document or collection, a company id for the configured company is required.
 * @returns {DocumentReference} The reference of the document or collection.
 */
export const refMetric = (
  id: string | undefined,
  metric_group_id: string,
  group_id?: string,
  company_id?: string
): DocumentReference => {
  const path =
    group_id && company_id
      ? `groups/${group_id}/companies/${company_id}/metric_groups/${metric_group_id}/metrics`
      : `/metric_group_master_list/${metric_group_id}/metrics`;
  return id ? doc(collection(db, path), id) : doc(collection(db, path));
};

/**
 * Query all metric documents for specified group and company joined with relative Metric Group, and optional Standard, data.
 * @param {boolean} master_list Return master list or configured metric documents.
 * @param {string} group_id ID of configured group.
 * @param {string} company_id ID of configured company.
 * @param {Array<string>} standard_ids Query metrics within specified Standard id's.
 * @param {boolean} get_relative_standard Optionally query relative Standard data associated with each Metric.
 * @returns {Promise<Array<MetricExtended>>}
 */
export const getMetrics = async (
  master_list: boolean,
  group_id?: string,
  company_id?: string,
  standard_ids?: Array<string>,
  get_relative_standard?: boolean
): Promise<Array<MetricExtended>> => {
  try {
    validateMasterListParams(master_list, group_id, company_id);
    // Query metric groups from Firestore.
    const metric_groups: Array<MetricGroup> = master_list
      ? await getMetricGroups(true)
      : await getMetricGroups(false, group_id, company_id);
    const metrics: Array<MetricExtended> = [];
    const metric_group_promises: Array<Promise<void>> = metric_groups.map(
      async (metric_group: MetricGroup) => {
        const collection_path = master_list
          ? `/metric_group_master_list/${metric_group.id}/metrics`
          : `groups/${group_id}/companies/${company_id}/metric_groups/${metric_group.id}/metrics`;
        const query_params: Array<FirestoreQueryParam> = [
          { field_name: 'deleted', operator: '==', value: null }
        ];
        // Define query parameters for fetching metrics with a relationship to the specified standard id's.
        if (standard_ids !== undefined && standard_ids.length > 0) {
          const standards: Array<DocumentReference> = [];
          standard_ids.forEach((id: string) => {
            standards.push(refStandard(id, group_id, company_id));
          });
          query_params.push({ field_name: 'standard', operator: 'in', value: standards });
        }
        // Create standards cache to reduce the amount of queries to the Standards collection.
        const metric_standard_cache: CachedStandard = {};
        if (master_list) {
          const metric_standards = await getStandardsMasterList();
          metric_standards.map((standard: Standard) => {
            metric_standard_cache[standard.id] = standard;
          });
        } else if (company_id && group_id) {
          const metric_standards = await getStandards(group_id, company_id);
          metric_standards.map((standard: Standard) => {
            metric_standard_cache[standard.id] = standard;
          });
        }
        // Query metrics from Firestore.
        const metric_snapshot: QuerySnapshot = await readFirestoreDocs(
          collection_path,
          query_params
        );
        const metric_promises: Array<Promise<void>> = metric_snapshot.docs.map(
          async (metric: QueryDocumentSnapshot) => {
            const metric_data: DocumentData = metric.data();
            // Build generic metric object.
            const metric_data_mapped = {
              ...{
                id: metric.id,
                deleted: metric_data.deleted,
                name: metric_data.name,
                description: metric_data.description,
                unit: metric_data.unit,
                scope: metric_data.scope,
                metric_group: metric_group
              },
              // Conditionally add non-master list properties to metric object.
              ...(!master_list &&
                metric_data.master_list_metric && {
                  master_list_metric: metric_data.master_list_metric
                })
            };
            // Append a Standard object to the Metrics array without querying the Standards collection for each Metric.
            if (get_relative_standard) {
              const metric_standard_id = metric_data.standard.id;
              const cached_metric_standard: Standard = metric_standard_cache[metric_standard_id];
              if (cached_metric_standard) {
                metric_data_mapped['standard'] = cached_metric_standard;
                metrics.push(metric_data_mapped);
              } else {
                const standard_ref: DocumentReference = refStandard(
                  metric_standard_id,
                  group_id,
                  company_id
                );
                const metric_standards: Array<Standard> = master_list
                  ? await getStandardsMasterList()
                  : await getStandards(group_id || '', company_id || '', undefined, [standard_ref]);
                metric_snapshot.forEach((metric: QueryDocumentSnapshot) => {
                  metric_standard_cache[metric.id] = metric.data() as Standard;
                });
                metric_data_mapped['standard'] = metric_standards[0];
                // Push only metrics for non-deleted standards.
                if (metric_data_mapped['standard']) metrics.push(metric_data_mapped);
              }
            } else {
              metric_data_mapped['standard'] = metric_data.standard;
              metrics.push(metric_data_mapped);
            }
          }
        );
        await Promise.all(metric_promises);
      }
    );
    await Promise.all(metric_group_promises);
    return metrics;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error ? err.message : 'Error: getMetrics failed on an unknown error.',
      {
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Query all unconfigured metric documents for specified group and company joined with an optional relative Standard object.
 * @param {string} group_id ID of configured group.
 * @param {string} company_id ID of configured company.
 * @param {Array<string>} standard_ids Query metrics within specified Standard id's.
 * @param {boolean} get_relative_standard Optionally query relative Standard data associated with each Metric.
 * @returns {Promise<Array<MetricExtended>>}
 */
export const getUnconfiguredMetrics = async (
  group_id: string,
  company_id: string,
  standard_ids?: Array<string>,
  get_relative_standard?: boolean
): Promise<Array<MetricExtended>> => {
  try {
    const metric_master_list: Array<MetricExtended> = await getMetrics(
      true,
      undefined,
      undefined,
      standard_ids,
      get_relative_standard
    );
    const metrics: Array<MetricExtended> = await getMetrics(
      false,
      group_id,
      company_id,
      undefined,
      get_relative_standard
    );
    validateMasterListComparison(metric_master_list, metrics);
    // Get the difference between the master and configured Metric list.
    const unconfigured_metrics: Array<MetricExtended> = metric_master_list.filter(
      (metric_master: MetricExtended) =>
        metrics.every(
          (metric: MetricExtended) =>
            !(metric.master_list_metric?.id
              ? metric.master_list_metric?.id.includes(metric_master.id)
              : '')
        )
    );
    return unconfigured_metrics;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: getUnconfiguredMetrics failed on an unknown error.',
      {
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Create metrics on company level from provided master list metrics.
 * @param {string} group currently selected group from context.
 * @param {string} company_id currently selected company from context.
 * @param {Array<string>} new_metrics list of new unconfigured metrics to be added to the company.
 * @param {boolean} emission_factors Optionally add default emission factors to the new metrics.
 * @returns {Promise<Array<void>>}
 */
export const createMetrics = async (
  group: Group,
  company: Company,
  new_metrics: Array<MetricExtended>,
  emission_factors?: Array<EmissionFactor>
) => {
  try {
    {
      const company_standards: Array<Standard> = await getStandards(group.id, company.id);
      const company_standard_reference_map: CompanyStandardNameToReferenceMap = {};
      company_standards.map((standard: Standard) => {
        company_standard_reference_map[standard.name] = standard.reference;
      });
      const company_metric_groups: Array<MetricGroup> = await getMetricGroups(
        false,
        group.id,
        company.id
      );
      const company_metric_group_id_map: CompanyMetricGroupNameToIdMap = {};
      company_metric_groups.map((metric_group: MetricGroup) => {
        company_metric_group_id_map[metric_group.name] = metric_group.id;
      });

      const metric_writes: Array<BatchWrite> = [];
      const emission_factor_writes: Array<BatchWrite> = [];
      for (const metric of new_metrics) {
        const metric_standard: Standard = metric.standard as Standard;
        const master_list_metric_collection: CollectionReference = collection(
          db,
          `/metric_group_master_list/${metric.metric_group.id}/metrics`
        );
        const master_list_metric_reference: DocumentReference = doc(
          master_list_metric_collection,
          metric.id
        );

        if (!company_metric_group_id_map[metric.metric_group.name]) {
          const new_metric_group_doc: DocumentReference = await createFirestoreDoc(
            `/groups/${group.id}/companies/${company.id}/metric_groups`,
            {
              deleted: null,
              description: metric.metric_group.description,
              master_list_metric_group: doc(
                db,
                `metric_group_master_list/${metric.metric_group.id}`
              ),
              name: metric.metric_group.name
            }
          );
          company_metric_group_id_map[metric.metric_group.name] = new_metric_group_doc.id;
        }

        const metrics_ref: DocumentReference = doc(
          collection(
            db,
            `/groups/${group.id}/companies/${company.id}/metric_groups/${company_metric_group_id_map[metric.metric_group.name]}/metrics`
          )
        );
        const metric_data: MetricData = {
          deleted: metric.deleted,
          description: metric.description,
          master_list_metric: master_list_metric_reference,
          name: metric.name,
          scope: metric.scope,
          standard: company_standard_reference_map[metric_standard.name],
          unit: metric.unit
        };
        metric_writes.push({ reference: metrics_ref, operation: 'create', data: metric_data });
        if (metric_standard.is_quantitative && emission_factors) {
          const matched_emission_factor: EmissionFactor | undefined = emission_factors.find(
            (emission_factor: EmissionFactor) => emission_factor.metric.id === metric.id
          );
          if (matched_emission_factor) {
            const emission_factor_data: EmissionFactorData = {
              deleted: null,
              end_date: matched_emission_factor.end_date,
              factor: matched_emission_factor.factor,
              source: matched_emission_factor.source,
              start_date: matched_emission_factor.start_date
            };
            const emission_factor_ref: DocumentReference = doc(
              collection(
                db,
                `/groups/${group.id}/companies/${company.id}/metric_groups/${company_metric_group_id_map[metric.metric_group.name]}/metrics/${metrics_ref.id}/emission_factors`
              )
            );

            emission_factor_writes.push({
              reference: emission_factor_ref,
              operation: 'create',
              data: emission_factor_data
            });
          }
        }
      }
      await processBatchWrites(metric_writes);
      await processBatchWrites(emission_factor_writes);
    }
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error ? err.message : 'Error: createMetrics failed on an unknown error.',
      {
        group: group,
        company: company,
        new_metrics: new_metrics,
        emission_factors: emission_factors
      },
      tracking_id
    );
  }
};

/**
 * Function to soft delete Metric
 * @param {string} group_id ID of Group to delete metric for
 * @param {string} company_id ID of Company to delete metric for
 * @param {string} metric_id ID of metric to delete
 * @param {string} metric_group_id ID of metric group to find delete metric in
 * @returns {void}
 */
export const deleteMetric = async (
  group_id: string,
  company_id: string,
  metric_id: string,
  metric_group_id: string,
  metric_name: string
) => {
  const collection_path = `groups/${group_id}/companies/${company_id}/metric_groups/${metric_group_id}/metrics`;
  try {
    const metric_doc: DocumentReference = await deleteFirestoreDoc(collection_path, metric_id);
    await createAuditLog(group_id, 'delete', metric_name, '', metric_doc);
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/metric.ts failed on an unknown error while calling deleteMetric.',
      {
        group_id: group_id,
        company_id: company_id,
        metric_id: metric_id,
        metric_group_id: metric_group_id,
        metric_name: metric_name
      },
      tracking_id
    );
  }
};

/**
 * Soft delete Metric and any nested Emission Factors in Firestore transaction
 * @param {string} group_id Group to delete metric from
 * @param {string} company_id Company to delete metric from
 * @param {string} metric_id ID of metric to delete
 * @param {string} metric_group_id ID of metric group to find metric in
 * @param {string} metric_name Name of metric to delete
 * @return {void}
 */
export const deleteMetricWithEmissionFactors = async (
  group_id: string,
  company_id: string,
  metric_id: string,
  metric_group_id: string,
  metric_name: string
) => {
  try {
    const metric_doc: DocumentReference = refMetric(
      metric_id,
      metric_group_id,
      group_id,
      company_id
    );
    const emission_factor_collection: CollectionReference = collection(
      metric_doc,
      'emission_factors'
    );
    const emission_factor_docs: QuerySnapshot = await getDocs(emission_factor_collection);

    await runTransaction(db, async (transaction: Transaction) => {
      const metric_snapshot: DocumentSnapshot = await transaction.get(metric_doc);
      if (!metric_snapshot.exists()) {
        throw 'Could not find Metric document to delete';
      }
      transaction.update(metric_doc, { deleted: new Date() });
      emission_factor_docs.docs.forEach((emission_factor: DocumentSnapshot) => {
        transaction.update(emission_factor.ref, { deleted: new Date() });
      });
    });
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/metric.ts failed on an unknown error while calling deleteMetricWithEmissionFactors.',
      {
        group_id: group_id,
        company_id: company_id,
        metric_id: metric_id,
        metric_group_id: metric_group_id,
        metric_name: metric_name
      },
      tracking_id
    );
  }
};

/**
 * Function to check if a Metric can be safely deleted
 * @param {string} group_id ID of Group to check Metric for
 * @param {string} company_id ID of Company to check Metric for
 * @param {string} metric_id ID of metric to check
 * @returns {boolean}
 */
export const allowDeleteMetric = async (
  group_id: string,
  company_id: string,
  metric_id: string,
  metric_group_id: string
) => {
  try {
    const query_params: Array<FirestoreQueryParam> = [
      {
        field_name: 'metric',
        operator: '==',
        value: doc(
          collection(
            refCompanyDoc(group_id, company_id),
            `metric_groups/${metric_group_id}/metrics`
          ),
          metric_id
        )
      }
    ];
    const metric_records = await getMetricRecords(group_id, company_id, query_params);
    const allow_delete = metric_records.length === 0;
    return allow_delete;
  } catch (err) {
    const error = `Error while checking Metric for deletion: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: allowDeleteMetric: ${JSON.stringify(error)}.`);
  }
};
