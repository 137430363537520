import React from 'react';
import { Tooltip } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  GridActionsCellItem,
  GridRowModesModel,
  GridRowModes,
  GridRowId,
  GridEditSingleSelectCellProps,
  useGridApiContext,
  GridEditSingleSelectCell,
  GridPreProcessEditCellProps,
  GridValueFormatterParams,
  GridEditInputCell,
  GridValueSetterParams,
  GridRenderEditCellParams
} from '@mui/x-data-grid';
import MetricRecordGridQuantitativeToolbar from './MetricRecordGridQuantitativeToolbar';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import {
  joinMetricRecordsMetrics,
  getMetricRecordsForContext,
  joinMetricRecordsExternalCompany,
  updateMetricRecord,
  deleteMetricRecord,
  MetricRecordWithMetricExtended,
  MetricRecordInputData
} from '../../../../lib/metric_capture/metric_record';
import DeleteConfirmModal from '../../../shared/modal/DeleteConfirmModal';
import { getFiles } from '../../../../lib/metric_capture/file';
import {
  Company,
  EmissionFactor,
  ExternalCompany,
  File,
  Group,
  MetricGroup,
  MetricRecord,
  ReportingPeriod,
  User
} from '@esg/esg-global-types';
import { DocumentReference } from 'firebase/firestore';
import UploadSupportingDocsModal from '../../supporting_docs/UploadSupportingDocsModal';
import ManageSupportingDocsModal from '../../supporting_docs/ManageSupportingDocsModal';
import { UserContext } from '../../../../context/UserContext';
import { FeedbackSnackbarContext } from '../../../../context/FeedbackSnackbarContext';
import { ViewAccess, getViewAccess } from '../../../../util/user_access';
import { GroupContext } from '../../../../context/GroupContext';
import { CompanyContext } from '../../../../context/CompanyContext';
import { getEmissionFactorForReportingPeriod } from '../../../../lib/app/emission_factor';
import { hasBannedCharacters } from '../../../../lib/validation/text_validation';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../../util/log';
import { reportingPeriodFilterByGroup } from '../../../../lib/metric_capture/reporting_period';
import { MetricExtended } from '../../../../lib/metric_capture/metric';
import { CaptureContext, MetricRecordEntities } from '../../../../@types/shared';
import { MetricRecordGridQualitativeNoRows } from '../qualitative/MetricRecordGridQualitativeNoRows';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import EditAverageRecordModal from './EditAverageRecordModal';

export interface QuantitativeMetricRecordGridRow {
  id: string;
  category: MetricGroup;
  metric: MetricExtended;
  value: string;
  unit: string;
  proportion: number;
  note: string | null;
  scope: number;
  external_company: ExternalCompany | null;
  docs: boolean;
  emission_factor: DocumentReference;
  reporting_period: string;
  is_averaged: boolean;
}

interface ReportingPeriodReferenceToNameMap {
  [document_path: string]: string;
}

interface SelectEditOption {
  value: string;
  label: string;
}

/**
 * Custom selector for editing the metric group (category) of a Metric Record.
 * @param {GridEditSingleSelectCellProps} props Predefined attributes of an MUI Datagrid Edit Cell element.
 * @returns {JSX.Element}
 */
const CustomCategoryEditComponent = (props: GridEditSingleSelectCellProps) => {
  const api_ref: React.MutableRefObject<GridApiCommunity> = useGridApiContext();
  const handleValueChange = async (): Promise<void> => {
    await api_ref.current.setEditCellValue({
      id: props.id,
      field: 'metric',
      value: ''
    });
    await api_ref.current.setEditCellValue({
      id: props.id,
      field: 'unit',
      value: ''
    });
  };

  return <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />;
};

/**
 * Datagrid handling full CRUD functionality for quantitative Metric Records
 * @param {CaptureContext} capture_context Object of context fields to use for Metric Record creation and editing
 * @param {MetricRecordEntities} capture_entities List of options user may set for Metric Record data
 * @returns {JSX.Element}
 */
const MetricRecordGridQuantitative = ({
  capture_context,
  capture_entities
}: {
  capture_context: CaptureContext;
  capture_entities: MetricRecordEntities;
}) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const user_info: User | null = React.useContext(UserContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);

  // Component variables
  const context_archived: boolean =
    capture_context.region?.archived ||
    capture_context.division?.archived ||
    capture_context.subdivision?.archived ||
    capture_context.site?.archived
      ? true
      : false;

  const reporting_period_group_locked: boolean =
    (capture_context.reporting_period_group &&
      capture_context.reporting_period_group?.id !== 'all' &&
      capture_context.reporting_period &&
      capture_context.reporting_period.id === 'all' &&
      reportingPeriodFilterByGroup(
        capture_context.reporting_period_group?.id,
        capture_entities.reporting_periods
      ).some((reporting_period) => {
        return reporting_period.locked === true;
      })) ??
    false;

  const view_access: ViewAccess =
    user_info && group && company
      ? getViewAccess(user_info, group.id, company.id, 'metric_collection.management')
      : null;
  const view_only: boolean =
    capture_context.reporting_period?.locked ||
    reporting_period_group_locked ||
    view_access === 'read' ||
    context_archived
      ? true
      : false;

  const [metricRecordRows, setMetricRecordRows] = React.useState<
    Array<QuantitativeMetricRecordGridRow>
  >([]);
  const [supportingDocs, setSupportingDocs] = React.useState<Array<File>>([]);
  const [actionRow, setActionRow] = React.useState<QuantitativeMetricRecordGridRow | null>(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [showUploadDocsModal, setShowUploadDocsModal] = React.useState<boolean>(false);
  const [showManageDocsModal, setShowManageDocsModal] = React.useState<boolean>(false);
  const [showEditAverageModal, setShowEditAverageModal] = React.useState(false);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [gridLoading, setGridLoading] = React.useState<boolean>(true);
  const [editRowsEmissionFactors, setEditRowsEmissionFactors] = React.useState<{
    [row_id: string]: DocumentReference | null;
  }>({});
  const [editRowsInputErrors, setEditRowsInputErrors] = React.useState<{
    [row_id: string]: boolean;
  }>({});

  const standard_metrics: Array<MetricExtended> = capture_entities.metrics.filter(
    (metric: MetricExtended) => metric.standard.id === capture_context.standard?.id
  );

  const averaged_value_warning =
    'This metric record was part of an averaged calculation across a Reporting Period Group. The changes will not be applied to any other associated metric records. Are you sure you want to proceed?';

  const categories: Array<MetricGroup> = standard_metrics.reduce(
    (accumulator: Array<MetricGroup>, metric: MetricExtended) => {
      if (
        !accumulator.some((option: MetricGroup) => option && option.id === metric.metric_group.id)
      ) {
        accumulator.push(metric.metric_group);
      }
      return accumulator;
    },
    []
  );

  const full_metric_names: Array<string> = standard_metrics.reduce(
    (accumulator: Array<string>, metric: MetricExtended) => {
      if (!accumulator.some((name: string) => name && name === metric.name)) {
        accumulator.push(metric.name);
      }
      return accumulator;
    },
    []
  );

  /**
   * Function to return Metrics belonging to a certain Metric Group for a datagrid cell select
   * @param {Array<MetricExtended>} metrics Full list of Metrics to filter
   * @param {string} filter_metric_group_id ID of Metric Group to filter on
   * @returns {Array<SelectEditOption>}
   */
  const getMetricOptions = (
    metrics: Array<MetricExtended>,
    filter_metric_group_id: string
  ): Array<SelectEditOption> => {
    return metrics
      .filter((metric: MetricExtended) => metric.metric_group.id === filter_metric_group_id)
      .map((metric: MetricExtended) => {
        return { value: metric.id, label: metric.name };
      });
  };

  // Handler functions
  const handleEditClick = (row: QuantitativeMetricRecordGridRow): void => {
    setActionRow(row);
    if (row.is_averaged) {
      setShowEditAverageModal(true);
    } else {
      setRowModesModel({ ...rowModesModel, [row.id]: { mode: GridRowModes.Edit } });
    }
  };

  const handleDeleteClick = (metric_record: QuantitativeMetricRecordGridRow): void => {
    setActionRow(metric_record);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = (): void => {
    setShowDeleteModal(false);
  };

  const handleCloseEditAverageModal = (): void => {
    setShowEditAverageModal(false);
  };

  const handleCancelClick = (id: GridRowId): void => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });
    setEditRowsEmissionFactors((emission_factors) => {
      const new_emission_factors_state = { ...emission_factors };
      delete new_emission_factors_state[id];
      return new_emission_factors_state;
    });
    setEditRowsInputErrors((input_errors) => {
      const new_input_errors_state = { ...input_errors };
      delete new_input_errors_state[id];
      return new_input_errors_state;
    });
  };

  const handleCloseUploadDocsModal = (): void => {
    setShowUploadDocsModal(false);
  };

  const handleCloseManageDocsModal = (): void => {
    setShowManageDocsModal(false);
  };

  const handleOpenUploadDocsModal = (action_row: QuantitativeMetricRecordGridRow): void => {
    setActionRow(action_row);
    setShowUploadDocsModal(true);
  };

  const handleOpenManageDocsModal = (action_row: QuantitativeMetricRecordGridRow): void => {
    setActionRow(action_row);
    setShowManageDocsModal(true);
  };

  const handleConfirmClick = (id: GridRowId): void => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleAddSupportingDoc = (row_id: string, supporting_doc: File): void => {
    setSupportingDocs([...supportingDocs, supporting_doc]);
    const new_rows: Array<QuantitativeMetricRecordGridRow> = metricRecordRows.map(
      (row: QuantitativeMetricRecordGridRow) => (row_id === row.id ? { ...row, docs: true } : row)
    );
    setMetricRecordRows(new_rows);
  };

  const handleRemoveSupportingDoc = (row_id: string, delete_doc_id: string): void => {
    const metric_record_docs: Array<File> = supportingDocs.filter(
      (doc: File) => doc.metric_record.id === row_id && doc.id !== delete_doc_id
    );
    if (metric_record_docs.length === 0) {
      const new_rows = metricRecordRows.map((row: QuantitativeMetricRecordGridRow) =>
        row_id === row.id ? { ...row, docs: false } : row
      );
      setMetricRecordRows(new_rows);
    }
    setSupportingDocs(supportingDocs.filter((doc) => doc.id !== delete_doc_id));
  };

  // Row functions
  const fetchRows = async (): Promise<void> => {
    try {
      if (group && company) {
        const metric_records: Array<MetricRecord> = await getMetricRecordsForContext(
          group.id,
          company.id,
          capture_context
        );
        const supporting_docs: Array<File> = await getFiles(group.id, company.id);
        const metric_records_with_external_company: Array<MetricRecord> =
          await joinMetricRecordsExternalCompany(metric_records);
        const reporting_period_reference_map: ReportingPeriodReferenceToNameMap = {};
        capture_entities.reporting_periods.map((reporting_period: ReportingPeriod) => {
          if (reporting_period.reference)
            reporting_period_reference_map[reporting_period.reference.id] = reporting_period.name;
        });
        const metric_records_with_metrics: Array<MetricRecordWithMetricExtended> =
          joinMetricRecordsMetrics(metric_records_with_external_company, standard_metrics);
        const metric_record_rows: Array<QuantitativeMetricRecordGridRow> =
          metric_records_with_metrics
            .filter((metric_record: MetricRecordWithMetricExtended) => {
              return metric_record.emission_factor && metric_record.scope;
            })
            .map((metric_record: MetricRecordWithMetricExtended) => {
              const proportion_corrected = metric_record.proportion
                ? metric_record.proportion * 100
                : 100;
              return {
                id: metric_record.id,
                category: metric_record.metric.metric_group,
                metric: metric_record.metric,
                value: metric_record.value.toString(),
                unit: metric_record.metric.unit,
                proportion: proportion_corrected,
                reporting_period:
                  reporting_period_reference_map[metric_record.reporting_period?.id ?? ''],
                note: metric_record.note ?? '',
                scope: metric_record.scope!,
                external_company: metric_record.external_company as ExternalCompany | null,
                is_averaged: metric_record.is_averaged ?? false,
                docs:
                  supporting_docs.filter((file: File) => {
                    return file.metric_record.id === metric_record.id;
                  }).length > 0,
                emission_factor: metric_record.emission_factor as DocumentReference
              };
            });
        setSupportingDocs(supporting_docs);
        setMetricRecordRows(metric_record_rows);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: MetricRecordGridQuantitative failed on an unknown error while calling fetchMetricRecordsForGrid.',
          {
            group: group,
            company: company,
            capture_context: capture_context
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch metric records. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setGridLoading(false);
    }
  };

  const validateMetricRecord = (metric_record: QuantitativeMetricRecordGridRow): boolean => {
    return (
      !!metric_record.category &&
      !!metric_record.metric &&
      metric_record.value !== null &&
      metric_record.value !== undefined &&
      !isNaN(Number(metric_record.value)) &&
      Number(metric_record.value) >= 0 &&
      Number(metric_record.proportion) >= 0 &&
      Number(metric_record.proportion) <= 100
    );
  };

  const updateRow = async (
    old_metric_record: QuantitativeMetricRecordGridRow,
    new_metric_record: QuantitativeMetricRecordGridRow
  ): Promise<QuantitativeMetricRecordGridRow | undefined> => {
    try {
      setGridLoading(true);
      const valid_input: boolean = validateMetricRecord(new_metric_record);
      if (!valid_input) {
        setFeedbackData({
          message: `Invalid inputs.`,
          state: true,
          type: 'warning'
        });
        return old_metric_record;
      }
      if (
        new_metric_record.metric === old_metric_record.metric &&
        new_metric_record.value === old_metric_record.value &&
        new_metric_record.proportion === old_metric_record.proportion &&
        new_metric_record.note === old_metric_record.note
      ) {
        return old_metric_record;
      }
      if (
        new_metric_record.metric &&
        new_metric_record.value !== undefined &&
        group &&
        company &&
        capture_context.reporting_period
      ) {
        if (!new_metric_record.proportion) new_metric_record.proportion = 100;
        const metric: MetricExtended = new_metric_record.metric;
        const emission_factor: DocumentReference | null | undefined =
          editRowsEmissionFactors[new_metric_record.id] === undefined
            ? old_metric_record.emission_factor
            : editRowsEmissionFactors[new_metric_record.id];
        if (!emission_factor) {
          setFeedbackData({
            message: `No Emission Factor configured for ${metric.name}, Contact An Admin To Resolve This Error`,
            state: true,
            type: 'error'
          });
          return old_metric_record;
        }
        const original_metric_record_data: MetricRecordInputData = {
          value: Number(old_metric_record.value),
          proportion: old_metric_record.proportion / 100,
          metric: old_metric_record.metric,
          emission_factor: old_metric_record.emission_factor,
          note: old_metric_record.note ? old_metric_record.note : null,
          scope: old_metric_record.scope
        };
        const updated_metric_record_data: MetricRecordInputData = {
          value: Number(new_metric_record.value),
          proportion: new_metric_record.proportion / 100,
          metric: metric,
          emission_factor: emission_factor,
          note: new_metric_record.note ? new_metric_record.note : null,
          scope: capture_context.external_company ? 3 : metric.scope
        };
        await updateMetricRecord(
          group.id,
          company.id,
          new_metric_record.id,
          original_metric_record_data,
          updated_metric_record_data
        );
        setMetricRecordRows([
          ...metricRecordRows.map((metric_record) => {
            return metric_record.id === new_metric_record.id ? new_metric_record : metric_record;
          })
        ]);
        return new_metric_record;
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: MetricRecordGridQuantitative failed on an unknown error while calling handleEditMetricRecord.',
          {
            old_metric_record: old_metric_record,
            new_metric_record: new_metric_record
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update metric record. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setGridLoading(false);
    }
  };

  const deleteRow = async (metric_record: QuantitativeMetricRecordGridRow): Promise<void> => {
    try {
      if (group && company) {
        await deleteMetricRecord(group.id, company.id, metric_record);
        setMetricRecordRows(metricRecordRows.filter((row) => row.id !== metric_record.id));
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: MetricRecordGridQuantitative failed on an unknown error while calling handleDeleteMetricRecord.',
          {
            group_id: group?.id,
            company_id: company?.id,
            metric_record_id: metric_record.id,
            metric_record_value: metric_record
          },
          tracking_id
        )
      );
    }
  };

  React.useEffect(() => {
    try {
      fetchRows();
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: MetricRecordGridQuantitative failed on an unknown error while initialising.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch metric records. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'category',
      headerName: 'Category',
      type: 'singleSelect',
      renderCell: (params: GridRenderCellParams) => {
        return params.row.category.name;
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomCategoryEditComponent variant="outlined" {...params} />
      ),
      valueGetter: (params: GridValueGetterParams) => (params.value ? params.value.id : ''),
      valueSetter: (params: GridValueSetterParams) => {
        const metric_group: MetricGroup | undefined = categories.find(
          (metric_group: MetricGroup) => metric_group.id === params.value
        );
        return metric_group ? { ...params.row, category: metric_group } : '';
      },
      valueOptions: categories.map((category: MetricGroup) => ({
        value: category.id,
        label: category.name
      })),
      flex: 1.2,
      editable: true
    },
    {
      field: 'metric',
      headerName: 'Metric',
      type: 'singleSelect',
      renderCell: (params: GridRenderCellParams) => {
        return params.row.metric.name;
      },
      valueGetter: (params: GridValueGetterParams) =>
        params.value && params.value.id ? params.value.id : '',
      valueSetter: (params: GridValueSetterParams) => {
        if (params.value) {
          const metric: MetricExtended | undefined = standard_metrics.find(
            (metric: MetricExtended) => metric.id === params.value
          );
          return metric ? { ...params.row, metric: metric } : '';
        }
        return params.row;
      },
      valueOptions: ({ row }) => {
        if (!row) {
          return full_metric_names;
        }
        return getMetricOptions(standard_metrics, row.category.id);
      },
      valueParser: (value, params) => {
        const metric: MetricExtended | undefined = standard_metrics.find(
          (metric: MetricExtended) => metric.id === value
        );
        if (metric) {
          setEditRowsEmissionFactors({
            ...editRowsEmissionFactors,
            [params?.row.id]: null
          });
          (async () => {
            const emission_factor: EmissionFactor | null =
              group && company && capture_context.reporting_period
                ? (await getEmissionFactorForReportingPeriod(
                    group.id,
                    company.id,
                    metric.metric_group.id,
                    metric.id,
                    capture_context.reporting_period
                  )) || null
                : null;
            setEditRowsEmissionFactors({
              ...editRowsEmissionFactors,
              [params?.row.id]: emission_factor ? emission_factor.ref : null
            });
            if (!emission_factor) {
              setFeedbackData({
                message: `No Emission Factor Configured for ${metric.name}, Contact An Admin To Resolve This Error`,
                state: true,
                type: 'warning'
              });
            }
          })();
        }
        return value;
      },
      flex: 1.2,
      editable: true
    },
    {
      field: 'reporting_period',
      headerName: 'Reporting Period',
      headerAlign: 'left',
      type: 'string',
      align: 'left',
      renderCell: (params: GridRenderCellParams) => {
        return params.value;
      },
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value == null) {
          return 'No Reporting Period';
        }
        return params.value;
      },
      valueGetter: (params) => {
        return params.row?.reporting_period;
      },
      flex: 1,
      editable: false
    },
    {
      field: 'value',
      headerName: 'Value',
      headerAlign: 'left',
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          value={Number(params.value)
            .toFixed(10)
            .replace(/\.?0+$/, '')}
          inputProps={{
            min: 0
          }}
        />
      ),
      type: 'number',
      align: 'left',
      flex: 0.75,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        return parseFloat(Number(params.value).toFixed(10));
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return 0;
        }
        return parseFloat(Number(params.value).toFixed(10));
      }
    },
    {
      field: 'unit',
      headerName: 'Unit',
      align: 'center',
      headerAlign: 'center',
      renderEditCell: (params) => <span>{params.value}</span>,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const metric: MetricExtended | undefined = standard_metrics.find(
          (metric: MetricExtended) => metric.id === params.otherFieldsProps?.metric.value
        );
        if (metric) {
          params.props.value = metric.unit;
        }
        return { ...params.props };
      },
      flex: 0.7,
      editable: true
    },
    {
      field: 'proportion',
      headerName: 'Client Allocation (%)',
      headerAlign: 'left',
      type: 'number',
      align: 'left',
      flex: 0.9,
      editable: capture_context.user_type?.id == 'internal' ? false : true,
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            max: 100,
            min: 0
          }}
        />
      ),
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return 100;
        }
        return parseFloat(Number(params.value).toFixed(2));
      }
    },
    {
      field: 'note',
      headerName: 'Note',
      headerAlign: 'left',
      renderEditCell: (params) => <GridEditInputCell {...params} />,
      type: 'string',
      align: 'left',
      flex: 1,
      editable: true,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params.value}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (params.value == null) {
          return '';
        }
        return params.value;
      },
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = !!(
          (params.props.value && params.props.value.length > 150) ||
          (params.props.value && hasBannedCharacters(params.props.value))
        );
        setEditRowsInputErrors({
          ...editRowsInputErrors,
          [params?.row.id]: hasError
        });
        return { ...params.props, error: hasError };
      }
    },
    {
      field: 'scope',
      headerName: 'Scope',
      headerAlign: 'left',
      type: 'number',
      align: 'left',
      renderCell: (params: GridRenderCellParams) => parseFloat(Number(params.value).toFixed(1)), // TODO: Allow only 1,2,3.
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return 0;
        }
        return parseFloat(Number(params.value).toFixed(1));
      },
      valueGetter: (params) => {
        return params.row.scope;
      },
      flex: 0.5,
      editable: false,
      hideable: true
    },
    {
      field: 'external_company',
      headerName: 'Captured by',
      headerAlign: 'left',
      type: 'string',
      align: 'left',
      renderCell: (params: GridRenderCellParams) => {
        return params.value;
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return 0;
        }
        return params.value;
      },
      valueGetter: (params) => {
        return params.row?.external_company?.name || company?.name;
      },
      flex: 1,
      editable: false
    }
  ];
  if (!view_only) {
    columns.push({
      field: 'actions',
      type: 'actions',
      headerName: '',
      align: 'right',
      flex: 1,
      getActions: ({ row }) => {
        const edit_row: boolean = rowModesModel[row.id]?.mode === GridRowModes.Edit;
        if (edit_row) {
          return [
            <Tooltip title="Save Record" key={1}>
              <GridActionsCellItem
                icon={<CheckIcon sx={{ fontSize: '1.5rem' }} />}
                label="Confirm"
                sx={{
                  color: '#053d5d'
                }}
                onClick={() => handleConfirmClick(row.id)}
                disabled={
                  (editRowsInputErrors[row.id] ?? false) ||
                  (editRowsEmissionFactors[row.id] === null ?? false)
                }
              />
            </Tooltip>,
            <Tooltip title="Discard Record" key={2}>
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                color="inherit"
                onClick={() => {
                  handleCancelClick(row.id);
                }}
              />
            </Tooltip>
          ];
        }
        return [
          <>
            {row.docs && (
              <Tooltip title="Manage Supporting Documents" key={1}>
                <GridActionsCellItem
                  icon={<FolderIcon sx={{ fontSize: '1.5rem' }} />}
                  label="Uploaded Documents"
                  sx={{
                    color: '#053d5d'
                  }}
                  onClick={() => handleOpenManageDocsModal(row)}
                  disabled={edit_row || view_only}
                />
              </Tooltip>
            )}
          </>,
          <Tooltip title="Upload Supporting Document" key={2}>
            <GridActionsCellItem
              icon={<AttachFileIcon sx={{ fontSize: '1.5rem' }} />}
              label="Supporting Documents"
              sx={{
                color: '#053d5d'
              }}
              onClick={() => handleOpenUploadDocsModal(row)}
              disabled={edit_row || view_only}
            />
          </Tooltip>,
          <Tooltip title="Edit Record" key={3}>
            <GridActionsCellItem
              disabled={view_only}
              icon={<EditIcon sx={{ fontSize: '1.5rem' }} />}
              label="Edit"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleEditClick(row)}
            />
          </Tooltip>,
          <Tooltip title="Delete Record" key={4}>
            <GridActionsCellItem
              disabled={view_only}
              icon={<DeleteIcon sx={{ fontSize: '1.5rem' }} />}
              label="Delete"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleDeleteClick(row)}
            />
          </Tooltip>
        ];
      }
    });
  }

  return (
    <>
      <EditAverageRecordModal
        open={showEditAverageModal}
        handleCloseEditModal={handleCloseEditAverageModal}
        action_row={actionRow!}
        row_modes_model={rowModesModel}
        setRowModesModel={setRowModesModel}
        message={averaged_value_warning}
      />
      <UploadSupportingDocsModal
        open={showUploadDocsModal}
        handleCloseDocsModal={handleCloseUploadDocsModal}
        metric_record={actionRow}
        metric_record_title={`${actionRow?.metric.name}: ${actionRow?.value} ${actionRow?.unit}`}
        handleAddSupportingDoc={handleAddSupportingDoc}
      />
      <ManageSupportingDocsModal
        open={showManageDocsModal}
        handleCloseDocsModal={handleCloseManageDocsModal}
        handleRemoveSupportingDoc={handleRemoveSupportingDoc}
        capture_context={capture_context}
        metric_record={actionRow}
        metric_record_title={`${actionRow?.metric.name}: ${actionRow?.value} ${actionRow?.unit}`}
        supporting_docs={supportingDocs.filter((supporting_doc: File) => {
          return supporting_doc.metric_record.id === actionRow?.id;
        })}
      />
      <DeleteConfirmModal
        allow_delete={true}
        allow_archive={false}
        alternate_message_flag={actionRow?.is_averaged}
        alternate_message={averaged_value_warning}
        open={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        delete_label={actionRow && `${actionRow.metric.name}: ${actionRow.value}`}
        delete_warning="Metric Record data will be deleted and not included in Reports"
        handleDelete={actionRow && (() => deleteRow(actionRow))}
      />
      <DataGrid
        autoHeight
        loading={gridLoading}
        rows={metricRecordRows}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          },
          columns: {
            columnVisibilityModel: {
              category: capture_context.reporting_period?.id === 'all' ? false : true,
              reporting_period: capture_context.reporting_period?.id === 'all' ? true : false
            }
          }
        }}
        onProcessRowUpdateError={(err: unknown) => {
          const tracking_id: string = uuidv4();
          log(
            'error',
            new MetadataError(
              err instanceof Error
                ? err.message
                : 'Error: MetricRecordGridQuantitative: DataGrid failed on an unknown error.',
              null,
              tracking_id
            )
          );
          setFeedbackData({
            message: `Unable to update row. Tracking ID: ${tracking_id}`,
            state: true,
            type: 'error'
          });
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        columns={columns}
        editMode="row"
        slots={{
          toolbar: view_only ? null : MetricRecordGridQuantitativeToolbar,
          noRowsOverlay: MetricRecordGridQualitativeNoRows
        }}
        slotProps={{
          toolbar: {
            metrics: standard_metrics,
            capture_context: capture_context,
            metric_record_rows: metricRecordRows,
            handleUpdateMetricRecordRows: (new_rows: Array<QuantitativeMetricRecordGridRow>) =>
              setMetricRecordRows(new_rows)
          }
        }}
        rowModesModel={rowModesModel}
        disableRowSelectionOnClick
        sx={{
          '& .MuiDataGrid-row': {
            boxSizing: 'border-box'
          },
          '& .metric-grid-row-proportion-internal': {
            fontStyle: 'italic'
          },
          '&, [class^=MuiDataGrid]': { border: 'none' }
        }}
        processRowUpdate={(updatedRow, originalRow) => {
          try {
            return updateRow(originalRow, updatedRow);
          } catch (err: unknown) {
            const tracking_id: string = uuidv4();
            log(
              'error',
              new MetadataError(
                err instanceof Error
                  ? err.message
                  : 'Error: MetricRecordGridQuantitative failed on an unknown error while calling handleEditMetricRecord.',
                {
                  originalRow: originalRow,
                  updatedRow: updatedRow
                },
                tracking_id
              )
            );
            setFeedbackData({
              message: `Unable to process row update. Tracking ID: ${tracking_id}`,
              state: true,
              type: 'error'
            });
          } finally {
            setEditRowsEmissionFactors((emission_factors) => {
              const new_emission_factors_state = { ...emission_factors };
              delete new_emission_factors_state[originalRow.id];
              return new_emission_factors_state;
            });
          }
        }}
      />
    </>
  );
};

export default MetricRecordGridQuantitative;
