import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ImportMetaData } from '@esg/esg-global-types';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ImportErrorsModal = ({
  open,
  handleCloseModal,
  import_data
}: {
  open: boolean;
  handleCloseModal: () => void;
  import_data: ImportMetaData | null;
}) => {
  return (
    <Dialog open={open} onClose={handleCloseModal}>
      <DialogTitle>{import_data?.file_name}</DialogTitle>
      <DialogContent>
        {import_data && import_data.errors.bannedCharacters.length > 0 ? (
          <DialogContentText>{`A Metric Record Containing Banned Characters Was Found In The Uploaded CSV. This Has Blocked All Other Records From Being Imported From This File.`}</DialogContentText>
        ) : (
          <DialogContentText>{`CSV contained ${import_data?.invalid_metric_records} Metric Records that could not be imported`}</DialogContentText>
        )}
        <Box sx={{ borderRadius: 3, backgroundColor: '#FFFDE7', my: 4, p: 1, color: '#E64A19' }}>
          {import_data && import_data.errors.bannedCharacters.length > 0 && (
            <>
              <Accordion
                defaultExpanded={true}
                sx={{ backgroundColor: '#FFFDE7', color: '#E64A19' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>Banned Characters found in rows with the following value:</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 1, mt: -2 }}
                    >
                      {import_data.errors.bannedCharacters.map((entry) => (
                        <Typography
                          key={entry}
                          sx={{
                            backgroundColor: '#FFF59D',
                            color: '#B71C1C',
                            borderRadius: 3,
                            px: 1.3,
                            py: 0.2,
                            whiteSpace: 'nowrap',
                            fontSize: '0.8rem'
                          }}
                        >
                          {entry}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                  <Typography sx={{ fontSize: '0.8rem', mt: 2 }}>
                    Ensure all values either contain only letters, numbers, spaces or hyphens
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </>
          )}
          {import_data && import_data.errors.sites.length > 0 && (
            <>
              <Accordion
                defaultExpanded={false}
                sx={{ backgroundColor: '#FFFDE7', color: '#E64A19' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>Invalid Row(s) were found with these Site(s):</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 1, mt: -2 }}
                    >
                      {import_data.errors.sites.map((site) => (
                        <Typography
                          key={site}
                          sx={{
                            backgroundColor: '#FFF59D',
                            color: '#B71C1C',
                            borderRadius: 3,
                            px: 1.3,
                            py: 0.2,
                            whiteSpace: 'nowrap',
                            fontSize: '0.8rem'
                          }}
                        >
                          {site}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                  <Typography sx={{ fontSize: '0.8rem', mt: 2 }}>
                    Ensure Division, Subdivision and Site names for each row match what is
                    Configured for the Company
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </>
          )}
          {import_data && import_data.errors.metrics.length > 0 && (
            <>
              <Accordion
                defaultExpanded={false}
                sx={{ backgroundColor: '#FFFDE7', color: '#E64A19' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>Invalid Row(s) were found with these Metric(s):</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 1, mt: -2 }}
                    >
                      {import_data.errors.metrics.map((metric) => (
                        <Typography
                          key={metric}
                          sx={{
                            backgroundColor: '#FFF59D',
                            color: '#B71C1C',
                            borderRadius: 3,
                            px: 1.3,
                            py: 0.2,
                            whiteSpace: 'nowrap',
                            fontSize: '0.8rem'
                          }}
                        >
                          {metric}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                  <Typography sx={{ fontSize: '0.8rem', mt: 2 }}>
                    Ensure Metric names for each row match what is Configured for the Company and at
                    least one Emission Factor is configured per Metric
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </>
          )}
          {import_data && import_data.errors.values.length > 0 && (
            <>
              <Accordion
                defaultExpanded={false}
                sx={{ backgroundColor: '#FFFDE7', color: '#E64A19' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>Invalid Row(s) were found with these Value(s):</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 1, mt: -2 }}
                    >
                      {import_data.errors.values.map((value) => (
                        <Typography
                          key={value}
                          sx={{
                            backgroundColor: '#FFF59D',
                            color: '#B71C1C',
                            borderRadius: 3,
                            px: 1.3,
                            py: 0.2,
                            whiteSpace: 'nowrap',
                            fontSize: '0.8rem'
                          }}
                        >
                          {value}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                  <Typography sx={{ fontSize: '0.8rem', mt: 2 }}>
                    {`Ensure Values are strictly integer or decimal values >= 0`}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </>
          )}
          {import_data && import_data.errors.proportions.length > 0 && (
            <>
              <Accordion
                defaultExpanded={false}
                sx={{ backgroundColor: '#FFFDE7', color: '#E64A19' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>Invalid Row(s) were found with these Proportion(s):</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 1, mt: -2 }}
                    >
                      {import_data.errors.proportions.map((proportion) => (
                        <Typography
                          key={proportion}
                          sx={{
                            backgroundColor: '#FFF59D',
                            color: '#B71C1C',
                            borderRadius: 3,
                            px: 1.3,
                            py: 0.2,
                            whiteSpace: 'nowrap',
                            fontSize: '0.8rem'
                          }}
                        >
                          {proportion}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                  <Typography sx={{ fontSize: '0.8rem', mt: 2 }}>
                    {`Ensure Values are strictly integer or decimal values >= 0 and <= 100`}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </>
          )}
          {import_data && import_data.errors.notes.length > 0 && (
            <>
              <Accordion
                defaultExpanded={false}
                sx={{ backgroundColor: '#FFFDE7', color: '#E64A19' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>Invalid Row(s) Found with the following Note(s):</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 1, mt: -2 }}
                    >
                      {import_data.errors.notes.map((note) => (
                        <Typography
                          key={note}
                          sx={{
                            backgroundColor: '#FFF59D',
                            color: '#B71C1C',
                            borderRadius: 3,
                            px: 1.3,
                            py: 0.2,
                            whiteSpace: 'nowrap',
                            fontSize: '0.8rem'
                          }}
                        >
                          {`${note.slice(0, 50)}.....`}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                  <Typography sx={{ fontSize: '0.8rem', mt: 2 }}>
                    {`Ensure notes contain less than 150 characters`}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </Box>
        <Box m={1} display="flex" justifyContent="flex-end" alignItems="flex-end">
          <IconButton color="primary" onClick={handleCloseModal} sx={{ mr: 1 }}>
            <ArrowBackIcon />
          </IconButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ImportErrorsModal;
