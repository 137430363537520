import React from 'react';
import { Tooltip, IconButton, Button } from '@mui/material';
import { GridToolbarContainer, GridToolbarQuickFilter, GridToolbarExport } from '@mui/x-data-grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';

export const PanelReportingPeriodGroupsToolbar = ({
  handleCreate
}: {
  handleCreate: () => void;
}) => {
  return (
    <>
      <GridToolbarContainer>
        <GridToolbarQuickFilter style={{ flex: 1 }} />
        <Tooltip title="Export reporting period groups to device">
          <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        </Tooltip>
        <Tooltip title="Learn more">
          <span>
            <IconButton
              aria-label="help"
              size="small"
              color="primary"
              sx={{ marginLeft: 'auto' }}
              onClick={() => {
                // Open assistance menu / bot here.
              }}
            >
              <HelpOutlineIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Add new reporting period group">
          <Button variant="contained" size="large" startIcon={<AddIcon />} onClick={handleCreate}>
            Create
          </Button>
        </Tooltip>
      </GridToolbarContainer>
    </>
  );
};
