import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Button from '@mui/material/Button';
import DefaultContent from '../shared/template/DefaultContent';
import Drawer from '@mui/material/Drawer';
import { useLocation } from 'react-router-dom';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { auth } from '../../lib/google/firebase';
import { sendEmailVerification } from 'firebase/auth';
import ei_logo from '../../assets/logo.webp';
import AccountMenu from './AccountMenu';
import SideMenuNavItems from './SideMenuNavItems';
import Divider from '@mui/material/Divider';
import { CompanyContext } from '../../context/CompanyContext';
import { Company, Group, User } from '@esg/esg-global-types';
import HelpIcon from '@mui/icons-material/Help';
import { UserContext } from '../../context/UserContext';
import { userAllowedCompany } from '../../util/user_access';
import SupportContactModal from './SupportContactModal';
import { GroupContext } from '../../context/GroupContext';
import { resolveObjectFromString } from '../../util/validation';
import CompanySelect from '../shared/input/select/CompanySelect';
import { CompanyExtended } from '../../lib/app/company';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface URLTitles {
  [key: string]: string | URLTitles;
}

/**
 * A static bar at the top of the app used to set the company portal and navigate to various views.
 * @param {Array<CompanyExtended>} companies List of companies to include as company selector options.
 * @param {void} handleCompanyChange Function to handle a change of selected company in the parent.
 * @param {void} handleGroupChange Function to handle a change of selected group in the parent.
 * @param {void} resetAppContext Handler function to set the user, group and company context of the app to null.
 * @returns {JSX.Element}
 */
const NavBarApp = ({
  companies,
  handleCompanyChange,
  handleGroupChange,
  resetAppContext
}: {
  companies: Array<CompanyExtended>;
  handleCompanyChange: (company: Company | null) => void;
  handleGroupChange: (group: Group | null) => void;
  resetAppContext: () => void;
}) => {
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
  })<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    })
  }));

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  }));

  const current_user: User | null = React.useContext(UserContext);
  const context_group: Group | null = React.useContext(GroupContext);
  const context_company: Company | null = React.useContext(CompanyContext);
  const [openNav, setOpenNav] = React.useState(false);
  const [openAssist, setOpenAssist] = React.useState(false);
  const [showContact, setShowContact] = React.useState<boolean>(false);

  const url_segments: Array<string> = useLocation().pathname.substring(1).split('/');
  const drawerWidth = 240;

  const allowed_companies: Array<CompanyExtended> = companies.filter((company: CompanyExtended) => {
    return current_user && userAllowedCompany(current_user, company);
  });

  const url_titles_map: URLTitles = {
    configuration: 'Configuration',
    readiness: 'Readiness Gauge',
    assessment: 'Assessment',
    metrics: 'Metric Collection',
    report: 'Reports',
    resource: 'Resource Centre',
    guide: 'User Guide',
    'metric-record-activity': 'Metric Record Activity',
    settings: {
      'group-management': 'Group Management',
      'user-management': 'User Management'
    }
  };

  let url_title = 'Syntiro';
  for (let i = 0; i < url_segments.length; i++) {
    const url_mapping: string | URLTitles = resolveObjectFromString(
      url_segments.slice(0, i + 1).join('.'),
      url_titles_map,
      '.'
    );
    if (url_mapping === undefined) break;
    if (typeof url_mapping === 'string') {
      url_title = url_mapping;
      break;
    }
  }

  const cached_group_id: string | null = localStorage.getItem('current_group');
  const cached_company_id: string | null = localStorage.getItem('current_company');

  const current_group: Group | null = context_group
    ? context_group
    : allowed_companies.length > 0
      ? cached_group_id
        ? allowed_companies.find((company: CompanyExtended) => company.group.id === cached_group_id)
            ?.group ?? null
        : allowed_companies[0].group
      : null;

  const current_company: Company | null = context_company
    ? context_company
    : allowed_companies.length > 0
      ? cached_company_id
        ? allowed_companies.find((company: CompanyExtended) => company.id === cached_company_id) ??
          null
        : allowed_companies[0]
      : null;

  const current_select_value: CompanyExtended | null =
    current_company && current_group
      ? {
          ...current_company,
          group: current_group
        }
      : null;

  const handleGroupCompanySelect = (
    company: Array<CompanyExtended> | CompanyExtended | null
  ): void => {
    handleGroupChange((company as CompanyExtended).group ?? null);
    handleCompanyChange(company as CompanyExtended);
  };

  React.useEffect(() => {
    if (current_company && current_group) {
      handleGroupChange(current_group);
      handleCompanyChange(current_company);
    }
  }, [companies]);

  const handleVerify = (): void => {
    const user = auth.currentUser;
    if (user) {
      sendEmailVerification(user);
    }
  };

  return (
    <>
      <SupportContactModal open={showContact} handleCloseModal={() => setShowContact(false)} />
      <AppBar position="fixed" open={openNav}>
        <Container maxWidth={false} sx={{ marginX: 0 }} className="appbar-container">
          <Toolbar disableGutters>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpenNav(true)}
              edge="start"
              sx={{ mr: 2, ...(openNav && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>

            <Box sx={{ mr: 8 }}>
              {context_company ? (
                context_company.configuration?.logo ? (
                  <img src={context_company?.configuration.logo} alt="Logo" width={55} />
                ) : (
                  <ApartmentIcon fontSize="large" />
                )
              ) : (
                <Skeleton variant="circular" height={50} />
              )}
            </Box>

            {/* Page Title */}
            <Typography
              fontSize={'1.1rem'}
              fontWeight={500}
              align="left"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              {url_title ? url_title.toUpperCase() : ''}
            </Typography>

            {auth.currentUser && !auth.currentUser?.emailVerified && (
              <Button variant="contained" onClick={handleVerify}>
                Verify Email
              </Button>
            )}
            <IconButton onClick={() => setShowContact(true)}>
              <HelpIcon sx={{ color: 'white', mx: 2 }} />
            </IconButton>
            <Box sx={{ minWidth: 200 }}>
              {companies.length === 0 ? (
                <Skeleton variant="rounded" height={50} />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <CompanySelect
                    group_by="group.name"
                    clearable={false}
                    searchable={false}
                    input_label=""
                    color="white"
                    handleChangeCompanies={handleGroupCompanySelect}
                    selected_options={current_select_value}
                    options={allowed_companies}
                  />
                </Box>
              )}
            </Box>

            <AccountMenu resetAppContext={() => resetAppContext()} />
          </Toolbar>
        </Container>
      </AppBar>

      {/* TODO: Move drawers to AppBase.tsx */}
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => openNav && setOpenNav(false)}
      >
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box'
            }
          }}
          variant="persistent"
          anchor="left"
          open={openNav}
        >
          <DrawerHeader>
            <Box sx={{ flexGrow: 1 }}>
              <img src={ei_logo} width="151.55" height="35" style={{ opacity: 0.5 }} />
            </Box>
          </DrawerHeader>
          <Divider />

          <SideMenuNavItems title={url_title} handleCloseNav={() => setOpenNav(false)} />
        </Drawer>
      </ClickAwayListener>

      {/* Assistance Drawer */}
      <Drawer anchor="right" open={openAssist} onClose={() => setOpenAssist(false)}>
        <Toolbar variant="dense">
          <Typography variant="h6" color="inherit" component="div">
            Assistance
          </Typography>
        </Toolbar>
        <Box
          sx={{
            width: 300,
            backgroundColor: 'white',
            padding: 3
          }}
        >
          <DefaultContent></DefaultContent>
        </Box>
      </Drawer>
    </>
  );
};

export default NavBarApp;
