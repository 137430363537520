import React from 'react';
import { Grid, Skeleton } from '@mui/material';

export const UsersTableLoading = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={2}>
        <Skeleton variant="rounded" height={20} />
      </Grid>
      <Grid item xs={2}>
        <Skeleton variant="rounded" height={20} />
      </Grid>
      <Grid item xs={2}>
        <Skeleton variant="rounded" height={20} />
      </Grid>
      <Grid item xs={2}>
        <Skeleton variant="rounded" height={20} />
      </Grid>
      <Grid item xs={2}>
        <Skeleton variant="rounded" height={20} />
      </Grid>
      <Grid item xs={2}>
        <Skeleton variant="rounded" height={20} />
      </Grid>
    </Grid>
  );
};
