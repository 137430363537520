import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React, { ReactNode } from 'react';
import { FeedbackSnackbarContext } from '../../context/FeedbackSnackbarContext';
import { Moment } from 'moment';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { hasBannedCharacters } from '../../lib/validation/text_validation';
import { MetadataError } from '@ep/error-handling';
import { log } from '../../util/log';
import { uuidv4 } from '@firebase/util';
import { MetricExtended } from '../../lib/metric_capture/metric';

export interface CreateInput {
  id: string;
  type: 'text' | 'select' | 'date' | 'node' | 'number';
  label: string;
  defaultValue?: string | number | Moment;
  inputNodeFactory?: (handleInputChange: (input_id: string, value: unknown) => void) => ReactNode;
  optional?: boolean;
}

interface ConfigAddWidgetProps {
  create_label: string;
  create_icon: ReactNode;
  create_function_inputs: readonly CreateInput[];
  existing_entities?: Array<any>;
  grid_spacing?: number;
  handleClose?: () => void;
  createFunction: ((...args: any) => void) | null;
  hide_configured_entities?: boolean; // NOTE: This is a temporary parameter to avoid causing defects throughout the rest of the application.
  hide_title?: boolean; // NOTE: This is a temporary parameter to avoid causing defects throughout the rest of the application.
}

const ConfigAddWidget = ({
  create_label,
  create_icon,
  create_function_inputs,
  existing_entities,
  grid_spacing,
  handleClose,
  createFunction,
  hide_configured_entities = false,
  hide_title = false
}: ConfigAddWidgetProps) => {
  const [createLoading, setCreateLoading] = React.useState<boolean>(false);
  const [inputValuesMap, setInputValuesMap] = React.useState(
    create_function_inputs.reduce((acc: { [key: string]: unknown }, input: CreateInput) => {
      acc[input.id] = input.defaultValue ? input.defaultValue : null;
      return acc;
    }, {})
  );
  const [invalidInput, setInvalidInput] = React.useState<boolean>(false);
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);

  const input_complete: boolean =
    !invalidInput &&
    create_function_inputs.every((create_input: CreateInput) => {
      return !!create_input.optional || !!inputValuesMap[create_input.id];
    });

  React.useEffect(() => {
    if (inputValuesMap) {
      if ('name' in inputValuesMap) {
        const { name } = inputValuesMap;
        name
          ? setInvalidInput(hasBannedCharacters(name as string) || (name as string).length < 1)
          : false;
      }
      if ('source' in inputValuesMap) {
        const { source } = inputValuesMap;
        source
          ? setInvalidInput(hasBannedCharacters(source as string) || (source as string).length < 1)
          : false;
      }
      if ('standards' in inputValuesMap && 'metrics' in inputValuesMap) {
        const metrics = inputValuesMap['metrics'];
        metrics ? setInvalidInput((metrics as Array<MetricExtended>).length < 1) : false;
      }
    }
  }, [inputValuesMap]);

  const handleInputChange = (input_id: string, new_value: unknown) => {
    setInputValuesMap({ ...inputValuesMap, [input_id]: new_value });
  };

  const resetDefaultValues = () => {
    setInputValuesMap(
      create_function_inputs.reduce((acc: { [key: string]: unknown }, input: CreateInput) => {
        acc[input.id] = input.defaultValue ? input.defaultValue : null;
        return acc;
      }, {})
    );
  };

  const handleCreateClick = async () => {
    setCreateLoading(true);
    try {
      if (createFunction) {
        const argument_values = create_function_inputs.map((input) => inputValuesMap[input.id]);
        await createFunction(...argument_values);
      }
      handleClose && handleClose();
      resetDefaultValues();
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: ConfigAddWidget failed on an unknown error while calling handleCreateClick.',
          {
            create_function_inputs: create_function_inputs
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to create entities. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setCreateLoading(false);
    }
    return;
  };
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={grid_spacing ? grid_spacing : 6}>
          {!hide_title && (
            <Grid item xs={10}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}
              >
                {create_icon}
                <Typography variant="h5" color="inherit" align="left">
                  Add {create_label}
                </Typography>
              </Box>
            </Grid>
          )}
          {!hide_configured_entities && existing_entities && existing_entities.length > 0 && (
            <>
              <Grid item xs={12}>
                <Typography>Configured {create_label}</Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 1 }}>
                {existing_entities.map((entity, index) => (
                  <Typography
                    key={index}
                    sx={{
                      backgroundColor: '#E7E8E9',
                      color: '#454545',
                      borderRadius: 3,
                      px: 1.3,
                      py: 0.2,
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {entity.name}
                  </Typography>
                ))}
              </Grid>
            </>
          )}
          {create_function_inputs.map((input) => {
            return (
              <Grid item xs={12} key={input.id}>
                {input.type === 'text' && (
                  <TextField
                    error={invalidInput}
                    fullWidth
                    id={input.id}
                    label={input.label}
                    value={inputValuesMap[input.id] ? inputValuesMap[input.id] : ''}
                    variant="standard"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                      setInputValuesMap({ ...inputValuesMap, [input.id]: event.target.value });
                    }}
                  />
                )}
                {input.type === 'number' && (
                  <TextField
                    inputProps={{ type: 'number' }}
                    fullWidth
                    id={input.id}
                    label={input.label}
                    value={inputValuesMap[input.id] ? inputValuesMap[input.id] : ''}
                    variant="standard"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                      setInputValuesMap({ ...inputValuesMap, [input.id]: event.target.value });
                    }}
                  />
                )}
                {input.type === 'date' && (
                  <DatePicker
                    label={input.label}
                    value={inputValuesMap[input.id]}
                    onChange={(new_date) =>
                      setInputValuesMap({ ...inputValuesMap, [input.id]: new_date })
                    }
                    sx={{ width: '100%' }}
                  />
                )}
                {input.type === 'node' &&
                  input.inputNodeFactory &&
                  input.inputNodeFactory(handleInputChange)}
              </Grid>
            );
          })}

          <Grid item xs={12} alignItems={'right'}>
            <Box m={1} display="flex" justifyContent="flex-end" alignItems="flex-end">
              <Button
                variant="text"
                startIcon={<ChevronLeftIcon />}
                color="primary"
                onClick={() => {
                  handleClose && handleClose();
                }}
              >
                Back
              </Button>
              &nbsp;
              <LoadingButton
                variant="contained"
                loading={createLoading}
                color="primary"
                onClick={async () => {
                  await handleCreateClick();
                }}
                disabled={!input_complete}
              >
                Create
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

export default ConfigAddWidget;
