import React from 'react';
import { Autocomplete, AutocompleteRenderInputParams, Box, TextField } from '@mui/material';
import { ReportingPeriod } from '@esg/esg-global-types';
import LockIcon from '@mui/icons-material/Lock';
import { getReportingPeriods } from '../../../../lib/metric_capture/reporting_period';

interface ReportingPeriodSelectProps {
  handleChangeReportingPeriods?: (
    reporting_periods: Array<ReportingPeriod> | ReportingPeriod | null
  ) => void;
  selected_reporting_periods?: Array<ReportingPeriod> | ReportingPeriod | null;
  reporting_period_options?: Array<ReportingPeriod>;
  group_id?: string;
  company_id?: string;
  allow_multi_select?: boolean;
  tag_limit?: number;
  disabled?: boolean;
  hide_icons?: boolean;
  input_label?: string;
}

const ReportingPeriodSelect = (props: ReportingPeriodSelectProps) => {
  const [reportingPeriodOptions, setReportingPeriodOptions] = React.useState<
    Array<ReportingPeriod>
  >(props.reporting_period_options ?? []);
  const [locked, setLocked] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async () => {
      if (props.reporting_period_options === undefined && props.group_id && props.company_id) {
        const reporting_periods: Array<ReportingPeriod> = await getReportingPeriods(
          props.group_id,
          props.company_id
        );
        setReportingPeriodOptions(reporting_periods);
      }
    })();
  }, [props.group_id, props.company_id]);

  return (
    <Autocomplete
      id="reporting-period-select"
      fullWidth
      disabled={props.disabled}
      limitTags={props.tag_limit}
      options={
        props.reporting_period_options ? props.reporting_period_options : reportingPeriodOptions
      }
      disableCloseOnSelect={props.allow_multi_select}
      value={props.selected_reporting_periods}
      getOptionLabel={(option: ReportingPeriod) => {
        return option.name;
      }}
      renderOption={(render_props, option: ReportingPeriod) => (
        <Box
          component="li"
          sx={{ display: 'flex', alignItems: 'center' }}
          {...render_props}
          key={option.id}
        >
          {option.name}
          {option.locked && props.hide_icons !== true && (
            <LockIcon sx={{ mx: 2, position: 'relative', color: 'primary', fontSize: '1.3rem' }} />
          )}
        </Box>
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      multiple={props.allow_multi_select}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label={props.input_label ?? 'Reporting Period'}
          variant="standard"
          InputProps={{
            ...params.InputProps,
            endAdornment:
              props.allow_multi_select !== true &&
              props.hide_icons !== true &&
              (props.selected_reporting_periods === undefined ||
                props.selected_reporting_periods !== null) &&
              locked ? (
                <>
                  <LockIcon sx={{ color: 'primary', fontSize: '1.3rem' }} />
                  {params.InputProps.endAdornment}
                </>
              ) : (
                params.InputProps.endAdornment
              )
          }}
        />
      )}
      onChange={(
        event: React.SyntheticEvent<Element, Event>,
        value: Array<ReportingPeriod> | ReportingPeriod | null
      ) => {
        if (!props.allow_multi_select) {
          setLocked(value ? (value as ReportingPeriod).locked : false);
        }
        props.handleChangeReportingPeriods && props.handleChangeReportingPeriods(value);
      }}
    />
  );
};

export default ReportingPeriodSelect;
