import {
  Box,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import {
  RecaptchaVerifier,
  User,
  UserCredential,
  sendEmailVerification,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { auth, getMfaResolver, startMfaSignin } from '../../../lib/google/firebase';
import { LoginState } from './LoginModal';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { validateEmail } from '../../../lib/validation/text_validation';

interface UserInfo extends User {
  reloadUserInfo?: { mfaInfo: unknown };
}

const SignIn = ({
  setSignInUser,
  handleLoginState,
  resetVerifyTimeout
}: {
  setSignInUser: (user: User | null) => void;
  handleLoginState: (login_state: LoginState) => void;
  resetVerifyTimeout: () => void;
}) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [activateSignIn, setActivateSignIn] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [emailFocused, setEmailFocus] = React.useState(false);
  const [validEmail, setValidEmail] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passwordFocused, setPasswordFocus] = React.useState(false);
  const [validPassword, setValidPassword] = React.useState(false);
  const [loginFault, setLoginFault] = React.useState(false);
  const [loginFaultMessage, setLoginFaultMessage] = React.useState('Unknown Error');
  const [signInLoad, setSignInLoad] = React.useState(false);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value);
  };

  const handleLoginFault = (state: boolean, message: string): void => {
    setLoginFault(state);
    setLoginFaultMessage(message);
  };
  const handleEmailVerifySend = () => {
    try {
      const user = auth.currentUser;
      if (user) {
        sendEmailVerification(user).catch((err) => {
          const tracking_id: string = uuidv4();
          log('error', new MetadataError(err, { user: user }, tracking_id));
          setFeedbackData({
            message: `${err.message}. Tracking ID: ${tracking_id}`,
            state: true,
            type: 'error'
          });
        });
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: SignIn failed on an unknown error while calling handleEMailVerifySend.',
          { 'auth.currentUser': auth.currentUser },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Failed to send email verification. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const onLogin = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setSignInLoad(true);
    try {
      const recaptcha_verifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible'
      });
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential: UserCredential) => {
          // Signed in
          setSignInUser(auth.currentUser);
          const user: UserInfo = userCredential.user;
          if (user.emailVerified) {
            handleLoginState('enroll_mfa');
          } else {
            handleEmailVerifySend();
            resetVerifyTimeout();
            handleLoginState('verify_email');
          }
        })
        .catch((err) => {
          if (err.code == 'auth/multi-factor-auth-required') {
            const resolver = getMfaResolver(err);
            (async () => {
              await startMfaSignin(resolver.hints[0], resolver.session, recaptcha_verifier).catch(
                (err) => {
                  const tracking_id: string = uuidv4();
                  log(
                    'error',
                    new MetadataError(err, { 'auth.currentUser': auth.currentUser }, tracking_id)
                  );
                  setFeedbackData({
                    message: `${err.message}. Tracking ID: ${tracking_id}`,
                    state: true,
                    type: 'error'
                  });
                }
              );
              handleLoginState('verify_mfa');
              setSignInLoad(false);
            })();
          } else if (err.code == 'mfa-not-activated') {
            // Handle the user not having MFA on their account
            handleLoginFault(true, 'User requires MFA activation');
            setSignInLoad(false);
          } else if (err.code == 'auth/too-many-requests') {
            // Handle brute force attacks
            handleLoginFault(
              true,
              'To many failed sign in requests, account blocked temporarily, please try again later.'
            );
            setSignInLoad(false);
          } else if (
            err.code === 'auth/user-not-found' ||
            err.code === 'auth/invalid-email' ||
            err.code === 'auth/user-disabled' ||
            err.code === 'auth/invalid-credential' ||
            err.code == 'auth/wrong-password'
          ) {
            log(
              'error',
              new MetadataError(err, { 'auth.currentUser': auth.currentUser }, uuidv4())
            );
            handleLoginFault(true, 'Invalid Email or Password please try again.');
            setSignInLoad(false);
          } else if (err.code === 'auth/missing-verification-id') {
            log(
              'error',
              new MetadataError(err, { 'auth.currentUser': auth.currentUser }, uuidv4())
            );
            handleLoginFault(true, 'Recaptcha Expired, please log in again.');
            setSignInLoad(false);
          } else {
            //Catch any errors that don't require specific handling
            const tracking_id: string = uuidv4();
            log(
              'error',
              new MetadataError(err, { 'auth.currentUser': auth.currentUser }, tracking_id)
            );
            setFeedbackData({
              message: `${err.message}. Tracking ID: ${tracking_id}`,
              state: true,
              type: 'error'
            });
          }
        });
    } catch (error: unknown) {
      throw new Error(JSON.stringify(error));
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      setValidEmail(validateEmail(email));
    }, 500);
    return () => {
      null;
    };
  }, [email, emailFocused]);

  React.useEffect(() => {
    setTimeout(() => {
      setValidPassword(password.length > 0);
    }, 500);
    return () => {
      null;
    };
  }, [password, passwordFocused]);

  React.useEffect(() => {
    setActivateSignIn(validEmail && validPassword);
  }, [validEmail, validPassword]);

  return (
    <Box>
      <DialogTitle>Sign In</DialogTitle>
      <form>
        <DialogContent>
          <TextField
            error={!validEmail && emailFocused}
            onBlur={() => {
              setEmailFocus(true);
            }}
            data-testid="email-input"
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            onInput={handleEmailChange}
            value={email}
          />
          <TextField
            error={!validPassword && passwordFocused}
            onBlur={() => {
              setPasswordFocus(true);
            }}
            data-testid="password-input"
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            onInput={handlePasswordChange}
            value={password}
            sx={{ my: 4 }}
          />
          {loginFault && (
            <DialogContentText
              sx={{
                my: 2,
                color: loginFaultMessage.includes('link sent') ? '#01579B' : 'red'
              }}
            >
              {loginFaultMessage}
            </DialogContentText>
          )}
          <Box sx={{ mt: 2 }}>
            <Link
              href="#"
              data-testid="recovery-button"
              id="recovery-button"
              onClick={() => handleLoginState('recovery')}
              sx={{ color: '#053d5d' }}
            >
              Forgot Password?
            </Link>
          </Box>
        </DialogContent>
        <LoadingButton
          data-testid="sign-in-button"
          disabled={!activateSignIn}
          loading={signInLoad}
          variant="contained"
          sx={{ minWidth: '200px', backgroundColor: '#053d5d' }}
          onClick={onLogin}
          id="sign-in-button"
          fullWidth
          type="submit"
        >
          Sign In
        </LoadingButton>
      </form>
      <Typography sx={{ textAlign: 'center', color: '#053d5d', my: 1, fontSize: '0.9rem' }}>
        Don&apos;t have an account yet? <a href="">Contact Us</a>
      </Typography>
    </Box>
  );
};

export default SignIn;
