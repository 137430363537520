import { v4 as uuidv4 } from 'uuid';
/**
 * Get a new UUIDv4 string.
 * @returns {string}
 */
export const generateUuidV4 = (): string => uuidv4();

/**
 * Check if the specified string is a valid UUIDv4 string.
 * @param {string} uuid A valid UUIDv4 string.
 * @returns {boolean} True = Valid UUIDv4 string, False = Invalid UUIDv4 string.
 */
export const isValidUuidV4 = (uuid: string): boolean =>
  new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i).test(uuid);
