import React, { ReactNode } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import EmojiTransportationOutlinedIcon from '@mui/icons-material/EmojiTransportationOutlined';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import ConfigurationCard from '../../components/shared/cards/ConfigurationCard';
import { UserContext } from '../../context/UserContext';
import { Company, Group, User } from '@esg/esg-global-types';
import { ViewAccess, getViewAccess } from '../../util/user_access';
import LoadingScreen from '../../components/shared/LoadingScreen';
import AccessDenied from '../shared/AccessDenied';
import { GroupContext } from '../../context/GroupContext';
import { CompanyContext } from '../../context/CompanyContext';
import { uuidv4 } from '@firebase/util';
import { log } from '../../util/log';
import { MetadataError } from '@ep/error-handling';
import { FeedbackSnackbarContext } from '../../context/FeedbackSnackbarContext';

interface ConfigurationGroup {
  title: string;
  configuration_items: Array<ConfigurationItem>;
}

interface ConfigurationItem {
  title: string;
  icon: ReactNode;
  description: string;
  route: string;
}

const ConfigurationDashboard = () => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const user_info: User | null = React.useContext(UserContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [access, setAccess] = React.useState<ViewAccess>(null);

  const configuration_settings: Array<ConfigurationGroup> = [
    {
      title: 'Represent your Company Entities in Syntiro',
      configuration_items: [
        {
          title: 'Personalisation',
          description: 'Fully brand your Company Portal',
          icon: <BrushOutlinedIcon />,
          route: 'configuration/personalisation'
        },
        {
          title: 'Geographical',
          icon: <LocationOnOutlinedIcon />,
          description: 'Countries and Regions of operation',
          route: 'configuration/geographical'
        },
        {
          title: 'Organisation',
          icon: <AccountTreeOutlinedIcon />,
          description: 'Structure your Divisions, Subdivisions and Sites',
          route: 'configuration/organisation'
        },
        {
          title: 'External Companies',
          icon: <EmojiTransportationOutlinedIcon />,
          description: 'Track partner company Metrics alongside yours',
          route: 'configuration/external-companies'
        }
      ]
    },
    {
      title: 'Track Metric activity over time',
      configuration_items: [
        {
          title: 'Reporting Periods',
          description: 'Distinct periods to Report and Capture by',
          icon: <AccessAlarmIcon />,
          route: 'configuration/reporting-periods'
        },
        {
          title: 'Reporting Period Groups',
          icon: <CalendarMonthOutlinedIcon />,
          description: 'Organise tracking on higher level time periods',
          route: 'configuration/reporting-period-groups'
        }
      ]
    },
    {
      title: 'Define ESG Data to Capture and Report on',
      configuration_items: [
        {
          title: 'Standards',
          description: 'Choose from global ESG templates',
          icon: <ChecklistRtlIcon />,
          route: 'configuration/standards'
        },
        {
          title: 'Metrics',
          icon: <AssessmentOutlinedIcon />,
          description: 'Specify your key data indicators',
          route: 'configuration/metrics'
        },
        {
          title: 'Other Configuration',
          icon: <TuneIcon />,
          description: 'Configure additional data required by your Standards',
          route: 'configuration/other-configuration'
        }
      ]
    }
  ];

  React.useEffect(() => {
    try {
      setAccess(
        group && company && user_info
          ? getViewAccess(user_info, group.id, company.id, 'configuration')
          : null
      );
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: ConfigurationDashboard failed on an unknown error while initialising.',
          {
            user_info: user_info,
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Could not verify user access. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  }, [user_info, company]);

  return !user_info || access === null ? (
    <LoadingScreen />
  ) : access !== 'none' ? (
    <Box sx={{ mx: 16, my: 4 }}>
      <Grid container spacing={6} sx={{ textAlign: 'left' }}>
        {configuration_settings.map((config_group: ConfigurationGroup) => {
          return (
            <Grid item xs={12} key={config_group.title}>
              <Typography>{config_group.title}</Typography>
              <Divider sx={{ my: 2 }} />
              <Grid container spacing={8}>
                {config_group.configuration_items.map((config_item: ConfigurationItem) => {
                  return (
                    <Grid item xs={4} key={config_item.title}>
                      <ConfigurationCard
                        title={config_item.title}
                        icon={config_item.icon}
                        description={config_item.description}
                        route={config_item.route}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  ) : (
    <AccessDenied />
  );
};

export default ConfigurationDashboard;
