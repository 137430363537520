import * as React from 'react';
import axios, { AxiosProgressEvent, AxiosResponse } from 'axios';
import { MuiFileInput } from 'mui-file-input';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { API_ENDPOINT, GCP_FILE_UPLOAD_BUCKET } from '../../../../env';
import { FeedbackSnackbarContext } from '../../../../context/FeedbackSnackbarContext';
import { generateFileUploadRequest } from '../../../../lib/file_upload/uploadRequestPrep';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../../util/log';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35, mt: 1.5 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const FileUpload = ({
  uploadedFileToParent,
  file_type,
  group_id,
  company_name
}: {
  uploadedFileToParent: (uploaded_file: AxiosResponse) => void;
  file_type: string;
  group_id: string;
  company_name: string;
}) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [file, setFile] = React.useState<File | null>();
  const [percent_completed, setProgress] = React.useState<number | null>(null);

  const handleChange = (new_file: File | null) => {
    try {
      setFile(new_file);

      if (!new_file) {
        setProgress(null);
        return;
      }
      const new_file_name = `logo-${company_name.toLowerCase().replace(/\s/g, '_')}`;
      const file_upload = generateFileUploadRequest(
        new_file,
        new_file_name,
        atob(GCP_FILE_UPLOAD_BUCKET),
        `${group_id}/${company_name.toLowerCase().replace(/\s/g, '_')}/${file_type}`,
        'true'
      );

      axios({
        method: 'post',
        url: `${API_ENDPOINT}/upload_file`, // TODO: Get URL from configuration.
        data: file_upload,
        onUploadProgress: (upload: AxiosProgressEvent) => {
          setProgress(Math.round((upload.loaded * 100) / upload.total!));
        }
      })
        .then((uploaded_file: AxiosResponse) => {
          uploadedFileToParent(uploaded_file);
        })
        .catch((error: unknown) => {
          setFeedbackData({
            message: error instanceof Error ? error.message : 'Error: Failed to upload file.',
            state: true,
            type: 'error'
          });
        });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: FileUpload failed on an unknown error while calling handleChange.',
          {
            new_file: new_file
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to upload file. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  // <MuiFileInput helperText={"File is invalid"} error={true} variant="outlined" onChange={handleChange} value={file} />

  return (
    <>
      <MuiFileInput
        helperText={''}
        error={false}
        variant="outlined"
        onChange={handleChange}
        title="Logo"
        label="Logo"
        value={file}
        inputProps={{ accept: '.png' }}
      />
      {percent_completed && (
        <LinearProgressWithLabel
          variant="determinate"
          value={percent_completed}
          sx={{ marginTop: '1rem' }}
        />
      )}
    </>
  );
};

export default FileUpload;
