import { QuerySnapshot, QueryDocumentSnapshot, DocumentData } from 'firebase/firestore';
import { EntityLabel, MetricGroup } from '@esg/esg-global-types';
import { uuidv4 } from '@firebase/util';
import { MetadataError } from '@ep/error-handling';
import { FirestoreQueryParam } from '../../@types/shared';
import { readFirestoreDocs } from '../app/db_util';
import { validateMasterListParams } from '../../util/validation';

export type MetricGroupData = Omit<MetricGroup, 'id'>;

// Singular and plural label for model entity.
export const metric_group_label: EntityLabel = {
  one: 'Metric Group',
  many: 'Metric Groups'
};

/**
 * Query all metric group documents for current portal company joined with relative metric group data
 * @param {boolean} master_list Return master list or configured metric group documents.
 * @param {string} group_id Group object of current portal company
 * @param {string} company_id id of current portal company to reference firestore documents
 * @param {string} id query a specific Metric Group by id.
 * @returns {Promise<Array<MetricGroup>>}
 */
export const getMetricGroups = async (
  master_list: boolean,
  group_id?: string,
  company_id?: string,
  id?: string
): Promise<Array<MetricGroup>> => {
  try {
    validateMasterListParams(master_list, group_id, company_id);
    const collection_path = master_list
      ? `metric_group_master_list`
      : `groups/${group_id}/companies/${company_id}/metric_groups`;
    const query_params: Array<FirestoreQueryParam> = [
      { field_name: 'deleted', operator: '==', value: null }
    ];
    if (id !== undefined && id.length > 0)
      query_params.push({ field_name: 'id', operator: '==', value: id });
    const metric_group_snapshot: QuerySnapshot = await readFirestoreDocs(
      collection_path,
      query_params
    );
    const metric_groups: Array<MetricGroup> = metric_group_snapshot.docs.map(
      (metric_group: QueryDocumentSnapshot) => {
        const metric_group_data: DocumentData = metric_group.data();
        return {
          ...{
            id: metric_group.id,
            deleted: metric_group_data.deleted,
            name: metric_group_data.name,
            description: metric_group_data.description
          },
          // Conditionally add non-master list properties.
          ...(!master_list && {
            master_list_metric_group: metric_group_data.master_list_metric_group
          })
        };
      }
    );
    return metric_groups;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error ? err.message : 'Error: getMetricGroups failed on an unknown error.',
      {
        master_list: master_list,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};
