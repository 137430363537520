import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import { CaptureContext, MetricRecordEntities } from '../../@types/shared';
import { Site, Company, User, Group } from '@esg/esg-global-types';
import { CompanyContext } from '../../context/CompanyContext';
import { DocumentReference } from 'firebase/firestore';
import { UserContext } from '../../context/UserContext';
import { ViewAccess, getViewAccess } from '../../util/user_access';
import AccessDenied from '../shared/AccessDenied';
import { getMetricRecordEntities } from '../../util/metric_record_entities';
import { FeedbackSnackbarContext } from '../../context/FeedbackSnackbarContext';
import MetricRecordHub from '../../components/metric_collection_data_management/MetricRecordHub';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { GroupContext } from '../../context/GroupContext';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../util/log';
import MetricRecordCaptureContext from '../../components/metric_collection_data_management/MetricRecordCaptureContext';

/**
 * View for handling Qualitative and Quantitative Metric Record capturing.
 * @returns {JSX.Element}
 */
const MetricCollectionDataManagement = () => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const user_info: User | null = React.useContext(UserContext);
  const [access, setAccess] = React.useState<ViewAccess>(null);
  const [showMetricRecords, setShowMetricRecords] = React.useState<boolean>(false);
  const [loadingEntities, setLoadingEntities] = React.useState<boolean>(true);
  const [captureEntities, setCaptureEntities] = React.useState<MetricRecordEntities>({
    countries: [],
    regions: [],
    divisions: [],
    subdivisions: [],
    sites: [],
    reporting_period_groups: [],
    reporting_periods: [],
    metrics: [],
    user_types: [],
    external_companies: [],
    emission_factors: []
  });
  const [captureContext, setCaptureContext] = React.useState<CaptureContext>({
    country: null,
    region: null,
    division: null,
    subdivision: null,
    site: null,
    reporting_period_group: null,
    reporting_period: null,
    user_type: null,
    external_company: null,
    level: '',
    standard: null
  });

  const handleShowMetricRecords = (): void => {
    setShowMetricRecords(true);
  };

  const fetchCaptureEntities = async () => {
    setLoadingEntities(true);
    if (group && company && user_info) {
      const capture_entities: MetricRecordEntities | void = await getMetricRecordEntities(
        group.id,
        company.id,
        user_info
      ).catch((err: unknown) => {
        const tracking_id: string = uuidv4();
        log(
          'error',
          new MetadataError(
            err instanceof Error
              ? err.message
              : 'Error: MetricCollectionDataManagement failed while calling getMetricRecordEntities',
            {
              group: group,
              company: company,
              user_info: user_info
            },
            tracking_id
          )
        );
        setFeedbackData({
          message: `Unable to fetch capture entities. Tracking ID: ${tracking_id}`,
          state: true,
          type: 'error'
        });
      });
      if (capture_entities) {
        setCaptureEntities({
          countries: capture_entities.countries,
          regions: capture_entities.regions,
          divisions: capture_entities.divisions,
          subdivisions: capture_entities.subdivisions,
          sites: capture_entities.sites.map((site: Site) => {
            return { ...site, region: { ...site.region, id: site.region.id } as DocumentReference };
          }),
          reporting_period_groups: capture_entities.reporting_period_groups,
          reporting_periods: capture_entities.reporting_periods,
          metrics: capture_entities.metrics,
          user_types: capture_entities.user_types.filter((item) => item.id !== 'any'),
          external_companies: capture_entities.external_companies,
          emission_factors: capture_entities.emission_factors
        });
      }
    }
    setLoadingEntities(false);
  };

  React.useEffect(() => {
    (async () => {
      setCaptureEntities({
        countries: [],
        regions: [],
        divisions: [],
        subdivisions: [],
        sites: [],
        reporting_period_groups: [],
        reporting_periods: [],
        metrics: [],
        user_types: [],
        external_companies: [],
        emission_factors: []
      });
      await fetchCaptureEntities();
      setShowMetricRecords(false);
    })();
  }, [company]);

  const handleContextChange = (new_context: CaptureContext) => {
    // Reset the Company Name select field when the User Type select field has changed to Internal.
    if (new_context && new_context.user_type?.id === 'internal')
      new_context.external_company = null;
    setCaptureContext({
      ...captureContext,
      ...new_context
    });
    setShowMetricRecords(false);
  };

  React.useEffect(() => {
    setAccess(
      group && company && user_info
        ? getViewAccess(user_info, group.id, company.id, 'metric_collection.management')
        : null
    );
  }, [user_info, company]);

  return !user_info || access === null ? (
    <LoadingScreen />
  ) : access !== 'none' ? (
    <Box
      sx={{
        paddingY: 2
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {loadingEntities || !company ? (
            <Skeleton height={250} width={'100%'} />
          ) : (
            <MetricRecordCaptureContext
              capture_context={captureContext}
              capture_entities={captureEntities}
              loading_entities={loadingEntities}
              handleContextChange={handleContextChange}
              handleShowMetricRecords={handleShowMetricRecords}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {showMetricRecords && (
            <MetricRecordHub capture_context={captureContext} capture_entities={captureEntities} />
          )}
        </Grid>
      </Grid>
    </Box>
  ) : (
    <AccessDenied />
  );
};

export default MetricCollectionDataManagement;
