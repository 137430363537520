import React from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { CaptureContext, MetricRecordEntities } from '../../@types/shared';
import MetricContextSelect from './MetricContextSelect';
import {
  Company,
  Group,
  Region,
  ReportingPeriod,
  ReportingPeriodGroup,
  Site,
  Standard,
  Subdivision,
  User
} from '@esg/esg-global-types';
import ReportingPeriodGroupSelect from '../shared/input/select/ReportingPeriodGroupSelect';
import moment from 'moment';
import {
  findHighestEndDateInGroup,
  findLowestStartDateInGroup
} from '../../lib/metric_capture/reporting_period_group';
import ReportingPeriodSelect from '../shared/input/select/ReportingPeriodSelect';
import { reportingPeriodFilterByGroup } from '../../lib/metric_capture/reporting_period';
import { GroupContext } from '../../context/GroupContext';
import { CompanyContext } from '../../context/CompanyContext';
import AlertBanner from '../shared/banner/AlertBanner';
import MetricCollectionWarningMessage from '../../views/metric_collection/MetricCollectionWarningMessage';
import { UserContext } from '../../context/UserContext';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import StandardSelect from '../shared/input/select/StandardSelect';
import AddModeratorIcon from '@mui/icons-material/AddModerator';

interface CaptureLevel {
  id: string;
  label: string;
  input_fields: Array<string>;
  disabled: boolean;
}

interface CollapsedInfo {
  id: string;
  value: string | null;
  icon: React.ReactNode;
}

/**
 * A collapsable list of inputs that defines the context of metric records to be captured.
 * @param {CaptureContext} capture_context Object of context fields to be manipulated in parent
 * @param {MetricRecordEntities} capture_entities List of options user may set per context input
 * @param {boolean} loading_entities Flag to check if entity choices are still being fetched from db
 * @param {void} handleContextChange Handler function to update the capture context object in parent
 * @param {void} handleMetricsLoad Function executed on button click to show metric record grid in parent
 * @returns {JSX.Element}
 */
const MetricRecordCaptureContext = ({
  capture_context,
  capture_entities,
  loading_entities,
  handleContextChange,
  handleShowMetricRecords
}: {
  capture_context: CaptureContext;
  capture_entities: MetricRecordEntities;
  loading_entities: boolean;
  handleContextChange: (new_context: CaptureContext) => void;
  handleShowMetricRecords: () => void;
}) => {
  const user_info: User | null = React.useContext(UserContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [tab, setTab] = React.useState<number>(0);
  const [collapse, setCollapse] = React.useState<boolean>(false);

  const capture_levels: Array<CaptureLevel> = [
    {
      id: 'company',
      label: 'Company',
      input_fields: ['reporting_period_group'],
      disabled: !capture_context.standard || capture_context.standard.is_quantitative
    },
    {
      id: 'national',
      label: 'Country',
      input_fields: ['country', 'reporting_period_group', 'reporting_period'],
      disabled:
        !capture_context.standard || capture_context.standard?.require_site_level ? true : false
    },
    {
      id: 'regional',
      label: 'Region',
      input_fields: ['country', 'region', 'reporting_period_group', 'reporting_period'],
      disabled:
        !capture_context.standard || capture_context.standard?.require_site_level ? true : false
    },
    {
      id: 'divisional',
      label: 'Division',
      input_fields: ['division', 'reporting_period_group', 'reporting_period'],
      disabled:
        !capture_context.standard || capture_context.standard?.require_site_level ? true : false
    },
    {
      id: 'subdivisional',
      label: 'Subdivision',
      input_fields: ['division', 'subdivision', 'reporting_period_group', 'reporting_period'],
      disabled:
        !capture_context.standard || capture_context.standard?.require_site_level ? true : false
    },
    {
      id: 'site',
      label: 'Site',
      input_fields: [
        'division',
        'subdivision',
        'site',
        'reporting_period_group',
        'reporting_period'
      ],
      disabled: !capture_context.standard ? true : false
    },
    {
      id: 'import',
      label: 'Import',
      input_fields: ['reporting_period_group', 'reporting_period'],
      disabled: !capture_context.standard ? true : false
    }
  ];

  const getStandardCaptureLevels = (standard: Standard): Array<CaptureLevel> => {
    return capture_levels.filter((level: CaptureLevel) => {
      return standard.is_quantitative
        ? standard.require_site_level
          ? level.id === 'site' || level.id === 'import'
          : level.id !== 'company'
        : level.id === 'company';
    });
  };

  const default_reporting_period_groups: Array<ReportingPeriodGroup> =
    capture_context.level === 'company'
      ? []
      : [
          {
            deleted: null,
            id: 'all',
            name: 'Capture By Reporting Period'
          }
        ];

  const averaged_reporting_period: ReportingPeriod = {
    id: 'all',
    start:
      capture_context.reporting_period_group && capture_context.reporting_period_group.id !== 'all'
        ? findLowestStartDateInGroup(
            capture_entities.reporting_period_groups,
            capture_entities.reporting_periods,
            capture_context.reporting_period_group
          )
        : moment(),
    end:
      capture_context.reporting_period_group && capture_context.reporting_period_group.id !== 'all'
        ? findHighestEndDateInGroup(
            capture_entities.reporting_period_groups,
            capture_entities.reporting_periods,
            capture_context.reporting_period_group
          )
        : moment(),
    deleted: null,
    locked: false,
    name: 'Average Over Group'
  };

  const current_capture_level: CaptureLevel | null = capture_context.standard
    ? getStandardCaptureLevels(capture_context.standard)[tab]
    : null;

  const current_input_fields: Array<string> = current_capture_level
    ? current_capture_level.input_fields
    : [];

  if (
    user_info &&
    group &&
    (user_info.type === 'any' || user_info.super_admin || user_info.groups[group.id].admin) &&
    capture_context.level !== 'company'
  ) {
    current_input_fields.push('user_type');
  }

  if (capture_context.user_type?.id === 'external' || user_info?.type === 'external') {
    current_input_fields.push('external_company');
  }

  const input_valid: boolean = current_input_fields.every((input_field: string) => {
    return capture_context[input_field] !== null;
  });

  const generateCollapsedInfo = (
    id: string,
    icon: React.ReactNode,
    input_fields: Array<string>
  ): CollapsedInfo => {
    let most_granular_value: string | null = null;
    for (const field of input_fields) {
      if (
        capture_context[field] &&
        capture_context[field].name &&
        capture_context[field].id !== 'all' &&
        !(field === 'reporting_period' && !capture_context.standard?.is_quantitative)
      ) {
        most_granular_value = capture_context[field].name;
      }
    }
    return {
      id: id,
      icon: icon,
      value: most_granular_value
    };
  };

  const collapsed_info: Array<CollapsedInfo> = [
    {
      id: 'standard',
      icon: <AddModeratorIcon />,
      value: capture_context.standard?.name ? capture_context.standard?.name : 'Standard'
    },
    generateCollapsedInfo('location', <LocationOnOutlinedIcon />, ['country', 'region']),
    generateCollapsedInfo('organisation', <AccountTreeOutlinedIcon />, [
      'division',
      'subdivision',
      'site'
    ]),
    generateCollapsedInfo('reporting_period', <AccessAlarmIcon />, [
      'reporting_period_group',
      'reporting_period'
    ]),
    {
      id: 'source',
      icon: <AccountCircleOutlinedIcon />,
      value: capture_context.external_company ? capture_context.external_company.name : 'Internal'
    }
  ];

  let missing_entities = '';
  switch (current_capture_level?.id) {
    case 'company':
      if (capture_entities.reporting_period_groups.length === 0)
        [(missing_entities = 'Reporting Period Groups')];
      break;
    case 'national':
      if (capture_entities.countries.length === 0) {
        missing_entities = 'Countries';
      }
      break;
    case 'regional':
      if (capture_entities.regions.length === 0) {
        missing_entities = 'Regions';
      }
      break;
    case 'divisional':
      if (capture_entities.divisions.length === 0) {
        missing_entities = 'Divisions';
      }
      break;
    case 'subdivisional':
      if (capture_entities.subdivisions.length === 0) {
        missing_entities = 'Subdivisions';
      }
      break;
    case 'site':
      if (capture_entities.sites.length === 0) {
        missing_entities = 'Sites';
      }
      break;
    case 'import':
      if (capture_entities.metrics.length === 0) {
        missing_entities = 'Metrics';
      }
      break;
    default:
      break;
  }
  if (capture_entities.reporting_periods.length === 0) {
    missing_entities = 'Reporting Periods';
  }

  const handleTabChange = (event: React.SyntheticEvent, new_tab_value: number): void => {
    handleContextChange({
      country: null,
      region: null,
      division: null,
      subdivision: null,
      site: null,
      reporting_period_group: null,
      reporting_period: null,
      user_type: null,
      external_company: null,
      level: capture_context.standard
        ? getStandardCaptureLevels(capture_context.standard)[new_tab_value].id
        : '',
      standard: capture_context.standard
    });
    setTab(new_tab_value);
  };

  React.useEffect(() => {
    handleContextChange({
      country: null,
      region: null,
      division: null,
      subdivision: null,
      site: null,
      reporting_period_group: null,
      reporting_period: null,
      user_type: null,
      external_company: null,
      level: '',
      standard: null
    });
  }, [group, company]);

  return (
    <Box
      sx={{
        backgroundColor: '#FAFAFA',
        padding: 4,
        textAlign: 'start',
        borderRadius: '1rem',
        my: 2
      }}
    >
      <Grid container spacing={4}>
        {collapse ? (
          <>
            <Grid item xs={11} sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
              {collapsed_info
                .filter((input_info: CollapsedInfo) => input_info.value !== null)
                .map((input_info: CollapsedInfo) => {
                  return (
                    <Box
                      key={input_info.id}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '#053d5d',
                        border: '1px solid #053d5d',
                        borderRadius: '10px',
                        gap: 1,
                        py: 1,
                        px: 2,
                        mx: 2,
                        minWidth: '150px'
                      }}
                    >
                      {input_info.icon}
                      <Typography>{input_info.value}</Typography>
                    </Box>
                  );
                })}
            </Grid>
            <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ color: 'black' }}
                onClick={() => {
                  setCollapse(false);
                }}
              >
                <ExpandMore fontSize="large" />
              </IconButton>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="h5">Capture by</Typography>
            </Grid>
            <Grid item xs={3}>
              <StandardSelect
                group_id={group ? group.id : ''}
                company_id={company ? company.id : ''}
                selected_options={capture_context.standard}
                handleChangeStandards={(standard) => {
                  const standard_casted = standard as Standard | null;
                  setTab(0);
                  handleContextChange({
                    country: null,
                    region: null,
                    division: null,
                    subdivision: null,
                    site: null,
                    reporting_period_group: null,
                    reporting_period: null,
                    user_type: null,
                    external_company: null,
                    level: standard_casted ? getStandardCaptureLevels(standard_casted)[0].id : '',
                    standard: standard_casted
                  });
                }}
                start_adornment={<AddModeratorIcon />}
                auto_select={true}
              />
            </Grid>
            {capture_context.standard && (
              <>
                <Grid item xs={12}>
                  <Tabs value={tab} onChange={handleTabChange} aria-label="capture-context-tabs">
                    {getStandardCaptureLevels(capture_context.standard).map(
                      (capture_level: CaptureLevel, index: number) => {
                        return (
                          <Tab
                            key={capture_level.id}
                            label={capture_level.label}
                            value={index}
                            disabled={capture_level.disabled}
                          />
                        );
                      }
                    )}
                  </Tabs>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3} sx={{ my: 1 }}>
                    {current_input_fields.map((capture_input: string, index: number) => {
                      return (
                        <Grid key={capture_input} item xs={2.4}>
                          {capture_input === 'country' && (
                            <MetricContextSelect
                              id="country-select"
                              label="Country"
                              value={
                                capture_context.country
                                  ? JSON.stringify(capture_context.country)
                                  : ''
                              }
                              disabled={
                                capture_entities.countries.length === 0 ||
                                current_capture_level?.disabled
                              }
                              choices={capture_entities.countries}
                              onChangeHandler={(event: SelectChangeEvent<string>) =>
                                handleContextChange({
                                  ...capture_context,
                                  country: JSON.parse(event.target.value),
                                  region: null
                                })
                              }
                            />
                          )}
                          {capture_input === 'region' && (
                            <MetricContextSelect
                              id="region-select"
                              label="Region"
                              value={
                                capture_context.region ? JSON.stringify(capture_context.region) : ''
                              }
                              disabled={
                                !capture_context.country ||
                                capture_entities.regions.length === 0 ||
                                current_capture_level?.disabled
                              }
                              choices={capture_entities.regions.filter((region: Region) => {
                                return capture_context.country
                                  ? region.country.id === capture_context.country.id
                                  : false;
                              })}
                              onChangeHandler={(event: SelectChangeEvent<string>) =>
                                handleContextChange({
                                  ...capture_context,
                                  region: JSON.parse(event.target.value)
                                })
                              }
                            />
                          )}
                          {capture_input === 'division' && (
                            <MetricContextSelect
                              id="division-select"
                              label="Division"
                              value={
                                capture_context.division
                                  ? JSON.stringify(capture_context.division)
                                  : ''
                              }
                              disabled={
                                capture_entities.divisions.length === 0 ||
                                current_capture_level?.disabled
                              }
                              choices={capture_entities.divisions}
                              onChangeHandler={(event: SelectChangeEvent<string>) =>
                                handleContextChange({
                                  ...capture_context,
                                  division: JSON.parse(event.target.value),
                                  subdivision: null,
                                  site: null
                                })
                              }
                            />
                          )}
                          {capture_input === 'subdivision' && (
                            <MetricContextSelect
                              id="subdivision-select"
                              label="Subdivision"
                              value={
                                capture_context.subdivision
                                  ? JSON.stringify(capture_context.subdivision)
                                  : ''
                              }
                              disabled={
                                !capture_context.division ||
                                capture_entities.subdivisions.length === 0 ||
                                current_capture_level?.disabled
                              }
                              choices={capture_entities.subdivisions.filter(
                                (subdivision: Subdivision) => {
                                  return capture_context.division
                                    ? subdivision.division.id === capture_context.division.id
                                    : false;
                                }
                              )}
                              onChangeHandler={(event: SelectChangeEvent<string>) =>
                                handleContextChange({
                                  ...capture_context,
                                  subdivision: JSON.parse(event.target.value),
                                  site: null
                                })
                              }
                            />
                          )}
                          {capture_input === 'site' && (
                            <MetricContextSelect
                              id="site-select"
                              label="Site"
                              value={
                                capture_context.site ? JSON.stringify(capture_context.site) : ''
                              }
                              disabled={
                                !capture_context.subdivision ||
                                capture_entities.sites.length === 0 ||
                                current_capture_level?.disabled
                              }
                              choices={capture_entities.sites.filter((site: Site) => {
                                return capture_context.subdivision
                                  ? site.subdivision.id === capture_context.subdivision.id
                                  : false;
                              })}
                              onChangeHandler={(event: SelectChangeEvent<string>) =>
                                handleContextChange({
                                  ...capture_context,
                                  site: JSON.parse(event.target.value)
                                })
                              }
                            />
                          )}
                          {capture_input === 'reporting_period_group' && (
                            <ReportingPeriodGroupSelect
                              disabled={
                                (!!current_input_fields[index - 1] &&
                                  !capture_context[current_input_fields[index - 1]]) ||
                                current_capture_level?.disabled
                              }
                              selected_reporting_period_group={
                                capture_context.reporting_period_group
                              }
                              reporting_period_group_options={default_reporting_period_groups.concat(
                                capture_entities.reporting_period_groups
                              )}
                              handleChangeReportingPeriodGroups={(
                                value: Array<ReportingPeriodGroup> | ReportingPeriodGroup | null
                              ) => {
                                if (!Array.isArray(value)) {
                                  handleContextChange({
                                    ...capture_context,
                                    reporting_period_group: value,
                                    reporting_period: null
                                  });
                                }
                              }}
                            />
                          )}
                          {capture_input === 'reporting_period' && (
                            <ReportingPeriodSelect
                              selected_reporting_periods={capture_context.reporting_period}
                              reporting_period_options={
                                capture_context.reporting_period_group?.id === 'all'
                                  ? reportingPeriodFilterByGroup(
                                      capture_context.reporting_period_group?.id ?? 'all',
                                      capture_entities.reporting_periods
                                    )
                                  : [averaged_reporting_period as ReportingPeriod].concat(
                                      reportingPeriodFilterByGroup(
                                        capture_context.reporting_period_group?.id ?? 'all',
                                        capture_entities.reporting_periods
                                      )
                                    )
                              }
                              disabled={
                                !capture_context.reporting_period_group ||
                                capture_entities.reporting_periods.length === 0 ||
                                current_capture_level?.disabled
                              }
                              handleChangeReportingPeriods={(value) =>
                                handleContextChange({
                                  ...capture_context,
                                  reporting_period: value as ReportingPeriod
                                })
                              }
                            />
                          )}
                          {capture_input === 'user_type' && (
                            <MetricContextSelect
                              id="user-type-select"
                              label="Capture As"
                              value={
                                capture_context.user_type
                                  ? JSON.stringify(capture_context.user_type)
                                  : ''
                              }
                              disabled={
                                !capture_context.reporting_period ||
                                capture_entities.user_types.length === 0 ||
                                current_capture_level?.disabled
                              }
                              choices={capture_entities.user_types}
                              onChangeHandler={(event: SelectChangeEvent<string>) =>
                                handleContextChange({
                                  ...capture_context,
                                  user_type: JSON.parse(event.target.value)
                                })
                              }
                            />
                          )}
                          {capture_input === 'external_company' && (
                            <MetricContextSelect
                              id="external-company-select"
                              label="External Company"
                              value={
                                capture_context.external_company
                                  ? JSON.stringify(capture_context.external_company)
                                  : ''
                              }
                              disabled={
                                capture_entities.external_companies.length === 0 ||
                                capture_context.user_type?.id === 'internal' ||
                                !capture_context.reporting_period ||
                                current_capture_level?.disabled
                              }
                              choices={capture_entities.external_companies}
                              onChangeHandler={(event: SelectChangeEvent<string>) =>
                                handleContextChange({
                                  ...capture_context,
                                  external_company: JSON.parse(event.target.value)
                                })
                              }
                            />
                          )}
                        </Grid>
                      );
                    })}
                    <Grid
                      item
                      xs={2.4}
                      sx={{
                        marginTop: 1
                      }}
                    >
                      <Button
                        variant="contained"
                        disabled={!input_valid}
                        onClick={() => {
                          handleShowMetricRecords();
                          setCollapse(true);
                        }}
                      >
                        {current_capture_level?.id === 'import'
                          ? 'View Imports'
                          : 'View Metric Records'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {missing_entities && !loading_entities && company && user_info && (
              <Grid item xs={12}>
                <AlertBanner
                  severity="warning"
                  message={MetricCollectionWarningMessage(
                    missing_entities,
                    company?.name,
                    group?.id,
                    user_info
                  )}
                  open
                  width_perc={100}
                />
              </Grid>
            )}
            {!missing_entities &&
              !loading_entities &&
              company &&
              capture_entities.external_companies.length === 0 &&
              user_info &&
              capture_context.user_type?.id === 'external' && (
                <Grid item xs={12}>
                  <AlertBanner
                    severity="warning"
                    message={MetricCollectionWarningMessage(
                      'External Companies',
                      company?.name,
                      group?.id,
                      user_info
                    )}
                    open
                    width_perc={100}
                  />
                </Grid>
              )}
          </>
        )}
        {capture_context.reporting_period && capture_context.reporting_period.id === 'all' && (
          <Grid item xs={12}>
            <AlertBanner
              severity="warning"
              message={
                'You are capturing on a reporting period group level, all captured data will be split evenly between each period in the reporting group.'
              }
              open
              width_perc={100}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default MetricRecordCaptureContext;
