import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const ConfigurationCard = ({
  title,
  icon,
  description,
  route
}: {
  title: string;
  icon: ReactNode;
  description: string;
  route: string;
}) => {
  return (
    <Box
      sx={{
        border: '1px solid #053d5d',
        borderRadius: '4px',
        maxWidth: '400px',
        height: '90px',
        overflow: 'hidden',
        px: 2,
        boxShadow: 'inset 0px -5px 2px -1px rgba(0, 0, 0, 0.1)'
      }}
    >
      <Link
        to={`/${route}`}
        style={{
          textDecoration: 'none',
          color: '#053d5d',
          minWidth: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {icon}
        <Box sx={{ mx: 2 }}>
          <Typography>{title}</Typography>
          <Typography fontSize={'0.8rem'}>{description}</Typography>
        </Box>
      </Link>
    </Box>
  );
};

export default ConfigurationCard;
