import * as React from 'react';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
import { Typography, Divider } from '@mui/material';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { styled } from '@mui/system';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { FeedbackSnackbarContext } from '../../context/FeedbackSnackbarContext';
import { User } from '@esg/esg-global-types';
import { API_ENDPOINT } from '../../env';
import AlertBanner from '../shared/banner/AlertBanner';
import { UserContext } from '../../context/UserContext';
import { auth } from '../../lib/google/firebase';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../util/log';

interface PropTypes {
  delete_user: User | null;
  users: Array<User>;
  handleDeleteUserRow: (delete_user: User) => void;
  closeDrawer: () => void;
}

const DrawBoxContainer = styled(Box)({
  margin: 30
});

const DeleteUser = (props: PropTypes) => {
  const current_user = React.useContext(UserContext);
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [deletingUser, setDeletingUser] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const remaining_super_admin = props.users.find(
    (user) => user.super_admin && user.email !== current_user?.email
  );

  const navigate = useNavigate();

  const handleSignOut = () => {
    try {
      signOut(auth)
        .then(() => {
          localStorage.removeItem('current_group');
          localStorage.removeItem('current_company');
          // Sign-out successful.
          navigate('/');
        })
        .catch((err: unknown) => {
          const tracking_id: string = uuidv4();
          log(
            'error',
            new MetadataError(
              err instanceof Error
                ? err.message
                : 'Error: DeleteUser failed on an unknown error while initializing.',
              {
                current_user: current_user
              },
              tracking_id
            )
          );
          setFeedbackData({
            message: `Unable to sign out. Tracking ID: ${tracking_id}`,
            state: true,
            type: 'error'
          });
        });
      props.closeDrawer();
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: DeleteUser failed on an unknown error while calling handleSignOut.',
          {
            current_user: current_user
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to sign out. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const handleDeleteUser = async (event: React.MouseEvent) => {
    try {
      if (props.delete_user) {
        event.preventDefault();
        const user_details = {
          id: props.delete_user.id
        };
        await getAuth()
          .currentUser?.getIdToken()
          .then(async (token) => {
            try {
              setDeletingUser(true);
              setErrorMessage('');
              await axios({
                method: 'post',
                url: `${API_ENDPOINT}/delete_user_request`,
                data: user_details,
                headers: {
                  authorization: String('Bearer ' + token)
                }
              })
                .then(() => {
                  props.closeDrawer();
                  setDeletingUser(false);
                  if (props.delete_user && current_user?.email === props.delete_user.email) {
                    handleSignOut();
                  } else {
                    props.delete_user && props.handleDeleteUserRow(props.delete_user);
                  }
                })
                .catch((error) => {
                  if (error.code !== 'ERR_NETWORK') {
                    setErrorMessage(error.response.data);
                  }
                  throw new Error(error);
                });
            } catch (err: unknown) {
              setErrorMessage(err instanceof Error ? err.message : 'Error: Unable to delete user');
              throw err;
            }
          })
          .catch((error) => {
            throw new Error(error);
          });
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: DeleteUser failed on an unknown error while calling handleDeleteUser.',
          {
            current_user: current_user,
            'props.delete_user': props.delete_user
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to delete user. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setDeletingUser(false);
    }
  };

  return (
    <Box>
      <DrawBoxContainer
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2
        }}
      >
        <PersonRemoveIcon />
        <Typography variant="h6">Delete {props.delete_user?.email}</Typography>
      </DrawBoxContainer>
      <Divider />
      <DrawBoxContainer>
        <AlertBanner
          severity="warning"
          message={
            'You are Deleting the Account currently in use. You will be logged out after this action'
          }
          open={current_user?.email === props.delete_user?.email}
        />
        <AlertBanner
          severity="error"
          message={'Cannot Delete only remaining Super Admin'}
          open={!remaining_super_admin && current_user?.email === props.delete_user?.email}
        />
        <LoadingButton
          loading={deletingUser}
          variant="contained"
          fullWidth
          onClick={handleDeleteUser}
          sx={{ my: 4 }}
          disabled={!remaining_super_admin && current_user?.email === props.delete_user?.email}
        >
          Delete User
        </LoadingButton>
        <Typography marginBottom={2} sx={{ color: 'red' }}>
          {errorMessage}
        </Typography>
      </DrawBoxContainer>
    </Box>
  );
};

export default DeleteUser;
