import React from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

const ContactHeader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        marginY: 6
      }}
    >
      <Typography color={'#053d5d'} fontWeight={600} fontSize={40}>
        Start Your ESG Journey
      </Typography>
      <Typography color={'#053d5d'} fontWeight={500} fontSize={25} letterSpacing={2}>
        esg@energypartners.co.za
      </Typography>
    </Box>
  );
};

export default ContactHeader;
