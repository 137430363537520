import React, { useEffect, useState } from 'react';
import { getUserClaims, Claims } from '../../lib/google/firebase';
import DefaultView from '../../components/shared/template/DefaultView';

const MetricCollectionAuditLogs = () => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    getUserClaims().then((claims: Claims) => {
      setIsActive(claims.metric_collection.active);
    });
  }, []);

  return isActive ? <DefaultView title="Audit Logs" /> : <h2>Please activate this service</h2>;
};

export default MetricCollectionAuditLogs;
