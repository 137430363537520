import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputBase
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import React from 'react';

/**
 * A modal displaying an expanded text input for long qualitative Metric Record values.
 * @param {boolean} open The display state of the modal.
 * @param {string | number | undefined} value The metric record value of the text input.
 * @param {string} title The title (metric name) of the modal.
 * @param {void} handleChange Handler function to update the text value in the parent edit component
 * @param {void} handleCloseModal Handler function to close the modal
 * @returns {JSX.Element}
 */
const ExpandValueEditModal = ({
  open,
  value,
  title,
  handleChange,
  handleCloseModal
}: {
  open: boolean;
  value?: string | number;
  title: string;
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleCloseModal: () => void;
}) => {
  return (
    <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <InputBase
          multiline
          rows={20}
          value={value}
          sx={{
            width: '100%',
            fontSize: '0.9rem',
            py: 2,
            px: 1,
            border: '1px solid rgba(0, 0, 0, 0.3)',
            borderRadius: '3px'
          }}
          onChange={handleChange}
        />
        <DialogActions sx={{ mt: 4 }}>
          <Button
            startIcon={<ArrowBackIosNewIcon />}
            variant="text"
            size="medium"
            onClick={handleCloseModal}
          >
            Back
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ExpandValueEditModal;
