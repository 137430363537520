import React from 'react';
import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material';
import { ReportingPeriodGroup } from '@esg/esg-global-types';
import { getReportingPeriodGroups } from '../../../../lib/metric_capture/reporting_period_group';

interface ReportingPeriodGroupSelectProps {
  handleChangeReportingPeriodGroups?: (
    reporting_period_groups: Array<ReportingPeriodGroup> | ReportingPeriodGroup | null
  ) => void;
  selected_reporting_period_group?: Array<ReportingPeriodGroup> | ReportingPeriodGroup | null;
  reporting_period_group_options?: Array<ReportingPeriodGroup>;
  disabled?: boolean;
  group_id?: string;
  company_id?: string;
  allow_multi_select?: boolean;
  tag_limit?: number;
}

/**
 * A general selector for Reporting Period Group options
 * @param {ReportingPeriodGroup | null | undefined} props.selected_reporting_period_group Optional controlled selected Reporting Period Group
 * @param {Array<ReportingPeriodGroup> | undefined} props.reporting_period_group_options Optional list of Reporting Period Groups to use as options
 * @param {boolean | undefined} props.disabled Optional disabling of selector
 * @param {string | undefined} props.group_id ID of Group to use for optional Reporting Period Group querying for options
 * @param {string | undefined} props.company_id ID of Company to use for optional Reporting Period Group querying for options
 * @param {boolean} props.allow_multi_select Allow multi option selection and keeps the select open while selecting. Defaults to a single select and closes after selecting an option.
 * @param {number} props.tag_limit Sets a limit on the amount of tags rendered in the input. Defaults to unlimited.
 * @param {void} props.handleChangeReportingPeriodGroups Optional handler function to process selected Reporting Period Groups in parent
 * @returns {JSX.Element}
 */
const ReportingPeriodGroupSelect = (props: ReportingPeriodGroupSelectProps) => {
  const [reportingPeriodGroupOptions, setReportingPeriodGroupOptions] = React.useState<
    Array<ReportingPeriodGroup>
  >([]);
  const [currentValue, setCurrentValue] = React.useState<
    Array<ReportingPeriodGroup> | ReportingPeriodGroup | null
  >(null);
  React.useEffect(() => {
    (async () => {
      if (
        props.reporting_period_group_options === undefined &&
        props.group_id &&
        props.company_id
      ) {
        const reporting_period_groups: Array<ReportingPeriodGroup> = await getReportingPeriodGroups(
          props.group_id,
          props.company_id
        );
        setReportingPeriodGroupOptions({
          ...reportingPeriodGroupOptions,
          ...reporting_period_groups
        });
      }
    })();
  }, []);

  return (
    <Autocomplete
      id="reporting-period-group-select"
      fullWidth
      multiple={props.allow_multi_select}
      disableCloseOnSelect={props.allow_multi_select}
      limitTags={props.tag_limit}
      disabled={props.disabled}
      options={
        props.reporting_period_group_options
          ? reportingPeriodGroupOptions.concat(props.reporting_period_group_options)
          : reportingPeriodGroupOptions
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={
        props.selected_reporting_period_group !== undefined
          ? props.selected_reporting_period_group
          : currentValue === null
            ? props.allow_multi_select
              ? []
              : null
            : currentValue
      }
      getOptionLabel={(option: ReportingPeriodGroup) => {
        return option.name;
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} label="Reporting Period Group" variant="standard" />
      )}
      onChange={(
        event: React.SyntheticEvent<Element, Event>,
        value: Array<ReportingPeriodGroup> | ReportingPeriodGroup | null
      ) => {
        setCurrentValue(value);
        props.handleChangeReportingPeriodGroups && props.handleChangeReportingPeriodGroups(value);
      }}
    />
  );
};
export default ReportingPeriodGroupSelect;
