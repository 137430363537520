import {
  collection,
  getDocs,
  query,
  CollectionReference,
  Query,
  QuerySnapshot,
  where,
  doc,
  setDoc,
  updateDoc,
  deleteField
} from 'firebase/firestore';
import { refCompanyDoc } from '../app/company';
import { Country, MetricRecord, Region, ReportingPeriod } from '@esg/esg-global-types';
import { getCountries } from './country';
import { db } from '../google/firebase';
import { createAuditLog } from '../app/audit';
import { BatchWrite, processBatchWrites } from '../app/db_util';
import { getReportingPeriods } from './reporting_period';
import { getMetricRecords } from './metric_record';
import { FirestoreQueryParam } from '../../@types/shared';
import { auth } from '../google/firebase';
import { generateAuditLogData, generateAuditLogDoc } from '../app/audit';
import { DocumentReference } from 'firebase/firestore';

export interface Location extends Region {
  country: Country;
}

/**
 * Function to query all region documents for current portal company
 * @param {Group} group_id Group object of current portal company
 * @param {string} company_id id of current portal company to reference firestore documents
 * @returns {Array<Region>}
 */
export const getRegions = async (group_id: string, company_id: string) => {
  const regions_collection: CollectionReference = collection(
    refCompanyDoc(group_id, company_id),
    'regions'
  );
  const regions_query: Query = query(regions_collection, where('deleted', '==', null));
  try {
    const region_docs: QuerySnapshot = await getDocs(regions_query);
    const regions: Array<Region> = region_docs.docs.map((region) => {
      return {
        id: region.id,
        deleted: region.data().deleted,
        name: region.data().name,
        country: region.data().country,
        archived: region.data().archived
      };
    });
    return regions;
  } catch (err) {
    const error = `Error while getting Regions from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        regions_collection: regions_collection,
        regions_query: regions_query
      }
    })}`;
    throw new Error(`Error: getRegions: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to query all Region documents with joined with relative Country data
 * @param {Group} group_id Group object of current portal company
 * @param {string} company_id id of current portal company to reference firestore documents
 * @returns {Array<Region>}
 */
export const getRegionJoinCountries = async (group_id: string, company_id: string) => {
  const regions_arr: Array<Region> = [];
  const regions_collection: CollectionReference = collection(
    refCompanyDoc(group_id, company_id),
    'regions'
  );
  try {
    const countries: Array<Country> = await getCountries(group_id, company_id);
    const region_promises: Array<Promise<void>> = countries.map(async (country: Country) => {
      const regions_query: Query = query(
        regions_collection,
        where(
          'country',
          '==',
          doc(db, `/groups/${group_id}/companies/${company_id}/countries/${country.id}`)
        ),
        where('deleted', '==', null)
      );
      const region_docs: QuerySnapshot = await getDocs(regions_query);
      region_docs.docs.forEach((region) => {
        regions_arr.push({
          id: region.id,
          deleted: region.data().deleted,
          name: region.data().name,
          country: country,
          archived: region.data().archived
        });
      });
    });
    await Promise.all(region_promises);
    return regions_arr;
  } catch (err) {
    const error = `Error while getting Regions from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        regions_arr: regions_arr,
        regions_collection: regions_collection
      }
    })}`;
    throw new Error(`Error: getMetrics: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to query all region documents from master list
 * @returns {Array<Region>}
 */
export const getRegionMasterList = async () => {
  const region_collection: CollectionReference = collection(db, 'region_master_list');
  const regions_query: Query = query(region_collection, where('deleted', '==', null));
  try {
    const region_docs: QuerySnapshot = await getDocs(regions_query);
    const regions: Array<Region> = region_docs.docs.map((region) => {
      return {
        id: region.id,
        deleted: region.data().deleted,
        name: region.data().name,
        country: region.data().country
      };
    });
    return regions;
  } catch (err) {
    const error = `Error while getting Regions from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        sector_collection: region_collection,
        sectors_query: regions_query
      }
    })}`;
    throw new Error(`Error: getRegionMasterList: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to create regions on a company level with joint country data
 * @param { Array<Location>} regions Regions to add
 * @param {string} group_id ID of Group to create regions for
 * @param {string} company_id ID of Company to create regions for
 * @returns {void}
 */
export const createRegionJoinCountriesBatch = async (
  regions: Array<Location>,
  group_id: string,
  company_id: string
) => {
  try {
    const region_writes: Array<BatchWrite> = [];
    const audit_log_batch_writes: Array<BatchWrite> = [];
    const countries: Array<Country> = await getCountries(group_id, company_id);
    const countries_map = countries.reduce((acc: { [key: string]: Country }, country: Country) => {
      const id: string = country.id;
      acc[id] = country;
      return acc;
    }, {});
    for (const region of regions) {
      if (countries_map[region.country.id]) {
        const region_ref = doc(
          collection(db, `/groups/${group_id}/companies/${company_id}/regions`),
          region.id
        );
        const region_data = {
          deleted: null,
          name: region.name,
          country: doc(
            collection(db, `/groups/${group_id}/companies/${company_id}/countries`),
            region.country.id
          )
        };
        region_writes.push({ reference: region_ref, operation: 'create', data: region_data });
        const audit_log_ref: DocumentReference = generateAuditLogDoc(group_id);
        const audit_log_data = generateAuditLogData(
          region.name,
          'create',
          '',
          region_ref,
          auth.currentUser?.email
        );
        audit_log_batch_writes.push({
          reference: audit_log_ref,
          operation: 'create',
          data: audit_log_data
        });
      } else {
        await setDoc(
          doc(
            collection(db, `/groups/${group_id}/companies/${company_id}/countries`),
            region.country.id
          ),
          {
            name: region.country.name,
            deleted: null,
            continent: region.country.continent,
            code: region.country.code
          }
        );
        countries_map[region.country.id] = {
          id: region.country.id,
          name: region.country.name,
          continent: region.country.continent,
          code: region.country.code,
          deleted: region.country.deleted
        };
        const region_ref = doc(
          collection(db, `/groups/${group_id}/companies/${company_id}/regions`),
          region.id
        );
        const region_data = {
          deleted: null,
          name: region.name,
          country: doc(
            collection(db, `/groups/${group_id}/companies/${company_id}/countries`),
            region.country.id
          )
        };
        region_writes.push({ reference: region_ref, operation: 'create', data: region_data });

        const audit_log_ref: DocumentReference = generateAuditLogDoc(group_id);
        const audit_log_data = generateAuditLogData(
          region.name,
          'create',
          '',
          region_ref,
          auth.currentUser?.email
        );
        audit_log_batch_writes.push({
          reference: audit_log_ref,
          operation: 'create',
          data: audit_log_data
        });
      }
    }
    await processBatchWrites(region_writes);
    await processBatchWrites(audit_log_batch_writes);
  } catch (err) {
    const error = `Error while creating Region in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: createRegionJoinCountryBatch: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to archive company Region
 * @param {string} group_id ID of Group to archive region for
 * @param {string} company_id ID of Company to archive region for
 * @param {string} region_id ID of region to archive
 * @returns {void}
 */
export const archiveRegion = async (group_id: string, company_id: string, region_id: string) => {
  try {
    if (group_id && company_id && region_id) {
      const region_doc = doc(collection(refCompanyDoc(group_id, company_id), `regions`), region_id);
      await updateDoc(region_doc, {
        archived: new Date()
      });
      await createAuditLog(group_id, 'archive', '', '', region_doc);
    }
    return;
  } catch (err) {
    const error = `Error while archiving Region in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: archiveRegion: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to unarchive company Region
 * @param {string} group_id ID of Group to unarchive region for
 * @param {string} company_id ID of Company to unarchive region for
 * @param {string} region_id ID of region to unarchive
 * @returns {void}
 */
export const unarchiveRegion = async (group_id: string, company_id: string, region_id: string) => {
  try {
    if (group_id && company_id && region_id) {
      const region_doc = doc(collection(refCompanyDoc(group_id, company_id), `regions`), region_id);
      await updateDoc(region_doc, {
        archived: deleteField()
      });
      await createAuditLog(group_id, 'archive', 'archived', '', region_doc);
    }
    return;
  } catch (err) {
    const error = `Error while unarchiving Region in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: unarchiveRegion: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to delete company Region
 * @param {string} group_id ID of Group to delete region for
 * @param {string} company_id ID of Company to delete region for
 * @param {string} region_id ID of region to delete
 * @returns {void}
 */
export const deleteRegion = async (
  group_id: string,
  company_id: string,
  region_id: string,
  region_name: string
) => {
  try {
    if (group_id && company_id && region_id) {
      const region_doc = doc(collection(refCompanyDoc(group_id, company_id), `regions`), region_id);
      await updateDoc(region_doc, {
        deleted: new Date()
      });
      await createAuditLog(group_id, 'delete', region_name, '', region_doc);
    }
    return;
  } catch (err) {
    const error = `Error while deleting Region in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: deleteRegion: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to check if a Region can be safely deleted
 * @param {string} group_id ID of Group to check Region for
 * @param {string} company_id ID of Company to check Region for
 * @param {string} region_id ID of region to check
 * @returns {boolean}
 */
export const allowDeleteRegion = async (
  group_id: string,
  company_id: string,
  region_id: string
) => {
  try {
    const reporting_periods = await getReportingPeriods(group_id, company_id, true);
    if (reporting_periods.length === 0) return true;
    const reporting_period_ids = reporting_periods.map(
      (reporting_period: ReportingPeriod) => reporting_period.id
    );
    const query_params: Array<FirestoreQueryParam> = [
      {
        field_name: 'region',
        operator: '==',
        value: doc(collection(refCompanyDoc(group_id, company_id), 'regions'), region_id)
      }
    ];
    const metric_records = await getMetricRecords(group_id, company_id, query_params);
    const allow_delete = !metric_records.some((metric_record: MetricRecord) => {
      if (reporting_period_ids.includes(metric_record.reporting_period?.id ?? '')) {
        return true;
      }
    });
    return allow_delete;
  } catch (err) {
    const error = `Error while checking Region for deletion: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: allowDeleteRegion: ${JSON.stringify(error)}.`);
  }
};
