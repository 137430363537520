import {
  CollectionReference,
  Query,
  QuerySnapshot,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore';
import { db } from '../google/firebase';
import { Group } from '@esg/esg-global-types';

/**
 * Function to query all group documents
 * @returns {Array<Group>}
 */
export const getGroups = async () => {
  const groups_collection: CollectionReference = collection(db, 'groups');
  const groups_query: Query = query(groups_collection);
  try {
    const group_docs: QuerySnapshot = await getDocs(groups_query);
    const groups: Array<Group> = group_docs.docs.map((group) => {
      return {
        id: group.id,
        name: group.data().name,
        active: group.data().active
      };
    });
    return groups;
  } catch (err) {
    const error = `Error while getting Groups from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        groups_collection: groups_collection,
        groups_query: groups_query
      }
    })}`;
    throw new Error(`Error: getGroups: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to query all group documents with nested company documents
 * @returns {Array<Group>}
 */
export const getGroupCompanies = async () => {
  try {
    const groups: Array<Group> = (await getGroups()).map((group: Group) => {
      return {
        ...group,
        companies: []
      };
    });
    const group_promises: Array<Promise<void>> = groups.map(async (group: Group) => {
      const companies_collection: CollectionReference = collection(
        doc(collection(db, 'groups'), group.id),
        'companies'
      );
      const companies_query: Query = query(companies_collection, where('deleted', '==', null));
      const company_docs: QuerySnapshot = await getDocs(companies_query);
      company_docs.docs.forEach((company) => {
        group.companies?.push({
          id: company.id,
          deleted: company.data().deleted,
          name: company.data().name,
          active: company.data().active,
          configuration: company.data().configuration
        });
      });
    });
    await Promise.all(group_promises);

    return groups;
  } catch (err) {
    const error = `Error while getting Groups with Companies from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: getGroupCompanies: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to create group with relative data
 * @param {string} group_name Name of new Group
 * @returns {DocumentReference}
 */
export const createGroup = async (group_name: string) => {
  try {
    const groups_collection: CollectionReference = collection(db, 'groups');
    const new_group_ref = doc(
      groups_collection,
      String(group_name).toLowerCase().replace(/\s/g, '_')
    );
    await setDoc(new_group_ref, {
      active: true,
      name: group_name
    });
    return new_group_ref;
  } catch (err) {
    const error = `Error while creating Group in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: createGroup: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to update group with relative data
 * @param {Group} updated_group New Group data to push into document
 * @returns {void}
 */
export const updateGroup = async (updated_group: Group) => {
  try {
    const group_doc = doc(collection(db, 'groups'), updated_group.id);
    await updateDoc(group_doc, {
      name: updated_group.name,
      active: updated_group.active
    });
  } catch (err) {
    const error = `Error while updating Group in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: updateGroup: ${JSON.stringify(error)}.`);
  }
};
