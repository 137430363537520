import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Switch,
  Tooltip,
  Typography
} from '@mui/material';
import { Company, Group } from '@esg/esg-global-types';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { PanelCompaniesToolbar } from './PanelCompaniesToolbar';
import { PanelCompaniesNoRows } from './PanelCompaniesNoRows';
import { PanelCompaniesLoading } from './PanelCompaniesLoading';
import AddCompanyWidget from './AddCompanyWidget';
import { CompanyExtended, updateCompany } from '../../../lib/app/company';
import SettingsIcon from '@mui/icons-material/Settings';
import { LoadingButton } from '@mui/lab';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { MetadataError } from '@ep/error-handling';
import { useNavigate } from 'react-router-dom';

/**
 * Datagrid for viewing and creating Companies for a previously selected Group
 * @param {Group} group Parent Group of the Companies in the datagrid
 * @param {void} addGroupCompany Function to add a newly created company in memory to be displayed within the app
 * @param {void} handleGroupChange Function to change the Group context of the app in the parent component
 * @param {void} handleCompanyChange Function to change the Company context of the app in the parent component
 * @returns {JSX.Element}
 */
const PanelCompanies = ({
  group,
  addGroupCompany,
  handleGroupChange,
  handleCompanyChange
}: {
  group: Group;
  addGroupCompany: (new_company: CompanyExtended) => void;
  handleGroupChange: (group: Group | null) => void;
  handleCompanyChange: (company: Company | null) => void;
}) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [actionRow, setActionRow] = React.useState<Company | null>(null);
  const [companyRows, setCompanyRows] = React.useState<Array<Company>>(group.companies ?? []);
  const [displayWidgetPanelAdd, setDisplayWidgetPanelAdd] = React.useState<boolean>(false);
  const [openDialogActive, setOpenDialogActive] = React.useState<boolean>(false);
  const [actionLoading, setActionLoading] = React.useState<boolean>(false);

  const navigate = useNavigate();

  // Handler functions
  const handleCreateClick = (): void => {
    setActionRow(null);
    setDisplayWidgetPanelAdd(true);
  };

  const handleActiveToggleClick = (row: Company): void => {
    setActionRow(row);
    setOpenDialogActive(true);
  };

  const handleUpdateRows = (rows_data: Array<Company>): void => {
    setCompanyRows(rows_data);
  };

  const handleEditCompany = (updated_row: Company): void => {
    const new_rows: Array<Company> = companyRows.map((row) =>
      updated_row.id === row.id ? { ...updated_row } : row
    );
    setCompanyRows(new_rows);
  };

  const handleCloseActiveModal = (): void => {
    setOpenDialogActive(false);
  };

  const handleCompanyActive = async (new_active: boolean): Promise<void> => {
    try {
      if (actionRow) {
        setActionLoading(true);
        await updateCompany(group.id, { ...actionRow, active: new_active }, actionRow).then(() => {
          handleEditCompany({ ...actionRow, active: new_active });
        });
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelCompanies failed on an unknown error while calling handleCompanyActive.',
          {
            actionRow: actionRow,
            new_active: new_active
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `An error occurred. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      handleCloseActiveModal();
      setActionLoading(false);
    }
  };

  const handleConfigCompany = (row: Company): void => {
    handleGroupChange(group);
    handleCompanyChange(row);
    navigate('/configuration/dashboard');
  };

  const columns: Array<GridColDef> = [
    {
      field: 'active',
      headerName: 'Active',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.row.active ? true : false}
            onChange={() => {
              setActionRow(params.row);
              handleActiveToggleClick(params.row);
            }}
          />
        );
      }
    },
    {
      field: 'logo',
      headerName: 'Logo',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        return <img width={56} src={params.row.configuration.logo as string} alt="logo" />;
      },
      flex: 1
    },
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      headerAlign: 'left',
      align: 'left',
      hideable: false,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <>
            <GridActionsCellItem
              key={1}
              icon={
                <Tooltip title="Configure Company">
                  <SettingsIcon fontSize="large" />
                </Tooltip>
              }
              size="large"
              label="Edit"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleConfigCompany(row)}
              disabled={!row.active}
            />
          </>
        ];
      }
    }
  ];

  return (
    <>
      {/* Set Company Active Modal */}
      <Dialog open={openDialogActive} onClose={handleCloseActiveModal}>
        <Box sx={{ p: 1.5 }}>
          <DialogTitle id="alert-dialog-title">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <WarningAmberIcon sx={{ marginRight: '1rem' }} fontSize="medium" />
              <Typography variant="h6" color="inherit" align="left">
                {actionRow?.active ? 'Disable' : 'Enable'}{' '}
                {actionRow?.name ? actionRow.name : 'Company'}?
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are about to {actionRow?.active ? 'deactivate' : 'activate'}{' '}
              {actionRow?.name ? actionRow.name : 'company'} {actionRow?.active ? 'from' : 'for'}{' '}
              your companies.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseActiveModal} variant="outlined" color="primary">
              Back
            </Button>
            <LoadingButton
              loading={actionLoading}
              onClick={() => actionRow && handleCompanyActive(actionRow.active ? false : true)}
              variant="contained"
              color="error"
              autoFocus
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>

      {/* Side Widget Panel */}
      <Drawer
        anchor={'right'}
        open={displayWidgetPanelAdd}
        onClose={() => setDisplayWidgetPanelAdd(false)}
        PaperProps={{ style: { width: '30%', padding: '1.5rem' } }}
      >
        <AddCompanyWidget
          group={group}
          handleCompanyToParent={(new_company: CompanyExtended) => {
            addGroupCompany(new_company);
            handleUpdateRows([new_company, ...companyRows]);
            setDisplayWidgetPanelAdd(false);
          }}
        />
      </Drawer>

      {/* Interactive Data Table */}
      <DataGrid
        autoHeight
        rowHeight={65}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          },
          sorting: {
            sortModel: [{ field: 'modified', sort: 'desc' }]
          }
        }}
        hideFooter={companyRows.length > 1 ? false : true}
        columnHeaderHeight={companyRows.length < 1 ? 0 : 56}
        pageSizeOptions={[10, 25, 50, 100]}
        rows={companyRows}
        columns={columns}
        disableRowSelectionOnClick
        slots={{
          toolbar: PanelCompaniesToolbar,
          noRowsOverlay: PanelCompaniesNoRows,
          loadingOverlay: PanelCompaniesLoading
        }}
        slotProps={{ toolbar: { handleCreate: handleCreateClick } }}
        sx={{ '&, [class^=MuiDataGrid]': { border: 'none' }, width: '100%' }}
      />
    </>
  );
};

export default PanelCompanies;
