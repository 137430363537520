import { Box } from '@mui/material';
import React from 'react';
import ConfigurationWrapper from '../../components/shared/ConfigurationWrapper';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EmojiTransportationOutlinedIcon from '@mui/icons-material/EmojiTransportationOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import PanelOrganisation from '../../components/configuration/organisation/PanelOrganisation';

const ConfigurationOrganisation = () => {
  return (
    <Box>
      <ConfigurationWrapper
        title="Organisation"
        icon={<AccountTreeOutlinedIcon fontSize="large" />}
        previous={{
          route: '/configuration/geographical',
          label: 'Geographical',
          icon: <LocationOnOutlinedIcon />
        }}
        next={{
          route: '/configuration/external-companies',
          label: 'External Companies',
          icon: <EmojiTransportationOutlinedIcon />
        }}
      >
        <PanelOrganisation />
      </ConfigurationWrapper>
    </Box>
  );
};

export default ConfigurationOrganisation;
