import React from 'react';
import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import process_img from '../../assets/business-technology.webp';
import Fade from '@mui/material/Fade';

const ProcessSection = () => {
  return (
    <Grid container spacing={6}>
      <Grid item xs={8}>
        <Typography fontWeight={600} fontSize={35}>
          Business Intelligence Through Energy Partners&rsquo; Proprietary Software
        </Typography>
        <Box marginY={3}>
          <Typography whiteSpace={'pre-wrap'}>
            Data collectors are used to acquire meter data from an Automated Meter Reading (AMR)
            platform, which is stored in a BigQuery(1) data warehouse. Additional data sources, such
            as site operations, billing data, and static information, such as refrigerant data, are
            imported into the warehouse. The GLO Intelligence layer is used to perform the required
            data processing and calculations.
            <br></br>
            <br></br>
            Our Carbon and Water Dashboard is accessible via Web Portal, configured using Power
            BI(2). The Power BI dashboards import data directly from GLO, therefore the client has
            access to all of the raw and processed data in the warehouse.
          </Typography>
        </Box>
        <Typography fontSize={13} whiteSpace={'pre-wrap'}>
          (1) BigQuery is a cloud-based, highly scalable data warehouse developed by Google, which
          allows the effective analysis of large datasets.
          <br></br>
          <br></br>
          (2) Power BI is a business analytics service developed by Microsoft.
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Fade in timeout={700}>
          <img src={process_img} width={500} />
        </Fade>
      </Grid>
    </Grid>
  );
};

export default ProcessSection;
