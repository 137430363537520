import * as React from 'react';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
import { Typography, Divider, Stepper, Step, StepLabel, Button } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { styled } from '@mui/system';
import { getAuth } from 'firebase/auth';
import axios, { AxiosError } from 'axios';
import { FeedbackSnackbarContext } from '../../context/FeedbackSnackbarContext';
import { CLOUD_FUNCTION_URL } from '../../env';
import { Company, Country, Group, Module, Role, User } from '@esg/esg-global-types';
import AccountDetails from './shared/AccountDetails';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CompanyAccessSelect from './shared/CompanyAccessSelect';
import CompanyAccessConfig from './shared/CompanyAccessConfig';
import { updateUser } from '../../lib/user_management/users';
import { InputErrors } from '../../@types/shared';
import { validateEmail, validatePhoneNumber } from '../../lib/validation/text_validation';
import { MetadataError } from '@ep/error-handling';
import { log } from '../../util/log';
import { uuidv4 } from '@firebase/util';

type StepProgress = 'invalid' | 'next' | 'finished';

const steps = ['Account Details', 'Access a Company', 'Configure Access'];

const DrawBoxContainer = styled(Box)({
  margin: 30
});

const formatName = (name: string) => {
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};

const EditUser = ({
  edit_user,
  groups,
  roles,
  modules,
  handleUpdateUserRow,
  closeDrawer
}: {
  edit_user: User | null;
  groups: Array<Group>;
  roles: Array<Role>;
  modules: Array<Module>;
  handleUpdateUserRow(updated_user: User): void;
  closeDrawer: () => void;
}) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [userData, setUserData] = React.useState<User | null>(edit_user);
  const [countryData, setCountryData] = React.useState<Country | null>(null);
  const [configGroup, setConfigGroup] = React.useState<Group | null>(null);
  const [configCompany, setConfigCompany] = React.useState<Company | null>(null);
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [inputErrors, setInputErrors] = React.useState<InputErrors>({
    first_name: '',
    last_name: '',
    email: '',
    phone: ''
  });
  const [loadingUserUpdate, setLoadingUserUpdate] = React.useState<boolean>(false);

  const valid_user_data = !!(
    userData &&
    userData.first_name !== '' &&
    userData.last_name !== '' &&
    countryData &&
    countryData.phone_regex &&
    validateEmail(userData.email) &&
    validatePhoneNumber(userData.phone, countryData.phone_regex)
  );

  // Validate current step input values
  let step_progress: StepProgress = 'invalid';
  switch (activeStep) {
    case 0:
      if (valid_user_data) {
        step_progress = 'next';
      } else step_progress = 'invalid';
      break;
    case 1:
      if (userData?.super_admin || (configGroup && userData?.groups[configGroup.id]?.admin))
        step_progress = 'finished';
      else if (configGroup && configCompany) step_progress = 'next';
      else step_progress = 'invalid';
      break;
    case 2:
      step_progress = 'finished';
      break;
    default:
      break;
  }

  const handleStepChange = (new_step: number) => {
    setActiveStep(new_step);
  };

  const handleInputErrors = (input_id: string, error: string) => {
    setInputErrors({ ...inputErrors, [input_id]: error });
  };

  const handleUpdateUserSubmit = async (event: React.MouseEvent) => {
    try {
      if (userData) {
        event.preventDefault();
        const user_details = {
          id: userData.id,
          first_name: formatName(userData.first_name),
          last_name: formatName(userData.last_name),
          contact_number: userData.phone
        };
        await getAuth()
          .currentUser?.getIdToken()
          .then(async (token) => {
            setLoadingUserUpdate(true);
            await axios({
              method: 'post',
              url: `${CLOUD_FUNCTION_URL}/updateUser`,
              data: user_details,
              headers: {
                authorization: String('Bearer ' + token)
              }
            })
              .then(async () => {
                await updateUser(userData.id, userData).catch((error) => {
                  setFeedbackData({ message: error.message, state: true, type: 'error' });
                });
                setLoadingUserUpdate(false);
                handleClose();
                handleUpdateUserRow(userData);
                setFeedbackData({
                  message: `User Successfully Updated`,
                  state: true,
                  type: 'success'
                });
              })
              .catch((error) => {
                throw error;
              });
          })
          .catch((error) => {
            throw error;
          });
      }
    } catch (err: unknown) {
      let error_message = 'Error: Failed to update user';
      if (err instanceof AxiosError && err.response?.data === 'auth/phone-number-already-exists') {
        error_message =
          'Error: Number already registered to another user, please use an alternate number or contact Energy Partners.';
      }
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: Edit failed on an unknown error while calling handleUpdateUserSubmit.',
          {
            userData: userData
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `${error_message}. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setLoadingUserUpdate(false);
    }
  };

  const handleClose = () => {
    setActiveStep(0);
    setConfigGroup(null);
    setConfigCompany(null);
    closeDrawer();
  };

  return (
    <Box>
      <DrawBoxContainer
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2
        }}
      >
        <ManageAccountsIcon />
        <Typography variant="h6">Edit {edit_user ? edit_user.email : 'User'}</Typography>
      </DrawBoxContainer>
      <Divider />
      <DrawBoxContainer>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {userData && activeStep === 0 && (
          <AccountDetails
            user={userData}
            input_errors={inputErrors}
            handleUserChange={(new_user: User) => setUserData(new_user)}
            handleCountryChange={(new_country: Country | null) => setCountryData(new_country)}
            handleInputErrors={handleInputErrors}
          />
        )}
        {userData && activeStep === 1 && (
          <CompanyAccessSelect
            user={userData}
            chosen_group={configGroup}
            chosen_company={configCompany}
            group_choices={groups}
            input_errors={inputErrors}
            handleUserChange={(new_user: User) => setUserData(new_user)}
            handleGroupChange={(new_group: Group) => {
              setConfigGroup(new_group);
              setConfigCompany(null);
            }}
            handleCompanyChange={(new_company: Company) => setConfigCompany(new_company)}
            handleInputErrors={handleInputErrors}
          />
        )}
        {activeStep === 2 && configGroup && configCompany && userData && (
          <CompanyAccessConfig
            user={userData}
            chosen_group={configGroup}
            chosen_company={configCompany}
            roles={roles}
            modules={modules}
            handleUserChange={(new_user: User) => setUserData(new_user)}
          />
        )}
        <Divider />
        <Box
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', pt: 2, px: 4 }}
        >
          <Button startIcon={<ChevronLeftIcon />} onClick={handleClose} sx={{ mr: 2 }}>
            Cancel
          </Button>
          {activeStep !== 0 && (
            <Button
              onClick={() => handleStepChange(activeStep - 1)}
              sx={{ mr: 2, minWidth: 150 }}
              variant="contained"
            >
              Back
            </Button>
          )}
          {activeStep !== 2 && step_progress !== 'finished' && (
            <Button
              disabled={step_progress === 'invalid'}
              onClick={() => handleStepChange(activeStep + 1)}
              variant="contained"
              sx={{ mr: 2, minWidth: 150 }}
            >
              Next
            </Button>
          )}
          <LoadingButton
            loading={loadingUserUpdate}
            disabled={!valid_user_data}
            onClick={(event: React.MouseEvent) => handleUpdateUserSubmit(event)}
            variant="contained"
            sx={{ minWidth: 150 }}
          >
            Update User
          </LoadingButton>
        </Box>
      </DrawBoxContainer>
    </Box>
  );
};

export default EditUser;
