import React from 'react';
import { Box, Typography } from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { Group } from '@esg/esg-global-types';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';

const AuditLogsGridNoRows = ({ selected_group }: { selected_group: Group | null }) => {
  return (
    <Box
      sx={{
        height: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'primary.main'
      }}
    >
      {selected_group ? (
        <>
          <SearchOffIcon fontSize="large" />
          <Typography variant="body1" component="h2">
            No audit logs found.
          </Typography>
        </>
      ) : (
        <>
          <AutoAwesomeMosaicIcon sx={{ fontSize: '2.5rem', marginBottom: 1.5 }} />
          <Typography variant="body1" component="h2">
            Select a Group to view Metric Record activity
          </Typography>
        </>
      )}
    </Box>
  );
};

export default AuditLogsGridNoRows;
