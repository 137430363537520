import React from 'react';
import { Box, Grid, Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CaptureContext, MetricRecordEntities } from '../../@types/shared';
import MetricRecordGridQuantitative from './metric_records/quantitative/MetricRecordGridQuantitative';
import ImportsHub from './import/ImportsHub';
import { reportingPeriodFilterByGroup } from '../../lib/metric_capture/reporting_period';
import MetricRecordGridQualitative from './metric_records/qualitative/MetricRecordGridQualitative';
import Alert from '@mui/material/Alert';
import { ReportingPeriod } from '@esg/esg-global-types';

/**
 * Wrapper component of metric record capturing functionality of various types
 * @param {CaptureContext} capture_context Context of metric record capturing to be used by children
 * @param {MetricRecordEntities} capture_entities List of Metric Record field options to be used by children
 * @returns {JSX.Element}
 */
const MetricRecordHub = ({
  capture_context,
  capture_entities
}: {
  capture_context: CaptureContext;
  capture_entities: MetricRecordEntities;
}) => {
  const [openSnackbarLockedReportingPeriod, setOpenSnackbarLockedReportingPeriod] =
    React.useState(false);
  const [openSnackbarArchived, setOpenSnackbarArchived] = React.useState<boolean>(false);
  const [lockedMessage, setLockedMessage] = React.useState<string>();

  const handleSnackbarCloseLockedReportingPeriod = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbarLockedReportingPeriod(false);
  };
  const handleSnackbarCloseArchived = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbarArchived(false);
  };
  React.useEffect(() => {
    if (capture_context.reporting_period?.id === 'all') {
      setOpenSnackbarLockedReportingPeriod(
        reportingPeriodFilterByGroup(
          capture_context.reporting_period_group?.id ?? 'all',
          capture_entities.reporting_periods
        ).some((reporting_period) => {
          return reporting_period.locked === true;
        })
      );
      setLockedMessage(
        capture_context.reporting_period?.id === 'all'
          ? `${capture_context.reporting_period_group?.name} contains a locked period`
          : `${capture_context.reporting_period?.name} is locked`
      );
    } else if (capture_context.reporting_period?.locked) {
      setOpenSnackbarLockedReportingPeriod(true);
      setLockedMessage(`${capture_context.reporting_period.name} is Locked`);
    } else if (
      capture_context.reporting_period_group &&
      reportingPeriodFilterByGroup(
        capture_context.reporting_period_group?.id,
        capture_entities.reporting_periods
      ).every((reporting_period: ReportingPeriod) => {
        return reporting_period.locked === true;
      })
    ) {
      setLockedMessage(`${capture_context.reporting_period_group.name} is Locked`);
      setOpenSnackbarLockedReportingPeriod(true);
    }
    if (
      capture_context.region?.archived ||
      capture_context.division?.archived ||
      capture_context.subdivision?.archived ||
      capture_context.site?.archived
    ) {
      setOpenSnackbarArchived(true);
    }
  }, [capture_context]);

  const snackbar_action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarCloseLockedReportingPeriod}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <Box
      sx={{
        backgroundColor: '#edf1f8',
        textAlign: 'start',
        padding: '2%'
      }}
      className="div-metrics-hub"
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {capture_context.level === 'import' ? (
            <ImportsHub capture_context={capture_context} capture_entities={capture_entities} />
          ) : capture_context.standard?.is_quantitative === true ? (
            <MetricRecordGridQuantitative
              capture_context={capture_context}
              capture_entities={capture_entities}
            />
          ) : (
            <MetricRecordGridQualitative
              capture_context={capture_context}
              capture_entities={capture_entities}
            />
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbarLockedReportingPeriod}
        autoHideDuration={null}
        onClose={handleSnackbarCloseLockedReportingPeriod}
        action={snackbar_action}
      >
        <Alert
          onClose={handleSnackbarCloseLockedReportingPeriod}
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {`${lockedMessage}: Metric Records cannot be Created, Deleted or Updated`}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackbarArchived}
        autoHideDuration={null}
        onClose={handleSnackbarCloseArchived}
        action={snackbar_action}
      >
        <Alert
          onClose={handleSnackbarCloseArchived}
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {`Context is Archived: Metric Records cannot be Created, Deleted or Updated`}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MetricRecordHub;
