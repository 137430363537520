import React from 'react';
import { Tooltip, IconButton, Button } from '@mui/material';
import { GridToolbarContainer, GridToolbarQuickFilter, GridToolbarExport } from '@mui/x-data-grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';
import { UserContext } from '../../../context/UserContext';
import { User } from '@esg/esg-global-types';

export const PanelGroupsToolbar = ({ handleCreate }: { handleCreate: () => void }) => {
  const user_info: User | null = React.useContext(UserContext);
  return (
    <>
      <GridToolbarContainer>
        <GridToolbarQuickFilter style={{ flex: 1 }} />
        <Tooltip title="Export Groups to device">
          <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        </Tooltip>
        <Tooltip title="Learn more">
          <IconButton
            aria-label="help"
            size="small"
            color="primary"
            sx={{ marginLeft: 'auto' }}
            onClick={() => {
              // Open assistance menu / bot here.
            }}
            disabled
          >
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
        {user_info && user_info.super_admin && (
          <Tooltip title="Add new Group">
            <Button variant="contained" size="large" startIcon={<AddIcon />} onClick={handleCreate}>
              Create
            </Button>
          </Tooltip>
        )}
      </GridToolbarContainer>
    </>
  );
};
