import { Button, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { LoadingButton } from '@mui/lab';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../../lib/google/firebase';
import { FirebaseError, uuidv4 } from '@firebase/util';
import { MetadataError } from '@ep/error-handling';
import { log } from '../../../util/log';

const RecoverAccount = ({ returnToSignIn }: { returnToSignIn: () => void }) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [requestSent, setRequestSent] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const [emailFocused, setEmailFocus] = React.useState(false);
  const [validEmail, setValidEmail] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [errorStatus, setErrorStatus] = React.useState<boolean>(false);
  const [resetLoad, setResetLoad] = React.useState<boolean>(false);

  const handleSendRecoveryEmail = async () => {
    try {
      setResetLoad(true);
      await sendPasswordResetEmail(auth, email)
        .then(() => setRequestSent(true))
        .catch((error: FirebaseError) => {
          if (error.code.includes('auth/user-not-found')) {
            setRequestSent(true);
          } else {
            setErrorStatus(true);
            setErrorMessage(error.code);
          }
        });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: RecoverAccount failed on an unknown error while calling handleSendRecoveryEmail.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `Failed to send recovery email. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
      setResetLoad(false);
    } finally {
      setResetLoad(false);
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      setValidEmail(email.includes('@'));
    }, 500);
    return () => {
      null;
    };
  }, [email, emailFocused]);

  return (
    <Box>
      <DialogTitle>Account Recovery</DialogTitle>
      {!requestSent && (
        <>
          <DialogContent>
            <DialogContentText>Enter Email to send recovery link to</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              error={!validEmail && emailFocused}
              id="text"
              label="Recovery Email"
              type="text"
              fullWidth
              variant="standard"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.currentTarget.value);
                setEmailFocus(true);
              }}
              value={email}
              sx={{ my: 4 }}
            />
            <DialogContentText sx={{ color: 'red', mt: 1, fontWeight: 'bold' }}>
              {errorStatus && errorMessage}
            </DialogContentText>
          </DialogContent>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mt: 6,
              gap: 1,
              mx: -2
            }}
          >
            <LoadingButton
              loading={resetLoad}
              data-testid="send-button"
              onClick={handleSendRecoveryEmail}
              variant="contained"
              sx={{ justifySelf: 'center' }}
              disabled={!validEmail}
            >
              Send Recovery Email
            </LoadingButton>
            <Button variant="outlined" id="back-button" onClick={() => returnToSignIn()}>
              Return to Sign In
            </Button>
          </Box>
        </>
      )}
      {requestSent && (
        <Box>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
            <DialogContentText sx={{ mb: 6 }}>
              Recovery Email has been sent to specified email if account exists
            </DialogContentText>
            <Button variant="outlined" id="back-button" onClick={() => returnToSignIn()}>
              Return to Sign In
            </Button>
          </DialogContent>
        </Box>
      )}
    </Box>
  );
};

export default RecoverAccount;
