import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { EmissionFactor } from '@esg/esg-global-types';
import { MetricExtended, getMetrics } from '../../../../lib/metric_capture/metric';

interface MetricSelectorProps {
  handleInputChange?: (input_id: string, value: MetricExtended) => void;
  value: EmissionFactor | null;
  group_id?: string;
  company_id?: string;
}

const MetricSelect = (props: MetricSelectorProps) => {
  const [metric_list_configured, setMetricListConfigured] = React.useState<Array<MetricExtended>>(
    []
  );
  const [selected_value] = React.useState<MetricExtended>();

  React.useEffect(() => {
    (async () => {
      const metrics: Array<MetricExtended> =
        props.group_id && props.company_id
          ? await getMetrics(false, props.group_id, props.company_id)
          : await getMetrics(true);
      setMetricListConfigured(metrics);
    })();
  }, []);

  return (
    <Autocomplete
      fullWidth
      id="metric-select"
      options={metric_list_configured}
      autoHighlight
      value={props.value ? props.value.metric : selected_value}
      groupBy={(option) => option.group_name}
      onChange={(event, value) => {
        props.handleInputChange && props.handleInputChange('metric', value);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Metric"
          inputProps={{
            ...params.inputProps
          }}
        />
      )}
    />
  );
};

export default MetricSelect;
