import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { resolveObjectFromString } from '../../../../util/validation';

interface GroupCheckboxListItem {
  [key: string]: any;
  id: string;
  name?: string;
}

const GroupedFilterCheckboxDropdown = ({
  input_items,
  selected_items,
  label,
  group_by,
  item_prefix,
  handleSelectionToParent,
  processSelectedCount
}: {
  input_items: Array<GroupCheckboxListItem>;
  selected_items: Array<GroupCheckboxListItem>;
  label: string;
  group_by: string;
  item_prefix?: string;
  handleSelectionToParent: (items: Array<string>) => void;
  processSelectedCount: (items: GroupCheckboxListItem[]) => number | 'All';
}) => {
  const [searchText, setSearchText] = React.useState<string>('');

  const groups: Array<string> = input_items.reduce(
    (accumulator: Array<string>, item: GroupCheckboxListItem) => {
      const group_label = item[group_by];
      if (!accumulator.some((group: string) => group && group === group_label)) {
        group_label && accumulator.push(group_label);
      }
      return accumulator;
    },
    []
  );

  const handleChange = (ids_value: Array<string> | string): void => {
    if (ids_value[ids_value.length - 1] === 'all') {
      handleSelectionToParent(
        selected_items.length === input_items.length ? [] : input_items.map((item) => item.id)
      );
      return;
    }
    const selected_ids: Array<string> =
      typeof ids_value === 'string' ? ids_value.split(',') : ids_value;
    handleSelectionToParent(selected_ids);
  };

  const toggleGroupSelection = (toggle: boolean, group_value: string) => {
    const grouped_item_ids: Array<string> = input_items
      .filter((item: GroupCheckboxListItem) => item[group_by] === group_value)
      .map((item) => item.id);
    const filtered_selection_ids: Array<string> = selected_items
      .filter(
        (selected_item: GroupCheckboxListItem) => !grouped_item_ids.includes(selected_item.id)
      )
      .map((item) => item.id);
    handleSelectionToParent(
      toggle ? [...filtered_selection_ids, ...grouped_item_ids] : filtered_selection_ids
    );
  };

  const checkGroupSelection = (group_value: string) => {
    const grouped_item_ids: Array<string> = input_items
      .filter((item: GroupCheckboxListItem) => item[group_by] === group_value)
      .map((item) => item.id);
    const selected_grouped_items: Array<GroupCheckboxListItem> = selected_items.filter(
      (selected_item: GroupCheckboxListItem) => grouped_item_ids.includes(selected_item.id)
    );
    return grouped_item_ids.length === selected_grouped_items.length;
  };

  return (
    <Box>
      <FormControl fullWidth sx={{ m: 1 }}>
        <Select
          id="site-select"
          multiple
          displayEmpty
          MenuProps={{
            autoFocus: false,
            disableAutoFocusItem: true,
            disableEnforceFocus: true,
            disableAutoFocus: true
          }}
          value={selected_items.map((item) => item.id)}
          onChange={(event: SelectChangeEvent<Array<string>>) => handleChange(event.target.value)}
          variant="standard"
          renderValue={() => (
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography sx={{ ml: 2 }}>{label}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  sx={{
                    backgroundColor: '#E7E8E9',
                    color: '#454545',
                    borderRadius: 3,
                    py: 0.2,
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                    maxWidth: '60px',
                    ml: 'auto'
                  }}
                >
                  {processSelectedCount(selected_items)}
                </Typography>
              </Grid>
            </Grid>
          )}
          onClose={() => setSearchText('')}
          fullWidth
        >
          <ListSubheader key="search">
            <TextField
              size="small"
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              onChange={(event) => setSearchText(event.target.value)}
              onKeyDown={(event) => {
                if (event.key !== 'Escape') {
                  event.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          <MenuItem key="all" value="all">
            <Checkbox checked={selected_items.length === input_items.length} />
            <ListItemText primary={'Select All'} />
          </MenuItem>
          {groups.map((group: string) => [
            <ListSubheader key={`group_${group}`} sx={{ fontSize: '1rem' }}>
              <Checkbox
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  toggleGroupSelection(event.target.checked, group)
                }
                checked={checkGroupSelection(group)}
              />
              {group}
            </ListSubheader>,
            ...input_items
              .filter((item) => {
                return (
                  item.name &&
                  (item.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
                    (item_prefix &&
                      resolveObjectFromString(item_prefix, item)
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) > -1)) &&
                  item[group_by] === group
                );
              })
              .map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  <Checkbox
                    checked={
                      selected_items.findIndex((selected_item) => selected_item.id === item.id) >= 0
                    }
                    sx={{ ml: 3 }}
                  />
                  {item_prefix ? (
                    <ListItemText
                      primary={`${resolveObjectFromString(item_prefix, item)}: ${item.name}`}
                    />
                  ) : (
                    <ListItemText primary={item.name} />
                  )}
                </MenuItem>
              ))
          ])}
        </Select>
      </FormControl>
    </Box>
  );
};

export default GroupedFilterCheckboxDropdown;
