import { Grid, Typography, TextField } from '@mui/material';
import React from 'react';
import { MuiColorInput } from 'mui-color-input';
import { MuiFileInput } from 'mui-file-input';
import { updateCompany } from '../../../lib/app/company';
import { Company, Group } from '@esg/esg-global-types';
import { LoadingButton } from '@mui/lab';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { GCP_FILE_UPLOAD_BUCKET, API_ENDPOINT } from '../../../env';
import { generateFileUploadRequest } from '../../../lib/file_upload/uploadRequestPrep';
import axios, { AxiosResponse } from 'axios';
import { hasBannedCharacters } from '../../../lib/validation/text_validation';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { CompanyContext } from '../../../context/CompanyContext';
import { GroupContext } from '../../../context/GroupContext';

const PanelPersonalisation = () => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [primaryColor, setPrimaryColor] = React.useState<string>('');
  const [secondaryColor, setSecondaryColor] = React.useState<string>('');
  const [logoFile, setLogoFile] = React.useState<File | null>(null);
  const [companyName, setCompanyName] = React.useState<string>('');
  const [updateLoading, setUpdateLoading] = React.useState<boolean>(false);

  const invalidCompanyName = companyName
    ? hasBannedCharacters(companyName)
    : companyName.length > 1
      ? false
      : true;

  const handlePrimaryChange = (newValue1: React.SetStateAction<string>) => {
    setPrimaryColor(newValue1);
  };
  const handleSecondaryChange = (newValue1: React.SetStateAction<string>) => {
    setSecondaryColor(newValue1);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(event.target.value);
  };

  const handleFileChange = (new_file: File | null) => {
    setLogoFile(new_file);
  };

  const handleCompanyDetailsUpdate = async () => {
    setUpdateLoading(true);
    try {
      if (group && company) {
        const old_company_object: Company = {
          id: company.id,
          deleted: null,
          name: company.name,
          active: true,
          configuration: {
            ...company.configuration,
            primary_color: company.configuration.primary_color,
            secondary_color: company.configuration.secondary_color
          }
        };
        const new_company_object: Company = {
          id: company.id,
          deleted: null,
          name: companyName,
          active: true,
          configuration: {
            ...company.configuration,
            primary_color: primaryColor,
            secondary_color: secondaryColor
          }
        };
        const file_path = `${group.id}/${company.id}/${'configuration'}`;
        if (logoFile) {
          const file_name = `logo-${company.name.toLowerCase().replace(/\s/g, '_')}`;
          const file_upload = generateFileUploadRequest(
            logoFile,
            file_name,
            atob(GCP_FILE_UPLOAD_BUCKET),
            file_path,
            'true'
          );

          await axios({
            method: 'post',
            url: `${API_ENDPOINT}/remove_file`,
            data: { file_path: `${file_path}/${file_name}` }
          }).catch((err: unknown) => {
            const tracking_id: string = uuidv4();
            log(
              'error',
              new MetadataError(
                err instanceof Error
                  ? err.message
                  : 'Error: PanelPersonalisation failed on an unknown error while calling handleCompanyDetailsUpdate.',
                {
                  new_company_object: new_company_object,
                  file_upload: file_upload
                },
                tracking_id
              )
            );
            setFeedbackData({
              message: `Unable to remove logo. Tracking ID: ${tracking_id}`,
              state: true,
              type: 'error'
            });
          });

          await axios({
            method: 'post',
            url: `${API_ENDPOINT}/upload_file`,
            data: file_upload
          })
            .then((uploaded_file: AxiosResponse) => {
              new_company_object.configuration.logo = uploaded_file.data.url;
            })
            .catch((err: unknown) => {
              const tracking_id: string = uuidv4();
              log(
                'error',
                new MetadataError(
                  err instanceof Error
                    ? err.message
                    : 'Error: PanelPersonalisation failed on an unknown error while calling handleCompanyDetailsUpdate.',
                  {
                    new_company_object: new_company_object,
                    file_upload: file_upload
                  },
                  tracking_id
                )
              );
              setFeedbackData({
                message: `Unable to upload logo. Tracking ID: ${tracking_id}`,
                state: true,
                type: 'error'
              });
            });
        }
        await updateCompany(group.id, new_company_object, old_company_object).catch((error) => {
          throw new Error(error);
        });
        setFeedbackData({
          message: `${companyName} updated, please refresh page for changes to take effect`,
          state: true,
          type: 'success'
        });
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelPersonalisation failed on an unknown error while calling handleCompanyDetailsUpdate.',
          {
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update company. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setUpdateLoading(false);
      setLogoFile(null);
    }
  };

  React.useEffect(() => {
    try {
      if (group && company) {
        setCompanyName(company.name);
        setPrimaryColor(company.configuration.primary_color);
        setSecondaryColor(company.configuration.secondary_color);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelPersonalisation failed on an unknown error while initialising.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `An error occurred. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  }, [group, company]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography align="left" paddingBottom={'10px'}>
          Name
        </Typography>
        <TextField
          error={invalidCompanyName}
          id="standard-basic"
          variant="standard"
          value={companyName}
          onChange={handleNameChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <Typography color="inherit" align="left" paddingBottom={'10px'} component="div">
          Primary Color
        </Typography>
        <MuiColorInput value={primaryColor} format="hex" fullWidth onChange={handlePrimaryChange} />
      </Grid>
      <Grid item xs={4}>
        <Typography color="inherit" align="left" paddingBottom={'10px'} component="div">
          Secondary Color
        </Typography>
        <MuiColorInput
          value={secondaryColor}
          format="hex"
          fullWidth
          onChange={handleSecondaryChange}
        />
      </Grid>
      <Grid item xs={4}>
        <Typography color="inherit" align="left" paddingBottom={'10px'} component="div">
          Logo
        </Typography>
        <MuiFileInput label={'Logo'} value={logoFile} onChange={handleFileChange} />
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <LoadingButton
          disabled={invalidCompanyName}
          loading={updateLoading}
          variant="contained"
          onClick={handleCompanyDetailsUpdate}
          sx={{ minWidth: '150px' }}
        >
          Update
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default PanelPersonalisation;
