import React from 'react';
import { Box } from '@mui/material';
import 'typeface-montserrat';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';

const AccessDenied = () => {
  const current_url = useLocation().pathname;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 8
        }}
      >
        <Typography fontWeight={600} fontSize={50} letterSpacing={10}>
          Access Denied
        </Typography>
        <Typography sx={{ mt: 4, fontSize: 20 }}>
          {`You don't have the required permissions to view ${current_url}.`}
        </Typography>
        <Typography sx={{ mt: -2, fontSize: 20 }}>
          {`Contact an Admin user of Energy Partners`}
        </Typography>
      </Box>
    </>
  );
};

export default AccessDenied;
