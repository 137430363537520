import * as React from 'react';
import Box from '@mui/material/Box';
import Alert, { AlertColor } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

interface ActionFunc {
  (): void;
}

const AlertBanner = ({
  severity,
  message,
  open,
  width_perc,
  icon,
  action
}: {
  severity: AlertColor;
  message: string | JSX.Element;
  open: boolean;
  width_perc?: number;
  icon?: React.ReactNode;
  action?: ActionFunc;
}) => {
  return (
    <Box sx={{ width: `${width_perc}%` }}>
      <Collapse in={open}>
        <Alert
          icon={icon}
          severity={severity}
          action={
            action ? (
              <IconButton aria-label="close" color="inherit" size="small" onClick={action}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            ) : null
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
  );
};

export default AlertBanner;
