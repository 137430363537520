import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, DialogActions } from '@mui/material';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { LoadingButton } from '@mui/lab';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import AlertBanner from '../banner/AlertBanner';

const RestoreConfirmModal = ({
  open,
  restore_label,
  restore_explainer,
  handleCloseRestoreModal,
  handleRestore
}: {
  open: boolean;
  restore_label: string | null;
  restore_explainer: string;
  handleCloseRestoreModal: () => void;
  handleRestore: (() => void) | null;
}) => {
  const [restoreLoading, setRestoreLoading] = React.useState(false);
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);

  const handleRestoreClick = async () => {
    setRestoreLoading(true);
    try {
      if (handleRestore) {
        await handleRestore();
      }
      handleCloseRestoreModal();
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: RestoreConfirmModal failed on an unknown error while calling handleRestoreClick. d',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to restore ${restore_label}. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setRestoreLoading(false);
    }
    return;
  };

  return (
    <Dialog open={open} onClose={handleCloseRestoreModal}>
      <DialogTitle
        sx={{ mb: 2 }}
      >{`Are you sure you want to Restore ${restore_label}`}</DialogTitle>
      <DialogContent>
        <AlertBanner severity="info" message={restore_explainer} open={true} />
        <DialogActions sx={{ my: 2 }}>
          <Button variant="text" size="medium" onClick={handleCloseRestoreModal}>
            Cancel
          </Button>
          <LoadingButton
            loading={restoreLoading}
            startIcon={<UnarchiveIcon />}
            onClick={async () => {
              await handleRestoreClick();
            }}
            variant="contained"
            autoFocus
          >
            Restore
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default RestoreConfirmModal;
