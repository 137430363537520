import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';

interface CheckboxListItem {
  id: string;
  name?: string;
}

const FilterCheckboxDropdown = ({
  input_items,
  selected_items,
  label,
  show_all,
  handleSelectionToParent,
  processSelectedCount
}: {
  input_items: Array<CheckboxListItem>;
  selected_items: Array<CheckboxListItem>;
  label: string;
  show_all?: boolean;
  handleSelectionToParent: (items: Array<string>) => void;
  processSelectedCount: (items: CheckboxListItem[]) => number | 'All';
}) => {
  const [searchText, setSearchText] = React.useState<string>('');

  const handleChange = (ids_value: Array<string> | string): void => {
    if (ids_value[ids_value.length - 1] === 'all') {
      handleSelectionToParent(
        selected_items.length === input_items.length ? [] : input_items.map((item) => item.id)
      );
      return;
    }
    const selected_ids: Array<string> =
      typeof ids_value === 'string' ? ids_value.split(',') : ids_value;
    handleSelectionToParent(selected_ids);
  };

  return (
    <Box>
      <FormControl fullWidth sx={{ my: 1 }}>
        <Select
          id={`${label}-filter`}
          multiple
          displayEmpty
          MenuProps={{ autoFocus: false }}
          value={selected_items.map((item) => item.id)}
          renderValue={() => (
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography textAlign="left" color="rgba(0, 0, 0, 0.54)">
                  {label}
                </Typography>
              </Grid>
              {!(show_all === false && processSelectedCount(selected_items) === 'All') && (
                <Grid item xs={5}>
                  <Typography
                    sx={{
                      backgroundColor: '#E7E8E9',
                      color: '#454545',
                      borderRadius: 3,
                      py: 0.2,
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                      maxWidth: '60px',
                      ml: 'auto'
                    }}
                  >
                    {processSelectedCount(selected_items)}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
          variant="standard"
          fullWidth
          onChange={(event: SelectChangeEvent<Array<string>>) => handleChange(event.target.value)}
          onClose={() => setSearchText('')}
        >
          <ListSubheader>
            <TextField
              size="small"
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              onChange={(event) => setSearchText(event.target.value)}
              onKeyDown={(event) => {
                if (event.key !== 'Escape') {
                  event.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          <MenuItem value="all">
            <Checkbox checked={selected_items.length === input_items.length} />
            <ListItemText primary={'Select All'} />
          </MenuItem>
          {input_items
            .filter((item) => {
              return item.name && item.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
            })
            .map((item) => (
              <MenuItem key={item.id} value={item.id}>
                <Checkbox
                  checked={
                    selected_items.findIndex(
                      (selected_item: CheckboxListItem) => selected_item.id === item.id
                    ) >= 0
                  }
                />
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FilterCheckboxDropdown;
