import { Box } from '@mui/material';
import React from 'react';
import ConfigurationWrapper from '../../components/shared/ConfigurationWrapper';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import TuneIcon from '@mui/icons-material/Tune';
import PanelMetrics from '../../components/configuration/metrics/PanelMetrics';

const ConfigurationMetrics = () => {
  return (
    <Box>
      <ConfigurationWrapper
        title="Metrics"
        icon={<AssessmentOutlinedIcon fontSize="large" />}
        previous={{
          route: '/configuration/standards',
          label: 'Standards',
          icon: <ChecklistRtlIcon />
        }}
        next={{
          route: '/configuration/other-configuration',
          label: 'Other Configuration',
          icon: <TuneIcon />
        }}
      >
        <PanelMetrics />
      </ConfigurationWrapper>
    </Box>
  );
};

export default ConfigurationMetrics;
