import { Box } from '@mui/material';
import React from 'react';
import ConfigurationWrapper from '../../components/shared/ConfigurationWrapper';
import TuneIcon from '@mui/icons-material/Tune';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import PanelOtherConfiguration from '../../components/configuration/other_configuration/PanelOtherConfiguration';

const ConfigurationOtherConfiguration = () => {
  return (
    <Box>
      <ConfigurationWrapper
        title="Other Configuration"
        icon={<TuneIcon fontSize="large" />}
        previous={{
          route: '/configuration/metrics',
          label: 'Metrics',
          icon: <AssessmentOutlinedIcon />
        }}
        next={null}
      >
        <PanelOtherConfiguration />
      </ConfigurationWrapper>
    </Box>
  );
};

export default ConfigurationOtherConfiguration;
