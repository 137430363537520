import { Box, LinearProgress } from '@mui/material';
import React from 'react';

const LoadingScreen = () => {
  return (
    <Box
      sx={{ minHeight: '85vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <LinearProgress sx={{ width: '50%' }} />
    </Box>
  );
};

export default LoadingScreen;
