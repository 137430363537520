import * as React from 'react';
import { UserContext } from '../../context/UserContext';
import { User } from '@esg/esg-global-types';
import AccessDenied from '../shared/AccessDenied';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { getAdminGroups } from '../../util/user_access';
import PanelGroups from '../../components/group_management/groups/PanelGroups';

const GroupManagement = () => {
  const user_info: User | null = React.useContext(UserContext);
  return user_info === null ? (
    <LoadingScreen />
  ) : user_info.super_admin || getAdminGroups(user_info).length > 0 ? (
    <PanelGroups />
  ) : (
    <AccessDenied />
  );
};

export default GroupManagement;
