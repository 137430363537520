import { Box } from '@mui/material';
import React from 'react';
import ConfigurationWrapper from '../../components/shared/ConfigurationWrapper';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import PanelReportingPeriodGroups from '../../components/configuration/reporting_period_groups/PanelReportingPeriodGroups';

const ConfigurationReportingPeriodGroups = () => {
  return (
    <Box>
      <ConfigurationWrapper
        title="Reporting Period Groups"
        icon={<CalendarMonthOutlinedIcon fontSize="large" />}
        previous={{
          route: '/configuration/reporting-periods',
          label: 'Reporting Periods',
          icon: <AccessAlarmIcon />
        }}
        next={{
          route: '/configuration/standards',
          label: 'Standards',
          icon: <ChecklistRtlIcon />
        }}
      >
        <PanelReportingPeriodGroups />
      </ConfigurationWrapper>
    </Box>
  );
};

export default ConfigurationReportingPeriodGroups;
