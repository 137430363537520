import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

const DefaultContent = () => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Skeleton variant="rounded" height={300} />
        </Grid>
        <Grid item xs={1}>
          <Skeleton variant="rounded" height={60} />
        </Grid>
        <Grid item xs={11}>
          <Skeleton variant="rounded" height={60} />
        </Grid>
        <Grid item xs={8}>
          <Skeleton variant="rectangular" height={20} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" height={20} />
        </Grid>
        <Grid item xs={8}>
          <Skeleton variant="rectangular" height={20} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" height={20} />
        </Grid>
        <Grid item xs={8}>
          <Skeleton variant="rectangular" height={20} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" height={20} />
        </Grid>
      </Grid>
    </>
  );
};

export default DefaultContent;
