import {
  Company,
  ExternalCompany,
  Group,
  Module,
  Role,
  Standard,
  User
} from '@esg/esg-global-types';
import {
  Box,
  Collapse,
  Divider,
  FormControl,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Typography
} from '@mui/material';
import React from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BlockIcon from '@mui/icons-material/Block';
import FilterCheckboxDropdown from '../../shared/input/checkbox/FilterCheckboxDropdown';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { MetricExtended, getMetrics } from '../../../lib/metric_capture/metric';
import { getSitesJoinParents } from '../../../lib/metric_capture/site';
import { getExternalCompanies } from '../../../lib/app/external_company';
import AlertBanner from '../../shared/banner/AlertBanner';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import GroupedFilterCheckboxDropdown from '../../shared/input/checkbox/GroupedFilterCheckboxDropdown';
import { SiteExtended } from '../../../lib/metric_capture/site';
import { getStandards } from '../../../lib/app/standard';
import { DocumentReference } from 'firebase/firestore';

interface StandardNameMap {
  [key: string]: string;
}

/**
 * A panel to allow users to update the company level permissions for a user from the parent flow
 * @param {User} user User from parent flow being updated
 * @param {Group} chosen_group Group to have user permissions updated for
 * @param {Company} chosen_company Company to have user permissions updated for
 * @param {Array<Role>} roles List of company roles updated user can have
 * @param {Array<Module>} modules List of portal modules updated user can have permission assigned to
 * @param {void} handleUserChange Handler function for updating user data in parent
 * @returns {JSX.Element}
 */
const CompanyAccessConfig = ({
  user,
  chosen_group,
  chosen_company,
  roles,
  modules,
  handleUserChange
}: {
  user: User;
  chosen_group: Group;
  chosen_company: Company;
  roles: Array<Role>;
  modules: Array<Module>;
  handleUserChange: (user_data: User) => void;
}) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [metrics, setMetrics] = React.useState<Array<MetricExtended>>([]);
  const [sites, setSites] = React.useState<Array<SiteExtended>>([]);
  const [externalCompanies, setExternalCompanies] = React.useState<Array<ExternalCompany>>([]);
  const [expandedModule, setExpandedModule] = React.useState<string>('');
  const [entitiesLoading, setEntitiesLoading] = React.useState<boolean>(false);

  const view_access_options = [
    { value: 'write', label: 'Write' },
    { value: 'read', label: 'Read-Only' },
    { value: 'none', label: 'None' }
  ];
  const company_permissions =
    user.groups?.[chosen_group.id]?.companies?.[chosen_company.id]?.permissions ?? [];

  const metric_permissions = company_permissions.filter((permission) =>
    permission.startsWith('metric.')
  );
  const site_permissions = company_permissions.filter((permission) =>
    permission.startsWith('site.')
  );
  const external_company_permissions = company_permissions.filter((permission) =>
    permission.startsWith('external_company.')
  );

  const handleChangeCompanyRole = (group_id: string, company_id: string, role: string) => {
    if (role === 'none') {
      const new_user_data = { ...user };
      if (new_user_data.groups?.[group_id]?.companies?.[company_id]) {
        delete new_user_data.groups[group_id].companies?.[company_id];
        handleUserChange(new_user_data);
      }
    } else {
      handleUserChange({
        ...user,
        groups: {
          ...user.groups,
          [group_id]: {
            ...user.groups?.[group_id],
            companies: {
              ...user.groups?.[group_id]?.companies,
              [company_id]:
                role === 'admin'
                  ? { role: 'admin' }
                  : {
                      ...user.groups?.[group_id]?.companies?.[company_id],
                      role: role
                    }
            }
          }
        }
      });
    }
  };

  const getModulePermission = (module: string, view: string) => {
    return (user.groups?.[chosen_group.id]?.companies?.[chosen_company.id]?.permissions ?? [])
      .find((permission) => permission.startsWith(`${module}.${view}`))
      ?.split('.')[2];
  };

  const handleChangeModulePermissions = (module: string, view: string, access: string) => {
    const new_permissions = company_permissions.filter(
      (permission) => !permission.startsWith(`${module}.${view}`)
    );
    if (access !== 'none') {
      new_permissions.push(`${module}.${view}.${access}`);
    }
    handleUserChange({
      ...user,
      groups: {
        ...user.groups,
        [chosen_group.id]: {
          ...user.groups[chosen_group.id],
          companies: {
            ...(user.groups?.[chosen_group.id]?.companies ?? {}),
            [chosen_company.id]:
              user.groups?.[chosen_group.id]?.companies?.[chosen_company.id] !== undefined
                ? {
                    ...user.groups[chosen_group.id].companies![chosen_company.id],
                    permissions: new_permissions
                  }
                : {
                    role: 'basic',
                    permissions: new_permissions
                  }
          }
        }
      }
    });
  };

  const handleChangeEntityPermissions = (permissions: Array<string>, permission_entity: string) => {
    const new_permissions = permissions.map((id) => `${permission_entity}.${id}`);
    const unrelated_permissions =
      company_permissions.filter((permission) => !permission.startsWith(`${permission_entity}.`)) ??
      [];
    handleUserChange({
      ...user,
      groups: {
        ...user.groups,
        [chosen_group.id]: {
          ...user.groups[chosen_group.id],
          companies: {
            ...(user.groups?.[chosen_group.id]?.companies ?? {}),
            [chosen_company.id]:
              user.groups?.[chosen_group.id]?.companies?.[chosen_company.id] !== undefined
                ? {
                    ...user.groups[chosen_group.id].companies![chosen_company.id],
                    permissions: [...unrelated_permissions, ...new_permissions]
                  }
                : {
                    role: 'basic',
                    permissions: [...unrelated_permissions, ...new_permissions]
                  }
          }
        }
      }
    });
  };

  const fetchEntities = async () => {
    setEntitiesLoading(true);
    try {
      const [metrics, sites, external_companies, standards]: [
        Array<MetricExtended & { standard_name?: string }>,
        Array<SiteExtended>,
        Array<ExternalCompany>,
        Array<Standard>
      ] = await Promise.all([
        getMetrics(false, chosen_group.id, chosen_company.id),
        getSitesJoinParents(chosen_group.id, chosen_company.id),
        getExternalCompanies(chosen_group.id, chosen_company.id),
        getStandards(chosen_group.id, chosen_company.id)
      ]);
      const ref_name_standard_map: StandardNameMap = {};
      for (const standard of standards) {
        ref_name_standard_map[standard.reference.path] = standard.name;
      }
      metrics.map((metric, index) => {
        const standard_string: string =
          ref_name_standard_map[metric.standard ? (metric.standard as DocumentReference).path : ''];
        metrics[index] = {
          ...metrics[index],
          standard_name: standard_string
        };
      });
      setMetrics(metrics);
      setSites(sites);
      setExternalCompanies(external_companies);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: CompanyAccessConfig failed on an unknown error while calling fetchEntities.',
          {
            chosen_group: chosen_group,
            chosen_company: chosen_company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch metrics and sites for ${chosen_company.name}. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setEntitiesLoading(false);
    }
  };

  React.useEffect(() => {
    try {
      (async () => {
        await fetchEntities().catch((error) => {
          throw new Error(error);
        });
      })().catch((error) => {
        throw new Error(error);
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: CompanyAccessConfig failed on an unknown error while initializing.',
          {
            chosen_group: chosen_group,
            chosen_company: chosen_company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch metrics and sites for ${chosen_company.name}. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  }, []);

  return (
    <Box>
      <Typography sx={{ fontSize: '1.4rem', letterSpacing: 2.5, mt: 6, mx: 4 }}>
        Configure {chosen_company ? chosen_company.name : 'Company'} Access for{' '}
        {user.first_name ? user.first_name : 'User'}
      </Typography>
      <Box sx={{ m: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, mb: 4 }}>
              <Typography sx={{ flexBasis: '10%' }}>Access</Typography>
              <FormControl>
                <Select
                  value={
                    user.groups?.[chosen_group.id]?.companies?.[chosen_company.id]?.role ?? 'none'
                  }
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={(event) =>
                    handleChangeCompanyRole(chosen_group.id, chosen_company.id, event.target.value)
                  }
                  sx={{ minWidth: 200 }}
                >
                  {[...roles, { id: 'none', deleted: null, name: 'None' }].map((role: Role) => {
                    return (
                      <MenuItem key={role.id} value={role.id}>
                        {role.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          {user.groups[chosen_group.id]?.companies?.[chosen_company.id]?.role === 'admin' && (
            <Box sx={{ my: 4 }}>
              <AlertBanner
                severity="info"
                open
                message={`${user.email} has Admin Access for ${chosen_company.name} and has full permissions for all Modules, Sites, Metrics and External Companies`}
              />
            </Box>
          )}
          {user.groups[chosen_group.id]?.companies?.[chosen_company.id] === undefined && (
            <Box sx={{ my: 4 }}>
              <AlertBanner
                severity="info"
                open
                message={`${user.email} has No Access for ${chosen_company.name}, set access level above to configure`}
              />
            </Box>
          )}
          {user.groups[chosen_group.id]?.companies?.[chosen_company.id]?.role === 'basic' && (
            <>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: '1.2rem', my: 2 }}>Module Permissions</Typography>
                <Divider />
                <List sx={{ width: '100%' }} component="nav">
                  {modules.map((module, index) => {
                    return (
                      <React.Fragment key={`module_${index}`}>
                        <ListItemButton
                          onClick={() =>
                            setExpandedModule(expandedModule === module.id ? '' : module.id)
                          }
                        >
                          <ListItemText primary={module.name} />
                          <Typography
                            sx={{
                              backgroundColor: '#E7E8E9',
                              color: '#454545',
                              borderRadius: 3,
                              px: 2,
                              mr: 4,
                              whiteSpace: 'nowrap',
                              textAlign: 'center',
                              width: '5%'
                            }}
                          >
                            {
                              company_permissions.filter((permission) =>
                                permission.startsWith(`${module.id}`)
                              ).length
                            }
                          </Typography>
                          {expandedModule === module.id ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={expandedModule === module.id} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {module.views?.map((view: string) => {
                              return (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    mb: 1,
                                    alignItems: 'center',
                                    pl: 4,
                                    width: '80%'
                                  }}
                                  key={view}
                                >
                                  <Typography fontSize={'0.9rem'}>
                                    {view.charAt(0).toUpperCase() + view.slice(1).toLowerCase()}
                                  </Typography>
                                  <FormControl sx={{ pl: 2, ml: 'auto' }}>
                                    <Select
                                      value={getModulePermission(module.id, view) ?? 'none'}
                                      displayEmpty
                                      inputProps={{ 'aria-label': 'Without label' }}
                                      renderValue={(value) => {
                                        const display_value = (
                                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {value === 'write' && <EditIcon />}
                                            {value === 'read' && <VisibilityIcon />}
                                            {value === 'none' && <BlockIcon />}
                                          </Box>
                                        );
                                        return display_value;
                                      }}
                                      onChange={(event) =>
                                        handleChangeModulePermissions(
                                          module.id,
                                          view,
                                          event.target.value
                                        )
                                      }
                                      disableUnderline
                                      variant="standard"
                                    >
                                      {view_access_options.map((access) => {
                                        return (
                                          <MenuItem key={access.value} value={access.value}>
                                            {access.label}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </Box>
                              );
                            })}
                          </List>
                        </Collapse>
                        <Divider />
                      </React.Fragment>
                    );
                  })}
                </List>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: '1.2rem', my: 2, textAlign: 'end' }}>
                  Entity Access
                </Typography>
                <Divider sx={{ mb: 1 }} />
                <Box sx={{ pl: 2 }}>
                  {entitiesLoading ? (
                    <Skeleton height={250} />
                  ) : (
                    <>
                      <Box sx={{ mb: 4 }}>
                        <GroupedFilterCheckboxDropdown
                          input_items={metrics}
                          group_by="standard_name"
                          item_prefix="metric_group.name"
                          selected_items={metric_permissions.map((permission) => {
                            return { id: permission.split('.')[1] };
                          })}
                          label="Metrics"
                          handleSelectionToParent={(metric_ids) =>
                            handleChangeEntityPermissions(metric_ids, 'metric')
                          }
                          processSelectedCount={(metric_ids) =>
                            metric_ids.length === metrics.length ? 'All' : metric_ids.length
                          }
                        />
                      </Box>
                      <Box sx={{ mb: 4 }}>
                        <GroupedFilterCheckboxDropdown
                          input_items={sites}
                          group_by="division_name"
                          selected_items={site_permissions.map((permission) => {
                            return { id: permission.split('.')[1] };
                          })}
                          label="Sites"
                          handleSelectionToParent={(site_ids) =>
                            handleChangeEntityPermissions(site_ids, 'site')
                          }
                          processSelectedCount={(site_ids) =>
                            site_ids.length === sites.length ? 'All' : site_ids.length
                          }
                        />
                      </Box>
                      {(user.type === 'any' || user.type === 'external') && (
                        <Box sx={{ mb: 4 }}>
                          <FilterCheckboxDropdown
                            input_items={externalCompanies}
                            selected_items={external_company_permissions.map((permission) => {
                              return { id: permission.split('.')[1] };
                            })}
                            label="External Companies"
                            handleSelectionToParent={(external_company_ids) =>
                              handleChangeEntityPermissions(
                                external_company_ids,
                                'external_company'
                              )
                            }
                            processSelectedCount={(external_company_ids) =>
                              external_company_ids.length === externalCompanies.length
                                ? 'All'
                                : external_company_ids.length
                            }
                          />
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default CompanyAccessConfig;
