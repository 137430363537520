import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { GridRowModes, GridRowModesModel } from '@mui/x-data-grid';
import { QuantitativeMetricRecordGridRow } from './MetricRecordGridQuantitative';

interface EditAverageRecordModalProps {
  open: boolean;
  action_row: QuantitativeMetricRecordGridRow;
  row_modes_model: GridRowModesModel;
  message: string;
  handleCloseEditModal: () => void;
  setRowModesModel: (GridRowModesModel: GridRowModesModel) => void;
}

export default function EditAverageRecordModal(props: EditAverageRecordModalProps) {
  const handleConfirm = () => {
    props.setRowModesModel({
      ...props.row_modes_model,
      [props.action_row.id]: { mode: GridRowModes.Edit }
    });
    props.handleCloseEditModal();
  };

  const handleCancel = () => {
    props.handleCloseEditModal();
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={handleCancel}>
        <DialogTitle>Confirm Edit Averaged Value</DialogTitle>
        <DialogContent>
          <DialogContentText textAlign={'justify'}>{props.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="medium" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="contained" size="medium" onClick={handleConfirm} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
